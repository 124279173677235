import React, { Component } from "react";
import PropTypes from "prop-types";
import D3HistogramSlider from "./d3HistogramSlider";

class HistogramSlider extends Component {
  static propTypes = {
    elementId: PropTypes.string,
    name: PropTypes.string,
    unit: PropTypes.string,
    // colorGradientDefinition: PropTypes.array, //[{color:String, value:Float, }] array sorted by value
    filterThr: PropTypes.object, // {min:Int, max:Int}
    valuesArr: PropTypes.array, // array of object containing values used to compute histogram : value is the default field otherwise add getValue accessor method in controllerMethods
    valueAccessor: PropTypes.func, // access values in valuesArr
    colorScaleProperties: PropTypes.object, // {values:[],colors:[],legendLabels:[],gradient:boolean} array (length max 2) with diff categorical values to compute 2 histograms
    // histoData: PropTypes.array, // [name:String, value:Int]
    syncSymetricRect: PropTypes.bool, // synchronized symetric rectangle for slider
    scaleDomain: PropTypes.object, // {min,max} // the forced domain scale (if not exist, computed from the data)
    scaleDomainExtPerc: PropTypes.number, // OPTIONAL: percentage used to extend min and max values of axis (to avoid drawing shapes on axis)
    brushExtent: PropTypes.object, // {min,max} in domain scale
    resizeTimestamp: PropTypes.number, // timestamp when resize is triggered saying the component needs to check for resize
    controllerMethods: PropTypes.func, // setFilterThreshold(), applyFilterThreshold(), optionaly getValue() accessor (overrided by valueAccessor if exists)
  };
  _initControllerMethods() {
    let controller = this.props.controllerMethods();
    if (controller === undefined) {
      controller = {};
    }
    if (this.props.valueAccessor) {
      controller.getValue = this.props.valueAccessor;
    } else if (!controller.getValue) {
      controller.getValue = (d) => {
        return d.value;
      };
    }
    return controller;
  }
  componentDidMount() {
    console.log("[HistogramSlider:life-cycle] did mount...");
    this.size = this.getChartSize();

    const config = {
      size: this.size,
      elementId: this.props.elementId,
      name: this.props.name,
      unit: this.props.unit,
      syncSymetricRect: this.props.syncSymetricRect,
    };
    const state = {
      data: this.props.valuesArr,
      filterThr: this.props.filterThr,
      colorScaleProperties: this.props.colorScaleProperties,
      scaleDomain: this.props.scaleDomain,
      scaleDomainExtPerc: this.props.scaleDomainExtPerc,
      brushExtent: this.props.brushExtent,
    };
    const controller = this._initControllerMethods();

    this.d3HistogramSlider = new D3HistogramSlider(this._rootNode);

    this._chart = this.d3HistogramSlider.create(config, state, controller);
    this._chart = this.d3HistogramSlider.update(
      config,
      state,
      controller,
      this._chart
    );
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const msg = [];
    msg.push("[HistogramSlider:life-cycle] should update?");
    let res = false;
    let shouldLog = false;
    Object.keys(this.props).forEach((key) => {
      if (this.props[key] !== nextProps[key]) {
        if (key === "valuesArr") {
          msg.push(
            "\tthis.props." +
              key +
              " changes " +
              this.props[key]?.length +
              "=>" +
              nextProps[key]?.length
          );
          shouldLog = true;
        } else if (key !== "filterThr") {
          // do not log for filterThr (due to dragging)
          msg.push(
            "\tthis.props." +
              key +
              " changes " +
              this.props[key] +
              "=>" +
              nextProps[key]
          );
          shouldLog = true;
        }
        res = true;
      }
    });
    if (shouldLog) {
      msg.forEach((d) => {
        console.log(d);
      });
    }

    const newSize = this.getChartSize();
    res =
      res ||
      this.size.height !== newSize.height ||
      this.size.width !== newSize.width;

    return res;
  }

  componentDidUpdate(prevProps) {
    let shouldLogMsg = true;
    const msg = [];
    msg.push("[HistogramSlider:life-cycle] did update...");

    const newSize = this.getChartSize();

    const config = {
      size: newSize,
      elementId: this.props.elementId,
      name: this.props.name,
      unit: this.props.unit,
      syncSymetricRect: this.props.syncSymetricRect,
    };
    const state = {
      data: this.props.valuesArr,
      filterThr: this.props.filterThr,
      colorScaleProperties: this.props.colorScaleProperties,
      scaleDomain: this.props.scaleDomain,
      scaleDomainExtPerc: this.props.scaleDomainExtPerc,
      brushExtent: this.props.brushExtent,
    };
    const controller = this._initControllerMethods();

    // check which data changed:
    if (
      newSize.height !== this.size.height ||
      newSize.width !== this.size.width
    ) {
      // use timeout to avoid rendering during resize
      this.size = newSize;
      clearTimeout(this.resizeend);
      this.resizeend = setTimeout(() => {
        msg.push("\tfor resize...");
        this.d3HistogramSlider.resize(config, state, controller);
      }, 200);
    } else if (prevProps.valuesArr !== this.props.valuesArr) {
      msg.push("\tfor valuesArr property...");
      this.d3HistogramSlider.update(config, state, controller, this._chart);
    } else if (prevProps.filterThr !== this.props.filterThr) {
      // console.log("for filterThr property...");
      this.d3HistogramSlider.updateSlider(this.props.filterThr);
      shouldLogMsg = false;
    } else {
      if (
        prevProps.valueAccessor !== this.props.valueAccessor ||
        prevProps.scaleDomain !== this.props.scaleDomain
      ) {
        // TODO: animation to change yAxis + histogram bars
        // this.d3Slider.update()
        msg.push("\tvalueAccessor changed...");
        this.d3HistogramSlider.updateYData(state, controller);
      }
      if (prevProps.unit !== this.props.unit) {
        this.d3HistogramSlider.updateUnit(this.props.unit);
      }
    }
    if (shouldLogMsg) {
      msg.forEach((d) => {
        console.log(d);
      });
    }
  }
  componentWillUnmount() {
    console.log("[HistogramSlider:life-cycle] will unmount...");
    this.d3HistogramSlider.destroy(this._rootNode);
  }

  getChartSize() {
    // return this.props.size;
    let width; // = 800;
    let height; // = 100;
    if (this._rootNode !== undefined) {
      width = this._rootNode.offsetWidth;
      // width = '100%';
      height = this._rootNode.offsetHeight;
      // height = '100%';
      // const styleHeight = this._rootNode.style.height;
      // const clientHeight = this._rootNode.clientHeight;
      // const scrollHeight = this._rootNode.scrollHeight;
      // // console.log(this.props.elementId+"=> styleHeight:"+styleHeight+" clientHeight:"+clientHeight+" scrollHeight:"+scrollHeight+" offsetHeight:"+height)
      // const styleWidth = this._rootNode.style.width;
      // const clientWidth = this._rootNode.clientWidth;
      // const scrollWidth = this._rootNode.scrollWidth;
      // // console.log(this.props.elementId+"=> styleWidth:"+styleWidth+" clientWidth:"+clientWidth+" scrollWidth:"+scrollWidth+" offsetWidth:"+width)
    }
    return { width: width, height: height };
  }

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }
  render() {
    const divStyle = { height: "100%" };
    return (
      <div
        className={this.props.elementId + "-slider-container"}
        ref={this._setRef.bind(this)}
        style={divStyle}
      ></div>
    );
  }
}
export default HistogramSlider;
