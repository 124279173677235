import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";

// lodash
import isEqual from "lodash/isEqual";

import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@material-ui/core";

import { withStyles, alpha } from "@material-ui/core/styles";

import Header from "./DataTableHeader";
import Toolbar from "./DataTableToolbar";
import Filter from "./DataTableFilter";

import { setHighlight } from "../../features/chart";

import setup from "../../utils/setup";
const { debug } = setup();

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  let stabilized = [];
  // if (array&&array.length>0){
  stabilized = array.map((element, index) => [element, index]);
  // }
  stabilized.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });
  return stabilized.map((el) => el[0]);
}

function search(
  value,
  data,
  columns = [],
  options = {
    caseSensitive: false,
    wholeWord: false,
  }
) {
  if (value === "") return;

  let initialValue = value;
  // return this.setState((state) => ({ ...state, page: 0, value: "", data: props.data }))

  let flags = "g";
  let regex;

  value = value.replace(/(\(|\)|\]|\[|\]|\.|\\|\?|\^|\$|\+|\*)/gm, "\\$1");
  if (!options.caseSensitive) flags += "i";
  if (options.wholeWord) value = `\\b${value}\\b`; // old version: `^${value}$`

  regex = new RegExp(value, flags);
  const result = data.filter((d) => {
    // For every single property of the array. If one does match the search return true
    if (columns.length === 0)
      return Object.values(d).find((v) => {
        if (options.wholeWord && v.length !== initialValue.length) return false;
        return regex.test(v);
      });
    else {
      return columns.find((c) => {
        // stringify numbers
        if (options.wholeWord && ("" + d[c]).length !== initialValue.length)
          return false;
        return regex.test(d[c]);
      });
    }
  });

  return result;
}

const styles = (theme) => ({
  root: {
    // width: "100%"
    position: "relative",
  },
  table: {
    "& > thead tr th.MuiTableCell-sizeSmall, & > tbody tr td.MuiTableCell-sizeSmall":
      {
        padding: "6px 8px",
        whiteSpace: "nowrap",
      },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

const TableContainerWithStyle = withStyles(() => ({
  root: {
    maxHeight: 400,
    position: "relative",
  },
}))(TableContainer);

const TableRowWithStyle = withStyles((theme) => ({
  root: {
    "&$selected, &$selected:hover": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      ),
    },
  },
  selected: {},
}))(TableRow);

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 50,
      rowsPerPageOptions: [25, 50, 100],
      order: "asc",
      orderBy: "id",

      // local state doesn't impact the source dataset
      data: props.data,
      previous: [],
      value: "",

      caseSensitive: null,
      wholeWord: null,
      selectedRow: null,
      shouldDerived: true,

      // advance search (parent-element for the popper component)
      showFilters: false,
      filterPositionY: null,
    };

    this.onClickFilters = this.onClickFilters.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onRequestSort = this.onRequestSort.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);

    this.onRowClick = this.onRowClick.bind(this);
    this.translate = this.translate.bind(this);
  }

  /** events */
  onSearch(event) {
    let value = event.target.value;

    const result = search(value, this.props.data, this.props.searchColumns, {
      caseSensitive: this.props.caseSensitive,
      wholeWord: this.props.wholeWord,
    });

    if (!result)
      return this.setState((state) => ({
        ...state,
        value: "",
        data: this.props.data,
        shouldDerived: true,
      }));

    return this.setState((state) => ({
      ...state,
      page: 0,
      value: event.target.value,
      data: result,
      shouldDerived: false,
      caseSensitive: this.props.caseSensitive,
      wholeWord: this.props.wholeWord,
    }));
  }
  onRequestSort(event, property) {
    const order =
      this.state.orderBy === property && this.state.order === "asc"
        ? "desc"
        : "asc";
    debug &&
      console.log(
        `[${DataTable.name}:event] ${
          /*this.onRequestSort.name*/ "onRequestSort"
        }: ${property} ${order}`
      );
    this.setState((state) => ({ ...state, order, orderBy: property }));
  }
  onPageChange(event, page) {
    debug &&
      console.log(
        `[${DataTable.name}:event] ${
          /*this.onPageChange.name*/ "onPageChange"
        }: ${page}`
      );
    this.setState((state) => ({ ...state, page }));
  }
  onRowsPerPageChange(event) {
    const value = parseInt(event.target.value, 10);
    debug &&
      console.log(
        `[${DataTable.name}:event] ${
          /*this.onRowsPerPageChange.name*/ "onRowsPerPageChange"
        }: ${value}`
      );
    this.setState((state) => ({ ...state, page: 0, rowsPerPage: value }));
  }
  onClickFilters(event) {
    event.stopPropagation();
    this.setState((state) => ({ ...state, showFilters: !state.showFilters }));
  }

  /** events to dispatch */
  onRowClick(row) {
    let selectedRow = null;
    if (this.props.selectedRows?.length > 0) {
      selectedRow = this.props.selectedRows[0];
    }
    if (isEqual(row, selectedRow)) {
      // was this.state.selectedRow
      this.props.setHighlight([]);
      // this.setState((state) => ({ ...state, selectedRow: null }));
    } else {
      this.props.setHighlight(row);
      // this.setState((state) => ({ ...state, selectedRow: row }));
    }
  }

  /** life-cycle */
  static getDerivedStateFromProps(props, state) {
    debug &&
      console.log(`[${DataTable.name}:life-cycle] getDerivedStateFromProps`);

    // The following lines avoid the search function to be exectued twice.
    if (!state.shouldDerived) return { ...state, shouldDerived: true };

    if (
      /** if data changes */
      (!isEqual(state.data, props.data) &&
        !isEqual(state.previous, props.data)) ||
      !isEqual(state.searchColumns, props.searchColumns) ||
      /** if search options change */
      props.value !== state.value ||
      props.caseSensitive !== state.caseSensitive ||
      props.wholeWord !== state.wholeWord
    ) {
      const result = search(state.value, props.data, props.searchColumns, {
        caseSensitive: props.caseSensitive,
        wholeWord: props.wholeWord,
      });

      const page =
        props.caseSensitive !== state.caseSensitive ||
        props.wholeWord !== state.wholeWord
          ? 0
          : state.page;

      if (!!result)
        return {
          ...state,
          data: result,
          previous: props.data,
          searchColumns: props.searchColumns,
          page,
          caseSensitive: props.caseSensitive,
          wholeWord: props.wholeWord,
        };
    }
    return { ...state, data: props.data, previous: props.data };
  }
  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false;
    /** For optimization reasons, replace "shouldUpdate = true" by "return true" */

    if (!isEqual(nextProps.data, this.props.data)) return true;
    // shouldUpdate = true;

    if (!isEqual(nextProps.searchColumns, this.props.searchColumns))
      return true;
    // shouldUpdate = true;

    if (
      !shouldUpdate &&
      !isEqual(nextProps.selectedColumns, this.props.selectedColumns)
    )
      return true;
    // shouldUpdate = true;

    // if (
    //   !shouldUpdate &&
    //   !isEqual(nextState.selectedRow, this.state.selectedRow)
    // )
    //   return true;
    // // shouldUpdate = true;

    if (
      !shouldUpdate &&
      !isEqual(nextProps.selectedRows, this.props.selectedRows)
    )
      return true;
    // shouldUpdate = true;

    if (
      !shouldUpdate &&
      (nextProps.caseSensitive !== this.props.caseSensitive ||
        nextProps.wholeWord !== this.props.wholeWord)
    ) {
      return true;
      // shouldUpdate = true;
    }

    if (
      this.state.windowInnerHeight !== window.innerHeight ||
      this.state.windowInnerWidth !== window.innerWidth
    ) {
      // shouldUpdate = true;
      return true;
    }

    const comparators = [
      "value",
      "page",
      "rowsPerPage",
      "order",
      "orderBy",
      "anchorEl",
    ];
    if (!shouldUpdate)
      comparators.forEach((c) => {
        if (nextState[c] !== this.state[c]) {
          shouldUpdate = true;
          return;
        }
      });

    debug &&
      console.log(
        `[${DataTable.name}:life-cycle] ${this.shouldComponentUpdate.name}: ${shouldUpdate}`
      );
    return shouldUpdate;
  }
  componentDidUpdate(prevProps, prevState) {
    debug &&
      console.log(
        `[${DataTable.name}:life-cycle] ${this.componentDidUpdate.name}`
      );
  }
  componentDidMount() {
    window.addEventListener("resize", (e) => {
      e.stopPropagation();
      setTimeout(() => {
        this.setState((state) => ({
          ...state,
          filterPositionY: this.translate(),
        }));
      }, 100);
    });

    const settingsToolbar = document.getElementById("settings-toolbar");

    this.setState((state) => ({
      ...state,
      settingsToolbar,
      filterPositionY: this.translate(settingsToolbar),
    }));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {});
  }

  translate(element = null) {
    let elem = this.state.settingsToolbar;
    if (element !== null) elem = element;

    if (elem) {
      let h = elem.getBoundingClientRect().height;
      return `translateY(${h}px)`;
    }
    return `translateY(0px)`;
  }

  render() {
    const { classes, selectedColumns, selectedRows } = this.props;
    if (!selectedColumns[0].name) {
      console.log("issue with " + selectedColumns);
    }
    const {
      order,
      orderBy,
      rowsPerPage,
      page,
      data,
      rowsPerPageOptions,
      value,
      showFilters,
    } = this.state;
    let selectedRow = null;
    if (selectedRows?.length > 0) {
      // get the first row of selectedOnes
      // until multi selection is enabled in the table
      selectedRow = selectedRows[0];
    }

    const rows =
      rowsPerPage > 0
        ? stableSort(data, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : data;
    function isNumber(value) {
      if (undefined === value || null === value) {
        return false;
      }
      if (typeof value == "number") {
        return true;
      }
      return !isNaN(value - 0);
    }
    function isInteger(value) {
      if (undefined === value || null === value) {
        return false;
      }
      return value % 1 == 0; // eslint-disable-line
    }
    const roundIfNumerical = (value) => {
      if (isNumber(value) && !isInteger(value)) {
        return Math.round(value * 1000) / 1000;
      } else {
        return value;
      }
    };
    const checkSelectedRow = function (r) {
      return isEqual(r, selectedRow);
    };
    return (
      <Paper className={classes.root}>
        <Toolbar
          onSearch={this.onSearch}
          onClickFilters={this.onClickFilters}
          search={value}
          id="settings-toolbar"
        >
          <TablePagination
            component="div"
            page={page}
            count={data.length}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
          />
        </Toolbar>

        {showFilters && (
          <Filter
            placement={{
              top: 42,
              left: 6,
              transform: this.state.filterPositionY,
            }}
          />
        )}

        <TableContainerWithStyle>
          <Table stickyHeader size="small" className={classes.table}>
            <Header
              cells={selectedColumns}
              order={order}
              orderBy={orderBy}
              classes={classes}
              onRequestSort={this.onRequestSort}
            />
            <TableBody>
              {rows.map((r, index) => (
                <TableRowWithStyle
                  hover
                  key={index}
                  onClick={() => this.onRowClick(r)}
                  selected={checkSelectedRow(r)}
                >
                  {selectedColumns.map((col) => (
                    <TableCell key={`${index}_${col.name}`}>
                      {roundIfNumerical(r[col.name])}
                    </TableCell>
                  ))}
                </TableRowWithStyle>
              ))}
              {rows.length === 0 && (
                <TableRowWithStyle hover>
                  <TableCell colSpan={selectedColumns.length}>
                    No data found
                  </TableCell>
                </TableRowWithStyle>
              )}
            </TableBody>
          </Table>
        </TableContainerWithStyle>
        <TablePagination
          component="div"
          page={page}
          count={data.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={this.onPageChange}
          onRowsPerPageChange={this.onRowsPerPageChange}
        />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedColumns: (
      state.table.syncColumns? state.chart.selectedColumns : state.table.selectedColumns
  ).map((column)=>{
      const displayedName=column.displayedName?.trim()?column.displayedName:column.name;
    return {...column,displayedName}
  }),
  caseSensitive: state.table.caseSensitive,
  wholeWord: state.table.wholeWord,
  searchColumns: state.table.searchColumns,
  schema: state.dataset.schema
  // syncWithBrushing: state.table.syncWithBrushing,
});

const mapDispatchToProps = (dispatch) => ({
  setHighlight: (row) => dispatch(setHighlight(row)),
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(DataTable);
