import React from "react";
import { useSelector } from "react-redux";

import { Switch, Route, BrowserRouter } from "react-router-dom";

// Material UI theme
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import blueGrey from "@material-ui/core/colors/blueGrey";
import lightBlue from "@material-ui/core/colors/lightBlue";

import Paper from "@material-ui/core/Paper";

// import containers
import Overview from "./Overview";
// import Login from "./Login";
import Data from "./Data";
import Correlation from "./Correlation";
import NotFound from "./NotFound";
import Admin from "./Admin";

// import components
import AppLayout from "../components/app/AppLayout";
import AppNotification from "../components/app/AppNotification";
import { green, orange } from "@material-ui/core/colors"; // eslint-disable-line

// import utils
import {
  /*AdminRoute,*/
  authenticatedUserRule,
  onlyAdminRule,
  // PrivateRoute,
  ProtectedRoute,
} from "../routes";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    // height: "900px"
  },
  offset: theme.mixins.toolbar,
  paper: {
    minHeight: "100vh",
    height: "100%",
  },
}));

function App() {
  const classes = useStyles();

  const darkMode = useSelector((state) => state.app.mode);

  const darkTheme = createTheme({
    palette: {
      type: "dark",
      primary: { main: lightBlue[700] },
      progress: {
        light: blueGrey[200],
        main: lightBlue[600],
      },
      warning: {
        main: orange[600],
      },
    },
  });

  const lightTheme = createTheme({
    palette: {
      type: "light",
      primary: { main: lightBlue[700] },
      progress: {
        light: blueGrey[200],
        main: lightBlue[200],
      },
      warning: {
        main: orange[200],
      },
    },
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Paper className={classes.paper} square>
        <BrowserRouter>
          {/* <AppBar /> */}
          <AppLayout />
          <div className={classes.root}>
            <Switch>
              <ProtectedRoute
                exact
                path="/"
                component={Overview}
                validator={authenticatedUserRule}
              />
              <ProtectedRoute
                exact
                path="/data"
                component={Data}
                validator={authenticatedUserRule}
              />
              <ProtectedRoute
                exact
                path="/correlation"
                component={Correlation}
                validator={authenticatedUserRule}
              />
              <ProtectedRoute
                exact
                path="/admin"
                component={Admin}
                validator={onlyAdminRule}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
          <AppNotification />
        </BrowserRouter>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
