import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { jsonFetchWithCredentials } from "../utils/fetch-builder";
import Notification from "../utils/notification";
import setup from "../utils/setup";
import { addNotification } from "./app";
import { getCorrelationParamObject, sendCorrelation } from "./analytic";
import {
  setCountByPair,
  setOriginMatrix,
  removeColumnsFromCorrelationMatrix,
} from "./correlation";

// import { logoutProfile } from "./app";

// import { logout, refresh } from "./app";

const { debug, backendURL, ID_KEY, getAuthorization } = setup();
export { ID_KEY };
// TODO https://redux.js.org/tutorials/fundamentals/part-8-modern-redux
// Create AsyncThunk && EntityAdapter
/* todoColorSelected: {
  reducer(state, action) {
    const { color, todoId } = action.payload
    state.entities[todoId].color = color
  },
  prepare(todoId, color) {
    return {
      payload: { todoId, color }
    }
  }
},
*/

export const deleteDataset = createAsyncThunk(
  "datasets/delete",
  async (datasetId, { dispatch, rejectWithValue }) => {
    try {
      let deleteRes = await fetch(`${backendURL}/datasets/${datasetId}`, {
        method: "DELETE",
        credentials: "include", // Important: it tell fetch to include cookies in the request
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthorization(),
        },
      });

      if (deleteRes.status === 403) return rejectWithValue(null);

      let fetchRes = await fetch(`${backendURL}/datasets/`, {
        method: "GET",
        credentials: "include", // Important: it tell fetch to include cookies in the request
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthorization(),
        },
      });

      if (fetchRes.status === 403) return rejectWithValue(null);
      const datasets = await fetchRes.json();

      await dispatch(setDatasets(datasets));
      if (datasets.length > 0) {
        let fetchCurrentRes = await fetch(`${backendURL}/datasets/current`, {
          method: "GET",
          credentials: "include", // Important: it tell fetch to include cookies in the request
          headers: {
            "Content-Type": "application/json",
            authorization: getAuthorization(),
          },
        });

        if (fetchCurrentRes === null || fetchCurrentRes.status === 403)
          return rejectWithValue(null);

        let current = await fetchCurrentRes.json();

        await dispatch(selectDataset(current[ID_KEY]));
        await Promise.all([
          dispatch(setInitSchema(current.schema)),
          dispatch(setInitData(current.data)),
        ]);

        return true; // there is at least one dataset
      }

      return false; // no dataset found remove empty store
    } catch (error) {
      console.error(`[${datasetSlice.name}:deleteDataset]`, error);
    }
  }
);

export const fetchDatasets = createAsyncThunk(
  "datasets/fetchAll",
  async (payload, { dispatch, rejectWithValue }) => {
    debug && console.log(`[dataset:fetchDatasets] Fetching a list of datasets`);

    try {
      let res = await fetch(`${backendURL}/datasets/`, {
        method: "GET",
        credentials: "include", // Important: it tell fetch to include cookies in the request
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthorization(),
        },
      });

      // Forbidden: try to refresh token
      /*if (res.status === 403 && payload !== false) {
        const refreshResponse = await dispatch(refresh());
        if (refreshResponse.type === "app/refresh/rejected")
          return rejectWithValue([]);
        const fetchResponse = await dispatch(fetchDatasets(false));
        if (fetchResponse.type === "datasets/fetchAll/fulfilled") return;
        else return rejectWithValue([]);
      } else*/ if (res.status === 403 /*&& payload === false*/)
        return rejectWithValue([]);

      let datasets = await res.json();
      return datasets;
    } catch (error) {
      console.error(`[${datasetSlice.name}:fetchDatasets]`, error);
    }
  }
);

export const fetchPublicDatasets = createAsyncThunk(
  "datasets/fetchPublic",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      let res = await fetch(`${backendURL}/datasets/public`, {
        method: "GET",
        credentials: "include", // Important: it tell fetch to include cookies in the request
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthorization(),
        },
      });

      if (res.status === 403 /*&& payload === false*/)
        return rejectWithValue([]);

      let publicDatasets = await res.json();
      // dispatch(setPublicDatasets(publicDatasets))
      return publicDatasets;
    } catch (error) {
      console.error(`[${datasetSlice.name}:fetchPublicDatasets]`, error);
    }
  }
);
export const updateSchemaRow = createAsyncThunk(
  "dataset/updateSchemaRow",
  async (arg, { dispatch, rejectWithValue }) => {
    debug &&
      console.log(`[${datasetSlice.name}:updateSchemaRow] updating schema row`);
    try {
      let { datasetId, currentSchema, newRow } = arg;

      let res = await fetch(
        `${backendURL}/datasets/${datasetId}/updateSchemaRow`,
        {
          method: "POST",
          credentials: "include", // Important: it tell fetch to include cookies in the request
          headers: {
            "Content-Type": "application/json",
            authorization: getAuthorization(),
          },
          body: JSON.stringify(newRow),
        }
      );

      if (res.status >= 500 || res.status === 401 || res.status === 403)
        return rejectWithValue(res.status);
      // > 500 addNotification({ message: "Service Unavailable", severity: "error" })
      // =401 || = 403 addNotification({ message: "Insufficient privileges", severity: "error" })

      // update the schema
      const newRowIndex = currentSchema.findIndex(
        (row) => row.name === newRow.name
      );
      const newSchema = [...currentSchema];
      newSchema.splice(newRowIndex, 1, newRow);

      // TODO: do not use setInitSchema use updateSchemaColumns instead
      // await dispatch(setInitSchema(newSchema));
      await dispatch(updateSchemaColumns(newSchema));
      // => In chart and table features: update selected columns
    } catch (err) {
      console.log(`[${datasetSlice.name}:${updateSchemaRow.name}]`, err);
      return rejectWithValue(err);
    }
  }
);
export const fetchSchemaColumn = createAsyncThunk(
  "dataset/fetchSchemaColumn",
  async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
      const { datasetId, columns } = payload;
      let res = await fetch(`${backendURL}/datasets/${datasetId}/columns`, {
        method: "POST",
        credentials: "include", // Important: it tell fetch to include cookies in the request
        headers: {
          authorization: getAuthorization(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ columns }),
      });

      if (res.status === 403) return rejectWithValue([]);

      if (res.status === 404) {
        dispatch(addNotification(new Notification(res.statusText, "error")));
        return rejectWithValue([]);
      }

      const result = await res.json();
      const newColumnsSchema = result.columnsSchema;
      const newColumnsData = result.columnsData;

      const state = getState();
      const { currentSchema, currentData, currentDataset } = state.dataset;
      const columnIdentifier = currentSchema.filter(
        (column) => column.group === "Id"
      )[0];
      let newData = currentData.slice();
      const newSchema = currentSchema.slice();

      const addedColumns = [];
      // check if the column already exists and update if any
      newColumnsSchema.forEach((column) => {
        // create or update column in schema
        const columnIdx = newSchema.findIndex(
          (oldColumn) => oldColumn.name === column.name
        );
        const newColumn = { ...column };
        if (columnIdx < 0) {
          newSchema.push(newColumn);
          addedColumns.push(newColumn);
        } else {
          newSchema[columnIdx] = newColumn;
        }
        // add or update data for the column
        newData = newData.map((row, i) => {
          // check if id of action.payload.data matches the id of the current data state
          const newRow = { ...row };
          if (
            row[columnIdentifier.name] ===
            newColumnsData[i][columnIdentifier.name]
          ) {
            // update or create data for the column
            newRow[column.name] = newColumnsData[i][column.name];
          }
          return newRow;
        });
      });

      // dispatch(setInitSchema(newSchema))
      await dispatch(updateColumns({ newSchema, newData }));
      // compute correlations to consider new columns
      if (addedColumns.length > 0) {
        const corrParamObj = getCorrelationParamObject(newData, newSchema);
        corrParamObj.currentDataset = currentDataset;
        dispatch(sendCorrelation(corrParamObj)); // do not wait
      }
      // add columns to table and chart
      // dispatch(updateColumns(newColumnsSchema));
      return result;
    } catch (error) {
      console.log(`[${datasetSlice.name}:fetchSchemaColumn]`, error);
      return rejectWithValue(error);
    }
  }
);

export const checkUniqueSchemaRow = createAsyncThunk(
  "dataset/checkUniqueSchemaRow",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      let res = await fetch(
        `${backendURL}/datasets/${payload.datasetId}/checkUniqueSchemaRow?name=${payload.name}`,
        {
          credentials: "include", // Important: it tell fetch to include cookies in the request
          headers: {
            "Content-Type": "application/json",
            authorization: getAuthorization(),
          },
        }
      );
      if (res.status >= 400 || res.status === 401 || res.status === 403) {
        return rejectWithValue(res.status);
      }
      const isUnique = await res.json();
      return isUnique;
    } catch (error) {
      console.log(`[${datasetSlice.name}:${updateSchemaRow.name}]`, error);
      return rejectWithValue(error);
    }
  }
);

function _removeColumnsInCurrentData(columns, currentData) {
  const newData = currentData.map((row) => {
    // check if id of action.payload.data matches the id of the current data state
    const newRow = { ...row };
    columns.forEach((column) => {
      delete newRow[column];
    });
    return newRow;
  });
  return newData;
}
export const deleteSchemaColumn = createAsyncThunk(
  "dataset/deleteSchemaColumn",
  async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
      const { datasetId, columns } = payload;
      let res = await fetch(`${backendURL}/datasets/${datasetId}/schema`, {
        method: "DELETE",
        credentials: "include", // Important: it tell fetch to include cookies in the request
        headers: {
          authorization: getAuthorization(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ columns }),
      });

      if (res.status === 403) return rejectWithValue([]);

      if (res.status === 404) {
        dispatch(addNotification(new Notification(res.statusText, "error")));
        return rejectWithValue([]);
      }

      const newSchema = await res.json();

      // remove column in data
      const state = getState();
      const { currentData } = state.dataset;
      const newData = _removeColumnsInCurrentData(columns, currentData);
      await dispatch(updateColumns({ newSchema, newData }));

      // remove columns from correlationMatrix
      // !!! dependency with correlation
      const newState = getState();
      const { selectedColumns } = newState.chart;
      const { corrMatrix, countByPair } = state.correlation;
      const newCorrelationData = removeColumnsFromCorrelationMatrix(
        columns,
        corrMatrix,
        countByPair
      );
      dispatch(
        setOriginMatrix({
          corrMatrix: newCorrelationData.corrMatrix,
          selectedColumns,
        })
      );
      dispatch(setCountByPair(newCorrelationData.countByPair));

      return newSchema;
    } catch (error) {
      console.log(`[${datasetSlice.name}:deleteSchemaColumn]`, error);
      return rejectWithValue(error);
    }
  }
);

export const updateDataset = createAsyncThunk(
  "dataset/updateDatasetDetails",
  async (details, { dispatch, rejectWithValue }) => {
    try {
      const { id, ...body } = details;
      let res = await fetch(`${backendURL}/datasets/${id}`, {
        ...jsonFetchWithCredentials(getAuthorization(), "PATCH"),
        body: JSON.stringify(body),
      });

      if (res.status === 304) return;

      const updatedDataset = await res.json();
      await dispatch(updateDatasetList([updatedDataset]));
      await dispatch(updateCurrenDataset(id));
      return true;
    } catch (error) {
      console.log(`[${datasetSlice.name}:updateDataset]`, error);
      return rejectWithValue(error);
    }
  }
);

export const importPublicDataset = createAsyncThunk(
  "datasets/importPublicDataset",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      let id = payload.datasetId;
      if (!id) return;
      let res = await fetch(`${backendURL}/datasets/${id}/import`, {
        ...jsonFetchWithCredentials(getAuthorization(), "POST"),
      });

      if (res.status === 403) return rejectWithValue([]);

      let result = await res.json();
      if (!result) rejectWithValue(false);

      batch(() => {
        dispatch(fetchPublicDatasets());
        dispatch(fetchDatasets());
      });
      return;
    } catch (error) {
      console.error(`[${datasetSlice.name}:importPublicDataset]`, error);
    }
  }
);
export function getRowIdName(schema) {
  return schema.find((s) => s.group === "Id").name;
}

export const exportDataset = createAsyncThunk(
  "datasets/exportDataset",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { dataset } = getState();
    try {
      let response = await fetch(
        `${backendURL}/datasets/csv/${dataset.currentDataset[ID_KEY]}`,
        {
          credentials: "include", // Important: it tell fetch to include cookies in the request
          headers: {
            authorization: getAuthorization(),
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response) return rejectWithValue(false);

      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      const contentDisposition = response.headers.get("content-disposition");
      const filename = contentDisposition.match(/filename="(.*\.csv)"/)[1];
      if (!filename) return rejectWithValue(false);

      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      return;
    } catch (error) {
      console.error(`[${datasetSlice.name}:exportDataset]`, error);
    }
  }
);

export const exportSchema = createAsyncThunk(
  "datasets/exportSchema",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { dataset } = getState();
    try {
      let response = await fetch(
        `${backendURL}/datasets/csv/${dataset.currentDataset[ID_KEY]}/schema`,
        {
          credentials: "include", // Important: it tell fetch to include cookies in the request
          headers: {
            authorization: getAuthorization(),
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response) return rejectWithValue(false);

      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      const contentDisposition = response.headers.get("content-disposition");
      const filename = contentDisposition.match(/filename="(.*\.csv)"/)[1];
      if (!filename) return rejectWithValue(false);

      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      return;
    } catch (error) {
      console.error(`[${datasetSlice.name}:exportSchema]`, error);
    }
  }
);

export const importUpdatedHeaders = createAsyncThunk(
  "datasets/importUpdatedHeaders",
  async ({ file, datasetId }, { dispatch, rejectWithValue }) => {
    try {
      const fd = new FormData();
      fd.append("file", file);
      let response = await fetch(
        `${backendURL}/datasets/${datasetId}/schema/import`,
        {
          method: "POST",
          credentials: "include",
          body: fd,
        }
      );

      if (
        response.status >= 500 ||
        response.status === 401 ||
        response.status === 403
      )
        return rejectWithValue(response.status);

      const newSchema = await response.json();
      await dispatch(updateSchemaColumns(newSchema));
    } catch (error) {
      console.log(`[${datasetSlice.name}:importUpdatedHeaders]`, error);
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: false,
  initStructure: false,
  initData: false,
  currentDataset: { [ID_KEY]: "" }, // the current dataset
  currentSchema: [], // diff with schema ? always the same as schema
  currentData: [], // can be subsetted
  data: [], // initial data (read only!)
  schema: [], // initial schema (read only!)
  datasets: [], // the list of datasets
  publicDatasets: [], // the list of public datasets
};
function _updateSchema(state, newSchema) {
  state.schema = newSchema;
  state.currentSchema = newSchema;
}
const datasetSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    selectDataset(state, action) {
      let datasets = current(state.datasets);
      let publicDatasets = current(state.publicDatasets);
      if (datasets.length > 0) {
        let currentDataset = datasets.find((d) => d[ID_KEY] === action.payload);
        // FIXED https://stackoverflow.com/questions/58850699/useselector-not-updating-when-store-has-changed-in-reducer-reactjs-redux
        Object.assign(state, initialState, {
          datasets,
          currentDataset,
          publicDatasets,
        });
      }
    },
    updateDatasetList(state, action) {
      let updatedDatasetList = action.payload;
      if (updatedDatasetList === null) return;
      if (!Array.isArray(action.payload))
        updatedDatasetList = [updatedDatasetList];

      updatedDatasetList.forEach((udl) => {
        let index = state.datasets.findIndex((d) => d[ID_KEY] === udl[ID_KEY]);
        state.datasets.splice(index, 1, udl);
      });
    },
    updateCurrenDataset(state, action) {
      let currentDataset = current(state.datasets).find(
        (d) => d[ID_KEY] === action.payload
      );
      state.currentDataset = currentDataset;
    },
    setInitSchema(state, action) {
      // If the schema has not identifier to sort the variables
      // Then, create one based on their initial position
      let schema = action.payload;
      if (action?.payload?.length > 0 && !("_pos" in action.payload[0]))
        schema = action.payload.map((s, i) => {
          s._pos = i;
          return s;
        });
      state.schema = schema;
      state.currentSchema = schema;
    },
    setDatasets(state, action) {
      if (action.payload.length > 0) state.datasets = action.payload;
      else state.datasets = [];
    },
    setPublicDatasets(state, action) {
      if (action.payload.length > fetchPublicDatasets)
        state.publicDatasets = action.payload;
      else state.publicDatasets = [];
    },
    setInitData(state, action) {
      state.data = action.payload;
      state.currentData = action.payload;
      state.initData = true;
    },
    setData(state, action) {
      state.currentData = action.payload;
    },
    updateColumns(state, action) {
      console.log("action.payload", action.payload);
      state.data = action.payload.newData;
      state.currentData = action.payload.newData;
      _updateSchema(state, action.payload.newSchema);
      // state.schema = action.payload.newSchema;
      // state.currentSchema = action.payload.newSchema;
    },
    updateSchemaColumns(state, action) {
      _updateSchema(state, action.payload);
    },
    initData(state, action) {
      state.initData = action.payload;
    },
    initStructure(state, action) {
      state.initStructure = action.payload;
    },
    keepDataSelection(state, action) {
      // action.payload = brushed data
      state.currentData = action.payload;
    },
    removeDataSelection(state, action) {
      // action.payload = brushed data

      const idKey = state.schema.find((s) => s.group === "Id").name;
      const excludedIds = action.payload.map((p) => p[idKey]);
      state.currentData = state.currentData.filter(
        (d) => excludedIds.indexOf(d[idKey]) === -1
      );
    },
    resetDataSelection(state, action) {
      state.currentData = state.data;
    },
  },
  extraReducers: (builder) => {
    builder

      // fetchDatasets
      .addCase(fetchDatasets.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchDatasets.fulfilled, (state, action) => {
        // Already updated
        if (action.payload === undefined || action.payload === null) {
        }
        // Updating datasets
        else if (action.payload.length > 0) state.datasets = action.payload;
        state.loading = false;
      })
      .addCase(fetchDatasets.rejected, (state, action) => {
        if (action.payload === []) state.datasets = action.payload;
        state.loading = false;
      })

      // fetchPublicDatasets
      .addCase(fetchPublicDatasets.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchPublicDatasets.fulfilled, (state, action) => {
        // Already updated
        if (action.payload === undefined || action.payload === null) {
        }
        // Updating datasets
        else state.publicDatasets = action.payload;
        state.loading = false;
      })
      .addCase(fetchPublicDatasets.rejected, (state, action) => {
        if (action.payload === []) state.publicDatasets = action.payload;
        state.loading = false;
      })

      .addCase(deleteDataset.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteDataset.fulfilled, (state, action) => {
        if (action.payload === false)
          state = Object.assign(state, initialState, { initStructure: true });
        else state.loading = false;
      })
      .addCase(deleteDataset.rejected, (state, action) => {
        if (action.payload === false)
          state = Object.assign(state, initialState, { initStructure: true });
        else state.loading = false;
      })

      // .addCase(logoutProfile, (state) => initialState)
      .addCase(importPublicDataset.pending, (state) => {
        state.loading = true;
      })
      .addCase(importPublicDataset.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(importPublicDataset.rejected, (state) => {
        state.loading = false;
      });

    // reset dataset store
    // .addCase(logout.fulfilled, (state) => initialState)
    // .addCase(logout.rejected, (state) => initialState)
    // .addCase(refresh.rejected, (state) => initialState);
  },
});

export const {
  initData,
  initStructure,
  selectDataset,
  updateDatasetList,
  updateCurrenDataset,
  setInitData,
  setData,
  updateColumns,
  updateSchemaColumns,
  setDatasets,
  setPublicDatasets,
  setLoading,
  setInitSchema,
  keepDataSelection,
  removeDataSelection,
  resetDataSelection,
} = datasetSlice.actions;

export default datasetSlice.reducer;

// Async functions (use dispatch)

// export const fetchDatasets = () => async (dispatch) => {
//   debug &&
//     console.log(
//       `[${datasetSlice.name}:${fetchDatasets.name}] Fetching a list of datasets`
//     );
//   try {
//     let res = await fetch(`${backendURL}/datasets/`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     res = await res.json();
//     await dispatch(setDatasets(res));
//     return res;
//   } catch (error) {

//     console.error(`[${datasetSlice.name}:${fetchDatasets.name}]`, error);
//   }
// };

//TODO: refactor with createAsyncThunk to benefit from live cycle in extraReducers
export const fetchCurrentDataset = () => async (dispatch) => {
  debug &&
    console.log(
      `[${datasetSlice.name}:${fetchCurrentDataset.name}] Get current dataset`
    );
  try {
    let res = await fetch(`${backendURL}/datasets/current`, {
      method: "GET",
      credentials: "include", // Important: it tell fetch to include cookies in the request
      headers: {
        "Content-Type": "application/json",
        authorization: getAuthorization(),
      },
    });
    res = await res.json();
    if ("code" in res && res.code !== 200) throw res;
    await dispatch(selectDataset(res[ID_KEY]));
    await Promise.all([
      dispatch(setInitSchema(res.schema)),
      dispatch(setInitData(res.data)),
    ]);

    return res;
  } catch (error) {
    if (error.code === 404)
      return console.error(
        `[${datasetSlice.name}:${fetchCurrentDataset.name}] No dataset found for the current user!`
      );

    console.error(`[${datasetSlice.name}:${fetchCurrentDataset.name}]`, error);
  }
};

export const fetchDatasetById = (datasetId) => async (dispatch) => {
  debug &&
    console.log(
      `[${datasetSlice.name}:${fetchDatasetById.name}] Get a dataset by ID`
    );
  try {
    let res = await fetch(`${backendURL}/datasets/${datasetId}`, {
      method: "GET",
      credentials: "include", // Important: it tell fetch to include cookies in the request
      headers: {
        "Content-Type": "application/json",
        authorization: getAuthorization(),
      },
    });

    res = await res.json();
    await dispatch(selectDataset(res[ID_KEY]));
    await Promise.all([
      dispatch(setInitSchema(res.schema)),
      dispatch(setInitData(res.data)),
    ]);

    return res;
  } catch (error) {
    console.error(`[${datasetSlice.name}:${fetchDatasetById.name}]`, error);
  }
};
// FIXME: Not used, URL does not work => to remove
export const fetchSchemaForDataset = (datasetId) => async (dispatch) => {
  debug &&
    console.log(
      `[${datasetSlice.name}:${fetchSchemaForDataset.name}] Fetching a schema for a dataset`
    );
  try {
    let res = await fetch(`${backendURL}/data/${datasetId}/schema`, {
      method: "GET",
      credentials: "include", // Important: it tell fetch to include cookies in the request
      headers: {
        "Content-Type": "application/json",
        authorization: getAuthorization(),
      },
    });

    res = await res.json();
    await dispatch(setInitSchema(res));
    return res;
  } catch (error) {
    console.error(
      `[${datasetSlice.name}:${fetchSchemaForDataset.name}]`,
      error
    );
  }
};

export const sendDatasetFile =
  (selectedFile, datasetName, datasetDescription) => async (dispatch) => {
    debug &&
      console.log(
        `[${datasetSlice.name}:${sendDatasetFile.name}] Send a dataset file (csv)`
      );
    try {
      await dispatch(setLoading(true));
      const fd = new FormData();
      fd.append("file", selectedFile);
      fd.append("name", datasetName);
      if (!!datasetDescription && datasetDescription !== "")
        fd.append("description", datasetDescription);

      let res = await fetch(`${backendURL}/datasets/csv`, {
        method: "POST",
        credentials: "include", // Important: it tell fetch to include cookies in the request
        headers: {
          authorization: getAuthorization(),
          // do not set the content-type otherwise the file upload won't works
          // "Content-Type": "multipart/form-data"
        },
        body: fd,
      });

      /*let text =*/ await res.text();
      await dispatch(fetchDatasets());
      await dispatch(fetchCurrentDataset());
      await dispatch(setLoading(false));
      // await batch(async () => {
      //   await dispatch(fetchCurrentDataset());
      //   await dispatch(setInitSchema(res));
      // });

      return true;
    } catch (error) {
      console.error(`[${datasetSlice.name}:${sendDatasetFile.name}]`, error);
      await dispatch(setLoading(false));
    }
  };

// FIXME: Not used, URL does not work => to remove
export const initApplication = () => async (dispatch) => {
  debug &&
    console.log(
      `[${datasetSlice.name}:${initApplication.name}] Initialise application with the latest selected dataset`
    );
  try {
    let res = await fetch(`${backendURL}/data/app.init`, {
      method: "POST",
      credentials: "include", // Important: it tell fetch to include cookies in the request
      headers: {
        Accept: "*/*",
        authorization: getAuthorization(),
      },
      body: JSON.stringify({ timestamp: Date.now() }),
    });

    res = await res.json();
    await batch(async () => {
      // dispatch(selectDataset(res.data.info.id));
      dispatch(selectDataset(res.data.info[ID_KEY]));
      dispatch(setInitSchema(res.data.schema));
    });

    return res;
  } catch (error) {
    console.error(`[${datasetSlice.name}:${initApplication.name}]`, error);
  }
};

// FIXME: Not used, URL does not work => to remove
export const fetchData = (variables) => async (dispatch) => {
  debug &&
    console.log(
      `[${datasetSlice.name}:${fetchData.name}] Get data for the current dataset`
    );
  try {
    let res = await fetch(`${backendURL}/data/data.get`, {
      method: "POST",
      credentials: "include", // Important: it tell fetch to include cookies in the request
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        authorization: getAuthorization(),
      },
      body: JSON.stringify({ variables }),
    });

    res = await res.json();
    await batch(async () => {
      dispatch(setInitData(res));
      dispatch(setData(res));
    });

    return res;
  } catch (error) {
    console.error(`[${datasetSlice.name}:${fetchData.name}]`, error);
  }
};

// Extra reducers functions

/**
 * On setSchema action this callback fn is inferred in:
 * chartReducer and tableReducer.
 *
 * @param {*} state the target reducer state
 * @param {*} action the source action
 */
export function partitionSchema(state, action) {
  // create a partition with the elements to exclude/include.
  // index 0: exclude, index 1: include
  let [toExclude, toSelect] = action.payload.reduce(
    (acc, curr, index) => {
      let res = { ...curr, _pos: index };
      acc[curr.displayOnLoad ? 1 : 0].push(res);
      return acc;
    },
    [[], []]
  );

  if ("_pos" in toExclude[0] || "_pos" in toSelect[0]) {
    toExclude = toExclude.sort((a, b) => a._pos - b._pos);
    toSelect = toSelect.sort((a, b) => a._pos - b._pos);
  }

  state.excludedColumns = toExclude;
  state.selectedColumns = toSelect;
}
