const redirectUri = window.location.origin;

export const oidcConfig = {
  onSignIn() {
    // Remove the "state" query object from URL after sign in
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  authority: window.__env__.REACT_APP_OIDC_AUTHORITY,
  clientId: window.__env__.REACT_APP_OIDC_CLIENT_ID,
  // clientSecret: window.__env__.REACT_APP_OIDC_CLIENT_SECRET,
  redirectUri: window.__env__.REACT_APP_OIDC_REDIRECT_URI ?? redirectUri,
};
