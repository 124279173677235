import React from "react";
import PropTypes from "prop-types";
import { LocationType, RouteType } from "../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  Link as RouterLink,
  /* useLocation */ withRouter,
} from "react-router-dom";

import { useAuth } from "oidc-react";

// routes
import routes from "../../routes";

// Redux actions
import { setDrawer, setMode } from "../../features/app";

// Material UI
import {
  AppBar as Bar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";

// Icons
import MenuIcon from "@material-ui/icons/Menu";
import DarkIcon from "@material-ui/icons/Brightness4";
import LightIcon from "@material-ui/icons/Brightness7";
import LoginIcon from "@material-ui/icons/LockOpen";
import LogoutIcon from "@material-ui/icons/ExitToApp";

import { isAuthenticated, isAdmin as isAdminFn } from "../../utils/auth";

const useStyles = makeStyles((theme) => {
  // m = margin, x = left and right
  const mx = {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  };

  return {
    toolbar: {
      backgroundColor: theme.palette.primary[theme.palette.type],
    },
    burger: {
      display: "block",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    title: {
      color: theme.palette.text.primary,
      ...mx,
    },
    link: {
      ...mx,
      textAlign: "center",
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
    monitoring: {
      width: 160,
      marginRight: theme.spacing(1),
    },
    theme: {
      padding: theme.spacing(1),
    },
    heightPercent: {
      height: "10%",
    },
  };
});

function AppBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const location = useLocation();

  // With OIDC (Keycloak)
  const { userManager, userData, signIn } = useAuth();

  const { drawer, mode } = useSelector((state) => state.app);
  const { monitoring, brushed } = useSelector((state) => state.chart);

  const mp = monitoring["parcoord"];
  const mb = monitoring["brush"];

  const isEqualBrushed = brushed.length !== 0 && brushed.length === mb.rows;

  // With OIDC (Keycloak)
  const isLogged = isAuthenticated(userData);
  const isAdmin = isAdminFn(userData);

  const monitoringData = {
    parcoord: {
      value: mp.progress,
      text:
        mp.rows - mp.remaining === mp.rows
          ? `${mp.rows} rows`
          : `${mp.rows - mp.remaining} out of ${mp.rows}`,
    },
    brush: {
      value: isEqualBrushed ? mb.progress : 0,
      text: isEqualBrushed
        ? `${mb.rows - mb.remaining} brushed`
        : `No brushed rows`,
    },
  };

  // internal
  const showMonitoring = () => mp.rows !== 0 && props.location.pathname === "/";

  // event
  const onDrawerClick = () => dispatch(setDrawer(!drawer));
  const onModeClick = (bool) => (event) => dispatch(setMode(bool));

  // With OIDC (Keycloak)
  const onLogout = async (event) => {
    event.preventDefault();

    /**
     * Look at id_token_hint documentation
     * source: https://openid.net/specs/openid-connect-core-1_0.html#AuthRequest
     *
     * OIDC (Keycloak) requires id_token_hint when prompt=none (no popup)
     */
    await userManager.signoutRedirect({
      id_token_hint: userData.id_token,
    });
  };

  // With OIDC (Keycloak), not in use because the authentication is done automatically when the user lands on the page
  const onLogin = async (event) => {
    event.preventDefault();
    signIn();
  };

  return (
    <Bar position="fixed">
      <Toolbar className={classes.toolbar} variant="dense">
        <IconButton
          edge="start"
          className={classes.burger}
          aria-label="side-menu"
          onClick={onDrawerClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Lamda
        </Typography>
        {isLogged && (
          <NavLinks
            className={classes.link}
            routes={routes}
            isAdmin={isAdmin}
          />
        )}
        <Box display="flex" flexGrow={1}></Box>
        {showMonitoring() && (
          <Monitoring className={classes.monitoring} data={monitoringData} />
        )}
        <Tooltip title="Toggle light/dark theme">
          {mode ? (
            <IconButton
              className={classes.theme}
              aria-label="Dark theme"
              onClick={onModeClick(false)}
            >
              <LightIcon />
            </IconButton>
          ) : (
            <IconButton
              className={classes.theme}
              aria-label="Light theme"
              onClick={onModeClick(true)}
            >
              <DarkIcon />
            </IconButton>
          )}
        </Tooltip>

        {isLogged ? (
          <IconButton className={classes.theme} onClick={onLogout}>
            <LogoutIcon />
          </IconButton>
        ) : (
          <IconButton className={classes.theme} onClick={onLogin}>
            <LoginIcon />
          </IconButton>
        )}
      </Toolbar>
    </Bar>
  );
}

AppBar.propTypes = {
  location: LocationType,
};

export default withRouter(AppBar);

function NavLinks({ className, routes, isAdmin }) {
  if (routes.length === 0) return;

  // if (sessionStorage.getItem("profileId") !== "admin")
  //   routes = routes.filter(r => r.metadata.admin === false || !r.metadata.admin)
  if (!isAdmin)
    routes = routes.filter(
      (r) => r.metadata.admin === false || !r.metadata.admin
    );

  return routes
    .filter((r) => r.metadata.menu)
    .sort((a, b) => a.index - b.index)
    .map((r) => (
      <Button
        component={RouterLink}
        key={r.index}
        className={className}
        to={r.link}
      >
        {r.title}
      </Button>
    ));
}

NavLinks.propTypes = {
  className: PropTypes.string,
  routes: PropTypes.arrayOf(RouteType),
  isAdmin: PropTypes.bool,
};

// Progress bar - start

const ProgressBar = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: theme.palette.progress,
  },
  bar: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
  },
}))(LinearProgress);

function LinearProgressWithLabel({ data, mb }) {
  return (
    <Box display="flex" flexDirection="column" position="relative" mb={mb}>
      <Box position="absolute" right="5px" top="-3px" zIndex="10">
        <Typography variant="caption" color="textSecondary">
          {data.text}
        </Typography>
      </Box>
      <Box width="100%" mr={1}>
        <ProgressBar variant="determinate" value={data.value} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.exact({
    text: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

function Monitoring({ className, data }) {
  return (
    <div className={className}>
      <LinearProgressWithLabel data={data["parcoord"]} mb={0.5} />
      <LinearProgressWithLabel data={data["brush"]} />
    </div>
  );
}

Monitoring.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }),
};

// Progress bar - end
