const jsonHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const credentials = "include";

export function jsonFetchWithCredentials(authorization, method = "GET") {
  if (!authorization)
    return {
      method,
      credentials,
      headers: {
        ...jsonHeader,
      },
    };

  return {
    method,
    credentials,
    headers: {
      ...jsonHeader,
      authorization,
    },
  };
}

const fb = {
  jsonFetchWithCredentials,
  jsonHeader,
  credentials,
};

export default fb;
