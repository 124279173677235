import React,{ Component } from 'react';
import PropTypes from 'prop-types';
import D3Scatterplot from "./d3Scatterplot";
class Scatterplot extends Component{
    static propTypes={
        data: PropTypes.array, // array of obj {id, xValue,yValue, type}
        title: PropTypes.string,
        xUnit: PropTypes.string,
        yUnit: PropTypes.string,
        margin: PropTypes.object,
        yFilterThreshold: PropTypes.object, // {min,max} compared to the value retrieved by highlightAccessor
        drawLinesByColor: PropTypes.bool,
        plotSize: PropTypes.number,
        colorScaleProperties:PropTypes.object, // {toGetByRowId:Bool, colorByRowId: {id:color}, values:[],colors:[],legendLabels:[]} if !toGetByRowId: values => categories of data.type and the corresponding colors
        showZeroLine:PropTypes.bool,
        showIdentityLine:PropTypes.bool,
        showBestFitLine:PropTypes.bool,
        xValueAccessor:PropTypes.func,
        yValueAccessor:PropTypes.func,
        highlightAccessor:PropTypes.func, // accessor to use to compare with yFilterThreshold for highlight
        idAccessor:PropTypes.func, // accessor to use to compare with yFilterThreshold for highlight
        scaleDomainExtPerc:PropTypes.number, // percentage of the domain values to extent around the data min max (to avoid plotting on axis )
        selectedItem:PropTypes.object, // id
        selectedItems:PropTypes.array, // [id]
        controllerMethods: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.dataById={}
    }
    updateDataById(data,idAccessor){
        this.dataById={};
        data.forEach(d=>{
            this.dataById[idAccessor(d)]=d;
        });
    }
    addAccessorsToController(controller){
        // setting controller tu put accessors for d3 view
        if(this.props.idAccessor){
            controller.idAccessor=this.props.idAccessor;
        }else if(!controller.idAccessor){
            controller.idAccessor = (d)=>{return d.id};
        }
        if(this.props.yValueAccessor){
            controller.getYValue=this.props.yValueAccessor;
        }else if(!controller.getYValue){
            controller.getYValue=(d)=>{return d.yValue};
        }
        if(this.props.xValueAccessor){
            controller.getXValue=this.props.xValueAccessor;
        }else if(!controller.getXValue){
            controller.getXValue=(d)=>{return d.xValue};
        }

    }
    componentDidMount() {
        console.log("Scatterplot did mount...");
        const size =this.getChartSize();
        if(this.props.margin){
            size.margin=this.props.margin;
        }
        const config = {
            size:size,
            title:this.props.title,
            xUnit:this.props.xUnit,
            yUnit:this.props.yUnit,
            plotSize:this.props.plotSize,
            drawLinesByColor:this.props.drawLinesByColor
        };
        const state = {
            data:this.props.data,
            colorScaleProperties:this.props.colorScaleProperties,
            yFilterThreshold:this.props.yFilterThreshold,
            showZeroLine:this.props.showZeroLine,
            showIdentityLine:this.props.showIdentityLine,
            showBestFitLine:this.props.showBestFitLine,
            scaleDomainExtPerc:this.props.scaleDomainExtPerc
        };
        let controller=this.props.controllerMethods();
        if (controller===undefined){
            controller={};
        }

        this.addAccessorsToController(controller);

        this.d3Scatterplot = new D3Scatterplot(this._rootNode);

        this._chart = this.d3Scatterplot.create(
            config,
            state,
            controller
        );
    }

    componentDidUpdate(prevProps) {
        console.log("Scatterplot did update...");
        const config = {
            title:this.props.title,
            xUnit:this.props.xUnit,
            yUnit:this.props.yUnit
        };
        const state = {
            data:this.props.data,
            dataById:this.dataById,
            colorScaleProperties:this.props.colorScaleProperties,
            yFilterThreshold:this.props.yFilterThreshold,
            showZeroLine:this.props.showZeroLine,
            showIdentityLine:this.props.showIdentityLine,
            showBestFitLine:this.props.showBestFitLine,
            scaleDomainExtPerc:this.props.scaleDomainExtPerc,
            highlightAccessor:this.props.highlightAccessor,
            selectedItems:this.props.selectedItems,
            selectedItem:this.props.selectedItem,
        };
        let controller=this.props.controllerMethods();
        if (controller===undefined){
            controller={};
        }
        this.addAccessorsToController(controller);


        // check which data changed:
        if(prevProps.data!==this.props.data){
            console.log("for scatterplot property...");
            this.updateDataById(this.props.data, controller.idAccessor);
            state.dataById=this.dataById;
            this.d3Scatterplot.update(config,state,controller,this._chart);
            this.d3Scatterplot.selectItems(state);
        }else if(this.props.data&&this.props.data.length>0
            && (prevProps.selectedItems!==this.props.selectedItems||prevProps.selectedItem!==this.props.selectedItem)
        ){
            this.d3Scatterplot.selectItems(state);
        }else{
            if(prevProps.colorScaleProperties!==this.props.colorScaleProperties){
                this.d3Scatterplot.updateColorOfPoints(state);
            }
            if(prevProps.yFilterThreshold!==this.props.yFilterThreshold){
                this.d3Scatterplot.filterByThreshold(state,controller)
            }
            // TODO: handle xAxis only and yAxis only
            if(prevProps.yValueAccessor!==this.props.yValueAccessor||prevProps.xValueAccessor!==this.props.xValueAccessor){
                console.log("valueAccessor changed...");
                this.d3Scatterplot.updateXYData(state,controller)
                // this.d3Scatterplot.update()
            }
            if(prevProps.showZeroLine!==this.props.showZeroLine){
                this.d3Scatterplot.showZeroLine(this.props.showZeroLine);
            }
            if(prevProps.showIdentityLine!==this.props.showIdentityLine){
                this.d3Scatterplot.showIdentityLine(this.props.showIdentityLine);
            }
            if(prevProps.showBestFitLine!==this.props.showBestFitLine){
                this.d3Scatterplot.showBestFitLine(this.props.showBestFitLine);
            }
            if(prevProps.title!==this.props.title){
                // this.d3Scatterplot.updateScatterplotAllTitles(this.props.title,this.props.xUnit,this.props.yUnit);
                this.d3Scatterplot.updateTitle(this.props.title);
            }
            if(prevProps.xUnit!==this.props.xUnit){
                this.d3Scatterplot.updateXUnit(this.props.xUnit);
            }
            if(prevProps.yUnit!==this.props.yUnit){
                this.d3Scatterplot.updateYUnit(this.props.yUnit);
            }
        }

    }
    componentWillUnmount() {
        console.log("Scatterplot will unmount...");
        this.d3Scatterplot.destroy(this._rootNode);
    }

    getChartSize(){
        // return this.props.size;
        let width;// = 800;
        let height;// = 100;
        if(this._rootNode!==undefined){
            width=this._rootNode.offsetWidth;
            // width = '100%';
            height=this._rootNode.offsetHeight;
            // height = '100%';
            const styleHeight=this._rootNode.style.height;
            const clientHeight=this._rootNode.clientHeight;
            const scrollHeight=this._rootNode.scrollHeight;
            console.log(this.props.elementId+"=> styleHeight:"+styleHeight+" clientHeight:"+clientHeight+" scrollHeight:"+scrollHeight+" offsetHeight:"+height)
            const styleWidth=this._rootNode.style.width;
            const clientWidth=this._rootNode.clientWidth;
            const scrollWidth=this._rootNode.scrollWidth;
            console.log(this.props.elementId+"=> styleWidth:"+styleWidth+" clientWidth:"+clientWidth+" scrollWidth:"+scrollWidth+" offsetWidth:"+width)
        }
        return {width:width,height:height};
    }

    _setRef(componentNode) {
        this._rootNode = componentNode;
    }
    render() {
        const divStyle = { height: "100%" };
        return (
            <div
                className="scatterplot-container"
                ref={this._setRef.bind(this)}
                style={divStyle}
            />
        );
    }

}
export default Scatterplot;