import React from "react";
import { render } from "react-dom";
import { AuthProvider } from "oidc-react";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import rootReducer from "./features";

import App from "./containers/App";

import { env } from "./utils/setup";
import { oidcConfig } from "./utils/oidc-config";

env();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });
    return middlewares;
  },
});

render(
  <Provider store={store}>
    <AuthProvider {...oidcConfig} autoSignIn>
      <App />
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
