import React from "react";
import PropTypes from "prop-types";

import { MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

function DisplayColumnMenu(props) {
	const { show, onClick } = props;

	if (!show)
		return (
			<MenuItem onClick={onClick}>
				<ListItemIcon>
					<VisibilityIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Show by default</ListItemText>
			</MenuItem>
		);
	else
		return (
			<MenuItem onClick={onClick}>
				<ListItemIcon>
					<VisibilityOffIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Hide by default</ListItemText>
			</MenuItem>
		);
}

DisplayColumnMenu.propTypes = {
	show: PropTypes.bool.isRequired,
	onClick: PropTypes.func,
};

export default DisplayColumnMenu;
