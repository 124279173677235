import { Button, alpha, IconButton, withStyles } from '@material-ui/core';

// I create a generic version of the following code, more robust and efficiant
// It also works with a "dark" and "light" theme
// Source: https://codesandbox.io/s/rj34pyo75p?file=/demo.js:1320-1334

export const coloredButton = function (lightColor, darkColor) {
	return withStyles((theme) => {
		const main = theme.palette.type === 'dark' ? darkColor : lightColor;
		const secondary = theme.palette.type === 'dark' ? lightColor : darkColor;

		return {
			text: {
				color: main,
				'&:hover': {
					backgroundColor: alpha(main, theme.palette.action.hoverOpacity),
					'@media (hover: none)': {
						backgroundColor: 'transparent',
					},
				},
			},
			outlined: {
				color: main,
				border: `1px solid ${alpha(main, 0.5)}`,
				'&:hover': {
					backgroundColor: alpha(main, theme.palette.action.hoverOpacity),
					border: `1px solid ${main}`,
					'@media (hover: none)': {
						backgroundColor: 'transparent',
					},
				},
				'&disabled': {
					border: `1px solid ${theme.palette.action.disabled}`,
				},
			},
			contained: {
				color: theme.palette.getContrastText(main),
				backgroundColor: main,
				'&:hover': {
					backgroundColor: secondary,
					'@media (hover: none)': {
						backgroundColor: main,
					},
				},
			},
		};
	})(Button);
};

export const coloredIconButton = function (lightColor, darkColor) {
	return withStyles((theme) => {
		const main = theme.palette.type === 'dark' ? darkColor : lightColor;
		// const secondary = theme.palette.type === "dark" ? lightColor : darkColor;
		return {
			root: {
				color: main,
				'&:hover': {
					backgroundColor: alpha(main, theme.palette.action.hoverOpacity),
					'@media (hover: none)': {
						backgroundColor: 'transparent',
					},
				},
				'&disabled': {
					backgroundColor: 'transparent',
					color: theme.palette.action.disabled,
				},
			},
		};
	})(IconButton);
};
