import React from "react";

import { Grid, makeStyles, Typography } from "@material-ui/core";

// Not used here, style is hardcoded in this first version.
const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function Admin() {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        className={classes.root}
        spacing={2}
        justifyContent="flex-end"
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant="body">This page is empty for now</Typography>
        </Grid>
      </Grid>

      {/* <Grid container>
        <Grid item xs={12}>
        </Grid>

        <Grid item xs={12} md={6} className={classes.chooseDataset}>
          <ChooseDataset admin />
        </Grid>

      </Grid> */}
    </>
  );
}
