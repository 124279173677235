import { createSlice, current } from "@reduxjs/toolkit";
import {
  keepDataSelection,
  partitionSchema,
  removeDataSelection,
  resetDataSelection,
  setInitSchema,
  updateColumns,
  updateSchemaColumns,
} from "./dataset";

import {
  defaultColor,
  toHexColor,
  darkColorInt,
  lightColorInt,
} from "../components/Colors/colors";
import { sort } from "../utils/array";

// import { logoutProfile } from "./app";
// import { sendCluster, sendSimilarity } from "./analytic";

const initialState = {
  render: true,
  menu: false,
  defaultColor: defaultColor,
  colorScheme: defaultColor,
  backgroundColor: "transparent",
  backgroundColorInt: 255, // Must be btw [47, 255] => ["424242", "ffffff"]
  alpha: 0.5,
  // chart: null,
  colorPerRows: null,
  brushPredicate: "AND",
  excludedColumns: [], // the list of columns to exclude (also in table)
  selectedColumns: [], // the selected columns (also in table)
  brushed: [],
  brushState: null,
  brushedMethod: null,
  isBrushed: false,
  highlight: [],
  lastHighlightedRow: undefined, // must be undefined for UI (used as parameter for similarity)
  variableSorting: "initial", // none or average
  applySort: false,
  monitoring: {
    parcoord: {
      rows: 0,
      remaining: 0,
      progress: 0,
    },
    brush: {
      rows: 0,
      remaining: 0,
      progress: 0,
    },
  },
};

// export to use it also in feature/table.js
export function updateSelectedExcludedColumns(state, newSchema) {
  // add new columns in selected and update existing in excluded or selected

  // merge all current excluded and selected
  let excluded = current(state.excludedColumns).slice();
  let selected = current(state.selectedColumns).slice();
  const merged = [].concat(excluded, selected);

  // new columns are added in selected at the end
  const columnsToAdd = newSchema.filter((column) => {
    const found = merged.find(
      (currentColumns) => currentColumns.name === column.name
    );
    return !found;
  });

  // update existing columns in excluded and selected
  const newSchemaByName = {};
  newSchema.forEach((column) => {
    newSchemaByName[column.name] = column;
  });

  const newExcluded = [];
  excluded.forEach((column) => {
    // avoid to add culumns that does not exist anymore in  newSchemaByName
    if (newSchemaByName[column.name]) {
      newExcluded.push(Object.assign({}, newSchemaByName[column.name]));
    }
  });
  // avoid to add culumns that does not exist anymore in newSchemaByName
  const newSelected = [];
  selected.forEach((column) => {
    // avoid to add culumns that does not exist anymore in newSchemaByName
    if (newSchemaByName[column.name]) {
      newSelected.push(Object.assign({}, newSchemaByName[column.name]));
    }
  });
  // add new column in selected
  columnsToAdd.forEach((columnToAdd) => {
    newSelected.push({ ...columnToAdd });
  });
  state.selectedColumns = newSelected;
  state.excludedColumns = newExcluded;
}

const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    toggleMenu(state, action) {
      state.menu = action.payload;
    },
    setRender(state, action) {
      state.render = action.payload;
    },
    setColor(state, action) {
      state.colorScheme = action.payload.colorScheme;
      state.colorPerRows = action.payload.colorPerRows;
    },
    setSchemeColor(state, action) {
      state.colorScheme = action.payload;
    },
    setAlpha(state, action) {
      state.alpha = action.payload;
    },
    setBackgroundColor(state, action) {
      if (
        action.payload === null ||
        isNaN(action.payload) ||
        // Mathematical reprensentation: ]-inf,darkColorInt] u [lightColorInt, +inf[
        action.payload >= lightColorInt ||
        action.payload <= darkColorInt
      ) {
        // state.backgroundColorInt = action.payload;
        state.backgroundColor = "transparent";
      } else {
        state.backgroundColorInt = action.payload;
        state.backgroundColor = toHexColor(action.payload);
      }
    },
    setBrushPredicate(state, action) {
      state.brushPredicate = action.payload;
    },
    setSelectedColumns(state, action) {
      state.selectedColumns = action.payload;
    },
    setExcludedColumns(state, action) {
      state.excludedColumns = action.payload;
    },
    setColumns(state, action) {
      state.selectedColumns = action.payload.selectedColumns;
      state.excludedColumns = action.payload.excludedColumns;
    },
    // + extraReducers in table.js
    reorderSchema(state, action) {
      // called by Parcoords.jsx to sort selectedColumns and excludedColumns when changed from interactions
      // Use state.orderedColumns to keep order of // coord
      let newSelected = sort(state.selectedColumns, action.payload, "name");
      let newExcluded = sort(state.excludedColumns, action.payload, "name");
      newSelected = newSelected.map((v, i) => {
        v._pos = i;
        return v;
      });
      newExcluded = newExcluded.map((v, i) => {
        v._pos = i;
        return v;
      });
      state.selectedColumns = newSelected;
      state.excludedColumns = newExcluded;
    },
    setBrushed(state, action) {
      const { method, brushed, brushState } = action.payload;
      state.brushedMethod = method;
      if (brushed === false) {
        state.brushed = [];
        state.brushState = null;
        state.isBrushed = false;
      } else {
        state.brushed = brushed;
        state.brushState = brushState;
        state.isBrushed = true;
      }
      console.log(
        `chart::setBrushed [${state.brushedMethod},${state.brushed.length}]`
      );
    },
    setHighlight(state, action) {
      if (Array.isArray(action.payload)) {
        state.highlight = action.payload;
        console.log(`chart::setHighlight [${state.highlight}]`);
        state.lastHighlightedRow =
          action.payload.length > 0
            ? action.payload[action.payload.length - 1]
            : undefined;
      } else {
        state.highlight = [action.payload];
        console.log(`chart::setHighlight [${state.highlight}]`);
        state.lastHighlightedRow = action.payload;
        if (state.lastHighlightedRow === null)
          state.lastHighlightedRow = undefined; // must not be null but undefined for UI
      }
    },
    setVariableSorting(state, action) {
      switch (action.payload) {
        case "average":
        case "correlation":
        case "initial":
          state.variableSorting = action.payload;
          break;
        default:
          console.log(
            `The provided sort "${action.payload}" does not exit. Set to "None"`
          );
          state.variableSorting = "initial";
          break;
      }
    },
    applySort(state, action) {
      state.applySort = true;
    },
    sortApplied(state, action) {
      state.applySort = false;
    },
    startMonitoring(state, { payload }) {
      const { action, rowsCount, rendering } = payload;
      if (rendering) {
        state.monitoring[action].name = action;
        state.monitoring[action].rows = rowsCount;
        state.monitoring[action].remaining = rowsCount;
        state.monitoring[action].progress = 0;
      }
    },
    setMonitoringProgress(state, { payload }) {
      const { action, nbRemaining } = payload;
      const p = state.monitoring[action].rows - nbRemaining;
      state.monitoring[action].remaining = nbRemaining;
      const progress = Math.round((p / state.monitoring[action].rows) * 100);
      if (progress !== state.monitoring[action].progress)
        state.monitoring[action].progress = progress;
    },
  },

  extraReducers: (builder) => {
    /**
     * When setSchema from dataset.js is trigger this reducer will
     * also performs a modification on its state.
     */
    builder

      .addCase(setInitSchema, (state, action) => {
        partitionSchema(state, action);
        state.brushed = [];
        state.colorPerRows = null;
        state.colorScheme = defaultColor;
        state.variableSorting = "initial";
      })
      .addCase(updateColumns, (state, action) => {
        const { newSchema, newData } = action.payload;
        // equivalent to initSchema

        // do not partition schema but add new columns in selected
        // partitionSchema(state, action);

        updateSelectedExcludedColumns(state, newSchema);

        // update highlights
        const newDataById = {};
        const idColumn = newSchema.find((column) => column.group === "Id");
        newData.forEach((d) => {
          newDataById[d[idColumn.name]] = d;
        });
        const newHighlight = state.highlight.slice().map((d) => {
          return newDataById[d[idColumn.name]];
        });
        state.highlight = newHighlight;
      })
      .addCase(updateSchemaColumns, (state, action) => {
        const newSchema = action.payload;
        updateSelectedExcludedColumns(state, newSchema);
      })
      .addCase(keepDataSelection, (state, action) => {
        state.brushed = [];
        state.isBrushed = false;
      })
      .addCase(removeDataSelection, (state, action) => {
        state.brushed = [];
        state.isBrushed = false;
      })
      .addCase(resetDataSelection, (state, action) => {
        state.brushed = [];
        state.isBrushed = false;
      });
    // .addCase(logoutProfile, (state) => initialState)
  },
});

export const {
  toggleMenu,
  setRender,
  setColor,
  setSchemeColor,
  setAlpha,
  setBackgroundColor,
  setBrushPredicate,
  setSelectedColumns,
  setExcludedColumns,
  setColumns,
  setBrushed,
  setVariableSorting,
  applySort,
  sortApplied,
  startMonitoring,
  setMonitoringProgress,
  setHighlight,
  reorderSchema,
} = chartSlice.actions;

export default chartSlice.reducer;

// const backendUrl = process.env.REACT_APP_BACKEND_URI;
// const debug = process.env.REACT_APP_DEBUGMODE === "true";

// Async functions (use dispatch)
