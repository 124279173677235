import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Parcoords from "../Parcoords/index";

import {
  darkColor,
  lightColor,
  darkColorInt,
  lightColorInt,
} from "../Colors/colors";
import {
  setMonitoringProgress,
  startMonitoring,
  reorderSchema, // based on select/excluded lists
  sortApplied,
} from "../../features/chart";
import { synchronizeWithSelectedColumns } from "../../features/correlation";

export default function ChartD3(props) {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.app);
  const { currentData, schema } = useSelector((state) => state.dataset);
  const { corrMatrix } = useSelector((state) => state.correlation);
  const { applySort } = useSelector((state) => state.chart);
  const {
    defaultColor,
    colorScheme,
    colorPerRows,
    alpha,
    backgroundColor,
    backgroundColorInt,
    brushPredicate,
    selectedColumns,
    variableSorting,
    highlight,
    brushed,
    brushedMethod,
    brushState,
  } = useSelector((state) => state.chart);

  const id = schema.find((s) => s.group === "Id").name;

  // Color
  const color =
    colorScheme !== defaultColor && colorPerRows
      ? function (item) {
        return colorPerRows[item[id]];
      }
      : colorScheme;

  // Background Color
  let shouldDark;
  if (backgroundColor === "transparent") shouldDark = mode;
  else if (backgroundColorInt <= Math.round((lightColorInt + darkColorInt) / 2))
    shouldDark = true;
  else shouldDark = false;

  // In a single loop (reducer): set the dimensions and the types
  // Complexity: O(n), n is the number of element in the list.
  function computeDimensions() {
    // use selected columns only
    let { dimensions, types, titles } = selectedColumns.reduce(
      // use selectedColumn to preserve // coord order
      (acc, curr) => {
        // check if exists in schema and retrieve the types
        if (schema.find((s) => s.name === curr.name)) {
          acc.dimensions.push(curr.name);
          acc.titles[curr.name] = (curr.displayedName?.trim())?curr.displayedName:curr.name
          acc.types[curr.name] =
            curr.type === "factor" || curr.type === "character"
              ? "string"
              : "number";
        }
        return acc;
      }, // end reduce function
      { dimensions: [], types: {}, titles: {} } // init list of reduce
    );
    return { dimensions, types, titles };
  }
  function computeBaseDimensions() {
    let { baseDimensions, baseTypes, titles } = schema.reduce(
      (acc, curr) => {
        // All Columns
        acc.baseDimensions.push(curr.name);
        acc.titles[curr.name] = (curr.displayedName?.trim())?curr.displayedName:curr.name;
        acc.baseTypes[curr.name] =
          curr.type === "factor" || curr.type === "character"
            ? "string"
            : "number";

        return acc;
      },
      { baseDimensions: [], baseTypes: {}, titles: {} }
    );
    return { baseDimensions, baseTypes, titles };
  }


  const initialState = {
    baseTypes: {},
    baseDimensions: [],
    baseTitles: {},
    types: {},
    dimensions: [],
    titles: {},
    valuesForOrdering: {},
    reorderDescDir: false,
    variableSorting: variableSorting,
    currentData: [],
  };
  const [state, setState] = useState(initialState);
  const currentDataRef = useRef(null);
  const schemaRef = useRef(null);
  const selectedColumnsRef = useRef(null);
  const variableSortingRef = useRef(null);
  const corrMatrixRef = useRef(null);

  useEffect(() => {
    let newState = { ...state };
    let { baseDimensions, baseTypes, baseTitles } = newState;
    if (schemaRef.current !== schema) {
      // schema changes => modify all columns
      const res = computeBaseDimensions();
      baseDimensions = res.baseDimensions;
      baseTypes = res.baseTypes;
      baseTitles = res.titles;

      schemaRef.current = schema;
      newState = { ...newState, baseDimensions, baseTypes, baseTitles };
    }
    if (currentDataRef.current !== currentData) {
      currentDataRef.current = currentData;
      newState = { ...newState, currentData };
    }
    let { dimensions, types, titles } = newState;
    if (selectedColumnsRef.current !== selectedColumns) {
      // selectedColumns changes modify dimensions
      const res = computeDimensions();
      dimensions = res.dimensions;
      types = res.types;
      titles = res.titles;
      selectedColumnsRef.current = selectedColumns;
      newState = { ...newState, dimensions, types, titles, };
      dispatch(synchronizeWithSelectedColumns(selectedColumns));
    }

    // add check corrMatrix if we want the axes sync with the matrix order  || (variableSorting==="correlation" && corrMatrixRef.current!==corrMatrix) ) {
    // add variableSortingRef.current !== variableSorting if reorder is triggered with variable sorting change
    if (applySort) {
      // set to true when pushing button Apply
      // compute average for sorting
      let valuesForOrdering = {};
      let reorderDescDir = false;
      if (variableSorting === "initial") {
        // sort from origin
        newState.baseDimensions.forEach((variable, i) => {
          if (valuesForOrdering[variable] === undefined) {
            valuesForOrdering[variable] = i;
          }
        });
      } else if (variableSorting === "average") {
        reorderDescDir = true;
        newState.dimensions.forEach((variable) => {
          if (newState.types[variable] === "string") {
            let categories = {};
            let maxValue = "";
            let max = 0;
            currentData.forEach((d) => {
              let value = d[variable];
              if (value in categories) categories[value]++;
              else categories[value] = 1;

              if (categories[value] > max) {
                max = categories[value];
                maxValue = value;
              }
            });
            valuesForOrdering[variable] = maxValue;
          } else {
            let [count, sum] = currentData.reduce(
              (acc, curr) => {
                if (variable in curr) {
                  acc[0]++;
                  acc[1] += curr[variable];
                }
                return acc;
              },
              [/*count:*/ 0, /*sum: */ 0]
            );
            valuesForOrdering[variable] = sum / count;
          }
        });
      } else if (variableSorting === "correlation") {
        let posOthers = 0;
        if (corrMatrix && corrMatrix.columns && corrMatrix.columns.length > 0) {
          corrMatrix.columns.forEach((variableName, i) => {
            valuesForOrdering[variableName] = i;
            posOthers = i;
          });
          posOthers++;
        }

        newState.dimensions.forEach((variable, i) => {
          if (valuesForOrdering[variable] === undefined) {
            valuesForOrdering[variable] = posOthers;
            posOthers++;
          }
        });
      }
      // TODO implement this one
      else if (variableSorting === "tree") {
      }
      newState = {
        ...newState,
        valuesForOrdering,
        reorderDescDir,
        variableSorting,
      };
      variableSortingRef.current = variableSorting;
      dispatch(sortApplied());
    } // end if variableSortingRef!==variableSorting
    setState(newState);
    if (corrMatrixRef.current !== corrMatrix) {
      corrMatrixRef.current = corrMatrix;
    }
    // eslint-disable-next-line
  }, [schema, currentData, selectedColumns, applySort]); // add corrMatrix if // sync with matrix order => not sync but sort call

  // init monitoring
  const monitoring = {
    startRendering: function (action, rowsCount) {
      dispatch(startMonitoring({ action, rowsCount, rendering: true }));
    },
    stopRendering: function (action, status) { },
    setRemainingRenderingNb: function (action, nbRemaining, status) {
      dispatch(setMonitoringProgress({ action, nbRemaining }));
    },
  };

  return (
    <Parcoords
      data={state.currentData}
      renderAtInit={false}
      brushMode="1D-axes-multi"
      brushed // use default false // Fixme: disambiguate brush and brushed
      brush={brushed} // Fixme: disambiguate brush and brushed
      brushedMethod={brushedMethod}
      brushState={brushState}
      height={props.height * 0.95}
      reorderable
      axesreorder={(newOrder) => dispatch(reorderSchema(newOrder))} // called when reordered with interaction
      queued
      color={color}
      colorName={colorScheme}
      brushedColor={shouldDark ? lightColor : darkColor}
      brushPredicate={brushPredicate}
      alphaOnBrushed={0.5}
      // onbrush={(sel) => console.log('brush', sel)}
      onbrushend={props.onBrushEnd}
      alpha={alpha}
      highlight={highlight}
      highlightColor={"red"}
      highlightFaded
      config={{
        rate: 50,
        dimensionTitleRotation: -15,
      }}
      types={state.types}
      dimensions={state.dimensions}
      dimensionTitles={state.titles}
      // baseTypes={state.baseTypes}
      // baseDimensions={state.baseDimensions}
      isDark={shouldDark}
      render={props.render}
      reorderStrategy={state.variableSorting}
      reorderDescDir={state.reorderDescDir}
      reorder={state.valuesForOrdering}
      monitoring={monitoring}
    />
  );
}
