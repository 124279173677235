import { decodeJwt } from "jose";

export default class Auth {
  static getAuth() {
    const storageKey = `oidc.user:${window.__env__.REACT_APP_OIDC_AUTHORITY}:${window.__env__.REACT_APP_OIDC_CLIENT_ID}`;
    // http://localhost:8080/realms/lamda
    // webclient
    const item = sessionStorage.getItem(storageKey);
    const auth = JSON.parse(item);
    return auth;
  }
}

export function isAuthenticated(userData) {
  return !!userData && !userData.expired;
}

export function isAdmin(userData) {
  if (!userData) return false;
  const decoded = isAuthenticated ? decodeJwt(userData.access_token) : false;

  if (!decoded) return false;

  if (decoded.realm_access.roles.includes("admin")) return true;

  return false;
}
