export function validateMargin(margin = {}) {
  let { top = 40, bottom = 12, right = 20, left = 20 } = margin;

  if (typeof margin === "number") {
    top = margin;
    bottom = margin;
    right = margin;
    left = margin;
  } else if (Array.isArray(margin)) {
    if (margin.length > 3) {
      top = margin[0] || top;
      bottom = margin[2] || bottom;
      right = margin[1] || right;
      left = margin[3] || left;
    } else if (margin.length > 1) {
      top = margin[0] || top;
      bottom = margin[0] || bottom;
      right = margin[1] || right;
      left = margin[1] || left;
    } else if (margin.length > 0) {
      top = margin[0] || top;
      bottom = margin[0] || bottom;
      right = margin[0] || right;
      left = margin[0] || left;
    }
  }

  return [top, right, bottom, left];
}

function average_number(array) {
  return (
    array.reduce((acc, curr) => {
      acc += curr;
      return acc;
    }, 0) / array.length
  );
}

function average_string(array) {
  let count = 0,
    name;
  array.reduce((acc, curr) => {
    if (acc[curr] === undefined) acc[curr] = 1;
    else acc[curr]++;

    if (acc[curr] > count) {
      count = acc[curr];
      name = curr;
    }
    return acc;
  });

  return [count, name];
}

export function average(array) {
  if (array.length === 0) return [];

  if (typeof array[0] === "number") return average_number(array);

  if (typeof array[0] === "string") return average_string(array)[1];

  if (typeof array[0] === "object") {
    const lists = array.reduce((acc, curr) => {
      for (let key in curr) {
        if (acc[key] === undefined) acc[key] = [];
        acc[key].push(curr[key]);
      }
      return acc;
    }, {});

    let averages = {};
    for (let variable in lists) {
      if (typeof lists[variable][0] === "string")
        averages[variable] = average_string(lists[variable])[1];
      else if (typeof lists[variable][0] === "number")
        averages[variable] = average_number(lists[variable]) / array.length;
    }

    return averages;
  }
}
