import React, { Component /*, useRef */ } from 'react';
import PropTypes from 'prop-types';

import { select } from 'd3';
// import { select } from "d3-selection";
import parcoords from './d3.parcoords';

import isEqual from 'lodash/isEqual';

import { Container } from './styled';
import { validateMargin } from './utils';

import setup from '../../utils/setup';
const { debug } = setup();

class Parcoords extends Component {
	constructor(props) {
		super(props);
		this.chartRef = React.createRef();
		this.setRef = this.setRef.bind(this);
	}

	/** internal */

	_init(config, reference) {
		debug && console.log(`[${Parcoords.name}] ${this._init.name}`);
		this.pc = parcoords(config)(reference);
	}
	_margin() {
		debug && console.log(`[${Parcoords.name}] ${this._margin.name}`);

		const valMargin = validateMargin(this.props.margin);
		const [top, right, bottom, left] = valMargin;
		if (this.pc) this.pc.margin({ top, bottom, right, left });
	}
	_queue() {
		debug && console.log(`[${Parcoords.name}] ${this._queue.name}`);

		if (this.pc) {
			if (this.props.queued) this.pc.mode('queue');
			else this.pc.mode('default');
		}
	}
	_data() {
		debug && console.log(`[${Parcoords.name}] ${this._data.name}`);

		if (this.pc) this.pc.data(this.props.data);
	}
	_color() {
		debug && console.log(`[${Parcoords.name}] ${this._color.name}`);
		if (this.pc) {
			this.pc.alpha(this.props.alpha);
			this.pc.color(this.props.color);
		}
	}
	_dimensions(init = false) {
		debug && console.log(`[${Parcoords.name}] ${this._dimensions.name}`);
		if (this.pc) {
			this.pc.types(this.props.types);
			this.pc.dimensions(this.props.dimensions);
			this.pc.dimensionTitles(this.props.dimensionTitles);
		}
	}
	_reorder() {
		debug && console.log(`[${Parcoords.name}] ${this._reorder.name}`);
		if (this.pc) {
			if (this.props.reorder && Object.keys(this.props.reorder).length > 0) {
				/**
				 * check if reorder is equal to average or not
				 * switch case ...
				 *
				 * refs:
				 * - angular version: in parcoordctl.js / setDimensions() ~line 150
				 * - in d3.parcoords.js lib pc.reorder ~1163l
				 */
				let compareYPos = false;
				let descDirection = this.props.reorderDescDir;
				if (this.props.reorderStrategy === 'average') {
					compareYPos = true;
				} else {
					compareYPos = false;
				}
				this.pc.reorder(this.props.reorder, compareYPos, descDirection);
			}
		}
	}
	_render(
		isCreate = false,
		shouldUpdateAxes = true,
		shouldApplyBrushState = false
	) {
		debug && console.log(`[${Parcoords.name}] ${this._render.name}`);
		if (this.props.render && this.pc) {
			if (isCreate) {
				this.pc.render();
				this.pc.createAxes();
			} else {
				if (shouldUpdateAxes) {
					this.pc.updateAxes(); // to avoid update axis for color changes
					// this.pc.applyBrushState({r_t44_peak_rating:[[130,120]]});
				}
				this.pc.render();
				if (shouldApplyBrushState) {
					this.pc.applyBrushState(this.props.brushState);
				}
			}
		}
	}
	_reorderable() {
		debug && console.log(`[${Parcoords.name}] ${this._reorderable.name}`);
		if (this.pc && this.props.reorderable) {
			if (this.props.axesreorder)
				this.pc.on('axesreorder', this.props.axesreorder);
			this.pc.reorderable();
		}
	}
	_highlight() {
		debug && console.log(`[${Parcoords.name}] ${this._highlight.name}`);
		if (this.pc) {
			this.pc.highlightColor(this.props.highlightColor);
			this.pc.isHighlightFaded(this.props.highlightFaded);
			if (this.props.highlight?.length > 0)
				this.pc.highlight(this.props.highlight, ['foreground']);
			else this.pc.unhighlight();
		}
	}
	_brushing() {
		// apply brushing properties
		debug && console.log(`[${Parcoords.name}] ${this._brushing.name}`);
		if (this.pc)
			if (this.props.brushed) {
				this.pc.brushMode(this.props.brushMode);
				this.pc.brushPredicate(this.props.brushPredicate);
				this.pc.alphaOnBrushed(this.props.alphaOnBrushed);
				this.pc.brushedColor(this.props.brushedColor);
				if (this.props.onbrush) this.pc.on('brush', this.props.onbrush);
				if (this.props.onbrushend)
					this.pc.on('brushend', this.props.onbrushend);
			} else this.pc.brushMode('None');
	}
	_setBrush() {
		debug && console.log(`[${Parcoords.name}] ${this._setBrush.name}`);
		if (this.pc) {
			this.pc.brushReset();
			if (this.props.brush.length > 0) {
				this.pc.brushed(this.props.brush);
				this.pc.renderBrushed();
			}
		}
	}
	_brushState() {
		debug && console.log(`[${Parcoords.name}] ${this._brushState.name}`);
		if (this.pc) {
			this.pc.brushState();
		}
	}
	_monitoring() {
		debug && console.log(`[${Parcoords.name}] ${this._monitoring.name}`);
		if (this.pc && this.props.monitoring)
			this.pc.setRenderingMonitoring(this.props.monitoring);
	}

	/** life-cycle */

	componentDidMount() {
		debug &&
			console.log(
				`[${Parcoords.name}:life-cycle] ${this.componentDidMount.name}`
			);
		this._init(this.props.config, this.chartRef);
		this._margin();
		this._queue();
		this._monitoring();
		this._data();
		this._color();
		// at this step the dimensions is empty or with all the variables detected from props.types or props.dimensions (ignoring display on load)
		this._dimensions(); // FIXME: props.dimensions always empty when componentDidMount()
		// create axes for all dimensions if props.data is not empty (does not render)
		this._render(true); // FIXME: props.data always empty when componentDidMount()
		this._highlight(); // FIXME: do nothing if no data
		this._reorderable();
		this._brushing();
		// Below: used to redraw the axes only with the selected columns.
		// Fixme: why not done during init before ?
		this._reorder();
		this.pc.updateAxes(); // update axes + render // FIXME: props.data always empty when componentDidMount()
	}
	componentWillUnmount() {
		debug &&
			console.log(
				`[${Parcoords.name}:life-cycle] ${this.componentWillUnmount.name}`
			);

		select(this.chartRef).selectAll('*').remove();
	}
	shouldComponentUpdate(nextProps, nextState) {
		let shouldUpdate = false;

		let comparators = [
			'colorName',
			'alpha',
			'isDark',
			'brushPredicate',
			// "brushedColor", // idem "isDark"
		];
		// !!! isEqual for data can be long !!! but done at the end and generally dimensions also change
		let isEqualComparators = ['reorder', 'dimensions', 'highlight', 'data'];

		comparators.forEach((c) => {
			if (nextProps[c] !== this.props[c]) {
				shouldUpdate = true;
				return;
			}
		});

		if (!shouldUpdate)
			isEqualComparators.forEach((ac) => {
				if (!isEqual(nextProps[ac], this.props[ac])) {
					shouldUpdate = true;
					return;
				}
			});

		// this section ensure that the brushed data are correctly removed and
		// consistent with the store (see below: componentDidMount)
		if (!shouldUpdate)
			if (
				nextProps.brushedMethod === 'DataTableToolbar::onKClosestClick' &&
				nextProps.brush !== this.props.brush
			)
				// used to replace and render the brush if coming from onKClosestClick
				// FIXME: Used to remove the brush if empty ? Why ?
				// if (nextProps.brush.length === 0 &&
				//     nextProps.brush.length !== this.props.brush.length
				// )
				shouldUpdate = true;
		debug &&
			console.log(
				`[${Parcoords.name}:life-cycle] ${this.shouldComponentUpdate.name}: ${shouldUpdate}`
			);

		return shouldUpdate;
	}
	componentDidUpdate(prevProps) {
		debug &&
			console.log(
				`[${Parcoords.name}:life-cycle] ${this.componentDidUpdate.name}`
			);

		let shouldRender = false;
		let shouldUpdateAxis = false;
		let shouldApplyBrushState = false;

		// FIXME: isEqual on data can be long
		const dataChanged = !isEqual(prevProps.data, this.props.data);
		if (dataChanged) {
			this._data();
			// set dimensions with the schema values where displayOnLoad = true
			// then auto scale axes,
			// and finally update axes.
			this._dimensions();
			this.pc.autoscale();
			// this._dimensions();

			shouldRender = true;
			shouldUpdateAxis = true;
			shouldApplyBrushState = true;
		}

		if (
			prevProps.alpha !== this.props.alpha ||
			prevProps.colorName !== this.props.colorName
		) {
			this._color();
			shouldRender = true;
		}

		if (
			!dataChanged &&
			prevProps.dimensions.length !== this.props.dimensions.length
		) {
			this._dimensions();
			this.pc.autoscale(true);
			shouldRender = true;
			shouldUpdateAxis = true;
		}
		// N.M. Put reorder after dimensions to avoid override reordered by dimensions
		// TODO: check if side effects appear in init, moving data changes from data<=>overview etc..
		if (!isEqual(prevProps.reorder, this.props.reorder)) {
			this._reorder();
		}

		if (shouldRender) {
			const isCreate = false;
			this._render(isCreate, shouldUpdateAxis, shouldApplyBrushState);
		}
		// apply selection in any case due to uncontrolled removed (normally just a few objects => not time consuming)
		// if (!isEqual(prevProps.highlight, this.props.highlight)){
		this._highlight();
		// }

		if (
			prevProps.brushMode !== this.props.brushMode ||
			prevProps.brushPredicate !== this.props.brushPredicate ||
			prevProps.alphaOnBrushed !== this.props.alphaOnBrushed ||
			prevProps.brushedColor !== this.props.brushedColor
		) {
			this._brushing();
		}
		// FIXME: why removing the brush if result is empty ?
		// if (this.props.brush.length === 0)
		//   this.pc.brushReset();
		// else{
		if (
			prevProps.brush !== this.props.brush &&
			this.props.brushedMethod === 'DataTableToolbar::onKClosestClick'
		) {
			console.log(
				`Brush changed by ${this.props.brushedMethod} => calling this._setBrush()`
			);
			this._setBrush(this.props.brush);
		}

		// // handle change on brushState
		// if(prevProps.brushState!==this.props.brushState){
		//   // DO something
		//   console.log("brushState changed");
		// }
		// this._reorderable();// FIXME: Why calling _reorderable at each update ?
	}

	setRef(ref) {
		this.chartRef = ref;
	}

	render() {
		// Extract color from styleProps otherwise you will receive a warning:
		// https://reactjs.org/blog/2017/09/08/dom-attributes-in-react-16.html#changes-in-detail
		const { className, width, height, color, ...styleProps } = this.props;
		return (
			<Container
				ref={this.setRef}
				className={className}
				width={typeof width === 'string' || !width ? width : `${width}px`}
				height={typeof height === 'string' || !height ? height : `${height}px`}
				{...styleProps}
			/>
		);
	}
}

Parcoords.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
	dimensions: PropTypes.array,
	// baseDimensions: PropTypes.array.isRequired,
	types: PropTypes.object,
	// baseTypes: PropTypes.object.isRequired,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	config: PropTypes.object,

	queued: PropTypes.bool,
	reorderable: PropTypes.bool,
	axesreorder: PropTypes.func,
	brush: PropTypes.array.isRequired,
	brushed: PropTypes.bool,
	brushedMethod: PropTypes.string,
	brushMode: PropTypes.string,
	onbrush: PropTypes.func,
	onbrushend: PropTypes.func,
	brushPredicate: PropTypes.oneOf(['AND', 'OR']),

	color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	colorName: PropTypes.string,
	alpha: PropTypes.number.isRequired,
	brushedColor: PropTypes.string,
	alphaOnBrushed: PropTypes.number,

	highlight: PropTypes.array,
	highlightColor: PropTypes.string,
	highlightFaded: PropTypes.bool,
	reorderStrategy: PropTypes.oneOf(['initial', 'average', 'correlation']),
	reorderDescDir: PropTypes.bool,
	reorder: PropTypes.object,
	monitoring: PropTypes.shape({
		startRendering: PropTypes.func,
		stopRendering: PropTypes.func,
		setRemainingRenderingNb: PropTypes.func,
	}),

	watch: PropTypes.arrayOf(PropTypes.array),
	styleProps: PropTypes.any,
};

Parcoords.defaultProps = {
	data: null,
	queued: false,
	reorderable: false,
	brush: [],
	brushed: false,
	brushMode: '1D-axes',
	brushPredicate: 'AND',
	color: '#069',
	colorName: '#069',
	highlightColor: 'red',
	highlightFaded: true,
	alpha: 0.5,
	brushedColor: 'black',
	alphaOnBrushed: 0,
	watch: [],
};

export default Parcoords;

export function startRendering(action, rowsCount) {
	console.log('This function is an interface');
	return;
}

export function stopRendering(action, status) {
	console.log('This function is an interface');
	return;
}

export function setRemainingRenderingNb(action, nbRemaining, status) {
	console.log('This function is an interface');
	return;
}
