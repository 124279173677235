import { Fab, withStyles } from "@material-ui/core";

export const coloredFab = function (lightColor, darkColor) {
	return withStyles((theme) => {
		const main = theme.palette.type === "dark" ? darkColor : lightColor;
		// const secondary = theme.palette.type === "dark" ? lightColor : darkColor;
		return {
			root: {
				color: theme.palette.primary.contrastText,
				backgroundColor: main,
				"&:hover": {
					backgroundColor: main,
					// Reset on touch devices, it doesn't add specificity
					"@media (hover: none)": {
						backgroundColor: main,
					},
				},
			},
		};
	})(Fab);
};
