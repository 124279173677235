import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import science from "science";
// import reorder from 'reorder.js/reorder.v1.min';
import reorder from "../utils/reorder.v1";
import setup from "../utils/setup";
import {setHighlight} from "./chart";
import {getRowIdName} from "./dataset"
const {debug}=setup();

function _corrMatrixToValArray(corrMatrix) {
  const data = corrMatrix.data;
  const res = [];
  for (let i = 0; i < data.length; i++) {
    for (let j = i + 1; j < data.length; j++) {
      res.push(data[i][j].value);
    }
  }
  return res;
}
function _applyPermutation(matrixData, perm) {
  var xNames = [];
  var yNames = [];
  var newData = [];
  perm.forEach((di) => {
    xNames.push(matrixData.index[di]);
    yNames.push(matrixData.columns[di]);
    const newRow = [];
    perm.forEach((dj) => {
      newRow.push(matrixData.data[di][dj]);
    });
    newData.push(newRow);
  });
  return { index: xNames, columns: yNames, data: newData };
}
function _getMatrixWithNewVariableOrder(matrixData, variableNameOrder) {
  const perm = []; // array of matrixData indexes in the new order
  // add variables of parcoords that exists in the matrix in the order of parcoords
  variableNameOrder.forEach((variableName) => {
    const varIdx = matrixData.index.indexOf(variableName);
    if (varIdx >= 0) {
      perm.push(varIdx);
    }
  });
  // add at the end the variables of matrix that are missing in parcoord
  // in the order where they appear (=>their index) in matrixData
  if (perm.length < matrixData.index.length) {
    const sortedPerm = perm.slice().sort((a, b) => a - b);
    let i = 0;
    // add missing in intermediate position
    sortedPerm.forEach((d) => {
      while (d > i) {
        perm.push(i);
        i++;
      }
      i++;
    });
    // add
    while (i < matrixData.index.length) {
      perm.push(i);
      i++;
    }
  }
  return _applyPermutation(matrixData, perm);
}
function _getScienceDistanceFromString(str) {
  if (str === "manhattan") {
    return science.stats.distance.manhattan;
  } else if (str === "euclidean") {
    return science.stats.distance.euclidean;
  } else if (str === "chebyshev") {
    return science.stats.distance.chebyshev;
  } else if (str === "hamming") {
    return science.stats.distance.hamming;
  } else if (str === "jaccard") {
    return science.stats.distance.jaccard;
  } else if (str === "braycurtis") {
    return science.stats.distance.braycurtis;
  } else {
    return undefined;
  }
}
// function _filterMatrixWithParCoordColumns(corrMatrix,parCoordSelectedColumns){
//     const xNames=[];
//     const yNames=[];
//     const data=[];
//     const parCoordSelectedColumnNames = parCoordSelectedColumns.map(x=>x.name);
//     corrMatrix.index.forEach((di,i)=>{
//         if(parCoordSelectedColumnNames.indexOf(di)>=0){
//             xNames.push(corrMatrix.index[i]);
//             yNames.push(corrMatrix.columns[i]);
//             const newRow=[];
//             corrMatrix.columns.forEach((dj,j)=>{
//                 if(parCoordSelectedColumnNames.indexOf(dj)>=0) {
//                     newRow.push(corrMatrix.data[i][j])
//                 }
//             })
//             data.push(newRow);
//         }
//     });
//     return {index:xNames,columns:yNames,data:data}
// }
function _synchronizeMatrixWithParCoordColumnsIfNecessary(
  originMatrix,
  corrMatrix,
  selectedColumns
) {
  // TODO: add/remove column if necessary
  const selectedColumnsName = selectedColumns.map((d) => d.name);
  let toUpdate = false;
  const xName = [];
  const yName = [];
  const data = [];
  // remove if not in selected Columns
  corrMatrix.index.forEach((rowName, i) => {
    if (selectedColumnsName.indexOf(rowName) >= 0) {
      // populate newMatrix in the case of a variable has been removed
      xName.push(rowName);
      yName.push(rowName);
      const newRow = [];
      corrMatrix.columns.forEach((colName, j) => {
        if (selectedColumnsName.indexOf(colName) >= 0) {
          newRow.push(corrMatrix.data[i][j]);
        }
      });
      data.push(newRow);
    } else {
      // a varaible has been removed set toUpdate but do not add the variable in the new matrix
      toUpdate = true;
    }
  });
  // add new variables not in matrix
  selectedColumns.forEach((column) => {
    const variable = column.name;
    if (xName.indexOf(variable) < 0) {
      // add new variable at the end...
      const originRowIdx = originMatrix.index.indexOf(variable);
      if (originRowIdx >= 0) {
        toUpdate = true;
        xName.push(variable);
        yName.push(variable);
        // add the new column to previous rows
        data.forEach((rowArr, i) => {
          const originColIdx = originMatrix.columns.indexOf(variable);
          const originI = originMatrix.index.indexOf(xName[i]);
          rowArr.push(originMatrix.data[originI][originColIdx]);
        });
        // add the new row
        const newRow = [];
        yName.forEach((colName) => {
          const originColIdx = originMatrix.columns.indexOf(colName);
          newRow.push(originMatrix.data[originRowIdx][originColIdx]);
        });
        data.push(newRow);
      } else if (column.type === "number") {
        // TODO: handle column not in originMatrix
        console.log(
          "Variable '" +
            variable +
            "' was not in correlation matrix originally computed! Matrix need to be computed again."
        );
      }
    }
  });
  if (toUpdate) {
    return { index: xName, columns: yName, data };
  } else {
    return corrMatrix;
  }
}

function _reorderMatrix(
  originMatrix,
  matrixToReorder,
  reorderingConfig,
  filterThr,
  parCoordSelectedColumns = null
) {
  // let order_f=reorder.order;
  if (reorderingConfig.selectedReorderingMethod === "initial") {
    // originMatrix.indexes
    return _getMatrixWithNewVariableOrder(matrixToReorder, originMatrix.index);
  } else if (reorderingConfig.selectedReorderingMethod === "seriation") {
    let absValuesMatrix = matrixToReorder.data.map((d1) =>
      d1.map((d2) => {
        return d2.value;
      })
    );
    if (reorderingConfig.ignoreCoefSign) {
      absValuesMatrix = absValuesMatrix.map((d) => {
        return d.map((d2) => {
          return Math.abs(d2);
        });
      });
    }
    if (reorderingConfig.isReorderedOnFilteredMatrix) {
      absValuesMatrix = absValuesMatrix.map((d) => {
        return d.map((d2) => {
          if (
            Math.abs(d2.value) < filterThr.min ||
            Math.abs(d2.value) > filterThr.max
          ) {
            return 0;
          } else {
            return d2;
          }
        });
      });
    }
    let order_f = reorder.optimal_leaf_order();
    const perm = order_f //.distance_matrix(data)
      .distance(
        _getScienceDistanceFromString(reorderingConfig.selectedDistanceMetric)
      )
      .reorder(absValuesMatrix);
    // console.log(perm.length);
    return _applyPermutation(matrixToReorder, perm);
  } else if (reorderingConfig.selectedReorderingMethod === "parcoord") {
    const variableNameOrder = parCoordSelectedColumns.map(
      (variable) => variable.name
    );
    return _getMatrixWithNewVariableOrder(matrixToReorder, variableNameOrder);
  }
  //
  // return originMatrix;
}
function _setCorrMatrix(state, corrMatrix) {
  state.corrMatrix = corrMatrix;
}
function _setFilterThr(state, filterThr) {
  state.filterThr = filterThr;
}

function _setSelectedReorderingMethod(state, methodName) {
  state.reorderingConfig.selectedReorderingMethod = methodName;
}

export function removeColumnsFromCorrelationMatrix(
  columns,
  corrMatrix,
  countByPair
) {
  const xName = [];
  const yName = [];
  const data = [];
  // remove if not in selected Columns
  corrMatrix.index.forEach((rowName, i) => {
    if (columns.indexOf(rowName) < 0) {
      // populate newMatrix in the case of a variable has been removed
      xName.push(rowName);
      yName.push(rowName);
      const newRow = [];
      corrMatrix.columns.forEach((colName, j) => {
        if (columns.indexOf(colName) < 0) {
          newRow.push(corrMatrix.data[i][j]);
        }
      });
      data.push(newRow);
    }
  });
  // remove columns from countByPair
  const newCountByPair = { ...countByPair };
  columns.forEach((column) => {
    delete newCountByPair[column];
  });
  Object.keys(newCountByPair).forEach((key) => {
    const currentObject = { ...newCountByPair[key] };
    columns.forEach((column) => {
      delete currentObject[column];
    });
    newCountByPair[key] = currentObject;
  });
  return {
    corrMatrix: { index: xName, columns: yName, data },
    countByPair: newCountByPair,
  };
}

export function getDataForPair(schema, data, xName, yName) {
  const idKey = getRowIdName(schema);
  return data.map((d) => {
    return { rowId: d[idKey], valueX: d[xName], valueY: d[yName] };
  });
}

export function getRowByRowId(schema, data, rowId){
  const idKey = getRowIdName(schema);
  return data.find(d=>d[idKey]===rowId);
}

export function connectDimensionsCorrMatrixToScatterplot(pairInMatrix){
  // xName in matrix is the column name => become label of x Axis in scatterplot (xName)
  // yName in matrix is the row name => become label of y Axis in scatterplot (yName)
  return {xName:pairInMatrix.xName, yName:pairInMatrix.yName}

}

export const handleClickedPairInMatrix = createAsyncThunk(
    "correlation/handleClickedPairInMatrix",
    async (clickedPairInMatrix, { dispatch, getState, rejectWithValue }) => {
      debug &&
      console.log(
          `[${correlationSlice.name}:handleClickedPairInMatrix]  `
      );
      try {
        const featurePairInScatterplot = connectDimensionsCorrMatrixToScatterplot(clickedPairInMatrix);
        const {schema,data} = getState().dataset;
        const scatterplotData = getDataForPair(schema,data,featurePairInScatterplot.xName,featurePairInScatterplot.yName);
        dispatch(setLastClickedPairInMatrix(clickedPairInMatrix))
        dispatch(setSingleScatterplotData({featurePair:featurePairInScatterplot, data:scatterplotData}))
      } catch (err) {
        console.log(`[${correlationSlice.name}:handleClickedPairInMatrix]`, err);
        return rejectWithValue(err);
      }
    });

export const handleHoveredPairInMatrix = createAsyncThunk(
    "correlation/handleHoveredPairInMatrix",
    async (hoveredPairInMatrix, { dispatch, getState, rejectWithValue }) => {
      debug &&
      console.log(
          `[${correlationSlice.name}:handleHoveredPairInMatrix]  function `
      );
      try {
        dispatch(setHoveredPairInMatrix(hoveredPairInMatrix));
      } catch (err) {
        console.log(`[${correlationSlice.name}:handleHoveredPairInMatrix]`, err);
        return rejectWithValue(err);
      }
    });

export const handleClickedItemInScatterplot = createAsyncThunk(
    "correlation/handleClickedItemInScatterplot",
    async (clickedItemInScatterplot, { dispatch, getState, rejectWithValue }) => {
      debug &&
      console.log(
          `[${correlationSlice.name}:handleClickedItemInScatterplot]  function `
      );
      try {
        const {schema,data} = getState().dataset
        const row = getRowByRowId(schema, data, clickedItemInScatterplot.rowId)
        dispatch(setClickedItemInScatterplot(clickedItemInScatterplot));
        dispatch(setHighlight(row));
      } catch (err) {
        console.log(`[${correlationSlice.name}:handleHoveredPairInMatrix]`, err);
        return rejectWithValue(err);
      }
    });


const correlationSlice = createSlice({
  name: "correlation",
  initialState: {
    originMatrix: null,
    corrMatrix: null,
    corrCoefArr: null,
    countByPair: null,
    loading: false,
    hoveredPairInMatrix: null,
    lastClickedPairInMatrix: null,
    singleScatterplotData: {featurePair:null, data:null},
    chosenFeatures: [],
    sliderScaleDomain: { min: -1, max: 1 },
    sliderBrushExtent: { min: 0, max: 1 },
    filterThr: { min: 0.5, max: 1 },
    syncWithParCoordColumns: true,
    resizeListenerAddedForHeatmap: false,
    resizeListenerAddedForSlider: false,
    reorderingConfig: {
      isReorderedOnFilteredMatrix: false,
      selectedReorderingMethod: "parcoord", // parcoord, seriation, initial
      ignoreCoefSign: true,
      selectedDistanceMetric: "euclidean",
    },
    clickedItemInScatterplot:null,
  },
  reducers: {
    setOriginMatrix(state, action) {
      const { corrMatrix, selectedColumns } = action.payload;
      state.originMatrix = corrMatrix;
      let filteredMatrix = corrMatrix;
      if (state.syncWithParCoordColumns) {
        filteredMatrix = _synchronizeMatrixWithParCoordColumnsIfNecessary(
          state.originMatrix,
          corrMatrix,
          selectedColumns
        );
      }
      const reorderedMatrix = _reorderMatrix(
        state.originMatrix,
        filteredMatrix,
        state.reorderingConfig,
        state.filterThr,
        selectedColumns
      );
      _setCorrMatrix(state, reorderedMatrix);
      state.corrCoefArr = _corrMatrixToValArray(reorderedMatrix);
    },
    setCorrMatrix(state, action) {
      _setCorrMatrix(state, action.payload);
    },
    setCountByPair(state, action) {
      state.countByPair = action.payload;
    },
    setHoveredPairInMatrix: (state, action) => {
      state.hoveredPairInMatrix = action.payload;
    },
    setLastClickedPairInMatrix: (state, action) => {
      state.lastClickedPairInMatrix = action.payload;
    },
    setSingleScatterplotData:(state, action)=>{
      state.singleScatterplotData = action.payload;
    },
    updateChosenFeatures: (state, action) => {
      let featureName = action.payload.id;
      // TODO: remove after test
      // if(featureName===undefined || featureName===null){
      //     featureName=schema[state.featureIdx].name
      // }
      const idx = state.chosenFeatures.indexOf(featureName);
      // let newState=Object.assign({},state);
      if (idx < 0) {
        state.chosenFeatures = [...state.chosenFeatures, featureName];
      } else {
        const arr = state.chosenFeatures.slice();
        arr.splice(idx, 1);
        state.chosenFeatures = arr;
      }
      // newState.featureIdx = newState.featureIdx + 1 ;
      // setState(newState);
    },
    setFilterThr: (state, action) => {
      _setFilterThr(state, action.payload);
    },
    applyFilterThreshold: (state, action) => {
      const newFilterThr = action.payload;
      _setFilterThr(state, action.payload);
      if (
        state.reorderingConfig.selectedReorderingMethod === "seriation" &&
        state.reorderingConfig.isReorderedOnFilteredMatrix
      ) {
        const newMatrix = _reorderMatrix(
          state.originMatrix,
          state.corrMatrix,
          state.reorderingConfig,
          newFilterThr
        );
        _setCorrMatrix(state, newMatrix);
        // }else{
        //     newMatrix=state.corrMatrix;
      }
    },
    // setSelectedReorderingMethod:(state,action)=>{
    //     _setSelectedReorderingMethod(state,action.payload);
    // },
    changeOrder: (state, action) => {
      // if (state.reorderingConfig.selectedReorderingMethod===""){
      //     _setSelectedReorderingMethod(state,"initial")
      // }else{
      //     _setSelectedReorderingMethod(state,"")
      // }
      _setSelectedReorderingMethod(state, action.payload.sortingMethod);
      const reorderedMatrix = _reorderMatrix(
        state.originMatrix,
        state.corrMatrix,
        state.reorderingConfig,
        state.filterThr,
        action.payload.selectedColumns
      );
      _setCorrMatrix(state, reorderedMatrix);
    },
    setResizeListenerAddedForSlider: (state, action) => {
      state.resizeListenerAddedForSlider = action;
    },
    setResizeListenerAddedForHeatmap: (state, action) => {
      state.resizeListenerAddedForHeatmap = action;
    },
    synchronizeWithSelectedColumns: (state, action) => {
      if (
        state.syncWithParCoordColumns &&
        state.originMatrix &&
        state.corrMatrix &&
        action.payload
      ) {
        const newCorrMatrix = _synchronizeMatrixWithParCoordColumnsIfNecessary(
          state.originMatrix,
          state.corrMatrix,
          action.payload
        );
        _setCorrMatrix(state, newCorrMatrix);
        state.corrCoefArr = _corrMatrixToValArray(newCorrMatrix);
      }
    },
    setClickedItemInScatterplot: (state,action)=>{
      state.clickedItemInScatterplot = action.payload;
    },
  },
  extraReducers: {
    // extraReducers: (builder)=>{
    // builder
    //     .addCase(setColumns,(state,action)=>{
    "chart/setColumns": (state, action) => {
      if (state.syncWithParCoordColumns) {
        const newCorrMatrix = _synchronizeMatrixWithParCoordColumnsIfNecessary(
          state.originMatrix,
          state.corrMatrix,
          action.payload.selectedColumns
        );
        _setCorrMatrix(state, newCorrMatrix);
        state.corrCoefArr = _corrMatrixToValArray(newCorrMatrix);
      }
    }, //)
    // "chart/setInitSchema":(state,action)=>{
    //     // do something with state and action.payload
    //     if(state.syncWithParCoordColumns) {
    //         _synchronizeMatrixWithParCoordColumnsIfNecessary(state.originMatrix,state.corrMatrix,action.payload)
    //     }
    // }
  },
});
export const {
  setOriginMatrix,
  setCorrMatrix,
  setCountByPair,
  setHoveredPairInMatrix,
  setLastClickedPairInMatrix,
  setSingleScatterplotData,
  updateChosenFeatures,
  setFilterThr,
  applyFilterThreshold,
  // setSelectedReorderingMethod,
  changeOrder,
  setResizeListenerAddedForSlider,
  setResizeListenerAddedForHeatmap,
  synchronizeWithSelectedColumns,
  setClickedItemInScatterplot,
} = correlationSlice.actions;

export default correlationSlice.reducer;
