import { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import { useAuth } from "oidc-react";
import { decodeJwt } from "jose";
import { isAuthenticated } from "./utils/auth";

// import Data from "./containers/Data";
// import Login from "./containers/Login";
// import NotFound from "./containers/NotFound";
// import Overview from "./containers/Overview";

export const aws = {
  index: 3,
  title: "Authentication",
  link: "auth",
  metadata: {
    auth: false,
    menu: false,
  },
};

export const admin = {
  index: 999,
  title: "Admin",
  link: "admin",
  metadata: {
    auth: false,
    admin: true,
    menu: true,
  },
  icon: "Settings",
};

export const redirectTo = "/callback";

const routes = [
  {
    index: 1,
    title: "Data",
    link: "/data",
    // exact: true,
    // component: Data,
    metadata: {
      auth: true,
      menu: true,
    },
    icon: "Storage",
  },
  {
    index: 2,
    title: "Overview",
    link: "/",
    // exact: true,
    // component: Overview,
    metadata: {
      auth: true,
      menu: true,
    },
    icon: "BarChart",
  },
  {
    index: 3,
    title: "Correlation",
    link: "/correlation",
    metadata: {
      auth: true,
      menu: true,
    },
    icon: "BarChart",
  },
  // { ...login },
  // { ...profile },
  { ...aws },
  { ...admin },
  {
    index: 4,
    title: "Not Found",
    link: "",
    metadata: {
      auth: false,
      menu: false,
    },
    icon: "Home",
  },
];

export default routes;

const access = {
  can: "can",
  cannot: "cannot",
  undefined: undefined,
};

export function onlyAdminRule(claims) {
  if (claims.realm_access.roles.includes("admin")) return true;
  return false;
}

export function authenticatedUserRule(claims) {
  if (claims.realm_access.roles.includes("default-roles-lamda")) return true;
  return false;
}

export const ProtectedRoute = ({ validator, children, ...rest }) => {
  const [hasPrivilegies, setHasPrivilegies] = useState(access.undefined);
  const { userData, isLoading } = useAuth();

  useEffect(
    () => {
      if (isLoading === false) {
        const decoded =
          !!userData && userData.access_token
            ? decodeJwt(userData.access_token)
            : false;
        setHasPrivilegies(() => {
          if (!!decoded && validator(decoded)) return "can";
          else return "cannot";
        });
      }
    },
    // eslint-disable-next-line
    [isLoading, userData]
  );

  if (isLoading) return <div>Loading...</div>;
  if (!isLoading && (!isAuthenticated(userData) || hasPrivilegies === "cannot"))
    return <Redirect to="/" />;

  if (isAuthenticated(userData) && hasPrivilegies === "can")
    return <Route {...rest} render={children} />;
  else
    return (
      <div>
        <span>You probably do not have access to this page.</span>
        <Link to="/">Go home</Link>
      </div>
    );
};
