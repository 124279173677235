import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  makeStyles,
  Typography,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Hidden,
  useTheme,
  Button,
  withStyles,
  InputLabel,
  ButtonGroup,
  MenuItem,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";

// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CaseSensitiveIcon from "../Icons/CaseSensitive";
import WholeWordIcon from "../Icons/WholeWord";
import FilterIcon from "@material-ui/icons/FilterList";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import { mdiTilde } from "@mdi/js";
import MDIIcon from "@mdi/react/Icon";

import { toggleCaseSensitive, toggleWholeWord } from "../../features/table";
import { setBrushed, setHighlight } from "../../features/chart";

import setup from "../../utils/setup";
import SyncMenu from "./data-table-header/SyncMenu";
import { toggleSchemaDialog } from "../../features/app";

import { coloredButton } from "../styled/Button";
import StyledSelect from "../styled/Select";

const GreenButton = coloredButton(green[400], green[400]);

const { debug } = setup();

// local components
function Row(props) {
  const { children, title, classes /*, ...other */ } = props;
  return (
    <Grid item xs={12} md={10} lg={8} className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={4} md={3} lg={3}>
          <InputLabel htmlFor={`${title}-label`}>{title}</InputLabel>
        </Grid>
        <Grid item xs={8} md={9} lg={9}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
const StyledRow = withStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(1),
    marginTop: theme.spacing(0),
    // marginBottom: theme.spacing(1),
    marginBottom: theme.spacing(0),
    maxHeight: "48px",
  },
}))(Row);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    display: "none",
    textTransform: "uppercase",
    letterSpacing: "0.02857em",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sync: {
    position: "relative",
    width: "100%",
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(1),
      width: "auto",
    },
    "& > button": {
      marginRight: theme.spacing(1),
    },
  },
  subtitle: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  searchForm: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const StyledAccordion = withStyles({
  root: { margin: "0 !important" },
})(Accordion);

const StyledAccordionSummary = withStyles({
  root: {
    maxHeight: "48px",
  },
  expanded: {
    maxHeight: "48px",
    minHeight: "48px !important",
  },
})(AccordionSummary);

const StyledGreenButton = withStyles({
  outlined: {
    backgroundColor: "transparent",
    cursor: "initial",
    borderColor: green[400],
  },
})(GreenButton);

export default function DataTableToolbar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  // store
  const {
    caseSensitive,
    wholeWord,
    syncColumns,
    syncWithAdvancedSearch,
    kClosest,
  } = useSelector((state) => state.table);
  const { schema, data } = useSelector((state) => state.dataset);

  // init state
  const defaultConfigObj = {
    kClosest: kClosest,
    similarityVariable: "",
  };
  const [state, setState] = useState(defaultConfigObj);

  // local for-loop component
  function Item(props) {
    const { name } = props;
    return (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    );
  }
  // local Icon component to integrate Material Design Icon in a button
  function TildeIcon() {
    return (
      <MDIIcon
        path={mdiTilde}
        title="Similarity"
        size={1}
        horizontal
        vertical
      ></MDIIcon>
    );
  }
  // events
  const onSearchActionClick = (fn) => (event) => {
    debug && console.log(`[${DataTableToolbar.name}] onSeachActionClick`);
    dispatch(fn());
  };

  const onColumnsClick = (event) => {
    event.stopPropagation();
    dispatch(toggleSchemaDialog("table"));
  };
  const onChangeIntNumber = (event) => {
    if (!event.target.value) return;
    const newState = {
      ...state,
      [event.target.name]: parseInt(event.target.value),
    };
    // console.log(newState)
    setState((state) => ({ ...newState }));
  };
  const onChange = (event) =>
    setState((state) => {
      const newState = { ...state, [event.target.name]: event.target.value };
      return newState;
    });
  const onKClosestClick = (event) => {
    event.stopPropagation();
    console.log(
      `onKClosestClick: k=${state.kClosest} distVariable=${state.similarityVariable}`
    );

    // get reference row
    // const refPattern=state.similarityVariable.split("_")[1]
    // const ref=Number.parseInt(refPattern.slice(3))
    const simVariableObj = schema.find(
      (s) => s.name === state.similarityVariable
    );
    const ref = simVariableObj.rowId;
    let row = data[ref]; // test quick access based on index.
    let idVariableName = schema.find((s) => s.group === "Id").name;
    if (row === undefined || row[idVariableName] !== ref) {
      if (row === undefined)
        console.log(
          `row[${idVariableName}]!==ref [${row === undefined ? undefined : row[idVariableName]
          }!==${ref}] at index in data[${ref}]: searching right row in the dataset in row.${idVariableName}...`
        );
      row = data.find((row) => row[idVariableName] === ref);
    } else {
      console.log(`Row correctly found in data at index ${ref}`);
    }
    // retrieve the K closest :
    let kClosestData = data.slice();
    kClosestData.sort((a, b) => {
      // ascending
      return a[state.similarityVariable] - b[state.similarityVariable];
    });
    kClosestData = kClosestData.slice(0, state.kClosest);
    console.log(
      "[DataTableToolbar::onKClosestClick] dispatch setHighlight with row..."
    );
    // highlight reference in // coord.
    dispatch(setHighlight(row));
    // show kClosest in brush layer in // coord
    dispatch(
      setBrushed({
        brushed: kClosestData,
        method: "DataTableToolbar::onKClosestClick",
      })
    );
  };
  const checkKclosestDisabled = (newState) => {
    return newState.similarityVariable === "" || newState.kClosest === 0;
  };

  const getInputFieldNumberSize = (number) => {
    const numberStr = "" + number;
    return 50 + numberStr.length * 9;
  };

  const stopPropagation = (event) => event.stopPropagation();

  const hasDistanceColumn = () => {
    return !!schema.find((s) => s.group === "Distance");
  };

  return (
    <React.Fragment>
      <StyledAccordion expanded={true} id={props.id}>
        <StyledAccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Title */}
            <Grid item md={3}>
              <Typography
                variant="h6"
                color="inherit"
                component="div"
                className={classes.title}
              >
                Settings
              </Typography>
            </Grid>
            {/* Switches sync (Hidden at md) */}
            <Hidden mdDown>
              <Grid item md={6} style={{ textAlign: "center" }}>
                <SyncMenu />
              </Grid>
            </Hidden>
            {/* Search */}
            <Grid item md={2}>
              <div style={{ textAlign: "end" }}>
                <TextField
                  name="searchText"
                  placeholder="Search..."
                  arial-label="search"
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={stopPropagation}
                  onFocus={stopPropagation}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <CaseSensitiveIcon
                          style={{ marginRight: ".4rem", cursor: "pointer" }}
                          htmlColor={
                            caseSensitive
                              ? theme.palette.progress.main
                              : theme.palette.progress.light
                          }
                          onClick={onSearchActionClick(toggleCaseSensitive)}
                        />
                        <WholeWordIcon
                          style={{ cursor: "pointer" }}
                          htmlColor={
                            wholeWord
                              ? theme.palette.progress.main
                              : theme.palette.progress.light
                          }
                          onClick={onSearchActionClick(toggleWholeWord)}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={props.onSearch}
                  value={props.search}
                />
              </div>
            </Grid>
          </Grid>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container direction="column" className={classes.root}>
            {/* Switches sync (visible at lg) */}
            <Hidden lgUp>
              <StyledRow title="Synchronization" style={{ maxHeight: "48px" }}>
                <SyncMenu />
              </StyledRow>
            </Hidden>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <ButtonGroup aria-label="button group" >
                  <Button
                    startIcon={<FilterIcon />}
                    onClick={props.onClickFilters}
                  >
                    Filters
                  </Button>
                  {syncWithAdvancedSearch && (
                    <StyledGreenButton>On</StyledGreenButton>
                  )}
                  {!syncWithAdvancedSearch && <Button disabled>Off</Button>}
                </ButtonGroup>
              </Grid>
              {/* Button for column selection if not sync with // coord */}
              {!syncColumns && (
                <Grid item>
                  <ButtonGroup aria-label="button group">
                    <Button
                      startIcon={<ViewColumnIcon />}
                      onClick={onColumnsClick}
                    >
                      Columns
                    </Button>
                  </ButtonGroup>
                </Grid>
              )}

              {/* Select K Closest rows if similarity column exists */}
              {hasDistanceColumn() && (
                <Grid item>
                  <ButtonGroup aria-label="button group">
                    <TextField
                      name="kClosest"
                      placeholder="Nb. neighbours to select"
                      arial-label="k closest"
                      color="primary"
                      size="small"
                      variant="outlined"
                      onChange={onChangeIntNumber}
                      value={state.kClosest}
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          inputMode: "decimal",
                          pattern: "[0-9]*",
                        },
                        style: {
                          width: `${getInputFieldNumberSize(state.kClosest)}px`,
                        },
                        // style: { width: `78px` },
                      }}
                    />
                    <StyledSelect
                      id="Similarity variable select"
                      name="similarityVariable"
                      variant="outlined"
                      value={state.similarityVariable}
                      onChange={onChange}
                    // className={classes.maxWidth}
                    >
                      {schema.filter((s) => s.group === "Distance").map(Item)}
                      {/* {mockeup.map(i => ({ name: i })).map(Item)} */}
                    </StyledSelect>

                    <Button
                      startIcon={<TildeIcon />}
                      // startIcon={<ViewColumnIcon />}
                      onClick={onKClosestClick}
                      disabled={checkKclosestDisabled(state)}
                    >
                      Show K Closest rows
                    </Button>
                  </ButtonGroup>
                </Grid>
              )}
              {/*<Box display="flex" flexGrow={1}>*/}
              {/*</Box>*/}
              {props.children}
              {/* {!props.open && (<ActiveFilter disabled={!syncWithAdvancedSearch} />)} */}
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </React.Fragment>
  );
}
