import React, { useEffect, useRef, useState } from "react";
// import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { handleClickedItemInScatterplot } from "../../features/correlation";
import Scatterplot from "../Scatterplot/Scatterplot";
import { getRowIdName } from "../../features/dataset";
export default function CorrScatterplotChart(props) {
  const dispatch = useDispatch();
  const { singleScatterplotData } = useSelector((state) => state.correlation);

  const { highlight, brushed, defaultColor, colorScheme, colorPerRows } =
    useSelector((state) => state.chart);
  const { schema } = useSelector((state) => state.dataset);

  const initialState = {
    data: [],
    colorScaleProperties: { values: ["all"], colors: [defaultColor] }, // was "#2c7bb6"
    selectedItem: null,
    selectedItems: [],
  };
  const [state, setState] = useState(initialState);

  const xValueAccessorRef = useRef((d) => {
    return d.valueX;
  });
  const yValueAccessorRef = useRef((d) => {
    return d.valueY;
  });
  const idAccessorRef = useRef((d) => {
    return d.rowId;
  });
  // const highlightAccessorRef = useRef((d)=>{return d.yValue});
  const controllerMethodsRef = useRef(() => {
    return {
      setSelectedRow: (selectedRow) => {
        dispatch(handleClickedItemInScatterplot(selectedRow));
      },
    };
  });

  function _getColorProperties(colorScheme, defaultColor, colorByRowId) {
    if (colorScheme !== defaultColor)
      return { toGetByRowId: true, colorByRowId };
    else return { values: ["all"], colors: [defaultColor] };
  }

  const highlightRef = useRef(null);
  const brushedRef = useRef(null);
  const colorPerRowsRef = useRef(null);

  useEffect(() => {
    let newState = { ...state };
    let stateToUpdate = false;
    if (singleScatterplotData?.data) {
      const data = singleScatterplotData?.data?.map((d) => {
        return { ...d, type: "all" };
      });
      stateToUpdate = true;
      newState = { ...newState, data };
      if (schema && schema.length) {
        const rowIdName = getRowIdName(schema);
        if (highlightRef.current !== highlight) {
          let selectedItem = null;
          if (highlight && highlight.length > 0)
            selectedItem = highlight[0][rowIdName];
          stateToUpdate = true;
          newState = { ...newState, selectedItem };
          highlightRef.current = highlight;
        }
        if (brushedRef.current !== brushed) {
          let selectedItems = [];
          if (brushed) selectedItems = brushed.map((d) => d[rowIdName]);
          stateToUpdate = true;
          newState = { ...newState, selectedItems };
          brushedRef.current = brushed;
        }
      }
      if (colorPerRowsRef.current !== colorPerRows) {
        stateToUpdate = true;
        const colorScaleProperties = _getColorProperties(
          colorScheme,
          defaultColor,
          colorPerRows
        );
        newState = { ...newState, colorScaleProperties };
        colorPerRowsRef.current = colorPerRows;
      }
    }

    if (stateToUpdate) {
      setState(newState);
    }
    return () => {
      // console.log("Destroying CorrScatterplotChart useEffect [clickedPairInMatrix]");
    };
    // eslint-disable-next-line
  }, [singleScatterplotData, highlight, brushed, colorScheme, colorPerRows]);

  if (state?.data) {
    const getColumnName = (name) => {
      if (name) {
        const column = schema.find((x) => {
          return x.name === name;
        });
        return column.displayedName?.trim()
          ? column.displayedName
          : column.name;
      } else return name;
    };
    return (
      // <div>
      //     Scatterplot for x:{singleScatterplotData.featurePair?.xName} and y:{singleScatterplotData.featurePair?.yName}
      //     <br/>
      //     data size: {singleScatterplotData.data?.length}
      // </div>
      <Scatterplot
        data={state.data}
        title={""}
        xUnit={getColumnName(singleScatterplotData.featurePair?.xName)}
        yUnit={getColumnName(singleScatterplotData.featurePair?.yName)}
        // colors:["#0868ac","#addd8e"]
        colorScaleProperties={state.colorScaleProperties}
        // yFilterThreshold={this.state.residualHistoThresholds}
        showZeroLine={false}
        showIdentityLine={false}
        showBestFitLine={false}
        xValueAccessor={xValueAccessorRef.current}
        yValueAccessor={yValueAccessorRef.current}
        // highlightAccessor={highlightAccessorRef.current}
        idAccessor={idAccessorRef.current}
        scaleDomainExtPerc={1}
        selectedItem={state.selectedItem}
        selectedItems={state.selectedItems}
        controllerMethods={controllerMethodsRef.current}
      />
    );
  } else return <div>loading...</div>;
}
