import React, { useState } from "react";
import PropTypes from "prop-types";

import {
	MenuItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

function DeleteColumnMenu(props) {
	const [confirm, setConfirm] = useState(false);
	const { onClick } = props;

	const onFirstClick = () => {
		setConfirm(true);
	};

	if (!confirm)
		return (
			<MenuItem onClick={onFirstClick}>
				<ListItemIcon>
					<DeleteIcon color="error" fontSize="small" />
				</ListItemIcon>
				<ListItemText>
					<Typography color="error">Delete column</Typography>
				</ListItemText>
			</MenuItem>
		);
	else
		return (
			<MenuItem onClick={onClick}>
				<ListItemIcon></ListItemIcon>
				<ListItemText>
					<Typography color="error">Are you sure?</Typography>
				</ListItemText>
			</MenuItem>
		);
}

DeleteColumnMenu.propTypes = {
	onClick: PropTypes.func,
};

export default DeleteColumnMenu;
