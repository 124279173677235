//Module Imports
import styled from "styled-components";

export const Container = styled.div`
  user-select: none;

  & > svg,
  & > canvas {
    font-size: 0.8rem;
    font-family: "sans-serif";
    position: absolute;
  }

  & > canvas {
    pointer-events: none;
  }

  & text.label,
  text {
    cursor: default;
    fill: ${({ isDark }) => (isDark ? "#ccc" : "#222")};
  }

  & text.staticlabel {
    cursor: default;
    font-family: "sans-serif";
  }

  & rect.background {
    fill: transparent;
  }

  & rect.background:hover {
    fill: rgba(120, 120, 120, 0.2);
  }

  & .resize rect {
    fill: rgba(0, 0, 0, 0.1);
  }

  & rect.extent {
    fill: rgba(255, 255, 255, 0.25);
    stroke: rgba(0, 0, 0, 0.6);
  }

  & .axis line,
  & .axis path {
    fill: none;
    stroke: ${({ isDark }) => (isDark ? "#ccc" : "#222")};
    shape-rendering: crispEdges;
  }

  & canvas {
    opacity: 1;
    transition: opacity 0.3s;
  }

  & canvas.faded {
    opacity: 0.25;
  }

  & canvas.dimmed {
    opacity: 0.85;
  }

  -webkit-touch-callout: none;
  user-select: none;
  background-color: ${({ background }) => background || "white"};
  display: "inline-block";
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "100%"};
`;
