import React, { Component } from "react";
import PropTypes from "prop-types";
import D3HeatMapMatrix from "./d3HeatMapMatrix.js";

class HeatMapMatrix extends Component {
  static propTypes = {
    elementId: PropTypes.string,
    title: PropTypes.string,
    xUnit: PropTypes.string,
    yUnit: PropTypes.string,
    data: PropTypes.object, // {columns:[String] ,indexes:[String], data:[[{value:numeric,list:[row_ids]}}]]} // used for color
    countByPair: PropTypes.object, // {key(columns):{key(indexes):int} // used for size if undefined size is mapped to data
    scatterPlotDataForPair: PropTypes.array, // [{valueX:number,valueY:number}] // scatterplot as tooltip
    originMatrix: PropTypes.object, // if !change, apply permutation only but not used in D3ConfusionMatrix
    selectedFeaturePairInMatrix: PropTypes.object, // {xName:String,yName:String} // used only for updating scatterplot in tooltip
    highlightedCellsInMatrix:PropTypes.array,// {xName:String,yName:String}
    chosenFeatures: PropTypes.array, // [String] list of features to highlight on the margin (selected by click)
    dataInfo: PropTypes.object, // {rowCount:int}
    filterThr: PropTypes.object, // {min:float, max:float}
    colorScaleProperties: PropTypes.object, // {values:[],colors:[],schemeName:String,legendLabels:[],gradient:boolean} array (length max 2) with diff categorical values to compute 2 histograms
    vectorBarchartDataToShow: PropTypes.object,
    resizeTimestamp: PropTypes.number, // timestamp when resize is triggered saying the component needs to check for resize
    controllerMethods: PropTypes.func,
    displayedNames: PropTypes.object, // {columnName:displayName}
  };

  componentDidMount() {
    console.log("[HeatMapMatrix:life-cycle] did mount...");
    this.size = this.getChartSize();
    const config = {
      title: this.props.title,
      xUnit: this.props.xUnit,
      yUnit: this.props.yUnit,
      size: this.size,
      colorScaleProperties: this.props.colorScaleProperties,
    };

    let rawDataCount = 0;
    if (this.props.dataInfo) {
      rawDataCount = this.props.dataInfo.rowCount;
    }
    const state = {
      data: this.props.data,
      countByPair: this.props.countByPair,
      scatterPlotDataForPair: this.props.scatterPlotDataForPair,
      selectedFeaturePairInMatrix: this.props.selectedFeaturePairInMatrix,
      highlightedCellsInMatrix: this.props.highlightedCellsInMatrix,
      chosenFeatures: this.props.chosenFeatures,
      rawDataCount: rawDataCount,
      filterThr: this.props.filterThr,
      displayedNames: this.props.displayedNames,
    };
    const controller = this.props.controllerMethods();
    this.d3ConfusionMatrix = new D3HeatMapMatrix(this._rootNode);
    this._chart = this.d3ConfusionMatrix.create(
      this._rootNode,
      config,
      state,
      controller
    );
    this._chart = this.d3ConfusionMatrix.update(
      this._rootNode,
      config,
      state,
      controller
    );
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let shouldLogMsg = false;
    const msg = [];
    msg.push("[HeatMapMatrix:life-cycle] should update?");
    let res = false;
    Object.keys(this.props).forEach((key) => {
      if (this.props[key] !== nextProps[key]) {
        if (key === "scatterPlotDataForPair" || key === "chosenFeatures") {
          // log message for list
          msg.push(
            "\tthis.props." +
              key +
              " changes " +
              this.props[key]?.length +
              "=>" +
              nextProps[key]?.length
          );
          shouldLogMsg = true;
        } else if (key !== "filterThr") { // not filterThr to avoid prints during slider move
          // log message for simple types or objects
          msg.push(
            "\tthis.props." +
              key +
              " changes " +
              this.props[key] +
              "=>" +
              nextProps[key]
          );
          shouldLogMsg = true;
        }
        res = true;
      }
    });
    if (shouldLogMsg) {
      msg.forEach((d) => console.log(d));
    }

    // check change of size
    const newSize = this.getChartSize();
    res =
      res ||
      this.size.height !== newSize.height ||
      this.size.width !== newSize.width;

    return res;
  }

  componentDidUpdate(prevProps) {
    let shouldLogMsg = true;
    const msg = [];
    msg.push("[HeatMapMatrix:life-cycle] did update...");
    const newSize = this.getChartSize();

    const config = {
      title: this.props.title,
      xUnit: this.props.xUnit,
      yUnit: this.props.yUnit,
      size: newSize,
      colorScaleProperties: this.props.colorScaleProperties,
    };
    let rawDataCount = 0;
    if (this.props.dataInfo) {
      rawDataCount = this.props.dataInfo.rowCount;
    }

    const state = {
      data: this.props.data,
      countByPair: this.props.countByPair,
      scatterPlotDataForPair: this.props.scatterPlotDataForPair,
      selectedFeaturePairInMatrix: this.props.selectedFeaturePairInMatrix,
      highlightedCellsInMatrix:this.props.highlightedCellsInMatrix,
      chosenFeatures: this.props.chosenFeatures,
      rawDataCount: rawDataCount,
      filterThr: this.props.filterThr,
      vectorBarchartDataToShow: this.props.vectorBarchartDataToShow,
      displayedNames: this.props.displayedNames,
    };
    const controller = this.props.controllerMethods();

    // check which data changed:

    // start by handling the size changes
    if (
      newSize.height !== this.size.height ||
      newSize.width !== this.size.width
    ) {
      // use timeout to avoid rendering during resize
      this.size = newSize;
      clearTimeout(this.resizeend);
      this.resizeend = setTimeout(() => {
        msg.push("\tfor resize...");
        this.d3ConfusionMatrix.resize(
          this._rootNode,
          config,
          state,
          controller,
          this._chart
        );
      }, 200);
    }
    if (prevProps.data !== this.props.data) {
      // handle the data changes
      shouldLogMsg=true;
      const msgForData = "\tfor data";
      if (prevProps.originMatrix === this.props.originMatrix) {
        msg.push(msgForData + " animated permutation...");
        this.d3ConfusionMatrix.animatedPermutation(this._rootNode, state);
      } else {
        msg.push(msgForData + "update...");
        this.d3ConfusionMatrix.update(
          this._rootNode,
          config,
          state,
          controller,
          this._chart
        );
      }
    }
    if (
      prevProps.scatterPlotDataForPair !== this.props.scatterPlotDataForPair
    ) {
      msg.push("\tfor scatterplot...");

      // const controller= this.props.controllerMethods();
      this.d3ConfusionMatrix.updateScatterPlot(this._rootNode, state, controller);
    }
    if (
      prevProps.chosenFeatures !== this.props.chosenFeatures &&
      this.props.data
    ) {
      msg.push("\tfor chosenFeatures...");
      this.d3ConfusionMatrix.updateChosenFeatures(this._rootNode, state);
    }
    if (prevProps.filterThr !== this.props.filterThr) {
      msg.push("\tfor filterThr...");
      shouldLogMsg = false;
      this.d3ConfusionMatrix.filterCellsByThr(this.props.filterThr);
    }
    if (
      prevProps.vectorBarchartDataToShow !== this.props.vectorBarchartDataToShow
    ) {
      msg.push("\tfor vector barchart...");
      this.d3ConfusionMatrix.updateVectorBarCharts(state);
    }
    if(prevProps.highlightedCellsInMatrix !== this.props.highlightedCellsInMatrix){
      this.d3ConfusionMatrix.updateHighlightedCells(this.props.highlightedCellsInMatrix)
    }
    if(prevProps.displayedNames !== this.props.displayedNames){
      msg.push("\tfor displayedNames...");
      shouldLogMsg = true;
      this.d3ConfusionMatrix.updateDisplayedLabels(state);
    }
    if (shouldLogMsg) {
      msg.forEach((d) => {
        console.log(d);
      });
    }
  }
  componentWillUnmount() {
    console.log("[HeatMapMatrix:life-cycle] will unmount...");
    this.d3ConfusionMatrix.destroy(this._rootNode);
  }

  getChartSize() {
    // return this.props.size;
    let width; // = 800;
    let height; // = 100;
    if (this._rootNode !== undefined) {
      width = this._rootNode.offsetWidth;
      // width = '100%';
      height = this._rootNode.offsetHeight;
      // height = '100%';
    }
    return { width: width, height: height };
  }

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }
  render() {
    const divStyle = { height: "100%" };
    return (
      <div
        className={this.props.elementId + "-matrix-container"}
        ref={this._setRef.bind(this)}
        style={divStyle}
      ></div>
    );
  }
}
export default HeatMapMatrix;
