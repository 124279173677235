import React from "react";

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";

export default function Header(props) {
  const { classes, order, orderBy, onRequestSort, cells } = props;

  const sortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead id={props.id}>
      <TableRow>
        {cells.map(({ name, displayedName }) => (
          <TableCell
            key={name}
            sortDirection={orderBy === name ? order : false}
          >
            <TableSortLabel
              active={orderBy === name}
              direction={orderBy === name ? order : "asc"}
              onClick={sortHandler(name)}
            >
              {displayedName}
              {orderBy === name ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
