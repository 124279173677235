import React from "react";
import { batch, useDispatch, useSelector } from "react-redux";

import { FormControlLabel, Switch } from "@material-ui/core";

import {
  toggleBrushing,
  toggleSubsetting,
  toggleColumns,
  syncTableData,
} from "../../../features/table";

import setup from "../../../utils/setup";
const { debug } = setup();

function SyncMenu(props) {
  const dispatch = useDispatch();

  // store
  const { syncColumns, syncWithBrushing, syncWithSubsetting } = useSelector(
    (state) => state.table
  );

  const { currentData, data } = useSelector((state) => state.dataset);
  const { brushed } = useSelector((state) => state.chart);

  // events
  const stopPropagation = (event) => event.stopPropagation();
  const onChange = (fn) => (event) => {
    debug && console.log(`[${SyncMenu.name}] onChange: ${event.target.name}`);
    // fn must be equals to "toggleBrushing" or "toggleSubsetting"
    batch(() => {
      dispatch(fn(event.target.checked));
      dispatch(syncTableData({ currentData, data, brushed }));
    });
  };

  return (
    <React.Fragment>
      {/* Use aria-label on FCL components to stop the event propagation */}
      {/* https://material-ui.com/components/accordion/#additional-actions */}
      <FormControlLabel
        label="Sync columns"
        aria-label="sync-columns"
        onClick={stopPropagation}
        onFocus={stopPropagation}
        control={
          <Switch
            checked={syncColumns}
            name="toggleColumns"
            color="primary"
            onChange={onChange(toggleColumns)}
          />
        }
      />

      <FormControlLabel
        label="Sync with brushed"
        aria-label="sync-with-brushed"
        onClick={stopPropagation}
        onFocus={stopPropagation}
        control={
          <Switch
            checked={syncWithBrushing}
            name="toggleBrushing"
            color="primary"
            onChange={onChange(toggleBrushing)}
          />
        }
      />
      <FormControlLabel
        label="Sync with subset"
        aria-label="sync-with-subset"
        onClick={stopPropagation}
        onFocus={stopPropagation}
        control={
          <Switch
            checked={syncWithSubsetting}
            name="toggleSubsetting"
            color="primary"
            onChange={onChange(toggleSubsetting)}
          />
        }
      />
    </React.Fragment>
  );
}

export default SyncMenu;
