import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleDeleteDatasetDialog } from "../../../features/app";
import { deleteDataset, ID_KEY } from "../../../features/dataset";

export default function DeleteDatasetConfirm(props) {
  const dispatch = useDispatch();

  // store
  const { currentDataset, loading } = useSelector((state) => state.dataset);

  // events
  const onClose = (event) => dispatch(toggleDeleteDatasetDialog(false));
  const onDeleteDataset = async () => {
    await dispatch(deleteDataset(currentDataset[ID_KEY]));
    dispatch(toggleDeleteDatasetDialog(false));
  };

  return (
    <Dialog open={props.open} color="primary" onClose={onClose}>
      <DialogContent>
        Are you sure you want to delete this dataset?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>No</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onDeleteDataset}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
