import React, { useEffect, useRef, useState } from "react";
// import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
// import {sendCorrelation, getCorrelationParamObject} from "../../features/analytic"
import HeatMapMatrix from "../HeatMapMatrix/HeatMapMatrix";
import {
  handleHoveredPairInMatrix,
  handleClickedPairInMatrix,
  updateChosenFeatures,
  setResizeListenerAddedForHeatmap,
  getDataForPair,
  connectDimensionsCorrMatrixToScatterplot,
} from "../../features/correlation";
// import { setRender } from "../../features/chart";
export default function CorrHeatmapChart(props) {
  const dispatch = useDispatch();

  // store
  const { data, schema } = useSelector((state) => state.dataset);
  // const { selectedColumn } = useSelector((state) => state.chart);
  const {
    originMatrix,
    corrMatrix,
    countByPair,
    hoveredPairInMatrix,
    lastClickedPairInMatrix,
    chosenFeatures,
    filterThr,
    resizeListenerAddedForHeatmap,
  } = useSelector((state) => state.correlation);
  // const { corrMatrix, countByPair } = useSelector((state) => state.analytic);

  // state
  const initialState = {
    scatterPlotDataForPair: [],
    highlightedCellsInMatrix: [],
    colorScalesProperties: {
      values: [-1, 0, 1],
      colors: ["#e31a1c", "#ffffff", "#08519c"],
      gradient: true,
    },
    dataInfo: {
      rowCount: data.length,
    },
    displayedNames: {},
  };
  const [state, setState] = useState(initialState);
  const [resizeTimestamp, setResizeTimestamp] = useState(0);

  function _getDataForPair(xName, yName) {
    // FIXME: why not currentData ? does not change with subsetting
    return getDataForPair(schema, data, xName, yName);
  }

  function _updateChosenFeatures(d) {
    dispatch(updateChosenFeatures(d));
  }

  // reference
  const selectedFeaturesInMatrixTimeout = useRef(undefined);
  const dataRef = useRef([]);
  const hoveredPairInMatrixRef = useRef(null);
  const lastClickedPairInMatrixRef = useRef(null);

  // use ref to avoid re-render every time it changes
  const _evalHeatMapMatrixControllerMethods = useRef(() => {
    return {
      setSelectedFeaturePairInMatrix: (featurePairInMatrix) => {
        if (selectedFeaturesInMatrixTimeout.current) {
          // console.log("cleartimeout '"+selectedFeaturesInMatrixTimeout.current+"'")
          clearTimeout(selectedFeaturesInMatrixTimeout.current);
          selectedFeaturesInMatrixTimeout.current = undefined;
        }
        selectedFeaturesInMatrixTimeout.current = setTimeout(() => {
          // console.log("Timeout !")
          // dispatch(setHoveredPairInMatrix(featurePairInMatrix));
          dispatch(handleHoveredPairInMatrix(featurePairInMatrix));
        }, 200);
      },
      updateChosenFeature: (chosenFeature) => {
        _updateChosenFeatures(chosenFeature);
      },
      clickedCell: (cellObject) => {
        let featuresInMatrix = {
          xName: cellObject.xName.name,
          yName: cellObject.yName.name,
        };
        dispatch(handleClickedPairInMatrix(featuresInMatrix));
        // if (cellObject.items && cellObject.items.length > 0) {
        //     const newRowId = cellObject.items[0];
        //     // const selectedRowIds=[newRowId];
        //     this.setState({selectedRowId: newRowId/*, selectedRowIds:selectedRowIds*/}, () => {
        //         // do something for more intensive operations after row id is selected
        //     })
        // }
      },
      connectDimensionsCorrMatrixToScatterplot: (featurePair) => {
        return connectDimensionsCorrMatrixToScatterplot(featurePair);
      },
    };
  });

  useEffect(() => {
    let toUpdate = false;
    // check if rebuildHeatmap is need
    let dataInfo = state.dataInfo;
    let displayedNames = state.displayedNames;
    if (dataRef.current !== data) {
      dataInfo = {
        rowCount: data.length,
      };
      displayedNames = {};
      schema.forEach((column) => {
        displayedNames[column.name] = column.displayedName?.trim()
          ? column.displayedName
          : column.name;
      });
      setState({ ...state, dataInfo, displayedNames });
      toUpdate = true;
    }

    let scatterPlotDataForPair = state.scatterPlotDataForPair;
    if (hoveredPairInMatrixRef.current !== hoveredPairInMatrix) {
      scatterPlotDataForPair = [];
      if (hoveredPairInMatrix) {
        const pairForScatterplot =
          connectDimensionsCorrMatrixToScatterplot(hoveredPairInMatrix);
        scatterPlotDataForPair = _getDataForPair(
          pairForScatterplot.xName,
          pairForScatterplot.yName
        );
      }
      toUpdate = true;
    }
    let highlightedCellsInMatrix = state.highlightedCellsInMatrix;
    if (lastClickedPairInMatrixRef.current !== lastClickedPairInMatrix) {
      highlightedCellsInMatrix = [lastClickedPairInMatrix];
      toUpdate = true;
    }
    if (toUpdate) {
      setState({
        ...state,
        scatterPlotDataForPair,
        dataInfo,
        highlightedCellsInMatrix,
        displayedNames,
      });
    }
    // update refs
    if (dataRef.current !== data) {
      dataRef.current = data;
    }
    if (hoveredPairInMatrixRef.current !== hoveredPairInMatrix) {
      hoveredPairInMatrixRef.current = hoveredPairInMatrix;
    }
    if (lastClickedPairInMatrixRef.current !== lastClickedPairInMatrix) {
      lastClickedPairInMatrixRef.current = lastClickedPairInMatrix;
    }
    if (!resizeListenerAddedForHeatmap) {
      window.addEventListener("resize", () => {
        setResizeTimestamp(Date.now());
      });
      dispatch(setResizeListenerAddedForHeatmap(true));
    }

    // rebuildHeatmap();
    return () => {
      // console.log("Destroying HeatmapChart useEffect [data,schema]");
    };
    // eslint-disable-next-line
  }, [data, hoveredPairInMatrix, lastClickedPairInMatrix]); // add schema  if needed

  return (
    <HeatMapMatrix
      elementId="correl"
      title="Correlation heatmap"
      xUnit=""
      yUnit=""
      data={corrMatrix} // {columns:[String] ,indexes:[String], data:[[{value:numeric,list:[row_ids]}}]]} // used for color
      countByPair={countByPair} // {key(columns):{key(indexes):int} // used for size if undefined size is mapped to data
      scatterPlotDataForPair={state.scatterPlotDataForPair} // [{valueX:number,valueY:number}] // scatterplot as tooltip
      originMatrix={originMatrix} // if !change, apply permutation only but not used in D3ConfusionMatrix
      selectedFeaturePairInMatrix={hoveredPairInMatrix} // {xName:String,yName:String} // names of feature selected for scatterplot (used only for updating scatterplot labels in tooltip)
      highlightedCellsInMatrix={state.highlightedCellsInMatrix} // cell data defined {xName:String,yName:String}as send in controllerMethods.clickedCell
      chosenFeatures={chosenFeatures} // [String] list of features to highlight on the margin (selected by click)
      dataInfo={state.dataInfo} // raw data in tabular format (2D array) to compute the total number of rows (used in D3ConfusionMatrix  but not used in D3ConfusionMatrix)
      filterThr={filterThr} // {min:float, max:float}
      colorScaleProperties={state.colorScalesProperties} // {values:[],colors:[],schemeName:String,legendLabels:[],gradient:boolean} array (length max 2) with diff categorical values to compute 2 histograms
      // vectorBarchartDataToShow={}
      resizeTimestamp={resizeTimestamp}
      controllerMethods={_evalHeatMapMatrixControllerMethods.current} // PropTypes.func,
      displayedNames={state.displayedNames}
    />
  );
}
