import React, { useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";

import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Slider,
  Typography,
} from "@material-ui/core";
import { StyledSelect } from "../../styled/Select";

import DarkIcon from "@material-ui/icons/Brightness4";
import LightIcon from "@material-ui/icons/Brightness7";
import ClearIcon from "@material-ui/icons/Clear";

// import { toggleSchemaDialog } from "../../../features/app";
import {
  setSearching,
  setTableDataFromRemoveSelection,
} from "../../../features/table";
import {
  setAlpha,
  setBackgroundColor,
  setBrushPredicate,
  setColor,
  setSchemeColor,
  setVariableSorting,
  applySort,
} from "../../../features/chart";
import {
  keepDataSelection,
  removeDataSelection,
  resetDataSelection,
} from "../../../features/dataset";
import {
  colorFn,
  colorList,
  darkColorInt,
  defaultColor,
  lightColorInt,
  mapColorType,
} from "../../Colors/colors";

import SelectVariables from "./columns-selection/SelectVariables";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  row: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  sliderBtn: {
    padding: "0 5px",
  },
}));

// local component
function Row(props) {
  const { children, title, className, ...other } = props; // eslint-disable-line
  return (
    <Grid item xs={12} md={10} lg={5} className={className}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <InputLabel htmlFor={`${title}-label`}>{title}</InputLabel>
        </Grid>
        <Grid item xs={8}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
function SelectVariable(props) {
  const { variable, schema, onChange, className } = props;
  return (
    <StyledSelect
      id="color-label-variable"
      name="variable"
      variant="outlined"
      value={variable}
      onChange={onChange}
      className={className}
      displayEmpty
    >
      <MenuItem value="" disabled dense>
        Choose a variable
      </MenuItem>
      {schema
        .filter((s) => s.group !== "Id")
        .map((s, i) => (
          <MenuItem key={i} value={s} dense>
            {s.name}
          </MenuItem>
        ))}
    </StyledSelect>
  );
}
function SelectType(props) {
  const { variable, method, onChange, className } = props;
  const type = mapColorType(variable.type);
  if (variable === "" || type !== "numeric") return null;
  else
    return (
      <StyledSelect
        id="color-label-type"
        name="method"
        variant="outlined"
        className={className}
        value={method}
        onChange={onChange}
      >
        <MenuItem value="value" dense>
          Value
        </MenuItem>
        <MenuItem value="decile" dense>
          Decile
        </MenuItem>
      </StyledSelect>
    );
}
function SelectColor(props) {
  const { variable, method, colorScheme, onChange } = props;

  if (variable === "") return null;
  else {
    let list;
    if (mapColorType(variable.type) === "numeric") {
      if (method === "value") list = colorList.numeric.value;
      else list = colorList.numeric.decile;
    } else list = colorList.factor.value;

    return (
      <StyledSelect
        id="Color-label"
        name="colorScheme"
        variant="outlined"
        value={colorScheme}
        onChange={onChange}
      >
        {list.map((v, i) => (
          <MenuItem value={v} key={i} dense>
            {v}
          </MenuItem>
        ))}
        <MenuItem value={defaultColor} dense>
          Default
        </MenuItem>
      </StyledSelect>
    );
  }
}

function Selection(props) {
  const { brushed, reset, loading, onKeep, onRemove, onReset } = props;
  if (!brushed && !reset)
    return <p style={{ margin: "8px 0" }}>No data selected</p>;
  else
    return (
      <React.Fragment>
        {brushed && (
          <Button
            color="primary"
            variant="outlined"
            onClick={onKeep}
            disabled={loading === "keep"}
          >
            keep
          </Button>
        )}
        {brushed && (
          <Button
            color="primary"
            variant="outlined"
            onClick={onRemove}
            disabled={loading === "remove"}
            style={{ marginLeft: ".5rem", marginRight: ".5rem" }}
          >
            Remove
          </Button>
        )}
        {reset && (
          <Button
            color="primary"
            variant="outlined"
            onClick={onReset}
            disabled={loading === "reset"}
          >
            Reset
          </Button>
        )}
      </React.Fragment>
    );
}

function ConfigurationTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // store
  const { currentSchema, schema, currentData } = useSelector(
    (state) => state.dataset
  );

  const {
    defaultColor,
    colorScheme,
    alpha,
    backgroundColorInt,
    brushPredicate,
    variableSorting,
    brushed,
    monitoring,
  } = useSelector((state) => state.chart);

  const { syncWithAdvancedSearch } = useSelector((state) => state.table);

  // state
  const [state, setState] = useState({
    variable: "",
    method: "value",
    backgroundColorInt: backgroundColorInt || lightColorInt,
    opacity: alpha || 0.5,
  });
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(null);

  // events
  const onChange = (event) =>
    setState((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  const onVariableChange = (event) => {
    dispatch(setSchemeColor(defaultColor));
    onChange(event);
  };
  const onColorChange = (event) => {
    if (event.target.value === colorScheme) return;

    const payload = {
      colorScheme: event.target.value,
      variable: state.variable,
      method:
        mapColorType(state.variable.type) === "numeric"
          ? state.method
          : "value",
      id: schema.find((s) => s.group === "Id").name,
    };

    payload.colorPerRows = colorFn(
      currentData,
      payload.variable,
      payload.method,
      payload.colorScheme,
      payload.id
    );

    dispatch(setColor(payload));
  };

  const onSliderChange = (name) => (event, value) =>
    onChange({ target: { name, value } });
  const onOpacityChangeCommitted = (event, value) => dispatch(setAlpha(value));
  const onBackgroundColorChangeCommitted = (event, value) =>
    dispatch(setBackgroundColor(value));
  const onClickClear = (event) =>
    dispatch(
      setBackgroundColor(
        backgroundColorInt <= (lightColorInt + darkColorInt) / 2
          ? darkColorInt
          : lightColorInt
      )
    );
  // const onClickVariable = (event) => dispatch(toggleSchemaDialog("chart"));

  const onKeep = (event) => {
    batch(() => {
      setLoading("keep");
      dispatch(keepDataSelection(brushed));
      setLoading(null);
      setReset(true);
    });
    if (syncWithAdvancedSearch) dispatch(setSearching(true));
  };
  const onRemove = (event) => {
    batch(() => {
      setLoading("remove");
      dispatch(removeDataSelection(brushed));
      dispatch(
        setTableDataFromRemoveSelection({ brushed, schema, data: currentData })
      );
      setLoading(null);
      setReset(true);
    });
    if (syncWithAdvancedSearch) dispatch(setSearching(true));
  };
  const onReset = (event) =>
    batch(() => {
      setLoading("reset");
      dispatch(resetDataSelection());
      setLoading(null);
      setReset(false);
    });

  /**
   * High order function (curried) that dispatch an action on change.
   * @param {*} fn [setBrushPredicate, setVariableSorting]
   */
  const onChangeDispatch = (fn) => (event) => dispatch(fn(event.target.value));
  const onSortButtonClick = () => {
    dispatch(applySort());
  };
  // constants
  const isBrushed =
    brushed.length !== 0 && brushed.length === monitoring["brush"].rows;

  return (
    <Grid container direction="row" className={classes.root}>
      <Row className={classes.row} title="Subsetting">
        <Selection
          brushed={isBrushed}
          reset={reset}
          loading={loading}
          onKeep={onKeep}
          onRemove={onRemove}
          onReset={onReset}
        />
      </Row>
      <Row className={classes.row} title="Color">
        <SelectVariable
          variable={state.variable}
          schema={currentSchema}
          onChange={onVariableChange}
          className={classes.marginRight}
        />
        <SelectType
          variable={state.variable}
          method={state.method}
          onChange={onChange}
          className={classes.marginRight}
        />
        <SelectColor
          variable={state.variable}
          method={state.method}
          colorScheme={colorScheme}
          defaultColor={defaultColor}
          onChange={onColorChange}
        ></SelectColor>
      </Row>
      <Row className={classes.row} title="Opacity">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <Typography
              color="primary"
              variant="h5"
              style={{ cursor: "default" }}
            >
              {" "}
              0
            </Typography>
          </Grid>
          <Grid item xs>
            <Slider
              id="opacity-label"
              name="opacity"
              color="primary"
              value={state.opacity}
              onChange={onSliderChange("opacity")}
              onChangeCommitted={onOpacityChangeCommitted}
              valueLabelDisplay="auto"
              min={0}
              max={1}
              step={0.01}
            />
          </Grid>
          <Grid item>
            <Typography
              color="primary"
              variant="h5"
              style={{ cursor: "default" }}
            >
              1
            </Typography>
          </Grid>
        </Grid>
      </Row>
      <Row className={classes.row} title="Background">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <DarkIcon color="primary" />
          </Grid>
          <Grid item xs>
            <Slider
              id="background-label"
              name="backgroundColorInt"
              color="primary"
              value={state.backgroundColorInt}
              onChange={onSliderChange("backgroundColorInt")}
              onChangeCommitted={onBackgroundColorChangeCommitted}
              // Reduce the limit of 1 on min and max
              // Thereby the dark/lightColorInt value are used for transparency.
              min={darkColorInt + 1}
              max={lightColorInt - 1}
              step={1}
            />
          </Grid>
          <Grid item>
            <LightIcon color="primary" />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              size="small"
              aria-label="Clear background"
              className={classes.clearBtn}
              color="primary"
              onClick={onClickClear}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Row>
      <Row className={classes.row} title="Variables">
        <SelectVariables feature="chart" apply />
      </Row>
      <Row className={classes.row} title="Brush predicate">
        <StyledSelect
          id="brush-predicate-label"
          name="brushPredicate"
          variant="outlined"
          value={brushPredicate}
          onChange={onChangeDispatch(setBrushPredicate)}
          displayEmpty
        >
          <MenuItem value="AND">AND</MenuItem>
          <MenuItem value="OR">OR</MenuItem>
        </StyledSelect>
      </Row>
      <Row className={classes.row} title="Variable sorting">
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item>
            <StyledSelect
              id="variable-sorting-label"
              name="variableSorting"
              variant="outlined"
              value={variableSorting}
              onChange={onChangeDispatch(setVariableSorting)}
              displayEmpty
            >
              <MenuItem value="initial">Initial</MenuItem>
              <MenuItem value="average">Average</MenuItem>
              <MenuItem value="correlation">Correlation</MenuItem>
            </StyledSelect>
          </Grid>
          <Grid item>
            <Button
              id="sort-button"
              variant="outlined"
              color="primary"
              onClick={onSortButtonClick}
            >
              Sort
            </Button>
          </Grid>
        </Grid>
      </Row>
      {/* <Row className={classes.row} title="Shared scale"> */}
    </Grid>
  );
}

export default ConfigurationTab;
