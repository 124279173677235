import { shape, string, object, number } from "prop-types";

export const LocationType = shape({
  pathname: string.isRequired,
  search: string,
  hash: string,
  state: object,
});

export const RouteType = shape({
  index: number.isRequired,
  title: string.isRequired,
  link: string.isRequired,
  metadata: object,
  icon: string,
});
