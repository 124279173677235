import React from "react";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";

import {
  Grid,
  Paper,
  InputLabel,
  Select,
  IconButton,
  TextField,
  Button,
  MenuItem,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ResetIcon from "@material-ui/icons/RotateLeft";
import SearchIcon from "@material-ui/icons/Search";

import Autocomplete from '@material-ui/lab/Autocomplete';


import {
  addFilter,
  removeFilter,
  toggleSyncSearch,
  setSearching,
  syncTableData,
  updateComparator,
  updateFilter,
  updateFilterColumn,
} from "../../features/table";

class DataTableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.updateColumn = this.updateColumn.bind(this);
    this.disabled = this.disabled.bind(this);
    this.onClickReset = this.onClickReset.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onClickRemoveFilter = this.onClickRemoveFilter.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
  }

  operatorsItems = (type) => {
    if (type === "string" || type === "character") {
      return [
        <MenuItem key="contains" value="contains">
          contains
        </MenuItem>,
        <MenuItem key="equals" value="equals">
          equals
        </MenuItem>,
        <MenuItem key="startsWith" value="startsWith">
          starts with
        </MenuItem>,
        <MenuItem key="endsWith" value="endsWith">
          ends with
        </MenuItem>,
      ];
    } else if (type === "number" || type === "integer" || type === "numeric")
      return [
        <MenuItem key="eq" value="eq">
          {"="}
        </MenuItem>,
        <MenuItem key="ne" value="ne">
          {"!="}
        </MenuItem>,
        <MenuItem key="gt" value="gt">
          {">"}
        </MenuItem>,
        <MenuItem key="ge" value="ge">
          {">="}
        </MenuItem>,
        <MenuItem key="lt" value="lt">
          {"<"}
        </MenuItem>,
        <MenuItem key="le" value="le">
          {"<="}
        </MenuItem>,
      ];
    else return null;
  };

  updateColumn = (index) => (event) => {
    const column = this.props.selectedColumns.find(
      (c) => c.name === event.target.value
    );
    let options = null
    if (column.type === 'character' || column.type === 'integer') {
      options = this.props.data.reduce((acc, row) => {
        if (!acc.includes("" + row[column.name]))
          // cast numerical value to string with "" +
          acc.push("" + row[column.name])
        return acc;
      }, []).sort((a, b) => {
        if (column.type === 'integer')
          return parseFloat(a) - parseFloat(b)
        // else if (column.type === 'character')
        return a.localeCompare(b)
      })

    }

    this.props.updateFilterColumn(index, column.type, options)(event);
  };

  disabled = (name) => {
    switch (name) {
      case "search":
      case "reset":
        let filled = this.props.filters.every(
          (filter) =>
            filter.column !== "" &&
            filter.operator !== "" &&
            filter.value !== ""
        );
        if (filled) return false;
        else {
          // this.props.setSearching(false)
          return true;
        }

      default:
        return false;
    }
  };

  onClickRemoveFilter = (index) => async (event) => {
    await this.props.removeFilter(index);

    let filled = this.props.filters.every(
      (filter) =>
        filter.column !== "" && filter.operator !== "" && filter.value !== ""
    );
    if (this.props.filters.length <= 0 || !filled)
      this.props.toggleSyncSearch(false);
    this.props.syncTableData(
      this.props.data,
      this.props.currentData,
      this.props.brushed
    );
    if (this.props.syncWithAdvancedSearch) this.props.setSearching(true);
  };

  onClickReset(event) {
    event.preventDefault();
    this.props.toggleSyncSearch(false);
    this.props.syncTableData(
      this.props.data,
      this.props.currentData,
      this.props.brushed
    );
  }
  onClickSearch(event) {
    event.preventDefault();
    this.props.syncTableData(
      this.props.data,
      this.props.currentData,
      this.props.brushed
    );
    this.props.setSearching(true);
  }
  onBlurInput(event) {
    event.preventDefault();
    let filled = this.props.filters.every(
      (filter) =>
        filter.column !== "" && filter.operator !== "" && filter.value !== ""
    );
    if (filled && this.props.syncWithAdvancedSearch) {
      this.props.syncTableData(
        this.props.data,
        this.props.currentData,
        this.props.brushed
      );
      this.props.setSearching(true);
    } else this.props.toggleSyncSearch(false);
  }

  render() {
    // const { filters, comparator } = this.state;

    const {
      filters,
      comparator,
      selectedColumns,
      addFilter,
      updateComparator,
      updateFilter,
    } = this.props;

    return (
      <div
        style={{
          position: "absolute",
          maxWidth: "fit-content",
          zIndex: 999,
          ...this.props.placement,
        }}
      >
        <Paper variant="outlined">
          <Grid container direction="column" style={{ padding: 4 }}>
            {filters.map((f, index) => {
              return (
                <Grid item key={index} style={{ padding: 8 }}>
                  <Grid container alignItems="flex-end">
                    <Grid item>
                      <IconButton
                        focusRipple={false}
                        size="small"
                        title="delete"
                        aria-label="delete"
                        onClick={this.onClickRemoveFilter(index)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Grid>

                    {index === 0 && (
                      <Grid
                        item
                        style={{ width: "53px", margin: "0 8px" }}
                      ></Grid>
                    )}

                    {index > 0 && (
                      <Grid item style={{ width: "53px", margin: "0 8px" }}>
                        <InputLabel shrink id="filter-comparator-label">
                          Operators
                        </InputLabel>
                        <Select
                          labelId="filter-comparator-label"
                          size="small"
                          name="comparator"
                          value={comparator}
                          onChange={updateComparator()}
                          disabled={index !== 1}
                        >
                          <MenuItem value="and">And</MenuItem>
                          <MenuItem value="or">Or</MenuItem>
                        </Select>
                      </Grid>
                    )}

                    <Grid item>
                      <InputLabel shrink id="filter-columns-label">
                        Columns
                      </InputLabel>
                      <Select
                        labelId="filter-columns-label"
                        style={{ width: 150 }}
                        name="column"
                        value={f.column}
                        onChange={this.updateColumn(index)}
                      >
                        {selectedColumns.map((s, index) => (
                          <MenuItem key={index} value={s.name}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item>
                      <InputLabel shrink id="filter-operators-label">
                        Operators
                      </InputLabel>
                      <Select
                        labelId="filter-operators-label"
                        style={{ width: 120 }}
                        name="operator"
                        value={f.operator}
                        disabled={f.type === ""}
                        size="small"
                        onChange={updateFilter(index, "operator")}
                      >
                        {this.operatorsItems(f.type)}
                      </Select>
                    </Grid>

                    <Grid item>
                      {/* <InputLabel shrink id="filter-value-label" >Value</InputLabel> */}
                      {this.props.filters[index].options ? (
                        this.props.filters[index].options &&
                        <Autocomplete
                          id="select"
                          options={this.props.filters[index].options}
                          freeSolo
                          style={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="Value" />}
                          onInputChange={(e, newInputValue) => updateFilter(index, 'value')({ target: { value: newInputValue } })}
                        />)
                        : (
                          <TextField
                            label="Value"
                            placeholder="Filter value"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: 200 }}
                            value={f.value}
                            onChange={updateFilter(index, "value")}
                            onBlur={this.onBlurInput}
                          />
                        )}



                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item>
              <Grid container alignItems="center">
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={addFilter}
                >
                  Add filter
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<ResetIcon />}
                  onClick={this.onClickReset}
                  disabled={this.disabled("reset")}
                >
                  Reset
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<SearchIcon />}
                  onClick={this.onClickSearch}
                  disabled={this.disabled("search")}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.table.filters,
  comparator: state.table.comparator,
  selectedColumns: state.table.syncColumns
    ? state.chart.selectedColumns
    : state.table.selectedColumns,
  data: state.dataset.data,
  currentData: state.dataset.currentData,
  brushed: state.chart.brushed,
  syncWithAdvancedSearch: state.table.syncWithAdvancedSearch,
});

const mapDispatchToProps = (dispatch) => ({
  addFilter: (event) => dispatch(addFilter()),
  removeFilter: (index) => dispatch(removeFilter(index)),
  updateComparator: () => (event) =>
    dispatch(updateComparator(event.target.value)),
  updateFilter: (index, key) => (event) =>
    dispatch(updateFilter({ index, value: event.target.value, key })),
  updateFilterColumn: (index, type, options) => (event) =>
    dispatch(updateFilterColumn({ index, value: event.target.value, type, options })),
  setSearching: (bool) => dispatch(setSearching(bool)),
  toggleSyncSearch: (bool) => dispatch(toggleSyncSearch(bool)),
  syncTableData: (data, currentData, brushed) =>
    dispatch(syncTableData({ data, currentData, brushed })),
});

export default compose(
  // withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(DataTableFilter);
