import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { withStyles } from '@material-ui/core';

export const StyledSelect = withStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(1.8),
		paddingTop: theme.spacing(1.064), // 36px high, same as a button
		paddingBottom: theme.spacing(1.064),
	},
}))(Select);

export default StyledSelect;

export const StyledNativeSelect = withStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(1.8),
		paddingTop: theme.spacing(1.064), // 36px high, same as a button
		paddingBottom: theme.spacing(1.064),
	},
}))(NativeSelect);
