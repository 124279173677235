import React from "react";
import PropTypes from "prop-types";
import { LocationType, RouteType } from "../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, withRouter } from "react-router-dom";

import { useAuth } from "oidc-react";
import { isAdmin as isAdminFn } from "../../utils/auth";

// routes
import routes from "../../routes";

// Redux actions
import { setDrawer } from "../../features/app";

// Material UI
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Material UI icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import * as icons from "@material-ui/icons";

const useStyles = makeStyles((theme) => {
  // Equals the toolbar height with the variant "dense"
  // It replaces the theme.mixins.toolbar
  const minHeight = 48;

  return {
    paper: { width: 240 },
    chevron: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      minHeight: minHeight,
    },
  };
});

function AppDrawer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { userData } = useAuth();

  // store
  const { drawer } = useSelector((state) => state.app);
  const isAdmin = isAdminFn(userData);

  // events
  const onDrawerClose = (event) => dispatch(setDrawer(false));

  return (
    <Drawer
      variant="temporary"
      anchor={theme.direction === "rtl" ? "right" : "left"}
      open={drawer}
      onClose={onDrawerClose}
      classes={{ paper: classes.paper }}
      ModalProps={{ keepMounted: true }} // Better "open" performance on mobile.
    >
      <div className={classes.chevron}>
        <IconButton onClick={onDrawerClose} size="small">
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <DrawerList location={props.location} routes={routes} isAdmin={isAdmin} />
    </Drawer>
  );
}

AppDrawer.propTypes = {
  location: LocationType,
};

export default withRouter(AppDrawer);

function DrawerList({ location, routes, isAdmin }) {
  if (routes.length === 0) return;
  if (!isAdmin)
    routes = routes.filter(
      (r) => r.metadata.admin === false || !r.metadata.admin
    );
  // if (sessionStorage.getItem("profileId") !== "admin")

  return (
    <List>
      {routes
        .filter((r) => r.metadata.menu)
        .sort((a, b) => a.index - b.index)
        .map((r) => {
          const Icon = icons[r.icon];
          return (
            <ListItem
              component={RouterLink}
              button
              key={r.title}
              selected={location.pathname === r.link}
              to={r.link}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={r.title}></ListItemText>
            </ListItem>
          );
        })}
    </List>
  );
}

DrawerList.propTypes = {
  location: LocationType,
  routes: PropTypes.arrayOf(RouteType),
  isAdmin: PropTypes.bool,
};
