import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import PropTypes from "prop-types";
import {
  setFilterThr,
  applyFilterThreshold,
  setResizeListenerAddedForSlider,
} from "../../features/correlation";
import HistogramSlider from "../HistogramSlider/HistogramSlider";

const _valueAccessor = function (d) {
  return d;
};

export default function CorrHeatmapSlider(props) {
  const dispatch = useDispatch();
  const {
    filterThr,
    corrCoefArr,
    sliderScaleDomain,
    sliderBrushExtent,
    resizeListenerAddedForSlider,
  } = useSelector((state) => state.correlation);
  const [resizeTimestamp, setResizeTimestamp] = useState(0);
  // const resizeListenerAdded = useRef(false);
  useEffect(() => {
    if (!resizeListenerAddedForSlider) {
      window.addEventListener("resize", () => {
        setResizeTimestamp(Date.now());
      });
      dispatch(setResizeListenerAddedForSlider(true));
    }
    return () => {
      // window.removeEventListener("resize", () => { });
    };
  });

  const controllerMethods = () => {
    return {
      setFilterThreshold: (newThr) => {
        dispatch(setFilterThr(Object.assign({}, newThr)));
      },
      applyFilterThreshold: (newThr) => {
        dispatch(
          applyFilterThreshold(Object.assign({ fromSlider: true }, newThr))
        );
      },
      // overrided by valueAccessor
      // getValue:(item)=>{
      //     return _valueAccessor(item); //.value;
      // }
    };
  };

  const _evalHistogramSliderControllerMethods = useRef(controllerMethods);

  return (
    <HistogramSlider
      elementId="correl"
      name="Corr. coef."
      unit="Correlation coefficient"
      // colorGradientDefinition: PropTypes.array, //[{color:String, value:Float, }] array sorted by value
      filterThr={filterThr} // {min:Int, max:Int}
      valuesArr={corrCoefArr} // array of object containing values used to compute histogram : value is the default value field otherwise add getValue accessor method in controllerMethods
      valueAccessor={_valueAccessor} // PropTypes.func, // access values in valuesArr
      // colorScaleProperties = {{values:[-1,0,1],colors:["#e31a1c","#ffffff","#08519c"],gradient:true}} // {values:[],colors:[],legendLabels:[],gradient:boolean} array (length max 2) with diff categorical values to compute 2 histograms
      // histoData: PropTypes.array, // [name:String, value:Int]
      syncSymetricRect={true}
      scaleDomain={sliderScaleDomain}
      // scaleDomainExtPerc = {0} // PropTypes.number, // percentage ]0,100] used to extend min and max values of axis (to avoid drawing shapes on axis)
      brushExtent={sliderBrushExtent}
      resizeTimestamp={resizeTimestamp}
      controllerMethods={_evalHistogramSliderControllerMethods.current} // PropTypes.func, // setFilterThreshold(), applyFilterThreshold(), optionaly getValue() accessor (overrided by valueAccessor if exists)
    />
  );
}
