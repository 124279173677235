import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import setup from "../utils/setup";
import { addNotification } from "./app";
import { fetchDatasetById, fetchSchemaColumn } from "./dataset";
import { setOriginMatrix, setCountByPair } from "./correlation";
const { debug, backendURL, ID_KEY, getAuthorization } = setup();
// dependencies with state.chart.selectedColumns (in sendCorrelation)

export const sendCluster = createAsyncThunk(
  "analytic/sendCluster",
  async (cluster, { dispatch, rejectWithValue }) => {
    debug &&
      console.log(
        `[${analyticSlice.name}:${sendCluster.name}] Clustering data`
      );
    try {
      let { currentDataset, currentSchema, syncColumns, ...body } = cluster;
      const datasetId = currentDataset[ID_KEY];
      let newColName = body.identifier;
      let res = await fetch(`${backendURL}/analytics/${datasetId}/kmeans`, {
        method: "POST",
        credentials: "include", // Important: it tell fetch to include cookies in the request
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthorization(),
        },
        body: JSON.stringify(body),
      });

      if (res.status >= 500 || res.status === 401 || res.status === 403)
        return rejectWithValue(res.status);
      // > 500 addNotification({ message: "Service Unavailable", severity: "error" })
      // =401 || = 403 addNotification({ message: "Insufficient privileges", severity: "error" })

      // too large for just adding column
      // const result = await dispatch(fetchDatasetById(datasetId));
      // Fetch columns and update schema + data accordingly
      const param = { datasetId, columns: [newColName] };
      const result = await dispatch(fetchSchemaColumn(param));
      // In chart and table features: add the new column to the selectedColumn
      // In app feature: add a notification
      // In dataset: duno at the moment
      if (result.payload.columnsSchema.length > 0) return body;
      else return rejectWithValue(404);
    } catch (err) {
      console.log(`[${analyticSlice.name}:${sendCluster.name}]`, err);
      return rejectWithValue(err);
    }
  }
);

export const sendSimilarity = createAsyncThunk(
  "analytic/sendSimilarity",
  async (similarity, { dispatch, rejectWithValue }) => {
    debug &&
      console.log(
        `[${analyticSlice.name}:${sendSimilarity.name}] similarity function `
      );
    try {
      let { currentDataset, currentSchema, syncColumns, ...body } = similarity;
      const datasetId = currentDataset[ID_KEY];
      let newColName = body.identifier;
      let res = await fetch(`${backendURL}/analytics/${datasetId}/computeKNN`, {
        method: "POST",
        credentials: "include", // Important: it tell fetch to include cookies in the request
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthorization(),
        },
        body: JSON.stringify(body),
      });

      if (res.status >= 500 || res.status === 401 || res.status === 403)
        return rejectWithValue(res.status);
      // > 500 addNotification({ message: "Service Unavailable", severity: "error" })
      // =401 || = 403 addNotification({ message: "Insufficient privileges", severity: "error" })

      // too large for just adding column
      // const result = await dispatch(fetchDatasetById(datasetId));
      // Fetch columns and update schema + data accordingly
      const param = { datasetId, columns: [newColName] };
      const result = await dispatch(fetchSchemaColumn(param));
      // In chart and table features: add the new column to the selectedColumn
      // In app feature: add a notification
      // In dataset: duno at the moment
      if (result.payload.columnsSchema.length > 0) return body;
      else return rejectWithValue(404);
    } catch (err) {
      console.log(`[${analyticSlice.name}:${sendSimilarity.name}]`, err);
      return rejectWithValue(err);
    }
  }
);
export const sendCorrelation = createAsyncThunk(
  "analytic/sendCorrelation",
  async (correlation, { dispatch, getState, rejectWithValue }) => {
    debug &&
      console.log(
        `[${analyticSlice.name}:sendCorrelation] correlation function `
      );
    try {
      let { methodName, variables, rowIds, currentDataset } = correlation;
      const datasetId = currentDataset[ID_KEY];
      const body = { methodName, variables, rowIds };
      let res = await fetch(
        `${backendURL}/analytics/${datasetId}/computeCorrelation`,
        {
          method: "POST",
          credentials: "include", // Important: it tell fetch to include cookies in the request
          headers: {
            "Content-Type": "application/json",
            authorization: getAuthorization(),
          },
          body: JSON.stringify(body),
        }
      );

      if (res.status >= 500 || res.status === 401 || res.status === 403)
        return rejectWithValue(res.status);
      // > 500 addNotification({ message: "Service Unavailable", severity: "error" })
      // =401 || = 403 addNotification({ message: "Insufficient privileges", severity: "error" })

      // in HeatmapChart render correlation matrix
      const response = await res.json();
      const corrMatrix = response.corr_mat;
      corrMatrix.data = corrMatrix.data.map((d1) =>
        d1.map((d2) => {
          return { value: d2 };
        })
      );
      // const corrCoefArr=this._corrMatrixToValArray(corrMatrix);
      const countByPair = response.count_by_pair;

      // get selectedColumn from state.chart
      const { selectedColumns } = getState().chart;

      batch(() => {
        dispatch(setOriginMatrix({ corrMatrix, selectedColumns }));
        dispatch(setCountByPair(countByPair));
      });
      if (response) {
        return response;
      } else return rejectWithValue(404);
    } catch (err) {
      console.log(`[${analyticSlice.name}:${sendCorrelation.name}]`, err);
      return rejectWithValue(err);
    }
  }
);
// const corrMatrixDefault= {
//   columns: ["Column1", "Column2", "Column3"],
//   index: ["Column1", "Column2", "Column3"],
//   data: [
//     [{value: 1, list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]},{value: 0.5, list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]},{value: 0.8, list: [1, 2, 3, 4, 5, 6, 7]}],
//     [{value: -0.5, list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]},{value: 1, list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]},{value: 0.9, list: [1, 2, 3, 4, 5, 6, 7]}],
//     [{value: -0.8, list: [1, 2, 3, 4, 5, 6, 7]},{value: -0.9, list: [1, 2, 3, 4, 5, 6, 7]},{value: 1, list: [1, 2, 3, 4, 5, 6, 7]}],
//   ]
// }
// const countByPairDefault={
//   Column1:{Column1:10,Column2:10,Column3:7},
//   Column2:{Column1:10,Column2:10,Column3:7},
//   Column3:{Column1:7,Column2:7,Column3:7},
// }
export const getCorrelationParamObject = function (currentData, schema) {
  // helper method to build parameters based on currentData and schema
  const variables = schema.map((d) => d.name);
  const idKey = schema.find((s) => s.group === "Id").name;
  const rowIds = currentData.map((d) => d[idKey]);
  // const methodName = "Pearson"; // linear
  const methodName = "Spearman"; // monotonic
  return { rowIds, variables, methodName };
};
const analyticSlice = createSlice({
  name: "analytic",
  initialState: {
    loading: false, // true during any analytic loading
    loadingCorrelation: false, // true during correlation loading only
    // list of methods not used anymore ?
    methods: [
      { name: "K-Means", value: "kmeans", _id: "123", type: "clustering" },
      {
        name: "ClinicalKernel",
        value: "ClinKernel",
        _id: "123",
        type: "similarity",
      },
    ],
  },
  // reducers not used anymore ?
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMethods(state, action) {
      state.methods = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendCluster.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(sendCluster.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendCluster.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(sendSimilarity.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(sendSimilarity.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendSimilarity.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(sendCorrelation.pending, (state, action) => {
        state.loadingCorrelation = true;
        state.loading = true;
      })
      .addCase(sendCorrelation.fulfilled, (state, action) => {
        state.loadingCorrelation = false;
        state.loading = false;
      })
      .addCase(sendCorrelation.rejected, (state, action) => {
        state.loadingCorrelation = false;
        state.loading = false;
      });
  },
});

export const { setLoading, setMethods } = analyticSlice.actions;

export default analyticSlice.reducer;

export const fetchClusteringMethods = () => async (dispatch) => {
  debug &&
    console.log(
      `[${analyticSlice.name}:${fetchClusteringMethods.name}] Fetch clustering methods`
    );
  try {
    const ctrl = new AbortController();
    const signal = ctrl.signal;
    let timeoutId = setTimeout(() => {
      ctrl.abort();
    }, 12000); // 12 seconds

    let res = await fetch(`${backendURL}/analytics/`, {
      method: "GET",
      credentials: "include", // Important: it tell fetch to include cookies in the request
      headers: {
        "Content-Type": "application/json",
        authorization: getAuthorization(),
      },
      signal,
    });

    // TODO check signal

    clearTimeout(timeoutId);

    res = await res.json();
    await dispatch(setMethods(res));
  } catch (error) {
    console.error(
      `[${analyticSlice.name}:${fetchClusteringMethods.name}]`,
      error
    );
  }
};

// Check data types of the variables, != numeric must be excluded
export const sendClusterDeprecated = (cluster) => async (dispatch) => {
  debug &&
    console.log(
      `[${analyticSlice.name}:${sendClusterDeprecated.name}] Clustering data`
    );
  try {
    dispatch(setLoading(true));
    let { currentDataset, ...body } = cluster;
    const datasetId = currentDataset[ID_KEY];

    const ctrl = new AbortController();
    const signal = ctrl.signal;
    let timeoutId = setTimeout(() => {
      ctrl.abort();
    }, 120000); // 2min

    let res = await fetch(`${backendURL}/analytics/${datasetId}/kmeans`, {
      method: "POST",
      credentials: "include", // Important: it tell fetch to include cookies in the request
      headers: {
        "Content-Type": "application/json",
        authorization: getAuthorization(),
      },
      body: JSON.stringify(body),
      signal,
    });

    // TODO check signal

    clearTimeout(timeoutId);

    dispatch(setLoading(false));
    if (res.status >= 500)
      return dispatch(
        addNotification({ message: "Service Unavailable", severity: "error" })
      );
    else if (res.status === 401 || res.status === 403)
      return dispatch(
        addNotification({
          message: "Insufficient privileges",
          severity: "error",
        })
      );

    batch(() => {
      // TODO: keep the previously selected columns only + new cluster results (if not synchronized, add it also in the data table)
      // TODO createAsyncThunk and split concerns in the respective slices/reducers
      dispatch(fetchDatasetById(datasetId));
      dispatch(
        addNotification({
          message: `Cluster "${cluster.identifier}" successfully created!`,
          severity: "success",
        })
      );
    });

    return body;
  } catch (error) {
    if (error instanceof DOMException && error.name === "AbortError") {
      dispatch(setLoading(false));
      throw new Error("Aborted");
    }
    console.log(`[${analyticSlice.name}:${sendClusterDeprecated.name}]`, error);
  }
};

export const sendSimilarityDeprecated = (similarity) => async (dispatch) => {
  debug &&
    console.log(
      `[${analyticSlice.name}:${sendSimilarity.name}] similiraty function `
    );
  try {
    dispatch(setLoading(true));
    let { currentDataset, ...body } = similarity;
    const datasetId = currentDataset[ID_KEY];

    const ctrl = new AbortController();
    const signal = ctrl.signal;
    let timeoutId = setTimeout(() => {
      ctrl.abort();
    }, 120000); // 2min

    let res = await fetch(`${backendURL}/analytics/${datasetId}/computeKNN`, {
      method: "POST",
      credentials: "include", // Important: it tell fetch to include cookies in the request
      headers: {
        "Content-Type": "application/json",
        authorization: getAuthorization(),
      },
      body: JSON.stringify(body),
      signal,
    });

    // TODO check signal

    clearTimeout(timeoutId);

    dispatch(setLoading(false));
    if (res.status >= 500)
      return dispatch(
        addNotification({ message: "Service Unavailable", severity: "error" })
      );
    else if (res.status === 401 || res.status === 403)
      return dispatch(
        addNotification({
          message: "Insufficient privileges",
          severity: "error",
        })
      );

    return batch(() => {
      // TODO: keep the previously selected columns only + new cluster results (if not synchronized, add it also in the data table)
      dispatch(fetchDatasetById(datasetId));
      dispatch(
        addNotification({
          message: `Distance column "${similarity.identifier}" successfully created!`,
          severity: "success",
        })
      );
    });
  } catch (error) {
    if (error instanceof DOMException && error.name === "AbortError") {
      dispatch(setLoading(false));
      throw new Error("Aborted");
    }
    console.log(`[${analyticSlice.name}:${sendSimilarity.name}]`, error);
  }
};
