import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormGroup,
	TextField,
	withStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toggleImportHeadersDialog } from '../../../features/app';
import { ID_KEY, importUpdatedHeaders } from '../../../features/dataset';

ImportUpdatedHeaders.propTypes = {
	open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const StyledTextField = withStyles({
	root: {
		'& fieldset': {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
		},
	},
})(TextField);

const StyledButton = withStyles({
	root: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		textTransform: 'capitalize',
	},
})(Button);

function ImportUpdatedHeaders(props) {
	const dispatch = useDispatch();

	// store
	const { loading, currentDataset } = useSelector((state) => state.dataset);

	// state
	const [file, setFile] = useState(null);

	// events
	const onClose = () => {
		dispatch(toggleImportHeadersDialog(false));
		setFile(null);
	};
	const onSubmit = async () => {
		const f = file;
		await dispatch(
			importUpdatedHeaders({ file: f, datasetId: currentDataset[ID_KEY] })
		);
		onClose();
	};

	const onFileUpload = (event) => {
		const f = event.target.files[0];
		if (f !== undefined) setFile(f);
	};

	return (
		<Dialog open={props.open} color="primary" onClose={onClose}>
			<DialogTitle>Import data headers</DialogTitle>
			<DialogContent>
				Conditions:
				<ul>
					<li>The file must be in CSV file (separator: ",")</li>
					<li>Empty fields are allowed, just add ",,"</li>
					<li>The column structure: </li>
					<code>
						Original name, displayed name, type, <br />
						group, group type, display on load
					</code>
				</ul>
				<input
					accept=".csv"
					type="file"
					id="input-file"
					style={{ display: 'none' }}
					onChange={onFileUpload}
				/>
				<label htmlFor="input-file">
					<FormGroup row>
						<StyledButton
							variant="contained"
							color="primary"
							component="span"
							disableElevation
						>
							Choose file
						</StyledButton>
						<StyledTextField
							type="input"
							variant="outlined"
							size="small"
							disabled
							value={file ? file.name : ''}
						/>
					</FormGroup>
				</label>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={onSubmit}
					disabled={loading}
				>
					{loading ? <CircularProgress size={24} /> : 'Import'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ImportUpdatedHeaders;
