import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Drawer, makeStyles, Tabs, Tab, Box } from "@material-ui/core";

import { toggleMenu } from "../../features/chart";

import ConfigurationTab from "./chart-menu-panel/Configuration";
import AnalyzeTab from "./chart-menu-panel/Analyze";
import SimilarityTab from "./chart-menu-panel/Similarity";
import { mdiTilde } from "@mdi/js";
import { mdiSelectGroup } from "@mdi/js";
import { mdiChartLineStacked } from "@mdi/js";
import MDIIcon from "@mdi/react/Icon";

// import TagsTab from "./chart-menu-panel/Tags";

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: "block",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& > div": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      "& > label": {
        display: "inline-block",
        marginRight: theme.spacing(1),
      },
    },
  },
  customSelect: {
    marginRight: theme.spacing(1),
    "& > div": {
      paddingLeft: theme.spacing(1.8),
      paddingTop: theme.spacing(1.06),
      paddingBottom: theme.spacing(1.06),
    },
  },
  clearBtn: {
    padding: "0",
    border: `2px solid ${theme.palette.primary.main}`,
  },
  sliderBtn: {
    padding: "0 5px",
  },
  tabPanel: {
    maxHeight: "30vh",
    minHeight: "30vh",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      maxHeight: "30vh",
      minHeight: "30vh",
    },
  },
}));

// Partial - tabpanel
function TabPanel(props) {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      className={className}
      {...other}
    >
      {/* condition: "value === index & ..." removed because I want to keep the state when switching from "tab to tab" */}
      <Box component="div" mx={2} my={1}>
        {children}
      </Box>
    </div>
  );
}
// local components for icons
function TildeIcon() {
  return (
    <MDIIcon
      path={mdiTilde}
      title="Similarity"
      size={1}
      horizontal
      vertical
    ></MDIIcon>
  );
}
function SelectGroupIcon() {
  return (
    <MDIIcon
      path={mdiSelectGroup}
      title="Clustering"
      size={1}
      horizontal
      vertical
    ></MDIIcon>
  );
}
function ChartLineStackedIcon() {
  return (
    <MDIIcon
      path={mdiChartLineStacked}
      title="Clustering"
      size={1}
      horizontal
      vertical
    ></MDIIcon>
  );
}

export default function ChartMenu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // state
  const [tab, setTab] = useState(0);

  // event
  const onTabChange = (event, value) => setTab(value);

  return (
    <Drawer
      anchor="bottom"
      open={props.open}
      onClose={() => dispatch(toggleMenu(false))}
      variant="persistent"
    >
      <Tabs
        value={tab}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab icon={<ChartLineStackedIcon />} label="Chart configuration" />
        <Tab icon={<SelectGroupIcon />} label="Clustering" />
        <Tab icon={<TildeIcon />} label="Similarity" />
        {/* <Tab label="Tags" /> */}
      </Tabs>

      <TabPanel className={classes.tabPanel} value={tab} index={0}>
        <ConfigurationTab />
      </TabPanel>

      <TabPanel className={classes.tabPanel} value={tab} index={1}>
        <AnalyzeTab />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={tab} index={2}>
        <SimilarityTab />
      </TabPanel>

      {/* <TabPanel className={classes.tabPanel} value={tab} index={3}>
        <TagsTab />
      </TabPanel> */}
    </Drawer>
  );
}
