import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, batch } from "react-redux";

import { useAuth } from "oidc-react";
import {
  initData as initDataFunc,
  initStructure as initStructureFunc,
  fetchDatasets,
  fetchCurrentDataset,
  ID_KEY,
  fetchDatasetById,
  updateDataset,
  exportSchema,
} from "../../features/dataset";

import {
  Card,
  CardContent,
  Chip,
  Divider,
  Fab,
  FormControl,
  Grid,
  Select,
  CircularProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { lightBlue, red, green } from "@material-ui/core/colors";

import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/GetApp";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { coloredFab } from "../styled/Fab";
import {
  toggleDeleteDatasetDialog,
  toggleImportHeadersDialog,
} from "../../features/app";
import { exportDataset } from "../../features/dataset";

import { isAdmin as isAdminFn } from "../../utils/auth";

const DeleteFab = coloredFab(red[400], red[800]);
const PublicFab = coloredFab(lightBlue[400], lightBlue[800]);
const DownloadFab = coloredFab(green[400], green[700]);
const LabelsFab = coloredFab(lightBlue[400], lightBlue[800]);

const PrivateFab = Fab;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "36px",
  },
  title: {
    textAlign: "left",
  },
  formControl: {
    minWidth: 120,
  },
  select: {
    textAlign: "right",
  },
  alignRight: {
    textAlign: "right",
  },
  details: {
    "& > div > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
}));

export default function ChooseDataset(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { userData } = useAuth();
  // state
  const [isLoading, setIsLoading] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  // const { admin } = props
  // store
  const { datasets, currentDataset, currentSchema, initStructure } =
    useSelector((state) => state.dataset);

  const isAdmin = isAdminFn(userData);

  useEffect(() => {
    async function fetchEverything() {
      // await dispatch(setLoading(true))
      try {
        await dispatch(fetchDatasets());
        batch(async () => {
          await dispatch(fetchCurrentDataset());
          await dispatch(initStructureFunc(true));
        });
      } catch (err) {
        console.error(err);
      } finally {
        // await dispatch(setLoading(false))
      }
    }
    !initStructure && fetchEverything();
    // eslint-disable-next-line
  }, [initStructure]);

  // events

  const onChooseDataset = async (event) => {
    const datasetId = event.target.value;

    await dispatch(fetchDatasetById(datasetId));
    await dispatch(initDataFunc(false));
  };

  const onClickPublicPrivate = (value) => (event) => {
    dispatch(updateDataset({ id: currentDataset[ID_KEY], public: value }));
  };

  const onClickDownloadDataset = async (event) => {
    setIsLoading("download");
    await dispatch(exportDataset());
    setIsLoading(null);
    setAnchorEl(null);
  };

  const onClickDownloadSchema = async (event) => {
    setIsLoading("download");
    await dispatch(exportSchema());
    setIsLoading(null);
    setAnchorEl(null);
  };

  const onClickImportHeaders = async (event) => {
    setIsLoading("import-labels");
    await dispatch(toggleImportHeadersDialog(true));
    setIsLoading(null);
  };

  const onClickDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Card>
      <CardContent>
        <Grid container alignItems="center" className={classes.container}>
          <Grid item xs={6} className={classes.title}>
            Choose a dataset:
          </Grid>
          <Grid item xs={6} className={classes.select}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <Select
                id="select-dataset"
                value={currentDataset[ID_KEY]}
                onChange={onChooseDataset}
                disabled={datasets.length === 0}
              >
                {datasets.length ? (
                  datasets.map((v) => (
                    <MenuItem key={v[ID_KEY]} value={v[ID_KEY]}>
                      {v.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" />
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent className={classes.details}>
        <Grid container>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              Description
            </Grid>
            <Grid item xs={8} className={classes.alignRight}>
              {!!currentDataset?.description
                ? currentDataset.description
                : "No description"}
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              Number of features
            </Grid>
            <Grid item xs={8} className={classes.alignRight}>
              <Chip label={currentSchema.length}></Chip>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              Actions
            </Grid>
            <Grid item xs={8} className={classes.alignRight}>
              {isAdmin &&
                (currentDataset.public ? (
                  <PublicFab
                    arial-label="public"
                    variant="extended"
                    size="small"
                    style={{ marginRight: "4px" }}
                    onClick={onClickPublicPrivate(false)}
                  >
                    public
                  </PublicFab>
                ) : (
                  <PrivateFab
                    arial-label="public"
                    variant="extended"
                    size="small"
                    style={{ marginRight: "4px" }}
                    onClick={onClickPublicPrivate(true)}
                  >
                    private
                  </PrivateFab>
                ))}

              <LabelsFab
                aria-label="Import labels"
                variant="extended"
                disabled={
                  datasets.length === 0 || isLoading === "import-labels"
                }
                size="small"
                onClick={onClickImportHeaders}
                style={{ margin: "0 5px" }}
              >
                {isLoading === "import-labels" ? (
                  <CircularProgress size={20} style={{ marginRight: "2px" }} />
                ) : (
                  <AttachFileIcon sx={{ mr: 1 }} />
                )}
                Data headers
              </LabelsFab>

              <DownloadFab
                aria-label="Export as CSV"
                variant="extended"
                disabled={datasets.length === 0 || isLoading === "download"}
                size="small"
                onClick={onClickDownload}
                style={{ margin: "0 5px" }}
              >
                {isLoading === "download" ? (
                  <CircularProgress size={20} style={{ marginRight: "2px" }} />
                ) : (
                  <DownloadIcon sx={{ mr: 1 }} />
                )}
                Export as CSV
              </DownloadFab>
              <Menu
                id="download-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onCloseMenu}
              >
                <MenuItem onClick={onClickDownloadDataset}>
                  Export data
                </MenuItem>
                <MenuItem onClick={onClickDownloadSchema}>
                  Export data headers
                </MenuItem>
              </Menu>
              <DeleteFab
                aria-label="Delete"
                variant="extended"
                disabled={datasets.length === 0}
                size="small"
                onClick={() => dispatch(toggleDeleteDatasetDialog(true))}
              >
                <DeleteIcon sx={{ mr: 1 }} />
                Delete
              </DeleteFab>
            </Grid>
          </Grid>
          {/* Following code: must retrieve the length of the data on dataset change. */}
          {/* {currentData.length > 0 && <Grid container alignItems="center">
            <Grid item xs={4}>
              Number of rows
            </Grid>

            <Grid item xs={8} className={classes.alignRight}>
              <Chip label={currentData.length}></Chip>
            </Grid>
          </Grid>} */}
        </Grid>
      </CardContent>
    </Card>
  );
}
