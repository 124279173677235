const d3 = require("d3");
class D3HistogramSlider {
  svg = undefined;
  sliderG = undefined;
  distriG = undefined;
  width = undefined;
  height = undefined;
  showUnit = true;
  // sliderWidthProportion=0.35;
  sliderWidth = 15; // default value
  histoWidth = undefined;
  defaultThrLabelFontSize = 15;
  thrLabelFontSize = this.defaultThrLabelFontSize;
  xAxisLabelFontSize = 12;
  yAxisLabelFontSize = 15;
  defaultLabelWidth = 40; // default
  thrLabelWidth = this.defaultLabelWidth;
  initialMargin = {
    left: this.thrLabelWidth + this.yAxisLabelFontSize,
    right: 20,
    top: 40,
    bottom: 40,
  }; // margin can be overrided in config.size.margin
  margin = {
    left: this.initialMargin.left,
    right: this.initialMargin.right,
    top: this.initialMargin.top,
    bottom: this.initialMargin.bottom,
  };
  xAxisG = undefined;
  minData = undefined;
  maxData = undefined;
  yAxisG = undefined;
  colorGradiantScaleRange = ["#e31a1c", "#ffffff", "#08519c"]; // default red (min) -> white (middle) -> blue (max) // default value overrided by state.colorScaleProperties.colors in update()
  colorGradiantScaleDomain = undefined;
  colorGradiantScale = undefined; // d3.scaleLinear().range(colorGradiantScaleRange).domain(colorGradiantScaleDomain);
  linearGradient = undefined;
  histoColorScaleRange = undefined; // [] given by state.colorScaleProperties.colors in update()
  histoColorScaleDomain = undefined; // [] given by state.colorScaleProperties.values in update()
  histoColorScale = undefined; // = d3.scaleLinear().range(colorRange).domain(histoColorScaleDomain);
  distriXscale = undefined; // for rectangle horizontal width (and x axis)
  distriYscale = undefined; // for rectangle vertical positions, slider y axis and threshold computation
  // generate_distri_data=undefined;
  slices = 40;
  distri_levels = undefined;
  distri_levels_array = undefined;
  distriMaxValue = undefined;
  // view initialization method
  constructor(el) {
    this.el = el;
  }

  /*
        config: {   size:{height,width,margin},
                    elementId:String, // name used as html element id to distinguish all instances of D3Slider component
                    name: String, // name of the feature associated to the slider (displayed as axis label)
                    unit: String, // unit name of the feature associated to the slider (displayed as axis label)
                 }
        state:  {   data: [number],
                    filterThr: {min:number, max:number},
                    colorScaleProperties:{colors:[string],values[number]} // sorted asc
                    scaleDomainExtPerc:int ]0,100] percentage can be null/undefined/0
                }
        controllerMethods:  {
                                setFilterThreshold: function(thresholdValue) // change threshold value on controller when slider change on brush
                                applyFilterThreshold: function(thresholdValue) // apply the chosen threshold value when brush stopped
                                getValue: function(valueArrObject) // OPTIONAL: get the value from valueArr object
                            }
     */
  create = function (config, state, controllerMethods) {
    // config.size.height and config.size.width are mandatory
    if (!config.size) {
      throw new Error("config.size is missing !");
    } else if (!config.size.height || !config.size.width) {
      const errorMessage =
        "config.size.height or config.size.width is missing !";
      console.log(errorMessage);
      // throw errorMessage
    }

    // TODO: check the need for this (done in update ??)
    // if(state.colorScaleProperties!==undefined&&state.colorScaleProperties.colors){
    //     this.colorGradiantScaleRange=state.colorScaleProperties.colors;
    // }

    // const size = config.size;
    this.svg = d3
      .select(this.el)
      .append("svg")
      .attr("class", "d3")
      .attr("width", config.size.width)
      .attr("height", config.size.height);

    // config.size.margin is not mandatory
    if (config.size.margin) {
      if (config.size.margin.left) {
        this.initialMargin.left = config.size.margin.left;
      }
      if (config.size.margin.right) {
        this.initialMargin.right = config.size.margin.right;
      }
      if (config.size.margin.top) {
        this.initialMargin.top = config.size.margin.top;
      }
      if (config.size.margin.bottom) {
        this.initialMargin.bottom = config.size.margin.bottom;
      }
      this.margin = {
        left: this.initialMargin.left,
        right: this.initialMargin.right,
        top: this.initialMargin.top,
        bottom: this.initialMargin.bottom,
      };
    }
    const testWidth =
      config.size.width - this.initialMargin.left - this.initialMargin.right;

    if (testWidth <= 2 * this.sliderWidth || testWidth <= 10) {
      this.showUnit = false;
      this.margin.right = 0;
      this.margin.left = 0;
      this.thrLabelWidth = 0;
      this.thrLabelFontSize = 15;
      this.width = config.size.width - this.margin.left - this.margin.right;
    } else {
      this.showUnit = true;
      this.margin = {
        left: this.initialMargin.left,
        right: this.initialMargin.right,
        top: this.initialMargin.top,
        bottom: this.initialMargin.bottom,
      };
      this.thrLabelWidth = this.defaultLabelWidth;
      this.thrLabelFontSize = this.defaultThrLabelFontSize;
      this.width = testWidth;
    }

    this.height = config.size.height - this.margin.top - this.margin.bottom;

    this.histoWidth = this.width - this.sliderWidth;
    if (this.histoWidth < 0) {
      this.histoWidth = 0;
    }

    this.colorGradiantScale = d3
      .scaleLinear()
      .range(this.colorGradiantScaleRange); // see default values
    this.distriXscale = d3.scaleLinear().range([0, this.histoWidth]);
    this.distriYscale = d3.scaleLinear().range([this.height, 0]);

    this.distriG = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(" +
          (this.margin.left + this.sliderWidth) +
          "," +
          this.margin.top +
          ")"
      );
    this.sliderG = this.svg
      .append("g")
      .attr("class", "sliderG")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
    if (this.showUnit) {
      this.svg
        .append("text")
        .attr("class", "yUnit noselect")
        // .attr("y",this.height/2)
        // .style("text-anchor", "middle")
        .style("font-size", this.yAxisLabelFontSize)
        .style("font-weight", "bold")
        .style("text-anchor", "middle")
        .attr(
          "transform",
          "rotate(-90) translate(" +
            -this.height / 2 +
            "," +
            this.yAxisLabelFontSize +
            ")"
        )
        .style("pointer-events", "none")
        .text(config.unit);
    }
  };

  update = function (config, state, controllerMethods, chart) {
    this.distriG.selectAll("*").remove();
    this.sliderG.selectAll("*").remove();

    if (!state.data || state.data.length === 0) {
      return;
    }
    // TODO What about gradient scale ?
    if (
      state.colorScaleProperties !== undefined &&
      state.colorScaleProperties.values !== undefined &&
      state.colorScaleProperties.colors !== undefined
    ) {
      this.histoColorScaleRange = state.colorScaleProperties.colors;
      this.histoColorScaleDomain = state.colorScaleProperties.values;
      this.histoColorScale = d3
        .scaleOrdinal()
        .range(this.histoColorScaleRange)
        .domain(this.histoColorScaleDomain);
    }
    // TODO: use state.data for range of values
    this.computeYScaleDomain(state, controllerMethods);

    this.yAxisG = this.sliderG
      .append("g")
      .attr("class", "yAxis noselect")
      .style("pointer-events", "none");
    this.buildYAxis();

    this.computeDistriLevelArrayAndScale(state, controllerMethods);

    this.xAxisG = this.distriG
      .append("g")
      .attr("class", "xAxis grid noselect")
      .style("pointer-events", "none");
    this.buildXAxis();

    // this.distriG.append("line")
    //     .attr("x1",this.distriXscale(Math.floor(this.distriMaxValue/2)))
    //     .attr("x2",this.distriXscale(Math.floor(this.distriMaxValue/2)))
    //     .attr("y1",0)
    //     .attr("y2",this.height)
    //     .attr("stroke","lightgrey")
    //     .attr("stroke-width",1)
    //     .attr("stroke-dasharray","4,2")
    // ;
    // this.distriG.append("line")
    //     .attr("x1",this.distriXscale(this.distriMaxValue))
    //     .attr("x2",this.distriXscale(this.distriMaxValue))
    //     .attr("y1",0)
    //     .attr("y2",this.height)
    //     .attr("stroke","lightgrey")
    //     .attr("stroke-width",1)
    //     .attr("stroke-dasharray","4,2")
    // ;

    this.distriG
      .selectAll(".barRect")
      .data(this.distri_levels_array, this.dataBinding)
      .enter()
      .append("rect")
      .attr("class", "barRect")
      .attr("x", 0)
      // .attr('y', (slice, index) =>{return index*(this.height/this.slices)})
      .attr("y", (slice) => {
        const yPos = this.distriYscale(slice.max);
        return yPos;
      })
      .attr("height", this.height / this.slices)
      .attr("width", (slice) => {
        // const width=this.histoWidth*slice.count/(this.distriMaxValue)
        // return width;
        return this.distriXscale(slice.count);
      })
      .attr("fill", (d) => {
        if (d.type) {
          return this.histoColorScale(d.type);
        } else {
          return "grey";
        }
      });
    // this.updateHistogram();

    this.buildSlider(config, state, controllerMethods);
  };
  computeYScaleDomain = function (state, controllerMethods) {
    if (state.scaleDomain) {
      this.minData = state.scaleDomain.min;
      this.maxData = state.scaleDomain.max;
    } else {
      this.minData = d3.min(state.data, controllerMethods.getValue);
      this.maxData = d3.max(state.data, controllerMethods.getValue);
    }
    if (state.scaleDomainExtPerc) {
      const ext =
        ((this.maxData - this.minData) * state.scaleDomainExtPerc) / 100;
      this.minData = this.minData - ext;
      this.maxData = this.maxData + ext;
    }
    this.distriYscale.domain([this.minData, this.maxData]);
  };
  buildYAxis = function () {
    this.yAxisG
      .call(d3.axisLeft(this.distriYscale))
      .selectAll("text")
      .style("font-size", this.thrLabelFontSize);
  };
  computeDistriLevelArrayAndScale = function (state, controllerMethods) {
    // NB: use state.data for range of values ?
    // => Not necessary because domain scale can be predefined in component props (e.g. correlation coef [1,-1])
    if (state.colorScaleProperties) {
      const dataset1 = state.data.filter((d) => {
        return d.type === state.colorScaleProperties.values[0];
      });
      const distri_levels1 = this._generate_distri_data(
        dataset1,
        controllerMethods.getValue,
        this.slices,
        [this.minData, this.maxData]
      ); // third argument is the number of slices
      distri_levels1.forEach((d) => {
        d.type = state.colorScaleProperties.values[0];
        d.id = d.type + d.numSlice;
      });
      const dataset2 = state.data.filter((d) => {
        return d.type === state.colorScaleProperties.values[1];
      });
      const distri_levels2 = this._generate_distri_data(
        dataset2,
        controllerMethods.getValue,
        this.slices,
        [this.minData, this.maxData]
      ); // third argument is the number of slices
      distri_levels2.forEach((d) => {
        d.type = state.colorScaleProperties.values[1];
        d.id = d.type + d.numSlice;
      });
      this.distri_levels_array = distri_levels1.concat(distri_levels2);
    } else {
      this.distri_levels_array = this._generate_distri_data(
        state.data,
        controllerMethods.getValue,
        this.slices,
        [this.minData, this.maxData]
      ); // third argument is the number of slices
    }
    this.distriMaxValue = Math.max(
      ...this.distri_levels_array.map((d) => {
        return d.count;
      })
    );
    this.distriXscale.domain([0, this.distriMaxValue]);
  };
  dataBinding = function (d) {
    return d.id;
  };
  updateHistogram = function () {
    this.distriG
      .selectAll(".barRect")
      .data(this.distri_levels_array, this.dataBinding)
      .transition()
      .duration(3000)
      .attr("width", (slice) => {
        // const width=this.histoWidth*slice.count/(this.distriMaxValue)
        // return width;
        return this.distriXscale(slice.count);
      });
  };
  buildXAxis = function () {
    const xAxis = d3
      .axisTop(this.distriXscale)
      // .ticks(3)
      .tickValues([0, Math.floor(this.distriMaxValue / 2), this.distriMaxValue])
      .tickSize(-this.height);
    this.xAxisG
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "start")
      .style("font-size", this.xAxisLabelFontSize)
      .style("font-weight", "bold")
      .attr("transform", "rotate(-65) translate(7,8)");
  };
  buildSlider = function (config, state, controllerMethods) {
    // let filterThr={min:this.minData,max:this.maxData};
    let filterThr = { min: this.minData, max: this.minData }; // min,max=minData => no selected data ? overrided if exists in state
    if (state.filterThr) {
      filterThr = Object.assign({}, state.filterThr);
    }

    // create linear gradiant
    const linearGradientId = "linear-gradient" + config.elementId;
    this.linearGradient = this.svg
      .append("defs")
      .append("linearGradient")
      .attr("id", linearGradientId)

      .attr("gradientTransform", "rotate(90)");
    this.rebuildGradiantColor();
    // this.linearGradient.selectAll("stop").remove();
    // for (let i = 0; i < this.colorGradiantScaleDomain.length; i++) {
    //     const value = this.colorGradiantScaleDomain[this.colorGradiantScaleDomain.length - 1 - i];
    //     const percentage = 100 * i / (this.colorGradiantScaleDomain.length - 1);
    //     const color = this.colorGradiantScale(value);
    //     this.linearGradient.append("stop")
    //         .attr("offset", percentage + "%")
    //         .attr("stop-color", color);
    //
    // }

    const gradiantRectWidth = this.sliderWidth;
    const sliderRectG = this.sliderG.append("g");
    sliderRectG
      .append("rect")
      .attr("width", gradiantRectWidth)
      .attr("height", this.height)
      .style("stroke", "black")
      .style("stroke-width", 1)
      .style("fill", "url(#" + linearGradientId + ")");

    const getSelectionCoord = () => {
      // const extent = d3.brushSelection(sliderRectG);

      const extent = d3.event.selection;
      // if(extent) {
      //     console.log("Selection [" + extent[0] + "," + extent[1] + "]");
      // }
      return extent;
    };
    const updateLocalThreshold = () => {
      const extent = getSelectionCoord();
      if (extent) {
        filterThr.max = this.distriYscale.invert(extent[0]);
        filterThr.min = this.distriYscale.invert(extent[1]);
      } else {
        filterThr.min = this.distriYscale.domain()[1];
        filterThr.max = this.distriYscale.domain()[1];
      }
    };
    let brushExtent = { xy1: [0, 0], xy2: [this.width, this.height] };
    if (state.brushExtent) {
      brushExtent.xy1[1] = this.distriYscale(state.brushExtent.max);
      brushExtent.xy2[1] = this.distriYscale(state.brushExtent.min);
    }

    const brushing = d3
      .brushY()
      .extent([brushExtent.xy1, brushExtent.xy2])
      // .move(sliderRectG,[0,150])
      .on("brush", () => {
        updateLocalThreshold();
        this._changeFilterThrVal(filterThr, controllerMethods);
      })
      .on("end", () => {
        updateLocalThreshold();
        this._applyNewThreshold(filterThr, controllerMethods);
      });
    const sliderBrushG = sliderRectG.append("g").attr("class", "brush");
    // .style("opacity",0.75)
    sliderBrushG
      .call(brushing)
      .call(brushing.move, [
        this.distriYscale(filterThr.max),
        this.distriYscale(filterThr.min),
      ]); // filterThr.max,filterThr.min
    if (config.syncSymetricRect) {
      sliderBrushG
        .append("rect")
        .attr("class", "symBrushedRect")
        .attr("y", () => this.distriYscale(-filterThr.min))
        .attr("width", this.width)
        .attr("height", () => {
          return (
            this.distriYscale(-filterThr.max) -
            this.distriYscale(-filterThr.min)
          );
        })
        .style("stroke", "#fff")
        .style("stroke-width", 1)
        .style("fill", "#777") // CIL Grimmy's Grey used by default in brush
        .style("opacity", "0.3");
    }
    // this.sliderG.append("rect")
    //     .attr("class","whiteRectForFilterMin")
    //     .attr("y",()=>this.distriYscale(filterThr.min))
    //     .attr("width",gradiantRectWidth)
    //     .attr("height",()=>this.distriYscale(-filterThr.min)-this.distriYscale(filterThr.min))
    //     .style("stroke", "black")
    //     .style("stroke-width",1)
    //     .style("fill","white")
    // ;
    // this.sliderG.append("rect")
    //     .attr("class","whiteRectForFilterMaxTop")
    //     .attr("width",gradiantRectWidth)
    //     .attr("height",()=>this.distriYscale(filterThr.max))
    //     .style("stroke", "black")
    //     .style("stroke-width",1)
    //     .style("fill","white")
    // ;
    // this.sliderG.append("rect")
    //     .attr("class","whiteRectForFilterMaxBottom")
    //     .attr("y",()=>this.distriYscale(-filterThr.max))
    //     .attr("width",gradiantRectWidth)
    //     .attr("height",()=>this.distriYscale(filterThr.max))
    //     .style("stroke", "black")
    //     .style("stroke-width",1)
    //     .style("fill","white")
    // ;
    const maxThrLabelG = this.sliderG
      .append("g")
      .attr("class", "maxThrLabelG")

      .attr(
        "transform",
        "translate(0," + this.distriYscale(filterThr.max) + ")"
      );
    // const shiftForTick = 6;
    maxThrLabelG
      .append("rect")
      // .attr("x",-this.thrLabelWidth-shiftForTick) // uncomment if label displayed on the left margin
      .attr("y", -this.thrLabelFontSize / 2)
      .attr("height", this.thrLabelFontSize)
      .attr("width", this.width) // this.thrLabelWidth if label displayed on the left margin
      .style("fill", "white")
      .style("opacity", "0.5")
      .style("pointer-events", "none");
    maxThrLabelG
      .append("text")
      .attr("class", "maxThrText noselect")
      // .attr("x",-shiftForTick-2) // uncomment if label displayed on the left margin
      .attr("y", this.thrLabelFontSize / 2 - 2)
      .style("font-size", this.thrLabelFontSize)
      .style("text-anchor", "start") // end if displayed on the left margin
      .style("pointer-events", "none")
      .text(Math.round(filterThr.max * 100) / 100);
    // .style('stroke',"grey")
    maxThrLabelG
      .append("line")
      .attr("class", "maxThrLine")
      .attr("x1", -6)
      .attr("y1", 1)
      .attr("x2", gradiantRectWidth)
      .attr("y2", 1)
      .style("stroke", "grey");

    const minThrLabelG = this.sliderG
      .append("g")
      .attr("class", "minThrLabelG")

      .attr(
        "transform",
        "translate(0," + this.distriYscale(filterThr.min) + ")"
      );
    minThrLabelG
      .append("rect")
      // .attr("x",-this.thrLabelWidth-shiftForTick) // uncomment if label displayed on the left margin
      .attr("y", -this.thrLabelFontSize / 2)
      .attr("height", this.thrLabelFontSize)
      .attr("width", this.width) // this.thrLabelWidth if label displayed on the left margin
      .style("fill", "white")
      .style("opacity", "0.5")
      .style("pointer-events", "none");
    // .style("stroke-width",1)
    // .style("stroke","black")

    minThrLabelG
      .append("text")
      // .attr("x",-shiftForTick-2) // uncomment if label displayed on the left margin
      .attr("class", "minThrText noselect")
      .attr("y", this.thrLabelFontSize / 2 - 2)
      .style("font-size", this.thrLabelFontSize)
      .style("text-anchor", "start") // end if displayed on the left margin
      .style("pointer-events", "none")
      .text(Math.round(filterThr.min * 100) / 100);
    // .style('stroke',"grey")
    minThrLabelG
      .append("line")
      .attr("class", "minThrLine")
      .attr("x1", -6)
      .attr("y1", 1)
      .attr("x2", gradiantRectWidth)
      .attr("y2", 1)
      .style("stroke", "grey");
  };
  rebuildGradiantColor = function () {
    this.colorGradiantScaleDomain = [this.minData, 0, this.maxData];
    this.colorGradiantScale.domain(this.colorGradiantScaleDomain);
    this.linearGradient.selectAll("stop").remove();
    for (let i = 0; i < this.colorGradiantScaleDomain.length; i++) {
      const value =
        this.colorGradiantScaleDomain[
          this.colorGradiantScaleDomain.length - 1 - i
        ];
      const percentage = (100 * i) / (this.colorGradiantScaleDomain.length - 1);
      const color = this.colorGradiantScale(value);
      this.linearGradient
        .append("stop")
        .attr("offset", percentage + "%")
        .attr("stop-color", color);
    }
  };
  updateSlider = function (newThr) {
    this.sliderG
      .select(".whiteRectForFilterMin")
      .attr("y", () => this.distriYscale(newThr.min))
      .attr(
        "height",
        () => this.distriYscale(-newThr.min) - this.distriYscale(newThr.min)
      );
    this.sliderG
      .select(".whiteRectForFilterMaxTop")
      .attr("height", () => this.distriYscale(newThr.max));
    this.sliderG
      .select(".whiteRectForFilterMaxBottom")
      .attr("y", () => this.distriYscale(-newThr.max))
      .attr("height", () => this.distriYscale(newThr.max));
    this.sliderG
      .select(".maxThrLabelG")
      .attr("transform", "translate(0," + this.distriYscale(newThr.max) + ")");
    this.sliderG
      .select(".maxThrText")
      //     .attr("y",this.distriYscale(newThr.max)+this.thrLabelFontSize/2)
      .text(Math.round(newThr.max * 100) / 100);
    this.sliderG
      .select(".minThrLabelG")
      .attr("transform", "translate(0," + this.distriYscale(newThr.min) + ")");
    this.sliderG
      .select(".minThrText")
      // .attr("y",this.distriYscale(newThr.min)+this.thrLabelFontSize/2)
      .text(Math.round(newThr.min * 100) / 100);
    // this.sliderG.select(".maxThrLine")
    //     .attr("y1",this.distriYscale(newThr.max))
    //     .attr("y2",this.distriYscale(newThr.max))
    // ;
    // this.sliderG.select(".minThrLine")
    //     .attr("y1",this.distriYscale(newThr.min))
    //     .attr("y2",this.distriYscale(newThr.min))
    // ;
    this.sliderG
      .select(".symBrushedRect")
      .attr("y", () => this.distriYscale(-newThr.min))
      .attr("width", this.width)
      .attr("height", () => {
        return this.distriYscale(-newThr.max) - this.distriYscale(-newThr.min);
      });
  };
  updateUnit = function (unit) {
    this.svg.select(".yUnit").text(unit);
  };
  updateYData = function (state, controllerMethods) {
    this.computeYScaleDomain(state, controllerMethods);
    // this.yAxisG
    //     // .transition().duration(1000)
    //     .call(this.yAxis)
    //     .selectAll("text")
    //     .style("font-size", this.scatterPlotLegendFontSize)
    // ;
    // TODO: remove YAxis and XAxis before rebuild ? or animated update ?
    this.buildYAxis();

    this.computeDistriLevelArrayAndScale(state, controllerMethods);
    this.buildXAxis();
    // TODO: re-render the y axis...
    // this.scatterPlotPointsG.selectAll(".scatterPlotPoint")
    //     .attr("cy",(d)=>{
    //         return this.yScale(controllerMethods.getValue(d));
    //     })
    // ;

    this.rebuildGradiantColor();
    this.updateHistogram();
  };
  // private helper methods
  _generate_distri_data(valArr, valueAccessor_f, slices, range) {
    if (slices === undefined) {
      slices = 20;
    }
    const min = range[0];
    const max = range[1];
    const sliceSpan = (max - min) / slices;
    const distri_levels = [];
    // init distribution object with slice ranges
    const getSliceRange = (numSlice) => {
      let sliceMin = min + numSlice * sliceSpan;
      let sliceMax = min + (numSlice + 1) * sliceSpan;
      return { min: sliceMin, max: sliceMax, count: 0 };
    };
    for (let i = 0; i < slices; i++) {
      const sliceRange = getSliceRange(i);
      distri_levels.push(sliceRange);
    }
    const getNumSliceFromValue = (obj) => {
      const val = valueAccessor_f(obj);
      let numSlice = (val - min) / sliceSpan;
      if (val === max) {
        numSlice = distri_levels.length - 1;
      }
      return Math.floor(numSlice);
    };
    valArr.forEach((d, i) => {
      const numSlice = getNumSliceFromValue(d);
      if (!distri_levels[numSlice]) {
        console.log(numSlice + "for " + valueAccessor_f(d));
      }
      distri_levels[numSlice].count = distri_levels[numSlice].count + 1;
    });
    const result = Object.keys(distri_levels).map((key) => {
      distri_levels[key].numSlice = key;
      return distri_levels[key];
    });
    return result; //Object.values(distri_levels);
  }

  _changeFilterThrVal = function (newThr, controllerMethods) {
    this.updateSlider(newThr);
    controllerMethods.setFilterThreshold(newThr);
  };

  _applyNewThreshold = function (newThr, controllerMethods) {
    console.log("apply new threshold: (" + newThr.min + "," + newThr.max + ")");
    this.updateSlider(newThr);
    controllerMethods.applyFilterThreshold(newThr);
  };

  destroy = function () {
    // Any clean-up would go here
    d3.select(this.el).selectAll("*").remove();
  };
  resize = function (config, state, controllerMethods) {
    this.destroy();
    this.create(config, state, controllerMethods);
    this.update(config, state, controllerMethods);
  };
}
export default D3HistogramSlider;
