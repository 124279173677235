import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  checkUniqueSchemaRow,
  deleteSchemaColumn,
  ID_KEY,
  updateSchemaRow,
} from "../../features/dataset";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
  withStyles,
  TablePagination,
  TableFooter,
  // Checkbox,
  MenuList,
  IconButton,
  Popper,
  Grow,
  ClickAwayListener,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

// Lamda components
import DisplayColumnMenu from "./menu/DisplayColumnMenu.jsx";
import DeleteColumnMenu from "./menu/DeleteColumnMenu";
import EditableTableCell from "./table/EditableTableCell";
import { addNotification } from "../../features/app";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightIcon />
        ) : (
          <KeyboardArrowLeftIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const TableHeader = () =>
  ["Feature", "Displayed name", "Type", "Group", "Group Type" /*, "Display on load"*/].map(
    (v, i) => <TableCell key={v}>{v}</TableCell>
  );

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function FeaturesTable() {
  const classes = useStyles();
  const { currentSchema, currentDataset } = useSelector(
    (state) => state.dataset
  );

  const datasetRef = useRef([]);
  const schemaRef = useRef([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [menu, setMenu] = useState(null); // The name of the row
  const [anchor, setAnchor] = useState(null); // The HTML element of the row.

  const dispatch = useDispatch();

  const onPageChange = (event, newPage) => {
    onCloseMenu();
    setPage(newPage);
  };
  const onRowsPerPageChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    setPage(0);
  };

  // const onDisplayOnLoadChange = (event, currentRow) => {
  //   const value = event.target.checked;
  //   const name = event.target.name;
  //   // currentRow.displayOnLoad=event.target.value;
  //   const newRow = { ...currentRow, [name]: value };

  //   dispatch(updateSchemaRow({ datasetId: currentDataset[ID_KEY], currentSchema, newRow }));
  // };

  useEffect(() => {
    async function resetPagination() {
      // check if resetPagination is need
      // if (schemaRef.current !== currentSchema ) {

      if (datasetRef.current.name !== currentDataset.name) {
        await setPage(0);
        await setRowsPerPage(10);
      }
      // update refs
      if (schemaRef.current !== currentSchema) {
        schemaRef.current = currentSchema;
      }
      if (datasetRef.current !== currentDataset) {
        datasetRef.current = currentDataset;
      }
    }

    resetPagination();
  }, [setPage, setRowsPerPage, currentSchema, currentDataset, schemaRef]);

  // events
  const onClickShowHideColumn = (currentRow) => (event) => {
    const newRow = { ...currentRow, displayOnLoad: !currentRow.displayOnLoad };

    dispatch(
      updateSchemaRow({
        datasetId: currentDataset[ID_KEY],
        currentSchema,
        newRow,
      })
    );
    onCloseMenu();
  };

  const onClickDeleteColumn = (columnName) => (event) => {
    dispatch(
      deleteSchemaColumn({
        datasetId: currentDataset[ID_KEY],
        columns: [columnName],
      })
    );
    if (currentSchema.length % rowsPerPage <= 1)
      setPage(page > 1 ? page - 1 : 0);
    onCloseMenu();
  };

  const onClickMenu = (rowName) => (event) => {
    event.preventDefault();
    setAnchor(event.currentTarget);
    setMenu(rowName);
  };

  const onCloseMenu = (event) => {
    setAnchor(null);
    setMenu(null);
  };

  const updateCellValue = async (newProp) => {
    const prevRow = currentSchema.find(row => row.name === newProp.name)

    if (typeof prevRow !== 'object')
      return

    if (prevRow[newProp.property] === newProp.value)
      return

    if (prevRow.group !== newProp.value && newProp.value === 'Unique') {


      const dataIsUnique = await dispatch(checkUniqueSchemaRow({
        datasetId: currentDataset[ID_KEY],
        name: newProp.name
      }))

      if (!dataIsUnique.payload)
        // Todo: notify the user the column can't be unique.
        return dispatch(addNotification({
          message: `The column "${newProp.property}" cannot be unique!`, severity: "warning"
        }))
    }

    const newRow = Object.assign({}, prevRow, { [newProp.property]: newProp.value })


    dispatch(updateSchemaRow({
      datasetId: currentDataset[ID_KEY],
      currentSchema,
      newRow,
    }))
  }

  // internal method
  function validGroup(group) {
    switch (group) {
      case "Cluster":
      case "Distance":
        return true;
      default:
        return false;
    }
  }

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableHeader />
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? currentSchema.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : currentSchema
            ).map((row) => (
              <StyledTableRow key={row.name}>
                <TableCell>
                  <Typography color={row.displayOnLoad ? "primary" : "initial"}>
                    {row.name}
                  </Typography>
                </TableCell>
                {/* 
                L'idée ici c'est d'ajouter une propriété "options" dans laquel on va mettre les options possible pour un Select component.
                Si la valeur de "options" n'est pas null ou undefined alors on utilise un select au lieu d'un textfield.
                */}
                <EditableTableCell update={updateCellValue} name={row.name} property="displayedName">
                  {row.displayedName ? row.displayedName : row.name}
                </EditableTableCell>
                <EditableTableCell update={updateCellValue} name={row.name} property="type" options={["integer", "character", "numeric"]}>{row.type}</EditableTableCell>
                {
                  row.group !== 'Id'

                    ? <EditableTableCell update={updateCellValue} name={row.name} property="group" options={row.type === 'character' ? ["Feature", "Unique"] :
                      ["Feature", "Cluster", "Distance", "Unique"]
                    }>{row.group}</EditableTableCell>
                    : <TableCell>{row.group}</TableCell>
                }

                {
                  row.group !== 'Id'
                    ? <EditableTableCell update={updateCellValue} name={row.name} property="group_type">{row.group_type}</EditableTableCell>
                    : <TableCell>{row.group_type}</TableCell>
                }
                {/*<TableCell>{"" + row.displayOnLoad}</TableCell>*/}

                <TableCell align="center">
                  <React.Fragment>
                    <IconButton
                      aria-label="show-hide-column"
                      component="span"
                      onClick={onClickShowHideColumn(row)}
                    >
                      {row.displayOnLoad ? (
                        <Tooltip title="Displayed by default">
                          <VisibilityIcon color="primary" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Hidden by default">
                          <VisibilityOffIcon />
                        </Tooltip>
                      )}
                    </IconButton>
                    <IconButton
                      id={`menu-btn-${row.name}`}
                      aria-label="menu"
                      component="span"
                      onClick={onClickMenu(row.name)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Popper
                      open={menu === row.name}
                      anchorEl={anchor}
                      role={undefined}
                      placement="bottom-start"
                      transition
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                        >
                          <Paper elevation={1}>
                            <ClickAwayListener onClickAway={onCloseMenu}>
                              <MenuList
                                id={`menu-${row.name}`}
                                autoFocusItem={menu === row.name}
                              >
                                <DisplayColumnMenu
                                  onClick={onClickShowHideColumn(row)}
                                  show={row.displayOnLoad}
                                />
                                {validGroup(row.group) && (
                                  <DeleteColumnMenu
                                    onClick={onClickDeleteColumn(row.name)}
                                  />
                                )}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </React.Fragment>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={currentSchema.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}
