import { createSlice, current } from "@reduxjs/toolkit";

import {
  keepDataSelection,
  partitionSchema,
  removeDataSelection,
  resetDataSelection,
  selectDataset,
  setData,
  setInitData,
  setInitSchema,
  updateColumns,
  updateSchemaColumns,
} from "./dataset";
import {
  reorderSchema,
  setBrushed,
  updateSelectedExcludedColumns,
} from "./chart";
import { sort } from "../utils/array";
// import { sendCluster } from "./analytic";
// import { logoutProfile, refresh } from "./app";
const isString = (value) => typeof value === "string";
/**
 * @param  a The search value provided by the user
 * @param b The value from the dataset
 * @return A boolean
 */
const numericOperators = {
  // eslint-disable-line
  eq: (a, b) => parseFloat(a) === parseFloat(b),
  ne: (a, b) => parseFloat(a) !== parseFloat(b),
  ge: (a, b) => parseFloat(a) <= parseFloat(b),
  gt: (a, b) => parseFloat(a) < parseFloat(b),
  le: (a, b) => parseFloat(a) >= parseFloat(b),
  lt: (a, b) => parseFloat(a) > parseFloat(b),

};

const stringOperators = {
  // eslint-disable-line
  contains: (a, b) => isString(b) && b.trim().includes(a.trim()),
  equals: (a, b) => isString(b) && b.trim() === a.trim(),
  startsWith: (a, b) => isString(b) && b.trim().startsWith(a.trim()),
  endsWith: (a, b) => isString(b) && b.trim().endsWith(a.trim()),
}

const operators = {
  // eslint-disable-line
  ...stringOperators,
  ...numericOperators
};

function _searchByFilters(data, filters, comparator = "and") {
  const results = [];

  if (filters.length === 0) return data;

  if (data.length === 0) return [];

  let count = 0;
  let len2 = filters.length;
  // fastest way to loop in JS
  for (let i = 0, len = data.length; i < len; i++) {
    for (let j = 0; j < len2; j++) {
      let operator = operators[filters[j].operator];
      if (!operator) return [];

      let { column, value } = filters[j];
      // the following section ensure that the operans are both string when string operators are used.
      let a = value, b = data[i][column];
      if (filters[j].operator in stringOperators) {
        a = "" + a
        b = "" + b
      }
      if (operator(a, b)) count++;
    }
    if (comparator === "and" && count === len2) results.push(data[i]);
    else if (comparator === "or" && count > 0) results.push(data[i]);
    count = 0;
  }

  return results;
}

const initialState = {
  loading: false,
  syncWithBrushing: true,
  syncWithSubsetting: true,
  syncColumns: true,
  excludedColumns: [], // the list of columns to exclude (also in chart)
  selectedColumns: [], // the selected columns (also in chart)
  // search parameters
  caseSensitive: false,
  wholeWord: false,
  searchColumns: [], // the list of columns on which the search is applied
  // table filters
  initialTableData: [],
  subsetData: [],
  tableData: [],
  searching: false,
  syncWithAdvancedSearch: false,
  filters: [
    {
      column: "",
      type: "",
      operator: "",
      value: "",
    },
  ],
  comparator: "and",
  kClosest: 30,
};

const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    toggleBrushing(state, action) {
      if (action.payload === null)
        state.syncWithBrushing = !state.syncWithBrushing;
      else state.syncWithBrushing = action.payload;
    },
    toggleSubsetting(state, action) {
      if (action.payload === null)
        state.syncWithSubsetting = !state.syncWithSubsetting;
      else state.syncWithSubsetting = action.payload;
    },
    toggleColumns(state, action) {
      if (action.payload === null) state.syncColumns = !state.syncColumns;
      else state.syncColumns = action.payload;
    },
    setSelectedColumns(state, action) {
      state.selectedColumns = action.payload;
    },
    setExcludedColumns(state, action) {
      state.excludedColumns = action.payload;
    },
    setColumns(state, action) {
      let { selectedColumns, excludedColumns } = action.payload;
      if (excludedColumns.length > 0 && "_pos" in excludedColumns[0])
        excludedColumns = excludedColumns.sort((a, b) => a._pos - b._pos);
      if (selectedColumns.length > 0 && "_pos" in selectedColumns[0])
        selectedColumns = selectedColumns.sort((a, b) => a._pos - b._pos);

      state.selectedColumns = selectedColumns;
      state.excludedColumns = excludedColumns;
      state.searchColumns = selectedColumns.map((s) => s.name);
    },
    toggleCaseSensitive(state, action) {
      if (action.payload === null || action.payload === undefined)
        state.caseSensitive = !state.caseSensitive;
      else state.caseSensitive = action.payload;
    },
    toggleWholeWord(state, action) {
      if (action.payload === null || action.payload === undefined)
        state.wholeWord = !state.wholeWord;
      else state.wholeWord = action.payload;
    },
    // searching fn
    setSearching(state, action) {
      state.searching = action.payload;

      if (state.searching === true) {
        state.syncWithAdvancedSearch = true;
        const tData = _searchByFilters(
          current(state.tableData),
          current(state.filters),
          state.comparator
        );
        state.tableData = tData;
        state.searching = false;
      }
    },
    setTableData(state, action) {
      if (state.syncWithAdvancedSearch) {
        const tData = _searchByFilters(
          action.payload,
          current(state.filters),
          state.comparator
        );
        state.tableData = tData;
      } else state.tableData = action.payload;
      state.searching = false;
    },
    setTableDataFromRemoveSelection(state, action) {
      const { schema, data, brushed } = action.payload;
      const ID = schema.find((s) => s.group === "Id").name;
      const excluded = brushed.map((b) => b[ID]);
      const tData = data.filter((d) => excluded.indexOf(d[ID]) === -1);
      state.subsetData = tData;
      if (state.syncWithSubsetting) state.tableData = tData;
      else state.tableData = state.initialTableData;
    },

    // Filters
    addFilter(state, action) {
      state.filters.push({
        column: "",
        type: "",
        operator: "",
        value: "",
      });
      state.syncWithAdvancedSearch = false;
    },
    removeFilter(state, action) {
      if (state.filters.length === 1) {
        state.filters = [
          {
            column: "",
            type: "",
            operator: "",
            value: "",
          },
        ];
      } else state.filters.splice(action.payload, 1);

      let filled = state.filters.every(
        (filter) =>
          filter.column !== "" && filter.operator !== "" && filter.value !== ""
      );
      if (!filled) state.syncWithAdvancedSearch = false;
    },
    updateComparator(state, action) {
      state.comparator = action.payload;
    },
    updateFilter(state, action) {
      const { index, value, key } = action.payload;
      state.filters[index][key] = value;
    },
    updateFilterColumn(state, action) {
      const { index, value, type, options } = action.payload;
      state.filters[index].type = type;
      state.filters[index].column = value;
      state.filters[index].options = options;
    },
    toggleSyncSearch(state, action) {
      if (action.payload === null || action.payload === undefined)
        state.syncWithAdvancedSearch = !state.syncWithAdvancedSearch;
      else state.syncWithAdvancedSearch = action.payload;
    },
    syncTableData(state, action) {
      const { data, currentData, brushed } = action.payload;
      let { syncWithBrushing, syncWithSubsetting, syncWithAdvancedSearch } =
        state;

      let newTableData = [];
      if (syncWithSubsetting && syncWithBrushing)
        newTableData = brushed.length > 0 ? brushed : currentData;
      else if (syncWithSubsetting) newTableData = currentData;
      else if (syncWithBrushing)
        newTableData = brushed.length > 0 ? brushed : data;
      else newTableData = data;

      // state.initialTableData = newTableData;
      if (syncWithAdvancedSearch)
        newTableData = _searchByFilters(
          newTableData.slice(),
          current(state.filters),
          state.comparator
        );

      state.tableData = newTableData;
    },
  },
  extraReducers: (builder) => {
    function updateAndFilterData(state, data, withInitData) {
      let tData = data;
      if (state.syncWithAdvancedSearch) {
        tData = _searchByFilters(
          data,
          current(state.filters),
          state.comparator
        );
      }
      state.tableData = tData;
      if (withInitData) {
        state.initialTableData = tData;
      }
    }

    /**
     * When setSchema from dataset.js is trigger this reducer will
     * also performs a modification on its state.
     */
    builder
      .addCase(setInitSchema, (state, action) => {
        partitionSchema(state, action);
        state.syncWithBrushing = true;
        state.syncWithSubsetting = true;
        state.syncColumns = true;

        // Applies the default search on the visible columns.
        state.searchColumns = state.selectedColumns.map((s) => s.name);
      })
      .addCase(reorderSchema, (state, action) => {
        let newSelected = sort(state.selectedColumns, action.payload, "name");
        let newExcluded = sort(state.excludedColumns, action.payload, "name");
        newSelected = newSelected.map((v, i) => {
          v._pos = i;
          return v;
        });
        newExcluded = newExcluded.map((v, i) => {
          v._pos = i;
          return v;
        });
        state.selectedColumns = newSelected;
        state.excludedColumns = newExcluded;

        // Applies the default search on the visible columns.
        state.searchColumns = state.selectedColumns.map((s) => s.name);
      })
      .addCase(setData, (state, action) => {
        updateAndFilterData(state, action.payload);
      })
      .addCase(setInitData, (state, action) => {
        updateAndFilterData(state, action.payload, true);
      })
      .addCase(updateColumns, (state, action) => {
        const { newData, newSchema } = action.payload;

        // add new columns in selected and update existing in excluded or selected
        updateSelectedExcludedColumns(state, newSchema);

        // // Applies the default search on the visible columns.
        state.searchColumns = state.selectedColumns.map((s) => s.name);

        updateAndFilterData(state, newData, true);
      })
      .addCase(updateSchemaColumns, (state, action) => {
        const newSchema = action.payload;
        updateSelectedExcludedColumns(state, newSchema);
      })
      .addCase(setBrushed, (state, action) => {
        // let d = Date.now().toLocaleString()
        // console.log(`[table-${d}]state.initialTableData`, current(state.initialTableData).length)
        // console.log(`[table-${d}]state.currentData`, current(state.currentData).length)
        // console.log(`[table-${d}]action.payload`, action.payload)
        // console.log(`[table-${d}]sync brush-filter`, state.syncWithBrushing, state.syncWithSubsetting)
        let newData = current(state.initialTableData);
        // <<<<<<< HEAD from Audren version of develop before merge with similarity
        //         // N.M. after merging similarity into develop setting a brush where not working due to change of payload structure
        //         if (state.syncWithBrushing /*&& action.payload*/)
        //           if (action.payload) // NM: payload have brushed + brushMethod see fix bellow
        //             newData = action.payload
        //           else
        //             newData = current(state.tableData) // NM: issue: tableData still contain previous brushed
        // ======= // N.M. I keep that part from similarity branch that fixed issue at unbrush and also setting brush.
        // TODO: The sequence should be the following:
        //        1. if sync with subset: newData <- subset
        //        2. if sync with brushing && brushed is not empty: newData <- brushed
        //        3. if sync with advancedSearch && filters exists && are enabled: newData <- apply filters on newData
        //        4. if quickSearch exists: newData <- apply quick search filter on newData
        //  to simplify the code and conditions the same sequence would apply for any filters/brush/sync actions)
        //  this should not cause perf issues (if any we will optimize and custom it for each action)
        const { brushed } = action.payload;
        if (state.syncWithBrushing && brushed /*&& action.payload*/) {
          // const {brushed} = action.payload;
          // if (brushed)
          newData = brushed;
          // else if (state.currentData) // N.M. before merge: Fixed error currentData == undefined when unbrush
          //   newData = current(state.currentData) // N.M. before merge
          // else if (state.tableData) // NM after merge with develop (issue: tableData still contain previous brushed)
          //   newData = current(state.tableData) // NM after merge with develop (issue: tableData still contain previous brushed)
        }
        // >>>>>>> similarity
        else if (
          state.syncWithSubsetting &&
          state.subsetData.length > 0 /*&& !state.syncWithBrushing*/
        )
          newData = current(state.subsetData); // get subset
        updateAndFilterData(state, newData);
      })
      .addCase(keepDataSelection, (state, action) => {
        state.subsetData = action.payload;
        if (state.syncWithSubsetting) {
          updateAndFilterData(state, action.payload);
        } else {
          state.tableData = state.initialTableData;
        }
      })
      .addCase(removeDataSelection, (state, action) => {
        if (state.syncWithSubsetting) {
          updateAndFilterData(state, state.initialTableData);
        } else {
          state.tableData = current(state.initialTableData);
        }
      })
      .addCase(resetDataSelection, (state, action) => {
        state.subsetData = [];
        if (state.syncWithSubsetting) {
          updateAndFilterData(state, state.initialTableData);
        }
      })
      .addCase(selectDataset, (state, action) => {
        // FIXED: see README.md (section: Fixed bugs)
        state = Object.assign(state, initialState);
      });

    // .addCase(logoutProfile, (state) => initialState)
    // .addCase(refresh.rejected, (state) =>  initialState)
  },
});

export const {
  setLoading,
  toggleBrushing,
  toggleSubsetting,
  toggleColumns,
  setSelectedColumns,
  setExcludedColumns,
  setColumns,
  toggleCaseSensitive,
  toggleWholeWord,
  setSearching,
  setTableData,
  // subsetting
  setTableDataFromRemoveSelection,
  // filters
  addFilter,
  removeFilter,
  updateComparator,
  updateFilter,
  updateFilterColumn,
  toggleSyncSearch,

  // sync table data
  syncTableData,
} = tableSlice.actions;

export default tableSlice.reducer;

// const backendUrl = process.env.REACT_APP_BACKEND_URI;
// const debug = process.env.REACT_APP_DEBUGMODE === "true";

// Async functions (use dispatch)
