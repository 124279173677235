import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useSelector, useDispatch, batch } from "react-redux";

import {
  initData as initDataFunc,
  initStructure as initStructureFunc,
  fetchDatasets,
  fetchCurrentDataset,
} from "../features/dataset";
import {
  sendCorrelation,
  getCorrelationParamObject,
} from "../features/analytic";

import { toggleMenu, setRender, setBrushed } from "../features/chart";

// components
import Chart from "../components/Overview/Chart";
import DataTable from "../components/Overview/DataTable";
import ChartMenu from "../components/Overview/ChartMenu";
import SchemaSelector from "../components/Overview/dialog/SchemaSelector";

import { Resizable } from "re-resizable";

import {
  Grid,
  Paper,
  makeStyles,
  Tabs,
  Tab,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import { Settings as SettingsIcon } from "@material-ui/icons";

import { setSearching } from "../features/table";
import { addNotification } from "../features/app";

import Notification from "../utils/notification";

const useStyles = makeStyles((theme) => ({
  fillHeight: {
    height: "100%",
  },
  resizable: {
    paddingBottom: theme.spacing(2),
  },
  resizableBottomHandle: {
    width: "32px !important",
    height: "8px !important",
    borderRadius: "3px",
    margin: "0 auto",
    left: 0,
    right: 0,
    bottom: "3px !important",
    backgroundColor: theme.palette.primary[theme.palette.type],
  },
  tabs: {
    backgroundColor: theme.palette.primary[theme.palette.type],
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  tableGrid: {
    marginBottom: theme.spacing(2),
  },
  chartMenuBtn: {
    position: "absolute",
    // zIndex: 10,
    bottom: "50%",
    right: theme.spacing(1.6),
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: "transparent",
    transition: "transform 3s",
    "&:hover": {
      backgroundColor: "transparent",
      transform: "rotate(360deg)",
    },
  },
  // This class is a tweak to display the table when the chart menu is shown
  chartMenu: {
    width: "100%",
    paddingBottom: "307px",
  },
}));

function TabPanel(props) {
  const { children, value, index, className, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} clone>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Overview() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const chartContainer = useRef(null);

  // https://www.debuggr.io/react-update-unmounted-component/#:~:text=Warning%3A%20Can't%20perform%20a,understand%20why%20it%20is%20happening.
  // const isMountedRef = useRef(null);

  // topbar + main container + resizer
  const paddingMargin = 64 + 32 + 16;

  // maxheight = 96% of (window size - padding)
  const [maxHeight, setMaxHeight] = useState(
    window.innerHeight - paddingMargin
  );

  const [resize, setResize] = useState({
    height: maxHeight * 0.54 < 400 ? 400 : maxHeight * 0.54,
  });

  const [tab, setTab] = useState(0);
  const [didOnce, setOnce] = useState(false);

  const {
    initData,
    initStructure,
    currentSchema,
    currentData,
    data,
    schema,
    datasets,
    currentDataset,
  } = useSelector((state) => state.dataset);

  const { debug, openSchemaDialog } = useSelector((state) => state.app);
  const { menu, render, backgroundColor, highlight } = useSelector(
    (state) => state.chart
  );
  const { filters, searching, tableData } = useSelector((state) => state.table);

  // let tableData = []
  // if (syncWithBrushing && brushed && brushed.length > 0)
  //   tableData = brushed
  // else if (syncWithSubsetting)
  //   tableData = currentData
  // else
  //   tableData = data
  const dataRef = useRef(data);
  if (filters.length > 0 && searching) dispatch(setSearching(false));

  useEffect(() => {
    // isMountedRef.current = true;
    async function fetchStructureEffect() {
      batch(async () => {
        setOnce(true); // Must be the 1st line otherwise it will execute the following code twice.
        await dispatch(fetchDatasets()); // This must be the 1st request, there is extra code to refresh token if not valid.
        await dispatch(initStructureFunc(true));
      });
    }

    async function fetchDataEffect() {
      batch(async () => {
        await dispatch(fetchCurrentDataset());
        await dispatch(initDataFunc(true));
      });
    }
    if (!initStructure && !didOnce) {
      fetchStructureEffect();
    }
    if (initStructure && !initData) {
      fetchDataEffect();
    }
    if (initStructure && initData && datasets.length === 0) {
      dispatch(
        addNotification(
          new Notification("No datasets found for the current user!", "warning")
        )
      );
      history.push("/data"); // FIXME why pushing "/data" into history ?
    }
    // call correlation only init
    if (
      initData && // in deps
      data.length > 0 && // not in deps
      schema.length > 0 && // not in deps
      dataRef.current !== data // not in deps
    ) {
      // only during initialization and if currentData changes
      const corrParamObj = getCorrelationParamObject(data, schema);
      corrParamObj.currentDataset = currentDataset;
      dispatch(sendCorrelation(corrParamObj));
    }

    window.addEventListener("resize", () => {
      /**
       * Hide the chart if resizeend is undefined.
       * Then try to clear the resizeend timeout.
       * In the timeout callback:
       * - set the max height
       * - display the chart
       * - set resizeend to undefined (this is important to keep things clean)
       */
      if (window.resizeend === undefined) dispatch(setRender(false));
      clearTimeout(window.resizeend);
      window.resizeend = setTimeout(() => {
        setMaxHeight(window.innerHeight - paddingMargin);
        dispatch(setRender(true));
        window.resizeend = undefined;
      }, 200);
    });
    // update refs for comparison
    if (dataRef.current !== data) dataRef.current = data;

    // cleanup
    return () => {
      // isMountedRef.current = false;
      window.removeEventListener("resize", () => {});
      window.removeEventListener("resizeend", () => {});
    };
    // eslint-disable-next-line
  }, [
    dispatch, // Fixme: Why dispatch in dependencies?
    initStructure,
    initData,
    paddingMargin,
    currentSchema,
    didOnce,
    setOnce,
    history,
    datasets,
  ]);

  const enable = {
    top: false,
    right: false,
    bottom: true,
    left: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  };

  function displayChart() {
    return (
      // chartContainer.current !== null &&
      currentSchema.length > 0 && currentData.length > 0 && initData && render
    );
  }

  function displayTable() {
    return currentSchema.length > 0 && initData;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="stretch"
      direction="row"
    >
      {/* Chart */}
      <Grid item xs={12} style={{ position: "relative" }}>
        <Resizable
          size={{ width: "auto", height: resize.height }}
          minHeight={400}
          enable={enable}
          className={classes.resizable}
          handleClasses={{
            bottom: classes.resizableBottomHandle,
          }}
          onResizeStart={() => {
            debug && console.log("[onResizeStart]", render);
            dispatch(setRender(false));
          }}
          onResizeStop={(e, direction, ref, delta) => {
            debug && console.log("[onResizeStop]", render);
            setResize((prev) => ({
              ...prev,
              height: prev.height + delta.height,
            }));
            dispatch(setRender(true));
          }}
        >
          <Paper
            className={classes.fillHeight}
            ref={chartContainer}
            variant="outlined"
            style={{ backgroundColor }}
          >
            {displayChart() && (
              <Chart
                parent={chartContainer}
                render={true}
                // data={currentData}
                height={resize.height}
                onBrushEnd={(selection, isBrushed, brushState) =>
                  dispatch(
                    setBrushed({
                      brushed: isBrushed ? selection : false,
                      brushState: brushState,
                      method: "onBrushEnd",
                    })
                  )
                }
              />
            )}
          </Paper>
        </Resizable>
        <Tooltip title="Chart settings">
          <IconButton
            size="small"
            aria-label="chart-menu"
            color="primary"
            className={classes.chartMenuBtn}
            onClick={() => dispatch(toggleMenu(!menu))}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      {/* Table */}
      <Grid item xs={12} className={classes.tableGrid}>
        <Paper className={classes.fillHeight} variant="outlined">
          <Tabs
            className={classes.tabs}
            indicatorColor="primary"
            value={tab}
            onChange={(e, v) => setTab(v)}
          >
            <Tab label="Data table" />
            <Tab label="Dimensionality reduction" />
          </Tabs>
          <TabPanel value={tab} index={0}>
            {displayTable() ? (
              <DataTable
                refid="tabpanel-0"
                data={tableData}
                initData={data}
                selectedRows={highlight}
              />
            ) : (
              <div></div>
            )}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <p>dimensionality reduction</p>
          </TabPanel>
        </Paper>
      </Grid>

      {/* Chart: Menu Drawer */}
      <Grid item className={menu ? classes.chartMenu : null}>
        {displayChart() && <ChartMenu open={menu} />}
      </Grid>

      {/* Dialogs */}
      <SchemaSelector feature={openSchemaDialog} />
    </Grid>
  );
}
