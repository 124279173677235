import { combineReducers } from "@reduxjs/toolkit";

import appReducer from "./app";
import datasetReducer from "./dataset";
import chartReducer from "./chart";
import tableReducer from "./table";
import analyticReducer from "./analytic";
import correlationReducer from "./correlation";

export default combineReducers({
  app: appReducer,
  dataset: datasetReducer,
  chart: chartReducer,
  table: tableReducer,
  analytic: analyticReducer,
  correlation: correlationReducer,
});

/**
 * If you want to filter the browser logs, use of the following Filter:
 *
 * Exclude all the logs that start by [Par, "[" can be any char
 * - /^((?!Par).)*$/
 */
