import React, { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TableCell, TextField, MenuItem, Select } from "@material-ui/core";

const EditableTableCell = React.forwardRef(function EditableTableCell(props, ref) {
    const {
        children,
        name,
        property,
        options,
        update,
        ...other
    } = props;

    const [editable, setEditable] = useState(false)
    const [newValue, setNewValue] = useState(children)
    const textfieldRef = createRef()

    useEffect(() => {
        reduceValue(children)
        // eslint-disable-next-line
    }, [])

    function reduceValue(node) {
        if (typeof node !== 'string' && 'props' in node && 'children' in node.props && typeof node.props.children === 'string')
            reduceValue(node.props.children)
        else
            setNewValue(node)
    }

    useEffect(() => {
        if (editable && textfieldRef.current)
            textfieldRef.current.focus()
    }, [editable, textfieldRef])


    const submitNewValue = async () => {
        //fetch
        try {

            await update({ name, value: newValue, property })
        }
        catch (error) {
            console.log(`Error: cannot update property "Type" for "${name}"`, error)
        }
        finally {

            setEditable(false);
        }

    }

    const checkKeyBeforeSubmit = (event) => {
        if (event.key !== 'Enter')
            return
        submitNewValue()
    }

    const onClickTableCell = () => {
        setEditable(true)
    }

    const onChange = (event) => {
        setNewValue(event.target.value)
    }

    if (editable)
        return <TableCell ref={ref} {...other} style={{ width: "auto" }}>
            {options && options.length > 0
                ? (<Select value={newValue} onChange={onChange} onBlur={submitNewValue}>
                    {options.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                </Select>)
                : <TextField inputRef={textfieldRef} onChange={onChange} onBlur={submitNewValue} onKeyPress={checkKeyBeforeSubmit} value={newValue}></TextField>
            }
        </TableCell >

    else

        return <TableCell ref={ref} {...other} >
            <span onClick={onClickTableCell} style={{ cursor: "pointer" }}>

                {children}
            </span>
        </TableCell>
})




EditableTableCell.propTypes = {
    name: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    update: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
}

export default EditableTableCell;