// import * as d3Lib from "d3"
import {
  dispatch,
  keys,
  scalePoint,
  scaleLinear,
  interpolateLab,
  extent,
  scaleOrdinal,
  scaleTime,
  map,
  min,
  axisLeft,
  event,
  drag,
  ascending,
  svg,
  mouse,
  timer,
} from "d3";
import { select, selectAll } from "d3-selection";

// const d3 = require("d3");
const d3 = { svg: {}, brush: {} };
// d3.svg.multibrush = require("./d3.svg.multibrush").default;
d3.brush.multibrush = require("./d3.brush.multibrush").default;
const $V = require("sylvester-es6");
// add old functor function to d3 for code compatibility
d3.oldD3Functor = (x) => {
  function constant(x) {
    return function () {
      return x;
    };
  }
  return typeof x === "function" ? x : constant(x);
};

d3.parcoords = function (config) {
  const ALPHA_SORT = 1;
  const COUNT_SORT = 2;
  var __ = {
    data: [],
    highlighted: [],
    dimensions: [],
    dimensionTitles: {},
    dimensionTitleRotation: 0,
    types: {},
    brushed: false,
    brushedColor: null,
    highlightColor: null,
    isHighlightFaded: true,
    alphaOnBrushed: 0.0,
    mode: "default",
    rate: 20,
    width: 600,
    height: 300,
    margin: { top: 40, right: 0, bottom: 12, left: 0 },
    color: "#069",
    backgroundColor: "transparent",
    labelColor: "black",
    composite: "source-over",
    alpha: 0.7,
    bundlingStrength: 0.5,
    bundleDimension: null,
    smoothness: 0.0,
    showControlPoints: false,
    hideAxis: [],
    displayOneByFrame: false, // if true render only the last line of each frame of the queue defined by __.rate
    hideCategoricalAxis: false,
    nbTicksMaxForCateg: 30,
    categDefaultSort: ALPHA_SORT,
    // categDefaultSort:COUNT_SORT,
    renderingMonitoring: null,
  };

  extend(__, config);

  var pc = function (selection) {
    selection = pc.selection = select(selection);
    const selectedElement = selection._groups[0][0];
    __.width = selectedElement.clientWidth;
    __.height = selectedElement.clientHeight;
    // __.width = selection[0][0].clientWidth;
    // __.height = selection[0][0].clientHeight;

    pc.selection.style("background-color", __.backgroundColor);

    // canvas data layers
    ["shadows", "marks", "foreground", "brushed", "highlight"].forEach(
      function (layer) {
        const cavasSelection = selection.append("canvas").attr("class", layer);
        canvas[layer] = cavasSelection._groups[0][0];
        ctx[layer] = canvas[layer].getContext("2d");
      }
    );

    // svg tick and brush layers
    pc.svg = selection
      .append("svg")
      .attr("width", __.width)
      .attr("height", __.height)
      // .style("background-color",__.backgroundColor)
      .append("svg:g")
      .attr(
        "transform",
        "translate(" + __.margin.left + "," + __.margin.top + ")"
      );

    return pc;
  }; // end pc

  // use d3DispatchMethod wrapper because d3.dispatch.apply overrides javascript apply
  function d3DispatchMethod(...types) {
    return dispatch(...types);
  }
  var events = d3DispatchMethod.apply(
    // use apply to specify this as context
    this,
    // var events = dispatch(
    [
      "render",
      "resize",
      "highlight",
      "brush",
      "brushend",
      "axesreorder",
    ].concat(keys(__))
  ),
    w = function () {
      return __.width - __.margin.right - __.margin.left;
    },
    h = function () {
      return __.height - __.margin.top - __.margin.bottom;
    },
    flags = {
      brushable: false,
      reorderable: false,
      axes: false,
      interactive: false,
      shadows: false,
      debug: false,
    },
    // xscale = scalePoint().range([0, w()], 1),
    xscale = scalePoint().padding(1),
    yscale = {},
    dragging = {},
    // axis = d3.svg.axis().orient("left").ticks(5), // axis created before call
    // axisCateg = d3.svg.axis().orient("left").ticks(5), // axis created before call
    g, // groups for axes, brushes
    ctx = {},
    canvas = {};

  const backgroundColorScale = scaleLinear()
    .domain([0, 1])
    .range(["#ffffff", "#000000"])
    .interpolate(interpolateLab);
  const labelColorScale = scaleLinear()
    .domain([0, 1])
    .range(["#2f2f2f", "#c8c8c8"])
    .interpolate(interpolateLab);
  pc.changeBackgroundColor = function (backgroundColor) {
    console.log(
      "d3.parcoords.changeBackgroundColor('" + backgroundColor + "')..."
    );
    // const labelColor="white";
    __.backgroundColor = backgroundColorScale(backgroundColor);
    __.labelColor = labelColorScale(0);
    if (backgroundColor >= 0.5) {
      __.labelColor = labelColorScale(1);
    }
    pc.selection.style("background-color", __.backgroundColor);
    const textSelection = pc.svg.selectAll("text");
    textSelection.style("fill", __.labelColor); // FIXME: no effect
    const axisLineSelection = pc.svg.selectAll(".axis line");
    axisLineSelection.style("stroke", __.labelColor); // FIXME: no effect
    const axisPathSelection = pc.svg.selectAll(".axis path");
    axisPathSelection.style("stroke", __.labelColor); // FIXME: no effect
    return pc;
  };

  // side effects for setters
  const sideEffectKeys = keys(__);
  var side_effects = d3DispatchMethod // use d3DispatchMethod wrapper because d3.dispatch.apply overrides javascript apply
    .apply(this, sideEffectKeys); // use apply to specify 'this' as context
  // var side_effects = dispatch(...keys(__));

  side_effects
    .on("composite", function (d) {
      ctx.foreground.globalCompositeOperation = d.value;
      ctx.brushed.globalCompositeOperation = d.value;
    })
    .on("alpha", function (d) {
      ctx.foreground.globalAlpha = d.value;
      ctx.brushed.globalAlpha = d.value;
    })
    .on("brushedColor", function (d) {
      ctx.brushed.strokeStyle = d.value;
    })
    .on("width", function (d) {
      pc.resize();
    })
    .on("highlightColor", function (d) {
      ctx.highlight.strokeStyle = d.value;
    })
    .on("height", function (d) {
      pc.resize();
    })
    .on("margin", function (d) {
      pc.resize();
    })
    .on("rate", function (d) {
      brushedQueue.rate(d.value);
      foregroundQueue.rate(d.value);
    })
    .on("data", function (d, i) {
      if (flags.shadows) {
        paths(__.data, ctx.shadows, i);
      }
    })
    .on("dimensions", function (d) {
      xscale.domain(__.dimensions);
      if (flags.interactive) {
        pc.render().updateAxes();
      }
    })
    .on("bundleDimension", function (d) {
      if (!__.dimensions.length) pc.detectDimensions();
      if (!(__.dimensions[0] in yscale)) pc.autoscale();
      if (typeof d.value === "number") {
        if (d.value < __.dimensions.length) {
          __.bundleDimension = __.dimensions[d.value];
        } else if (d.value < __.hideAxis.length) {
          __.bundleDimension = __.hideAxis[d.value];
        }
      } else {
        __.bundleDimension = d.value;
      }

      __.clusterCentroids = compute_cluster_centroids(__.bundleDimension);
    })
    .on("hideAxis", function (d) {
      if (!__.dimensions.length) pc.detectDimensions();
      pc.dimensions(without(__.dimensions, d.value));
    });

  // expose the state of the chart
  pc.state = __;
  pc.flags = flags;

  // create getter/setters
  getset(pc, __, events);

  /* FROM https://github.com/d3/d3/blob/v3.5.17/src/core/rebind.js */
  // Copies a variable number of methods from source to target.
  function rebind(target, source) {
    var i = 1,
      n = arguments.length,
      method;
    while (++i < n)
      target[(method = arguments[i])] = d3_rebind(
        target,
        source,
        source[method]
      );
    return target;
  }

  // Method is assumed to be a standard D3 getter-setter:
  // If passed with no arguments, gets the value.
  // If passed with arguments, sets the value and returns the target.
  function d3_rebind(target, source, method) {
    return function () {
      var value = method.apply(source, arguments);
      return value === source ? target : value;
    };
  }
  // expose events
  rebind(pc, events, "on");
  // d3.rebind(pc, events, "on");

  // getter/setter with event firing
  function getset(obj, state, events) {
    keys(state).forEach(function (key) {
      obj[key] = function (x) {
        if (!arguments.length) {
          // no arguments in function (x) => getter
          return state[key]; // return value from __
        }
        // if arguments in function(x) => setter: change the state and trigger listeners with old value in previous key
        var old = state[key];
        state[key] = x;
        // side_effects[key].call(pc, { value: x, previous: old });
        // events[key].call(pc, { value: x, previous: old });
        side_effects.call(key, pc, { value: x, previous: old });
        events.call(key, pc, { value: x, previous: old });
        return obj;
      };
    });
  }

  function extend(target, source) {
    for (var key in source) {
      target[key] = source[key];
    }
    return target;
  }

  function without(arr, items) {
    return arr.filter(function (elem) {
      return items.indexOf(elem) === -1;
    });
  }
  pc.autoscale = function (newDimensionsOnly = false) {
    // yscale
    var defaultScales = {
      date: function (k) {
        var dateExtent = extent(__.data, function (d) {
          return d[k] ? d[k].getTime() : null;
        });

        // special case if single value
        if (dateExtent[0] === dateExtent[1]) {
          return scaleOrdinal()
            .domain([dateExtent[0]])
            .rangePoints([h() + 1, 1]);
        }

        return scaleTime()
          .domain(dateExtent)
          .range([h() + 1, 1]);
      },
      number: function (k) {
        var numberExtent = extent(__.data, function (d) {
          return +d[k];
        });

        // special case if single value
        if (numberExtent[0] === numberExtent[1]) {
          return (
            scalePoint()
              // .ordinal()
              .domain([numberExtent[0]])
              .range([h() + 1, 1])
          );
        }

        return scaleLinear()
          .domain(numberExtent)
          .range([h() + 1, 1]);
      },
      string: function (k) {
        // FIXME: optimize
        var counts = {},
          domain = [];

        // Let's get the count for each value so that we can sort the domain based
        // on the number of items for each value.
        __.data.forEach(function (p, i) {
          // N.M. code to check issue with empty character
          // function characterIsNumber(valueStr){
          //   let value=Number.parseFloat(valueStr);
          //   if (isNaN(value)) {
          //     value = Number.parseInt(valueStr);
          //   }
          //   return !isNaN(value);
          // }
          // if(k==="f_general_load_index_single_x"||k==="f_mold_section_height_mm"){
          //   const value=p[k];
          //   if(!characterIsNumber(value)){
          //     console.log(i+";"+p[""]+";"+p["m_sri_construction_number"]+";"+value+p["type"]);
          //   }
          // }
          if (counts[p[k]] === undefined) {
            counts[p[k]] = 1;
          } else {
            counts[p[k]] = counts[p[k]] + 1;
          }
        });
        if (__.categDefaultSort === COUNT_SORT) {
          domain = Object.getOwnPropertyNames(counts).sort(function (a, b) {
            return counts[a] - counts[b];
          });
        } else if (__.categDefaultSort === ALPHA_SORT) {
          domain = Object.getOwnPropertyNames(counts).sort();
        }
        return scalePoint()
          .domain(domain)
          .range([h() + 1, 1]);
        // .rangeRoundPoints([h()+1, 1]); // NM
      },
    }; // end defaultScale

    __.dimensions.forEach(function (k) {
      if (!newDimensionsOnly || !(k in yscale)) {
        yscale[k] = defaultScales[__.types[k]](k);
      }
    });

    __.hideAxis.forEach(function (k) {
      if (!newDimensionsOnly || !(k in yscale)) {
        yscale[k] = defaultScales[__.types[k]](k);
      }
    });

    // xscale
    // xscale.rangePoints([0, w()], 1);
    xscale.range([0, w()]);

    // canvas sizes
    pc.selection
      .selectAll("canvas")
      .style("margin-top", __.margin.top + "px")
      .style("margin-left", __.margin.left + "px")
      .attr("width", w() + 2)
      .attr("height", h() + 2);

    // default styles, needs to be set when canvas width changes
    ctx.foreground.strokeStyle = __.color;
    ctx.foreground.lineWidth = 1.4;
    ctx.foreground.globalCompositeOperation = __.composite;
    ctx.foreground.globalAlpha = __.alpha;
    ctx.brushed.strokeStyle = __.brushedColor;
    ctx.brushed.lineWidth = 1.4;
    ctx.brushed.globalCompositeOperation = __.composite;
    ctx.brushed.globalAlpha = __.alpha;
    ctx.highlight.lineWidth = 3;
    ctx.highlight.strokeStyle = __.highlightColor;
    ctx.shadows.strokeStyle = "#dadada";

    return this;
  }; // end pc.autoscale()

  pc.scale = function (d, domain) {
    yscale[d].domain(domain);

    return this;
  };

  pc.flip = function (d) {
    //yscale[d].domain().reverse();					// does not work
    yscale[d].domain(yscale[d].domain().reverse()); // works

    return this;
  };

  pc.commonScale = function (global, type) {
    var t = type || "number";
    if (typeof global === "undefined") {
      global = true;
    }

    // scales of the same type
    var scales = __.dimensions.concat(__.hideAxis).filter(function (p) {
      return __.types[p] === t;
    });

    if (global) {
      var globalExtent = extent(
        scales
          .map(function (p, i) {
            return yscale[p].domain();
          })
          .reduce(function (a, b) {
            return a.concat(b);
          })
      );

      scales.forEach(function (d) {
        yscale[d].domain(globalExtent);
      });
    } else {
      scales.forEach(function (k) {
        yscale[k].domain(
          extent(__.data, function (d) {
            return +d[k];
          })
        );
      });
    }

    // update centroids
    if (__.bundleDimension !== null) {
      pc.bundleDimension(__.bundleDimension);
    }

    return this;
  }; // end pc.commonScale
  pc.detectDimensions = function () {
    pc.types(pc.detectDimensionTypes(__.data));
    pc.dimensions(keys(pc.types()));
    return this;
  };

  // a better "typeof" from this post: http://stackoverflow.com/questions/7390426/better-way-to-get-type-of-a-javascript-variable
  pc.toType = function (v) {
    return {}.toString
      .call(v)
      .match(/\s([a-zA-Z]+)/)[1]
      .toLowerCase();
  };

  // try to coerce to number before returning type
  pc.toTypeCoerceNumbers = function (v) {
    /** Modified by Audren Guillaume */
    // if ((parseFloat(v) == v) && (v != null)) {
    if (parseFloat(v) === v && v !== null) {
      return "number";
    }
    return pc.toType(v);
  };

  // attempt to determine types of each dimension based on first row of data
  pc.detectDimensionTypes = function (data) {
    var types = {};
    keys(data[0]).forEach(function (col) {
      types[col] = pc.toTypeCoerceNumbers(data[0][col]);
    });
    return types;
  };
  pc.render = function () {
    // try to autodetect dimensions and create scales
    if (!__.dimensions.length) pc.detectDimensions();
    if (!(__.dimensions[0] in yscale)) pc.autoscale();

    pc.render[__.mode]();

    // events.render.call(this);
    events.call("render", this);
    return this;
  };

  pc.renderBrushed = function () {
    if (!__.dimensions.length) pc.detectDimensions();
    if (!(__.dimensions[0] in yscale)) pc.autoscale();

    pc.renderBrushed[__.mode]();

    // events.render.call(this);
    events.call("render", this);

    return this;
  };

  function isBrushed() {
    if (__.brushed && __.brushed.length !== __.data.length) return true;

    var object = brush.currentMode().brushState();

    // if object as at least one property return true
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        return true;
      }
    }
    return false;
  }

  pc.render.default = function () {
    pc.clear("foreground");
    pc.clear("highlight");

    pc.renderBrushed.default();

    __.data.forEach((d, i) => {
      path_foreground(d, i, __.data.length, {});
    });
  };

  var foregroundQueue = d3
    .renderQueue(path_foreground)
    .renderingMonitoring(__.renderingMonitoring)
    .action("parcoord")
    .rate(__.rate)
    .color(__.color)
    .clear(function () {
      pc.clear("foreground");
      pc.clear("highlight");
    });

  pc.render.queue = function () {
    pc.renderBrushed.queue();

    foregroundQueue.action("parcoord");
    foregroundQueue.color(__.color);
    foregroundQueue(__.data);
  };
  pc.render.invalidateQueue = function () {
    foregroundQueue.invalidate();
  };
  pc.renderBrushed.default = function () {
    pc.clear("brushed");

    if (isBrushed()) {
      __.brushed.forEach((d, i) => {
        path_brushed(d, i, __.brushed.length, {});
      });
    }
  };

  var brushedQueue = d3
    .renderQueue(path_brushed)
    // .renderingMonitoring(__.renderingMonitoring) // set during pc.setRenderingMonitoring
    .action("brush")
    .rate(__.rate)
    .color(__.color)
    .clear(function () {
      pc.clear("brushed");
    });

  pc.renderBrushed.queue = function () {
    brushedQueue.action("brush");
    if (__.brushedColor !== null) {
      brushedQueue.color(__.brushedColor);
    } else {
      brushedQueue.color(__.color);
    }
    if (isBrushed()) {
      brushedQueue(__.brushed);
    } else {
      brushedQueue([]); // This is needed to clear the currently brushed items
    }
  };
  function compute_cluster_centroids(d) {
    var clusterCentroids = map();
    var clusterCounts = map();
    // determine clusterCounts
    __.data.forEach(function (row) {
      var scaled = yscale[d](row[d]);
      if (!clusterCounts.has(scaled)) {
        clusterCounts.set(scaled, 0);
      }
      var count = clusterCounts.get(scaled);
      clusterCounts.set(scaled, count + 1);
    });

    __.data.forEach(function (row) {
      __.dimensions.forEach(function (p, i) {
        var scaled = yscale[d](row[d]);
        if (!clusterCentroids.has(scaled)) {
          var mapTmp = map();
          clusterCentroids.set(scaled, mapTmp);
        }
        if (!clusterCentroids.get(scaled).has(p)) {
          clusterCentroids.get(scaled).set(p, 0);
        }
        var value = clusterCentroids.get(scaled).get(p);
        value += yscale[p](row[p]) / clusterCounts.get(scaled);
        clusterCentroids.get(scaled).set(p, value);
      });
    });

    return clusterCentroids;
  } // end compute_cluster_centroids

  function compute_centroids(row) {
    var centroids = [];

    var p = __.dimensions;
    var cols = p.length;
    var a = 0.5; // center between axes
    for (var i = 0; i < cols; ++i) {
      // centroids on 'real' axes
      var x = position(p[i]);
      var y = yscale[p[i]](row[p[i]]);
      centroids.push($V([x, y]));

      // centroids on 'virtual' axes
      if (i < cols - 1) {
        var cx = x + a * (position(p[i + 1]) - x);
        var cy = y + a * (yscale[p[i + 1]](row[p[i + 1]]) - y);
        if (__.bundleDimension !== null) {
          var leftCentroid = __.clusterCentroids
            .get(yscale[__.bundleDimension](row[__.bundleDimension]))
            .get(p[i]);
          var rightCentroid = __.clusterCentroids
            .get(yscale[__.bundleDimension](row[__.bundleDimension]))
            .get(p[i + 1]);
          var centroid = 0.5 * (leftCentroid + rightCentroid);
          cy = centroid + (1 - __.bundlingStrength) * (cy - centroid);
        }
        centroids.push($V([cx, cy]));
      }
    }

    return centroids;
  } // end compute_centroid()

  function compute_control_points(centroids) {
    var cols = centroids.length;
    var a = __.smoothness;
    var cps = [];

    cps.push(centroids[0]);
    cps.push(
      $V([
        centroids[0].e(1) + a * 2 * (centroids[1].e(1) - centroids[0].e(1)),
        centroids[0].e(2),
      ])
    );
    for (var col = 1; col < cols - 1; ++col) {
      var mid = centroids[col];
      var left = centroids[col - 1];
      var right = centroids[col + 1];

      var diff = left.subtract(right);
      cps.push(mid.add(diff.x(a)));
      cps.push(mid);
      cps.push(mid.subtract(diff.x(a)));
    }
    cps.push(
      $V([
        centroids[cols - 1].e(1) +
        a * 2 * (centroids[cols - 2].e(1) - centroids[cols - 1].e(1)),
        centroids[cols - 1].e(2),
      ])
    );
    cps.push(centroids[cols - 1]);

    return cps;
  } // end compute_control_points()

  pc.shadows = function () {
    flags.shadows = true;
    if (__.data.length > 0) {
      paths(__.data, ctx.shadows);
    }
    return this;
  };

  // draw little dots on the axis line where data intersects
  pc.axisDots = function () {
    var ctx = pc.ctx.marks;
    ctx.globalAlpha = min([1 / Math.pow(__.data.length, 1 / 2), 1]);
    __.data.forEach(function (d) {
      __.dimensions.forEach(function (p, i) {
        ctx.fillRect(position(p) - 0.75, yscale[p](d[p]) - 0.75, 1.5, 1.5);
      });
    });
    return this;
  };

  // draw single cubic bezier curve
  function single_curve(d, ctx) {
    var centroids = compute_centroids(d);
    var cps = compute_control_points(centroids);

    ctx.moveTo(cps[0].e(1), cps[0].e(2));
    for (var i = 1; i < cps.length; i += 3) {
      if (__.showControlPoints) {
        for (var j = 0; j < 3; j++) {
          ctx.fillRect(cps[i + j].e(1), cps[i + j].e(2), 2, 2);
        }
      }
      ctx.bezierCurveTo(
        cps[i].e(1),
        cps[i].e(2),
        cps[i + 1].e(1),
        cps[i + 1].e(2),
        cps[i + 2].e(1),
        cps[i + 2].e(2)
      );
    }
  }

  // draw single polyline
  function color_path(d, ctx, i, totalNbItems, forceSetStyle) {
    if (i % __.rate === 0 || forceSetStyle[i]) {
      // if(i===0){
      ctx.beginPath();
    }
    if (
      (__.bundleDimension !== null && __.bundlingStrength > 0) ||
      __.smoothness > 0
    ) {
      single_curve(d, ctx);
    } else {
      single_path(d, ctx);
    }
    if (i % __.rate === __.rate - 1 || i === totalNbItems - 1) {
      ctx.stroke();
    }
    // if(i<totalNbItems-1 && i%__.rate===0){
    //     ctx.beginPath();
    // }
    // if(__.displayOneByFrame && i%__.rate===0) { // FIXME: optimize rendering but render only the last of each frame of the queue defined by __.rate
    //     ctx.stroke();
    //     ctx.beginPath();
    // }
  }

  // draw many polylines of the same color
  function paths(data, ctx) {
    ctx.clearRect(-1, -1, w() + 2, h() + 2);
    ctx.beginPath();
    data.forEach(function (d) {
      if (
        (__.bundleDimension !== null && __.bundlingStrength > 0) ||
        __.smoothness > 0
      ) {
        single_curve(d, ctx);
      } else {
        single_path(d, ctx);
      }
    });
    ctx.stroke();
  }

  function single_path(d, ctx) {
    let valueFound = false;
    __.dimensions.forEach(function (p, i) {
      /** Modified by Audren Guillaume */
      // if (i == 0) {
      /** Modified By Nicolas Medoc **/
      // if (i === 0) {
      if (!valueFound) {
        // for highlighted or brushed data that have not values in the first dimension
        valueFound = d[p] !== undefined;

        ctx.moveTo(position(p), yscale[p](d[p]));
      } else {
        ctx.lineTo(position(p), yscale[p](d[p]));
      }
    });
  }

  function path_brushed(d, i, totalNbItems, forceSetStyle) {
    if (forceSetStyle[i]) {
      if (i > 0) {
        ctx.brushed.stroke();
      }
      if (__.brushedColor !== null) {
        ctx.brushed.strokeStyle = d3.oldD3Functor(__.brushedColor)(d, i);
      } else {
        ctx.brushed.strokeStyle = d3.oldD3Functor(__.color)(d, i);
      }
    }
    return color_path(d, ctx.brushed, i, totalNbItems, forceSetStyle);
  }

  function path_foreground(d, i, totalNbItems, forceSetStyle) {
    if (!forceSetStyle || Object.keys(forceSetStyle).length === 0) {
      ctx.foreground.strokeStyle = d3.oldD3Functor(__.color)(d, i);
    } else if (forceSetStyle[i]) {
      if (i > 0) {
        ctx.foreground.stroke();
      }
      ctx.foreground.strokeStyle = d3.oldD3Functor(__.color)(d, i);
    }
    return color_path(d, ctx.foreground, i, totalNbItems, forceSetStyle);
  }

  function path_highlight(d, i, totalNbItems, forceSetStyle) {
    // if (!forceSetStyle || Object.keys(forceSetStyle).length === 0) {
    //   ctx.highlight.strokeStyle = d3.oldD3Functor(__.color)(d, i);
    // } else if (forceSetStyle[i]) {
    if (
      forceSetStyle &&
      Object.keys(forceSetStyle).length > 0 &&
      forceSetStyle[i]
    ) {
      if (i > 0) {
        ctx.highlight.stroke();
      }
      // ctx.highlight.strokeStyle = d3.oldD3Functor(__.color)(d, i);
    }
    return color_path(d, ctx.highlight, i, totalNbItems, forceSetStyle);
  }
  pc.clear = function (layer) {
    ctx[layer].clearRect(0, 0, w() + 2, h() + 2);

    // This will make sure that the foreground items are transparent
    // without the need for changing the opacity style of the foreground canvas
    // as this would stop the css styling from working
    if (layer === "brushed" && isBrushed()) {
      ctx.brushed.fillStyle = pc.selection.style("background-color");
      ctx.brushed.globalAlpha = 1 - __.alphaOnBrushed;
      ctx.brushed.fillRect(0, 0, w() + 2, h() + 2);
      ctx.brushed.globalAlpha = __.alpha;
    }
    return this;
  };
  // expose axis methods
  // FIXME: Why exposing axis methods to pc ?
  // d3.rebind(
  //   pc,
  //   axis,
  //   "ticks",
  //   "orient",
  //   "tickValues",
  //   "tickSubdivide",
  //   "tickSize",
  //   "tickPadding",
  //   "tickFormat"
  // );
  // expose axisCateg methods
  // FIXME: Why exposing axis methods to pc ?
  // d3.rebind(
  //   pc,
  //   axisCateg,
  //   "ticks",
  //   "orient",
  //   "tickValues",
  //   "tickSubdivide",
  //   "tickSize",
  //   "tickPadding",
  //   "tickFormat"
  // );

  // zoomAxis
  // Functionality added by fintan.mcgee@list.lu
  // when the user dbl click on the eye icon below and axis
  // a linear fisheye lens effect, focused on the centre of the current brush, rescales the axis
  // if there are multiple brushes overall max and min are used
  // if click without brushes axis the effect is removed
  /*function zoomAxis(dimension) {

    var g = pc.svg.selectAll(".dimension");
    // step 1 get the dimensions of the brush
    var ext,
      dExtents = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];
    ext = brush.modes["1D-axes"].brushState();
    if (!ext[dimension]) {
      ext = brush.modes["1D-axes-multi"].brushState();
    }

    if (ext[dimension]) {

      ext[dimension].forEach(function (e) {
        if (dExtents[0] > e[0]) {
          dExtents[0] = e[0];
        }
        if (dExtents[1] < e[1]) {
          dExtents[1] = e[1];
        }
      });
      pc.brushReset();
      pc.zoomScale(dimension, dExtents);
    } else {
      // no brush on the data
      // an undo zoom
      if (pc.checkZoomScale(dimension)) {
        pc.clearZoomScale(dimension);
      }
    }
    if (__.types[dimension] == "number" && yscale[dimension].isZoomed) {
      //include extra ticks if the axis is a number axis and zoomed
      select(this.parentElement)
        .transition()
        .duration(1100)
        .call(axis.scale(yscale[dimension]).ticks(10));

    } else if (!__.hideCategoricalAxis) {
      select(this.parentElement)
        .transition()
        .duration(1100)
        .call(axisCateg.scale(yscale[dimension]).ticks(5));
      // .call(axis.scale(yscale[dimension]).ticks(5));
    }
    pc.render();
    if (flags.shadows) paths(__.data, ctx.shadows);
  }*/

  function flipAxisAndUpdatePCP(dimension) {
    // Modified by Audren Guillaume
    // var g = pc.svg.selectAll(".dimension");

    pc.flip(dimension);
    if (__.types[dimension] === "number") {
      const axis = axisLeft(yscale[dimension]).ticks(5);
      select(this.parentElement).transition().duration(1100).call(axis);
      // .call(axis.scale(yscale[dimension]))
    } else if (!__.hideCategoricalAxis) {
      const axisCateg = axisLeft(yscale[dimension]).ticks(5);
      select(this.parentElement).transition().duration(1100).call(axisCateg);
    }
    pc.render();
    if (flags.shadows) paths(__.data, ctx.shadows);
  }

  function rotateLabels() {
    event.preventDefault();
    var delta = event.deltaY;
    delta = delta < 0 ? -5 : delta;
    delta = delta > 0 ? 5 : delta;

    __.dimensionTitleRotation += delta;
    pc.svg
      .selectAll("text.label")
      .attr(
        "transform",
        "translate(0,-5) rotate(" + __.dimensionTitleRotation + ")"
      );
  }

  function dimensionLabels(d) {
    return d in __.dimensionTitles ? __.dimensionTitles[d] : d; // dimension display names
  }
  function labelTruncation(_this, rangeBand) {
    var self = select(_this),
      textLength = self.node().getComputedTextLength(),
      text = self.text();
    let i = 0;
    while (textLength > rangeBand && text.length > 0) {
      if (i === 0) {
        text = text.slice(0, -4);
      } else {
        text = text.slice(0, -1);
      }
      self.text(text + "...");
      textLength = self.node().getComputedTextLength();
      i++;
    }
  }
  function callAxis(selectedElement, dimension) {
    if (__.types[dimension] === "number") {
      const axis = axisLeft(yscale[dimension]).ticks(5);
      selectedElement.call(axis);
      // selectedElement.call(axis.scale(yscale[dimension]));
    } else if (!__.hideCategoricalAxis) {
      const dimensionItems = yscale[dimension].domain();
      const nbTicks = Math.min(dimensionItems.length, __.nbTicksMaxForCateg);
      const binSize = Math.floor(dimensionItems.length / nbTicks);
      const tickValues = [];
      for (let i = 0; i < nbTicks; i++) {
        const tickValue = dimensionItems[i * binSize];
        tickValues.push(tickValue);
      }
      const range = xscale.range();
      const axisCateg = axisLeft(yscale[dimension])
        .ticks(nbTicks)
        .tickValues(tickValues);
      const tickSize = axisCateg.tickSize(); // can be adjusted by d3;
      const rangeBand = Math.floor(range[1] - range[0] - 1 - tickSize); // -1 to adjust (possible due to range round ?)
      // const rangeBand=xscale.rangeBand();
      selectedElement
        // .call(axisCateg.scale(yscale[dimension]).tickValues(tickValues))
        .call(axisCateg)
        .selectAll(".tick text")
        .style("text-anchor", "end")
        // .attr('fill', '#8a9299')
        // .attr('transform', 'rotate(-60)')
        .each(function (d) {
          labelTruncation(this, rangeBand);
        });
    }
  }
  pc.createAxes = function () {
    if (g) pc.removeAxes();

    // Add a group element for each dimension.
    g = pc.svg
      .selectAll(".dimension")
      .data(__.dimensions, function (d) {
        return d;
      })
      .enter()
      .append("svg:g")
      .attr("class", "dimension")
      .attr("transform", function (d) {
        return "translate(" + xscale(d) + ",0)";
      });

    // Add an axis and title.
    var svgAxes = g
      .append("svg:g")
      .attr("class", "axis")
      .attr("transform", "translate(0,0)")
      .each(function (d) {
        callAxis(select(this), d);
        // select(this).call(axis.scale(yscale[d]));
      });
    svgAxes
      .append("svg:text")
      // .attr({
      .attr("text-anchor", "middle")
      .attr("y", 0)
      .attr(
        "transform",
        "translate(0, -5) rotate(" + __.dimensionTitleRotation + ")"
      )
      .attr("x", 0)
      .attr("class", "label")
      // })
      .text(dimensionLabels)
      .on("dblclick", flipAxisAndUpdatePCP)
      .on("wheel", rotateLabels);

    svgAxes
      .append("svg:text")
      // .attr({
      .attr("text-anchor", "middle")
      .attr("y", 0)
      .attr("x", 0)
      .attr("class", "staticlabel")
      // })
      .attr("transform", function (d) {
        return (
          "translate(0," + (parseInt(yscale[d].range(), 10) * 2 + 11) + ")"
        );
      })
      .text(function (d) {
        return "\uf06e";
      }); // eye icon in font awseome
    // .on("dblclick", zoomAxis);

    flags.axes = true;
    return this;
  };

  pc.removeAxes = function () {
    g.remove();
    return this;
  };

  pc.updateAxes = function () {
    var g_data = pc.svg.selectAll(".dimension").data(__.dimensions);

    // Enter
    var svgAxes = g_data
      .enter()
      .append("svg:g")
      .attr("class", "dimension")
      .attr("transform", function (p) {
        return "translate(" + position(p) + ")";
      })
      .style("opacity", 0)
      .append("svg:g")
      .attr("class", "axis")
      .attr("transform", "translate(0,0)")
      .each(function (d) {
        callAxis(select(this), d);
        // select(this).call(axis.scale(yscale[d]));
      });
    svgAxes
      .append("svg:text")
      // .attr({
      .attr("text-anchor", "middle")
      .attr("y", 0)
      .attr(
        "transform",
        "translate(0,-5) rotate(" + __.dimensionTitleRotation + ")"
      )
      .attr("x", 0)
      .attr("class", "label")
      // })
      .text(dimensionLabels)
      .on("dblclick", flipAxisAndUpdatePCP)
      .on("wheel", rotateLabels);
    svgAxes
      .append("svg:text")
      // .attr({
      .attr("text-anchor", "middle")
      .attr("y", 0)
      .attr("x", 0)
      .attr("class", "staticlabel")
      // })
      .attr("transform", function (d) {
        return "translate(0," + (parseInt(h(), 10) + 12) + ")";
      })
      .text(function (d) {
        return "\uf06e";
      }); // eye icon in font awseome
    // .on("dblclick", zoomAxis);

    // Update
    g_data.attr("opacity", 0);
    g_data
      .select(".axis")
      .transition()
      .duration(1100)
      .each(function (d) {
        callAxis(select(this), d);
        // select(this).call(axis.scale(yscale[d]));
      });
    g_data
      .select(".label")
      .transition()
      .duration(1100)
      .text(dimensionLabels)
      .attr(
        "transform",
        "translate(0,-5) rotate(" + __.dimensionTitleRotation + ")"
      );

    // Exit
    g_data.exit().remove();

    g = pc.svg.selectAll(".dimension");
    g.transition()
      .duration(1100)
      .attr("transform", function (p) {
        return "translate(" + position(p) + ")";
      })
      .style("opacity", 1);

    pc.svg
      .selectAll(".axis")
      .transition()
      .duration(1100)
      .each(function (d) {
        callAxis(select(this), d);
        // select(this).call(axis.scale(yscale[d]));
      });

    if (flags.shadows) paths(__.data, ctx.shadows);
    if (flags.brushable) pc.brushable();
    if (flags.reorderable) pc.reorderable();
    // reinit brush in order to re-install brush to all axes
    if (pc.brushMode() !== "None") {
      // NM 01/2021: retrieve brush extends to redraw them
      var currentBrushExtents = pc.brushState();
      var mode = pc.brushMode();
      pc.brushMode("None");
      pc.brushMode(mode);
      // NM 01/2021: redraw initial brush extents for remaining axes
      if (currentBrushExtents && Object.keys(currentBrushExtents).length > 0) {
        // update and redraw extents for remaining axes
        pc.applyBrushState(currentBrushExtents);
      }
    }
    return this;
  };

  // Jason Davies, http://bl.ocks.org/1341281
  pc.reorderable = function () {
    if (!g) pc.createAxes();

    g.style("cursor", "move"); // add move on cursor
    // const d3Behavior=d3.behavior;
    const dragBehavior = drag()
      // .origin(function(d){return{x:xscale(d)}}) // NM: just for accessor
      // .on("dragstart", function (d) {
      .on("start", function (d) {
        dragging[d] = this.__origin__ = xscale(d); // put the origin in __origin__
        // dragging[d] = xscale(d);
        // TODO: add background.attr("visibility", "hidden");
      })
      .on("drag", function (d) {
        dragging[d] = Math.min(
          w(),
          Math.max(0, (this.__origin__ += event.dx)) // use the __origin__
        );
        __.dimensions.sort(function (a, b) {
          return position(a) - position(b);
        });
        xscale.domain(__.dimensions);

        pc.renderAllLayers();

        g.attr("transform", function (d) {
          return "translate(" + position(d) + ")";
        });
      })
      // .on("dragend", function (d) {
      .on("end", function (d) {
        // Let's see if the order has changed and send out an event if so.
        var i = 0,
          j = __.dimensions.indexOf(d),
          elem = this,
          parent = this.parentElement;

        while ((elem = elem.previousElementSibling) != null) ++i;
        if (i !== j) {
          events.call("axesreorder", pc, __.dimensions);
          // We now also want to reorder the actual dom elements that represent
          // the axes. That is, the g.dimension elements. If we don't do this,
          // we get a weird and confusing transition when updateAxes is called.
          // This is due to the fact that, initially the nth g.dimension element
          // represents the nth axis. However, after a manual reordering,
          // without reordering the dom elements, the nth dom elements no longer
          // necessarily represents the nth axis.
          //
          // i is the original index of the dom element
          // j is the new index of the dom element
          if (i > j) {
            // Element moved left
            parent.insertBefore(this, parent.children[j - 1]);
          } else {
            // Element moved right
            if (j + 1 < parent.children.length) {
              parent.insertBefore(this, parent.children[j + 1]);
            } else {
              parent.appendChild(this);
            }
          }
        }

        delete this.__origin__;
        delete dragging[d];
        select(this)
          .transition()
          .attr("transform", "translate(" + xscale(d) + ")");
        // foreground.transition().duration(500).attr("d", path); // TODO: rebuild path with transition
        // background // TODO: rebuild path with transition
        //     .attr("d", path)
        //     .transition()
        //     .delay(500)
        //     .duration(0)
        //     .attr("visibility", null);

        pc.renderAllLayers();

        if (flags.shadows) paths(__.data, ctx.shadows);
      });
    // end d3.behavior.drag() declaration
    g.call(dragBehavior); // end call
    flags.reorderable = true;
    return this;
  }; // end pc.reorderable function {}

  // Reorder dimensions, such that the highest value (visually regarding y axis position if compareYPos) is on the left and
  // the lowest on the right (if descDir, the invert if not). If compareYPos, Visual values are determined by the data values (to be able to compute y pos inside the axis) in
  // the given row otherwise the relative order between values is considered.
  pc.reorder = function (rowdata, compareYPos = true, descDir = true) {
    var dims = __.dimensions.slice(0);
    __.dimensions.sort(function (a, b) {
      let firstItem = b;
      let secondItem = a;
      // eslint-disable-next-line
      if ((compareYPos && descDir) || (!compareYPos && !descDir)) {
        firstItem = a;
        secondItem = b;
      }
      let pixelDifference;
      if (compareYPos) {
        pixelDifference =
          yscale[firstItem](rowdata[firstItem]) -
          yscale[secondItem](rowdata[secondItem]);
      } else {
        pixelDifference = rowdata[firstItem] - rowdata[secondItem];
      }
      // Array.sort is not necessarily stable, this means that if pixelDifference is zero
      // the ordering of dimensions might change unexpectedly. This is solved by sorting on
      // variable name in that case.
      if (pixelDifference === 0) {
        return firstItem.localeCompare(secondItem);
      } // else
      return pixelDifference;
    });

    // NOTE: this is relatively cheap given that:
    // number of dimensions < number of data items
    // Thus we check equality of order to prevent rerendering when this is the case.
    var reordered = false;
    dims.some(function (val, index) {
      reordered = val !== __.dimensions[index];
      return reordered;
    });

    if (reordered) {
      events.call("axesreorder", pc, __.dimensions); // N.M : added to notify enclosing component the change of axes order
      xscale.domain(__.dimensions);
      var highlighted = __.highlighted.slice(0);
      pc.unhighlight();

      g.transition()
        .duration(1500)
        .attr("transform", function (d) {
          return "translate(" + xscale(d) + ")";
        });
      pc.render();

      // pc.highlight() does not check whether highlighted is length zero, so we do that here.
      if (highlighted.length !== 0) {
        pc.highlight(highlighted);
      }
    }
  };

  // pairs of adjacent dimensions
  pc.adjacent_pairs = function (arr) {
    var ret = [];
    for (var i = 0; i < arr.length - 1; i++) {
      ret.push([arr[i], arr[i + 1]]);
    }
    return ret;
  };

  var brush = {
    modes: {
      None: {
        install: function (pc) { }, // Nothing to be done.
        uninstall: function (pc) { }, // Nothing to be done.
        selected: function () {
          return [];
        }, // Nothing to return
        brushState: function () {
          return {};
        },
      },
    },
    mode: "None",
    predicate: "AND",
    currentMode: function () {
      return this.modes[this.mode];
    },
  };

  // This function can be used for 'live' updates of brushes. That is, during the
  // specification of a brush, this method can be called to update the view.
  //
  // @param newSelection - The new set of data items that is currently contained
  //                       by the brushes
  function handleForegroundLayerFading() {
    if (__.fadedCanvasItems && __.fadedCanvasItems.length > 0) {
      selectAll(__.fadedCanvasItems).classed("faded", false);
    }
    __.fadedCanvasItems = [];
    if (
      isBrushed() ||
      (pc.isHighlightFaded() && __.highlighted && __.highlighted.length > 0)
    ) {
      __.fadedCanvasItems.push(canvas.foreground);
      selectAll(__.fadedCanvasItems).classed("faded", true);
      // }else{
      //   selectAll(__.fadedCanvasItems).classed("faded", false);
    }
  }
  function brushUpdated(newSelection) {
    __.brushed = newSelection;
    handleForegroundLayerFading();
    events.call("brush", pc, __.brushed, isBrushed());
    pc.renderBrushed();
  }

  function brushPredicate(predicate) {
    if (!arguments.length) {
      return brush.predicate;
    }

    predicate = String(predicate).toUpperCase();
    if (predicate !== "AND" && predicate !== "OR") {
      throw new Error("Invalid predicate " + predicate);
    }

    brush.predicate = predicate;
    __.brushed = brush.currentMode().selected();
    handleForegroundLayerFading();
    pc.renderBrushed();
    return pc;
  }

  pc.brushModes = function () {
    return Object.getOwnPropertyNames(brush.modes);
  };

  pc.brushMode = function (mode) {
    if (arguments.length === 0) {
      return brush.mode;
    }

    if (pc.brushModes().indexOf(mode) === -1) {
      throw new Error("pc.brushmode: Unsupported brush mode: " + mode);
    }

    // Make sure that we don't trigger unnecessary events by checking if the mode
    // actually changes.
    if (mode !== brush.mode) {
      // When changing brush modes, the first thing we need to do is clearing any
      // brushes from the current mode, if any.
      if (brush.mode !== "None") {
        pc.brushReset();
      }

      // Next, we need to 'uninstall' the current brushMode.
      brush.modes[brush.mode].uninstall(pc);
      // Finally, we can install the requested one.
      brush.mode = mode;
      brush.modes[brush.mode].install();
      if (mode === "None") {
        delete pc.brushPredicate;
      } else {
        pc.brushPredicate = brushPredicate;
      }
    }

    return pc;
  };

  // brush mode: 1D-Axes

  (function () {
    var brushes = {};

    function is_brushed(p) {
      return !brushes[p].empty();
    }

    // data within extents
    function selected() {
      var actives = __.dimensions.filter(is_brushed),
        extents = actives.map(function (p) {
          return brushes[p].extent();
        });

      // We don't want to return the full data set when there are no axes brushed.
      // Actually, when there are no axes brushed, by definition, no items are
      // selected. So, let's avoid the filtering and just return false.
      //if (actives.length === 0) return false;

      // Resolves broken examples for now. They expect to get the full dataset back from empty brushes
      if (actives.length === 0) return __.data;

      // test if within range
      var within = {
        date: function (d, p, dimension) {
          return extents[dimension][0] <= d[p] && d[p] <= extents[dimension][1];
        },
        number: function (d, p, dimension) {
          return extents[dimension][0] <= d[p] && d[p] <= extents[dimension][1];
        },
        string: function (d, p, dimension) {
          return (
            extents[dimension][0] <= yscale[p](d[p]) &&
            yscale[p](d[p]) <= extents[dimension][1]
          );
        },
      };

      return __.data.filter(function (d) {
        switch (brush.predicate) {
          case "AND":
            return actives.every(function (p, dimension) {
              return within[__.types[p]](d, p, dimension);
            });
          case "OR":
            return actives.some(function (p, dimension) {
              return within[__.types[p]](d, p, dimension);
            });
          default:
            throw new Error("Unknown brush predicate " + __.brushPredicate);
        }
      });
    }

    function brushExtents() {
      var extents = {};
      __.dimensions.forEach(function (d) {
        var brush = brushes[d];
        if (brush !== undefined && !brush.empty()) {
          var brushExtent = brush.extent();
          brushExtent.sort(ascending);
          extents[d] = brushExtent;
        }
      });
      return extents;
    }

    function brushFor(dimension) {
      var brush = svg.brush();

      brush
        .y(yscale[dimension])
        .on("brushstart", function () {
          event.sourceEvent.stopPropagation();
        })
        .on("brush", function () {
          brushUpdated(selected());
        })
        .on("brushend", function () {
          events.call("brushend", pc, __.brushed, isBrushed(), brushExtents());
        });

      brushes[dimension] = brush;
      return brush;
    }

    function brushReset(dimension) {
      __.brushed = false;
      handleForegroundLayerFading();
      if (g) {
        g.selectAll(".brush").each(function (d) {
          select(this).call(brushes[d].clear());
        });
        pc.renderBrushed();
      }
      return this;
    }
    // NM. Added to init brush extents after (re-) intalling brushes to retrieve previous states
    function brushApplyExtents(extents) {
      if (g) {
        Object.keys(extents).forEach((key) => {
          var extent = extents[key];
          if (extent) {
            // console.log("applyExtents for "+key);
            //TODO: update and draw brush
            // get y position from domain values of extent min and max (by getting brush scale)
            // call move()
            // brushes[key].move()

            // we have to update __.brushed
            brushUpdated(selected());

            // and call brushend method of enclosing component
            events.call(
              "brushend",
              pc,
              __.brushed,
              isBrushed(),
              brushExtents()
            );
          }
        });
      }
    }

    function install() {
      if (!g) pc.createAxes();

      // Add and store a brush for each axis.
      g.append("svg:g")
        .attr("class", "brush")
        .each(function (d) {
          select(this).call(brushFor(d));
        })
        .selectAll("rect")
        .style("visibility", null)
        .attr("x", -15)
        .attr("width", 30);

      pc.brushState = brushExtents;
      pc.brushReset = brushReset;
      return pc;
    }

    brush.modes["1D-axes"] = {
      install: install,
      uninstall: function () {
        g.selectAll(".brush").remove();
        brushes = {};
        delete pc.brushState;
        delete pc.brushReset;
      },
      selected: selected,
      brushState: brushExtents,
      applyState: brushApplyExtents,
    };
  })();
  // brush mode: 2D-strums
  // bl.ocks.org/syntagmatic/5441022

  (function () {
    var strums = {},
      strumRect;

    function drawStrum(strum, activePoint) {
      var gStrums = pc.selection.select("svg").select("g#strums"),
        id = strum.dims.i,
        points = [strum.p1, strum.p2],
        line = gStrums.selectAll("line#strum-" + id).data([strum]),
        circles = gStrums.selectAll("circle#strum-" + id).data(points),
        // drag = d3.behavior.drag();
        d3Drag = drag();

      line
        .enter()
        .append("line")
        .attr("id", "strum-" + id)
        .attr("class", "strum");

      line
        .attr("x1", function (d) {
          return d.p1[0];
        })
        .attr("y1", function (d) {
          return d.p1[1];
        })
        .attr("x2", function (d) {
          return d.p2[0];
        })
        .attr("y2", function (d) {
          return d.p2[1];
        })
        .attr("stroke", "black")
        .attr("stroke-width", 2);

      d3Drag
        .on("drag", function (d, i) {
          var ev = event;
          i = i + 1;
          strum["p" + i][0] = Math.min(
            Math.max(strum.minX + 1, ev.x),
            strum.maxX
          );
          strum["p" + i][1] = Math.min(Math.max(strum.minY, ev.y), strum.maxY);
          drawStrum(strum, i - 1);
        })
        .on("dragend", onDragEnd());

      circles
        .enter()
        .append("circle")
        .attr("id", "strum-" + id)
        .attr("class", "strum");

      circles
        .attr("cx", function (d) {
          return d[0];
        })
        .attr("cy", function (d) {
          return d[1];
        })
        .attr("r", 5)
        .style("opacity", function (d, i) {
          return activePoint !== undefined && i === activePoint ? 0.8 : 0;
        })
        .on("mouseover", function () {
          select(this).style("opacity", 0.8);
        })
        .on("mouseout", function () {
          select(this).style("opacity", 0);
        })
        .call(d3Drag);
    }

    function dimensionsForPoint(p) {
      var dims = { i: -1, left: undefined, right: undefined };
      __.dimensions.some(function (dim, i) {
        if (xscale(dim) < p[0]) {
          var next = __.dimensions[i + 1];
          dims.i = i;
          dims.left = dim;
          dims.right = next;
          return false;
        }
        return true;
      });

      if (dims.left === undefined) {
        // Event on the left side of the first axis.
        dims.i = 0;
        dims.left = __.dimensions[0];
        dims.right = __.dimensions[1];
      } else if (dims.right === undefined) {
        // Event on the right side of the last axis
        dims.i = __.dimensions.length - 1;
        dims.right = dims.left;
        dims.left = __.dimensions[__.dimensions.length - 2];
      }

      return dims;
    }

    function onDragStart() {
      // First we need to determine between which two axes the sturm was started.
      // This will determine the freedom of movement, because a strum can
      // logically only happen between two axes, so no movement outside these axes
      // should be allowed.
      return function () {
        var p = mouse(strumRect[0][0]),
          dims,
          strum;

        p[0] = p[0] - __.margin.left;
        p[1] = p[1] - __.margin.top;

        dims = dimensionsForPoint(p);
        strum = {
          p1: p,
          dims: dims,
          minX: xscale(dims.left),
          maxX: xscale(dims.right),
          minY: 0,
          maxY: h(),
        };

        strums[dims.i] = strum;
        strums.active = dims.i;

        // Make sure that the point is within the bounds
        strum.p1[0] = Math.min(Math.max(strum.minX, p[0]), strum.maxX);
        strum.p2 = strum.p1.slice();
      };
    }

    function onDrag() {
      return function () {
        var ev = event,
          strum = strums[strums.active];

        // Make sure that the point is within the bounds
        strum.p2[0] = Math.min(
          Math.max(strum.minX + 1, ev.x - __.margin.left),
          strum.maxX
        );
        strum.p2[1] = Math.min(
          Math.max(strum.minY, ev.y - __.margin.top),
          strum.maxY
        );
        drawStrum(strum, 1);
      };
    }

    function containmentTest(strum, width) {
      var p1 = [strum.p1[0] - strum.minX, strum.p1[1] - strum.minX],
        p2 = [strum.p2[0] - strum.minX, strum.p2[1] - strum.minX],
        m1 = 1 - width / p1[0],
        b1 = p1[1] * (1 - m1),
        m2 = 1 - width / p2[0],
        b2 = p2[1] * (1 - m2);

      // test if point falls between lines
      return function (p) {
        var x = p[0],
          y = p[1],
          y1 = m1 * x + b1,
          y2 = m2 * x + b2;

        if (y > Math.min(y1, y2) && y < Math.max(y1, y2)) {
          return true;
        }

        return false;
      };
    }

    function selected() {
      var ids = Object.getOwnPropertyNames(strums),
        brushed = __.data;

      // Get the ids of the currently active strums.
      ids = ids.filter(function (d) {
        return !isNaN(d);
      });

      function crossesStrum(d, id) {
        var strum = strums[id],
          test = containmentTest(strum, strums.width(id)),
          d1 = strum.dims.left,
          d2 = strum.dims.right,
          y1 = yscale[d1],
          y2 = yscale[d2],
          point = [y1(d[d1]) - strum.minX, y2(d[d2]) - strum.minX];
        return test(point);
      }

      if (ids.length === 0) {
        return brushed;
      }

      return brushed.filter(function (d) {
        switch (brush.predicate) {
          case "AND":
            return ids.every(function (id) {
              return crossesStrum(d, id);
            });
          case "OR":
            return ids.some(function (id) {
              return crossesStrum(d, id);
            });
          default:
            throw new Error("Unknown brush predicate " + __.brushPredicate);
        }
      });
    }

    function brushStrums() {
      return strums;
    }

    function removeStrum() {
      var strum = strums[strums.active],
        gStrum = pc.selection.select("svg").select("g#strums");

      delete strums[strums.active];
      strums.active = undefined;
      gStrum.selectAll("line#strum-" + strum.dims.i).remove();
      gStrum.selectAll("circle#strum-" + strum.dims.i).remove();
    }

    function onDragEnd() {
      return function () {
        var brushed = __.data,
          strum = strums[strums.active];

        // Okay, somewhat unexpected, but not totally unsurprising, a mousclick is
        // considered a drag without move. So we have to deal with that case
        if (
          strum &&
          strum.p1[0] === strum.p2[0] &&
          strum.p1[1] === strum.p2[1]
        ) {
          removeStrum(strums);
        }

        brushed = selected(strums);
        strums.active = undefined;
        __.brushed = brushed;
        handleForegroundLayerFading();
        pc.renderBrushed();
        events.call("brushend", pc, __.brushed, isBrushed(), brushStrums());
      };
    }

    function brushReset(strums) {
      return function () {
        var ids = Object.getOwnPropertyNames(strums).filter(function (d) {
          return !isNaN(d);
        });

        ids.forEach(function (d) {
          strums.active = d;
          removeStrum(strums);
        });
        onDragEnd(strums)();
      };
    }
    // NM. Added to init brush extents after (re-) intalling brushes to retrieve previous states
    function brushApplyStrums(strums) {
      if (g) {
        // TODO: update and draw strums

        // we have to update __.brushed
        brushUpdated(selected());

        // and call brushend method of enclosing component
        events.call("brushend", pc, __.brushed, isBrushed(), brushStrums());
      }
    }

    function install() {
      // var drag = d3.behavior.drag();
      var d3Drag = drag();

      // Map of current strums. Strums are stored per segment of the PC. A segment,
      // being the area between two axes. The left most area is indexed at 0.
      strums.active = undefined;
      // Returns the width of the PC segment where currently a strum is being
      // placed. NOTE: even though they are evenly spaced in our current
      // implementation, we keep for when non-even spaced segments are supported as
      // well.
      strums.width = function (id) {
        var strum = strums[id];

        if (strum === undefined) {
          return undefined;
        }

        return strum.maxX - strum.minX;
      };

      pc.on("axesreorder.strums", function () {
        var ids = Object.getOwnPropertyNames(strums).filter(function (d) {
          return !isNaN(d);
        });

        // Checks if the first dimension is directly left of the second dimension.
        function consecutive(first, second) {
          var length = __.dimensions.length;
          return __.dimensions.some(function (d, i) {
            return d === first
              ? i + i < length && __.dimensions[i + 1] === second
              : false;
          });
        }

        if (ids.length > 0) {
          // We have some strums, which might need to be removed.
          ids.forEach(function (d) {
            var dims = strums[d].dims;
            strums.active = d;
            // If the two dimensions of the current strum are not next to each other
            // any more, than we'll need to remove the strum. Otherwise we keep it.
            if (!consecutive(dims.left, dims.right)) {
              removeStrum(strums);
            }
          });
          onDragEnd(strums)();
        }
      });

      // Add a new svg group in which we draw the strums.
      pc.selection
        .select("svg")
        .append("g")
        .attr("id", "strums")
        .attr(
          "transform",
          "translate(" + __.margin.left + "," + __.margin.top + ")"
        );

      // Install the required brushReset function
      pc.brushReset = brushReset(strums);

      d3Drag
        .on("dragstart", onDragStart(strums))
        .on("drag", onDrag(strums))
        .on("dragend", onDragEnd(strums));

      // NOTE: The styling needs to be done here and not in the css. This is because
      //       for 1D brushing, the canvas layers should not listen to
      //       pointer-events.
      strumRect = pc.selection
        .select("svg")
        .insert("rect", "g#strums")
        .attr("id", "strum-events")
        .attr("x", __.margin.left)
        .attr("y", __.margin.top)
        .attr("width", w())
        .attr("height", h() + 2)
        .style("opacity", 0)
        .call(d3Drag);

      // install the brushExtents function
      pc.brushState = brushStrums;
    }

    brush.modes["2D-strums"] = {
      install: install,
      uninstall: function () {
        pc.selection.select("svg").select("g#strums").remove();
        pc.selection.select("svg").select("rect#strum-events").remove();
        pc.on("axesreorder.strums", undefined);
        delete pc.brushReset;

        strumRect = undefined;
      },
      selected: selected,
      brushState: brushStrums,
      applyState: brushApplyStrums,
    };
  })();

  // brush mode: 1D-Axes with multiple extents
  // requires d3.brush.multibrush

  (function () {
    if (typeof d3.brush.multibrush !== "function") {
      return;
    }
    var brushes = {};

    function is_brushed(p) {
      return !brushes[p].empty();
    }

    // data within extents
    function selected() {
      var actives = __.dimensions.filter(is_brushed),
        extents = actives.map(function (p) {
          return brushes[p].extent();
        });

      // We don't want to return the full data set when there are no axes brushed.
      // Actually, when there are no axes brushed, by definition, no items are
      // selected. So, let's avoid the filtering and just return false.
      //if (actives.length === 0) return false;

      // Resolves broken examples for now. They expect to get the full dataset back from empty brushes
      if (actives.length === 0) return __.data;

      // test if within range
      var within = {
        date: function (d, p, dimension, b) {
          return b[0] <= d[p] && d[p] <= b[1];
        },
        number: function (d, p, dimension, b) {
          return b[0] <= d[p] && d[p] <= b[1];
        },
        string: function (d, p, dimension, b) {
          return b[0] <= yscale[p](d[p]) && yscale[p](d[p]) <= b[1];
        },
      };

      return __.data.filter(function (d) {
        switch (brush.predicate) {
          case "AND":
            return actives.every(function (p, dimension) {
              return extents[dimension].some(function (b) {
                return within[__.types[p]](d, p, dimension, b);
              });
            });
          case "OR":
            return actives.some(function (p, dimension) {
              return extents[dimension].some(function (b) {
                return within[__.types[p]](d, p, dimension, b);
              });
            });
          default:
            throw new Error("Unknown brush predicate " + __.brushPredicate);
        }
      });
    }

    function brushExtents() {
      var extents = {};
      __.dimensions.forEach(function (d) {
        var brush = brushes[d];
        if (brush !== undefined && !brush.empty()) {
          var brushExtent = brush.extent();
          extents[d] = brushExtent;
        }
      });
      return extents;
    }

    function brushFor(dimension) {
      var brush = d3.brush.multibrush();

      brush
        .y(yscale[dimension])
        .on("brushstart", function () {
          brush.current_event.sourceEvent.stopPropagation();
        })
        .on("brush", function () {
          brushUpdated(selected());
        })
        .on("brushend", function () {
          // d3.brush.multibrush clears extents just before calling 'brushend'
          // so we have to update here again.
          // This fixes issue #103 for now, but should be changed in d3.brush.multibrush
          // to avoid unnecessary computation.
          brushUpdated(selected());

          // Audren - 2021-08-06: add an extra property to the following event "isBrushed"
          // "__.brushed" always returns something and we can't determine if parcoord is brushed or not.
          events.call("brushend", pc, __.brushed, isBrushed(), brushExtents());
        })
        .extentAdaption(function (selection) {
          selection.style("visibility", null).attr("x", -15).attr("width", 30);
        })
        .resizeAdaption(function (selection) {
          selection.selectAll("rect").attr("x", -15).attr("width", 30);
        });

      brushes[dimension] = brush;
      return brush;
    }

    function brushReset(dimension) {
      __.brushed = false;
      handleForegroundLayerFading();
      if (g) {
        g.selectAll(".brush").each(function (d) {
          select(this).call(brushes[d].clear());
        });
        pc.renderBrushed();
      }
      return this;
    }

    // NM. Added to init brush extents after (re-) intalling brushes to retrieve previous states
    function brushApplyExtents(extents) {
      if (g) {
        Object.keys(extents).forEach((key) => {
          var multiExtents = extents[key];
          if (multiExtents && multiExtents.length > 0) {
            console.log("applyExtents for " + key);
            // call applyExtents in d3.brush.multibrush.js
            brushes[key].applyExtents(multiExtents, 1);
          }
        });
        // we have to update __.brushed
        brushUpdated(selected());

        // and call brushend method of enclosing component
        events.call("brushend", pc, __.brushed, isBrushed(), brushExtents());
      }
    }

    function install() {
      if (!g) pc.createAxes();

      // Add and store a brush for each axis.
      g.append("svg:g")
        .attr("class", "brush")
        .each(function (d) {
          select(this).call(brushFor(d));
        })
        .selectAll("rect")
        .style("visibility", false)
        .attr("x", -15)
        .attr("width", 30);

      pc.brushState = brushExtents;
      pc.brushReset = brushReset;
      return pc;
    }

    brush.modes["1D-axes-multi"] = {
      install: install,
      uninstall: function () {
        g.selectAll(".brush").remove();
        brushes = {};
        delete pc.brushState;
        delete pc.brushReset;
      },
      selected: selected,
      brushState: brushExtents,
      applyState: brushApplyExtents,
    };
  })();

  pc.applyBrushState = function (extents) {
    if (extents && Object.keys(extents)) {
      // clear existing brushes
      pc.brushReset();
      // compare variables of brush extents with those in the current axes
      const brushExtentsToUpdate = {};
      Object.keys(extents).forEach((key) => {
        if (__.dimensions.indexOf(key) >= 0) {
          brushExtentsToUpdate[key] = extents[key];
        }
      });
      // apply extents on current axes
      brush.currentMode().applyState(brushExtentsToUpdate);
    }
  };

  pc.interactive = function () {
    flags.interactive = true;
    return this;
  };

  // expose a few objects
  pc.xscale = xscale;
  pc.yscale = yscale;
  pc.ctx = ctx;
  pc.canvas = canvas;
  pc.g = function () {
    return g;
  };

  // rescale for height, width and margins
  // TODO currently assumes chart is brushable, and destroys old brushes
  pc.resize = function () {
    // selection size
    pc.selection
      .select("svg")
      .attr("width", __.width)
      .attr("height", __.height);
    pc.svg.attr(
      "transform",
      "translate(" + __.margin.left + "," + __.margin.top + ")"
    );

    // FIXME: the current brush state should pass through
    if (flags.brushable) pc.brushReset();

    // scales
    pc.autoscale();

    // axes, destroys old brushes.
    if (g) pc.createAxes();
    if (flags.shadows) paths(__.data, ctx.shadows);
    if (flags.brushable) pc.brushable();
    if (flags.reorderable) pc.reorderable();

    // events.resize.call(this, {
    events.call("resize", this, {
      width: __.width,
      height: __.height,
      margin: __.margin,
    });
    return this;
  };
  // highlight an array of data
  pc.highlight = function (data, fadedLayers) {
    if (arguments.length === 0) {
      return __.highlighted;
    }

    __.highlighted = data;
    pc.clear("highlight");
    handleForegroundLayerFading();
    // __.fadedCanvasItems = [];
    // // if fadedLayers===undefined push everything for fading and use isHighlightFaded to disable
    // if (!fadedLayers || fadedLayers.indexOf("foreground") >= 0) {
    //   __.fadedCanvasItems.push(canvas.foreground);
    // }
    if (!fadedLayers || fadedLayers.indexOf("brushed") >= 0) {
      __.fadedCanvasItems.push(canvas.brushed);
    }
    // reapply fading to handle brushed faded
    if (pc.isHighlightFaded() && __.fadedCanvasItems) {
      selectAll(__.fadedCanvasItems).classed("faded", true);
    }
    data.forEach((d, i) => {
      path_highlight(d, i, data.length, {});
    });
    events.call("highlight", this, data);
    // events.highlight.call(this, data);
    return this;
  };

  // clear highlighting
  pc.unhighlight = function () {
    __.highlighted = [];
    pc.clear("highlight");
    handleForegroundLayerFading();
    // if (pc.isHighlightFaded() && __.fadedCanvasItems) {
    //   selectAll(__.fadedCanvasItems).classed("faded", false);
    //   __.fadedCanvasItems = [];
    // }
    return this;
  };

  // render all layers
  pc.renderAllLayers = function () {
    var highlighted = __.highlighted.slice(0);
    pc.unhighlight();
    pc.render();
    if (highlighted.length !== 0) {
      pc.highlight(highlighted);
    }
  };

  // calculate 2d intersection of line a->b with line c->d
  // points are objects with x and y properties
  pc.intersection = function (a, b, c, d) {
    return {
      x:
        ((a.x * b.y - a.y * b.x) * (c.x - d.x) -
          (a.x - b.x) * (c.x * d.y - c.y * d.x)) /
        ((a.x - b.x) * (c.y - d.y) - (a.y - b.y) * (c.x - d.x)),
      y:
        ((a.x * b.y - a.y * b.x) * (c.y - d.y) -
          (a.y - b.y) * (c.x * d.y - c.y * d.x)) /
        ((a.x - b.x) * (c.y - d.y) - (a.y - b.y) * (c.x - d.x)),
    };
  };

  // set renderingMonitoring object with settotalRows
  pc.setRenderingMonitoring = function (monitoring) {
    __.renderingMonitoring = monitoring;
    if (brushedQueue) {
      brushedQueue.renderingMonitoring(monitoring);
    }
    if (foregroundQueue) {
      foregroundQueue.renderingMonitoring(monitoring);
    }
    return this;
  };

  function position(d) {
    var v = dragging[d];
    return v == null ? xscale(d) : v;
  }
  pc.version = "0.6.0";
  // this descriptive text should live with other introspective methods
  pc.toString = function () {
    return (
      "Parallel Coordinates: " +
      __.dimensions.length +
      " dimensions (" +
      keys(__.data[0]).length +
      " total) , " +
      __.data.length +
      " rows"
    );
  };

  return pc;
}; //end pc function

/*
 * This method manage a queue build based on data items to process rendering on the background
 * without blocking user interactions. It takes an external function (func(itemObject, itemIndex) in parameter)
 * to process for rendering one item.
 * This method provide a main function in rq variable that can be called after calling initialization functions which call
 * initialization function rq.data, rq.invalidate() and call rq.render() at the end.
 * The rq.render() method call d3.timer to iteratively call the func() function to render each item put in the queue.
 * The initialization methods return all the main function rq calling rq.render() method:
 * - rq.data() : init data in the queue
 * - rq.rate() : define the number of call per frame
 * - rq.remaining(): return the remaining number of items to process in the queue
 * - rq.clear(): clear the canvas view
 * - rq.invalidate(): does nothing before first render is called. Calling this method stop renderering
 *   the remaining items in the queue.
 */
d3.renderQueue = function (func) {
  var rq = function (data) {
    if (data) rq.data(data);
    rq.invalidate();
    rq._clear();
    rq.render();
  };
  rq._queue = []; // data to be rendere
  rq._rate = 10; // number of calls per frame
  rq._forceSetStyle = {}; // contains indexes of the queue where to force a stroke(), set new style and beginPath()
  rq._clear = function () { }; // clearing function
  rq._i = 0; // current iteration
  rq._renderingMonitoring = null;
  rq._action = "";

  rq.render = function () {
    rq._i = 0;
    var valid = true;
    rq.invalidate = function () {
      valid = false;
      if (rq._renderingMonitoring !== null)
        rq._renderingMonitoring.stopRendering(rq._action);
    };
    if (rq._queue.length === 0) return;
    if (rq._renderingMonitoring !== null)
      rq._renderingMonitoring.startRendering(rq._action, rq._queue.length);
    function doFrame() {
      // !!! set withApply to true inside doFrame() because called in d3.timer (outside angular scope)
      if (!valid) {
        if (rq._renderingMonitoring !== null)
          rq._renderingMonitoring.stopRendering(rq._action, true);
        return true;
      }
      if (rq._i > rq._queue.length) {
        if (rq._renderingMonitoring !== null)
          rq._renderingMonitoring.stopRendering(rq._action, true);
        return true;
      }
      // Typical d3 behavior is to pass a data item *and* its index. As the
      // render queue splits the original data set, we'll have to be slightly
      // more carefull about passing the correct index with the data item.
      var end = Math.min(rq._i + rq._rate, rq._queue.length);
      for (var i = rq._i; i < end; i++) {
        func(rq._queue[i], i, rq._queue.length, rq._forceSetStyle);
      }
      rq._i += rq._rate;
      const remaining = Math.max(0, rq._queue.length - rq._i);
      if (rq._renderingMonitoring !== null)
        rq._renderingMonitoring.setRemainingRenderingNb(
          rq._action,
          remaining,
          true
        );
      if (remaining && rq._renderingMonitoring !== null)
        rq._renderingMonitoring.stopRendering(rq._action, true);
    }

    timer(doFrame);
  };

  rq.data = function (data) {
    rq.invalidate();
    rq._queue = data.slice(0);
    // sort the queue by color (common stroke style to be more generic)
    rq._queue.sort((a, b) => {
      const colorA = d3.oldD3Functor(rq._color)(a, rq.i); // get color from data value a;
      const colorB = d3.oldD3Functor(rq._color)(b, rq.i); // get color from data value b;
      if (colorA < colorB) {
        return 1;
      } else if (colorA > colorB) {
        return -1;
      } else {
        return 0;
      }
      // return colorA-colorB;
    });
    /*const colorCheck = rq._queue.map((d, i) => {
      return d3.oldD3Functor(rq._color)(d, i)// get color from data value d; to check consistency
    });*/

    // get Position to force beginPath setting the collor and stroke();
    rq._forceSetStyle = {};
    rq._queue.forEach((d, i) => {
      if (i === 0) {
        rq._forceSetStyle[i] = true;
      } else {
        const colorA = d3.oldD3Functor(rq._color)(rq._queue[i - 1], i); // get color from rq._queue[i-1]
        const colorB = d3.oldD3Functor(rq._color)(d, i); // get color from d
        if (colorA !== colorB) {
          rq._forceSetStyle[i] = true;
        }
      }
    });

    return rq;
  };
  rq.color = function (color) {
    rq._color = color;
    return rq;
  };
  rq.rate = function (value) {
    if (!arguments.length) return rq._rate;
    rq._rate = value;
    return rq;
  };
  rq.action = function (action) {
    rq._action = action;
    return rq;
  };

  rq.renderingMonitoring = function (renderingMonitoring) {
    rq._renderingMonitoring = renderingMonitoring;
    return rq;
  };

  rq.remaining = function () {
    return rq._queue.length - rq._i;
  };

  // clear the canvas
  rq.clear = function (func) {
    if (!arguments.length) {
      rq._clear();
      return rq;
    }
    rq._clear = func;
    return rq;
  };

  rq.invalidate = function () {
    if (rq._renderingMonitoring !== null)
      rq._renderingMonitoring.stopRendering(rq._action);
  };
  return rq;
}; // end d3.renderQueue()

export default d3.parcoords;
