import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";

import { toggleSchemaDialog } from "../../../features/app";
import { setColumns as setColumnsChart } from "../../../features/chart";
import { setColumns as setColumnsTable } from "../../../features/table";
import StyledSelect from "../../styled/Select";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingBottom: "8px",
  },
  dialogContent: {
    paddingTop: "0px",
  },
  select: {
    maxWidth: "100%",
  },
}));

// const intersection = (a, b) => a.filter((v) => b.indexOf(v) !== -1);
const not = (a, b) => a.filter((v) => b.indexOf(v) === -1);
const union = (a, b) => [...a, ...not(b, a)];

export default function SchemaSelector(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // state
  const [shiftCount, setShiftCount] = useState(0);
  const [firstShiftColumn, setFirstShiftColumn] = useState(null);

  // store
  // const { debug } = useSelector((state) => state.app);
  const { schema } = useSelector((state) => state.dataset);
  const { excludedColumns, selectedColumns } = useSelector((state) => {
    if (props.feature in state) return state[props.feature];
    else return { excludedColumns: [], selectedColumns: [] };
  });
  const { syncColumns } = useSelector((state) => state.table);
  const dispatchSetColumns = (columnObject) => {
    // Maintain the previous order of selectedColumns
    const maintainedColumnOrder = {
      excludedColumns: columnObject.excludedColumns,
      selectedColumns: undefined,
    };
    // keep columns already existing in selectedColumns (with preserved order)
    maintainedColumnOrder.selectedColumns = selectedColumns.filter((d) => {
      return (
        columnObject.selectedColumns.map((d2) => d2.name).indexOf(d.name) >= 0
      );
    });
    // add new columns at the end
    columnObject.selectedColumns.forEach((d) => {
      if (
        maintainedColumnOrder.selectedColumns
          .map((d2) => d2.name)
          .indexOf(d.name) < 0
      ) {
        maintainedColumnOrder.selectedColumns.push(d);
      }
    });
    // dispatch columns with preserved order
    const setColumns =
      props.feature === "table" ? setColumnsTable : setColumnsChart;
    dispatch(setColumns(maintainedColumnOrder));
  };

  // func

  // event
  const onClose = (event) => dispatch(toggleSchemaDialog(false));

  const onChange = (event) => {
    if (!!event.shiftKey) {
      let newShiftCount = shiftCount + 1;
      setShiftCount(newShiftCount);

      if (newShiftCount === 1) {
        let selectedColumnNames = selectedColumns.map((s) => s.name);
        let nameDiff = [],
          action = "add";
        if (selectedColumnNames.length < event.target.value.length)
          nameDiff = not(event.target.value, selectedColumnNames)[0];
        else {
          nameDiff = not(selectedColumnNames, event.target.value)[0];
          action = "remove";
        }
        let index = schema.findIndex((s) => s.name === nameDiff);
        setFirstShiftColumn({ index, name: nameDiff, action });
        return;
      }
      if (newShiftCount === 2) {
        let selectedColumnNames = selectedColumns.map((s) => s.name);
        let nameDiff = [];
        if (selectedColumnNames.length < event.target.value.length)
          nameDiff = not(event.target.value, selectedColumnNames);
        else nameDiff = not(selectedColumnNames, event.target.value);

        let a = firstShiftColumn.index,
          b = schema.findIndex((s) => s.name === nameDiff[0]);
        if (a > b) {
          a = b;
          b = firstShiftColumn.index;
        }

        let shiftElements = schema.slice(a, b + 1);
        let shiftElementNames = shiftElements.map((s) => s.name);
        let selectedColumnsWithShift = [];
        if (firstShiftColumn.action === "add")
          selectedColumnsWithShift = union(
            event.target.value,
            shiftElementNames
          );
        else
          selectedColumnsWithShift = event.target.value.filter(
            (s) => !shiftElementNames.includes(s)
          );
        const [excluded, selected] = schema.reduce(
          (acc, column) => {
            if (selectedColumnsWithShift.includes(column.name))
              acc[1].push(column);
            else acc[0].push(column);
            return acc;
          },
          [[], []]
        );
        dispatchSetColumns({
          selectedColumns: selected,
          excludedColumns: excluded,
        });

        setShiftCount(0);
        return;
      } else setShiftCount(0);
    }
    const [excluded, selected] = []
      .concat(selectedColumns, excludedColumns)
      .reduce(
        (acc, column, index, array) => {
          if (event.target.value.includes(column.name)) acc[1].push(column);
          else acc[0].push(column);

          return acc;
        },
        [[], []]
      );
    dispatchSetColumns({
      selectedColumns: selected,
      excludedColumns: excluded,
    });
  };

  const open = props.feature !== null && typeof props.feature === "string";
  const place =
    props.feature === "table"
      ? "the table view"
      : syncColumns
      ? "the table and the chart view"
      : "the chart view";
  return (
    <Dialog open={open} color="primary" onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>Select columns</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          <span>You can select the columns to display in {place}.</span>
          <Tooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Available shortcuts</Typography>
                <b>shift + click</b>: when 2 items are clicked with the shift
                key pressed, it will <b>select / unselect</b> all the items
                in-between.
              </React.Fragment>
            }
          >
            <InfoIcon fontSize="small" style={{ verticalAlign: "super" }} />
          </Tooltip>
        </DialogContentText>

        <StyledSelect
          value={selectedColumns.map((s) => s.name)}
          className={classes.select}
          variant="outlined"
          onChange={onChange}
          // autoWidth
          autoFocus
          multiple
        >
          {schema.map((s) => (
            <MenuItem value={s.name} key={s.name} dense>
              {s.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
