import React, { useState, useEffect } from "react"; // import useEffect if necessary
import { useDispatch, useSelector } from "react-redux";
// MUI components
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  TextField,
  withStyles,
} from "@material-ui/core";
// Lamda components
import StyledSelect from "../../styled/Select";

import { sendSimilarity } from "../../../features/analytic";
import { toggleColumns } from "../../../features/table";

import { searchIfExistAndUpdateIdentifier } from "../../../utils/character";
import SelectSimilarityVariables from "./columns-selection/SelectSimilarityVariables";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  maxWidth: {
    maxWidth: "300px",
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}));

// local components
function Row(props) {
  const { children, title, classes, ...other } = props; // eslint-disable-line
  return (
    <Grid item xs={12} md={10} lg={5} className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <InputLabel htmlFor={`${title}-label`}>{title}</InputLabel>
        </Grid>
        <Grid item xs={8}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
const StyledRow = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))(Row);


function SimilarityTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // store
  const { schema, currentDataset } = useSelector((state) => state.dataset);
  const selectedColumnsChart = useSelector(
    (state) => state.chart.selectedColumns
  );
  const selectedColumnsTable = useSelector(
    (state) => state.table.selectedColumns
  );
  const { syncColumns } = useSelector((state) => state.table);
  const { loading } = useSelector((state) => state.analytic);
  const { lastHighlightedRow } = useSelector((state) => state.chart);

  // state
  const defaultConfigObj = {
    method: "ClinicalKernel",
    identifier: searchIfExistAndUpdateIdentifier("ClinicalKernelDist", schema),
    // referenceId: lastHighlightedRow,
    k: 30,
    sigma: 0,
    description: "",
    variables: [],
    schema: [],
    // disabled: true,
  };
  const [state, setState] = useState(defaultConfigObj);
  const [variables, setVariables] = useState([]);
  const [reset, setReset] = useState(false);

  const checkDisabled = (newState) => {
    if (!variables)
      return false;
    return (
      variables.length === 0 ||
      // || newState.referenceId === null || newState.referenceId === undefined
      lastHighlightedRow === null ||
      lastHighlightedRow === undefined ||
      newState.k === 0
      // || newState.identifier.length <= 3
    );
  };
  // events
  const onChange = (event) => {
    setState((state) => {
      const newState = {
        ...state,
        [event.target.name]: event.target.value,
      };
      // do not store in state but compute dynamically by calling the checkDisabled
      // let disabled = checkDisabled(newState);
      // return { ...newState, disabled };
      return newState;
    });
  };

  // const onChangeNumber = (event) => {
  //   if (!event.target.value) return;

  //   const newState = {
  //     ...state,
  //     [event.target.name]: parseFloat(event.target.value),
  //     identifier: identifierName(),
  //   };
  //   // do not store in state but computed dynamically by calling the checkDisabled
  //   // let disabled = checkDisabled(newState);
  //   // setState((state) => ({ ...newState, disabled }));
  //   setState((state) => newState);
  // };

  const onIdentifierChange = (event) => {
    // do not store in state but computed dynamically by calling the checkDisabled
    // let disabled = checkDisabled(newState);
    // setState((state) => ({ ...newState, disabled }));
    setState((state) => {
      const newIdentifier = searchIfExistAndUpdateIdentifier(
        event.target.value,
        schema
      );

      const newState = {
        ...state,
        [event.target.name]: newIdentifier,
      };

      return newState;
    });
  };

  const onCalculate = async (event) => {
    // if (state.disabled)
    if (checkDisabled(state)) return;

    const syncColumns_ = syncColumns;
    const vars = variables.slice()

    // set the column name to store the distance result
    // let colName = identifierName();

    dispatch(
      // TODO: call sendSimilarity instead
      sendSimilarity({
        method: state.method,
        identifier: state.identifier,
        // referenceId:state.referenceId,
        referenceRow: lastHighlightedRow,
        k: state.k,
        variables: vars,
        description: state.description,
        currentDataset,
        selectedColumns: {
          chart: selectedColumnsChart,
          table: selectedColumnsTable,
        },
      })
    )
      .then(() => dispatch(toggleColumns(syncColumns_))) //  keep syncColumns as it was before FIXME why it is needed?
      // done in useEffect When schema changes
      .then(() => onClear(event)) // reset parameters with default ones
      .catch(console.log);
  };
  const onClear = (event) => {
    setReset(true)
    setState((state) => {
      defaultConfigObj.identifier = searchIfExistAndUpdateIdentifier(
        "ClinicalKernelDist",
        state.schema
      );
      return { ...defaultConfigObj, schema: state.schema };
    });
  };

  function rowDataToString(rowObj) {
    let resString = "No reference selected in the data table!";
    if (rowObj !== undefined && rowObj !== null) {
      resString = "";
      schema.forEach((feature) => {
        if (feature.group === "Id" || feature.group === "Id") { // FIXME: duplicated conditions for || operator
          resString =
            resString + feature.name + "=" + rowObj[feature.name] + ", ";
        }
      });
      if (schema.length > 0) {
        resString = resString.slice(0, -2); // remove last character
      }
    }
    return resString;
  }
  // function identifierName() {
  //   // init sigma parameter
  //   let sigma = state.sigma;
  //   if (sigma === undefined) {
  //     sigma = 0;
  //   } else {
  //     sigma = Math.round(sigma * 100);
  //   }

  //   // let idVariableName = schema.find(s => s.group === "Id").name
  //   // let ref = "";
  //   // if (lastHighlightedRow) {
  //   //   ref = lastHighlightedRow[idVariableName];
  //   // }
  //   return "ClinicalKernDist_1"
  //     // + "_ref" + ref
  //     // // +"_k"+req.body.args.k
  //     // + "_sig" + sigma
  //     // + "_nvar" + state.variables.length
  //     // // +"_nr"+filteredData.length
  //     ;
  // }
  // template for react useEffect() equ. to didUpdate + didUnmont for a list of items in the state and useSelector
  useEffect(() => {
    // do something when dependents items are updated
    defaultConfigObj.identifier = searchIfExistAndUpdateIdentifier(
      "ClinicalKernelDist",
      schema
    );
    setState((state) => {
      return { ...defaultConfigObj, schema };
    });
    // cleanup
    return () => { }; // return a function for cleaning the component (equ to didUnmount)
    // eslint-disable-next-line
  }, [schema]); // list of dependent items coming from useSelector and useState

  // const
  // const hasDistanceColumn = !!schema.find((s) => s.group === "Distance");
  // const mockeup = range(0, 41, 2);
  return (
    <Grid container direction="row" className={classes.root}>
      <StyledRow title="Method">
        <StyledSelect
          id="Method-label"
          name="method"
          variant="outlined"
          value={state.method}
          onChange={onChange}
          className={classes.marginRight}
          displayEmpty
        >
          <MenuItem key="clinkern" value="ClinicalKernel" dense>
            ClinicalKernel
          </MenuItem>
          {/*<MenuItem key="kmeansNN" value="KMeansNN">*/}
          {/*  K-Means NN*/}
          {/*</MenuItem>*/}
        </StyledSelect>
      </StyledRow>
      <StyledRow title="Reference row id">
        <p style={{ margin: "8px 0" }}>{rowDataToString(lastHighlightedRow)}</p>
      </StyledRow>
      {/*<StyledRow title="Nearest neighbours">*/}
      {/*  <TextField*/}
      {/*    name="k"*/}
      {/*    placeholder="Number of neighbors (default: 30)"*/}
      {/*    arial-label="number neighbors"*/}
      {/*    color="primary"*/}
      {/*    size="small"*/}
      {/*    variant="outlined"*/}
      {/*    onChange={onChangeNumber}*/}
      {/*    value={state.k}*/}
      {/*    type="number"*/}
      {/*    InputProps={{*/}
      {/*      inputProps: { min: 1, inputMode: "decimal", pattern: "[0-9]*" },*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</StyledRow>*/}
      <StyledRow title="Variables for comparison">
        <SelectSimilarityVariables updateParentState={setVariables} reset={reset} setReset={setReset} />
      </StyledRow>
      <StyledRow title="Identifier">
        {/*{identifierName()}*/}
        <TextField
          name="identifier"
          placeholder="Column name"
          arial-label="column name"
          color="primary"
          size="small"
          variant="outlined"
          onChange={onIdentifierChange}
          value={state.identifier}
        />
      </StyledRow>
      <StyledRow title="Description">
        {/*{identifierName()}*/}
        <TextField
          name="description"
          placeholder="Description"
          arial-label="descritpion"
          color="primary"
          size="small"
          variant="outlined"
          onChange={onChange}
          value={state.description}
        />
      </StyledRow>
      <StyledRow title="Actions">
        <Button
          variant="outlined"
          color="primary"
          // disabled={state.disabled || loading}
          disabled={checkDisabled(state) || loading}
          onClick={onCalculate}
          className={classes.marginRight}
        >
          {loading ? <CircularProgress size={24} /> : "Calculate"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          disabled={loading}
          onClick={onClear}
        >
          Clear
        </Button>
      </StyledRow>
    </Grid>
  );
}

export default SimilarityTab;
