import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import setup from "../utils/setup";
import Notification from "../utils/notification";
// import Auth from "../utils/auth";
import { sendCluster } from "./analytic";
import { sendSimilarity } from "./analytic";
// Inside the reducers, by default the action object return 2 things: type and payload
// If you want to custom the action payload (for example: a count) you can do as below
// let count = 0; const prepareCount = (payload) => ({...payload, count++})

const { debug, backendURL } = setup();

export const awsAuthenticationCheck = createAsyncThunk(
  "app/aws-callback",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      let response = await fetch(`${backendURL}/aws`, {
        method: "GET",
        credentials: "include", // Important: it tell fetch to include cookies in the request
      });
      if (response.status > 201) return rejectWithValue(null);
      let isAuthenticated = await response.json();
      return fulfillWithValue(isAuthenticated);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFakeAwsCookie = createAsyncThunk(
  "app/get-fake-aws-cookie",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      let response = await fetch(`${backendURL}/aws/cookie101`, {
        credentials: "include",
      });
      if (response.status > 201) return rejectWithValue(null);
      return fulfillWithValue(true);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: true,
  mode: false, // dark mode = true
  debug: debug,
  openSchemaDialog: null, // available values: chart, table
  openDeleteDatasetDialog: false,
  openImportHeadersDialog: false,
  drawer: false, // display or not the drawer,
  // notifications
  notifications: [],
  hasNotification: false,
  // auth
  // isLogged: accessToken !== "bearer null" || !!refreshToken,

  // profile: temporary solution
  profiles: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMode(state, action) {
      state.mode = action.payload;
    },
    toggleSchemaDialog(state, action) {
      switch (action.payload) {
        case "chart":
        case "table":
          state.openSchemaDialog = action.payload;
          break;
        case null:
        case undefined:
        case false:
        default:
          state.openSchemaDialog = null;
          break;
      }
    },
    toggleDeleteDatasetDialog(state, action) {
      if (action.payload !== null)
        state.openDeleteDatasetDialog = action.payload;
      else state.openDeleteDatasetDialog = !state.openDeleteDatasetDialog;
    },
    toggleImportHeadersDialog(state, action) {
      if (action.payload !== null)
        state.openImportHeadersDialog = action.payload;
      else state.openImportHeadersDialog = !state.openImportHeadersDialog;
    },

    setDrawer(state, action) {
      state.drawer = action.payload;
    },
    setAuth(state, action) {
      state.isLogged = action.payload;
    },
    addNotification(state, action) {
      state.hasNotification = true;
      state.notifications.push(action.payload);
    },
    popNotification(state, action) {
      state.hasNotification = state.notifications.length - 1 > 0;

      if (state.notifications.length <= 0) return;

      let spliced = state.notifications;
      spliced.splice(0, 1);

      state.notifications = spliced;
    },
    clearNotification(state, action) {
      state.hasNotification = false;
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(awsAuthenticationCheck.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(awsAuthenticationCheck.fulfilled, (state, action) => {
        state.loading = false;
        state.isLogged = action.payload?.isLogged || false;
      })
      .addCase(awsAuthenticationCheck.rejected, (state, action) => {
        state.notifications.push(
          new Notification("Not authenticated", "error")
        );
        state.hasNotification = true;
        state.isLogged = false;
        state.loading = false;
      })
      .addCase(sendCluster.rejected, (state, action) => {
        let newNotification;

        if (action.payload >= 500)
          newNotification = new Notification("Service Unavailable", "error");
        else if (action.payload === 401 || action.payload === 403)
          newNotification = new Notification(
            "Insufficient privileges",
            "error"
          );
        else if ("error" in action)
          newNotification = new Notification(action.error.message, "error");
        else newNotification = new Notification("Unknown error!", "error");

        state.notifications.push(newNotification);
        state.hasNotification = true;
        state.loading = false;
      })
      .addCase(sendCluster.fulfilled, (state, action) => {
        state.notifications.push(
          new Notification(
            `Cluster "${action.payload.identifier}" successfully created!`,
            "success"
          )
        );
        state.hasNotification = true;
        state.loading = false;
      })
      .addCase(sendSimilarity.rejected, (state, action) => {
        let newNotification;

        if (action.payload >= 500)
          newNotification = new Notification("Service Unavailable", "error");
        else if (action.payload === 401 || action.payload === 403)
          newNotification = new Notification(
            "Insufficient privileges",
            "error"
          );
        else if ("error" in action)
          newNotification = new Notification(action.error.message, "error");
        else newNotification = new Notification("Unknown error!", "error");

        state.notifications.push(newNotification);
        state.hasNotification = true;
        state.loading = false;
      })
      .addCase(sendSimilarity.fulfilled, (state, action) => {
        state.notifications.push(
          new Notification(
            `Similarity "${action.payload.identifier}" successfully created!`,
            "success"
          )
        );
        state.hasNotification = true;
        state.loading = false;
      });
  },
});

export const {
  setLoading,
  setMode,
  toggleSchemaDialog,
  toggleDeleteDatasetDialog,
  toggleImportHeadersDialog,
  setDrawer,
  setAuth,
  addNotification,
  popNotification,
  clearNotification,
} = appSlice.actions;

export default appSlice.reducer;
