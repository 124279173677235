import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
// import { Resizable } from "re-resizable";

// MUI components
import { Grid, makeStyles, Paper, Button, MenuItem } from "@material-ui/core";

// Lamda components
import StyledSelect from "../components/styled/Select";
import CorrHeatmapChart from "../components/Correlation/CorrHeatmapChart";
import CorrHeatmapSlider from "../components/Correlation/CorrHeatmapSlider";
import CorrScatterplotChart from "../components/Correlation/CorrScatterplot";

// Store actions
import {
  getCorrelationParamObject,
  sendCorrelation,
} from "../features/analytic";
import { addNotification } from "../features/app";
import { changeOrder } from "../features/correlation";
import {
  fetchCurrentDataset,
  fetchDatasets,
  initData as initDataFunc,
  initStructure as initStructureFunc,
} from "../features/dataset";
// import { synchronizeWithSelectedColumns } from "../features/correlation";
import Notification from "../utils/notification";

const useStyles = makeStyles((theme) => ({
  fixedHeight: {
    height: "900px",
  },
  fillHeight: {
    height: "100%",
  },
  configHeight: {
    height: "10%",
  },
  viewHeight: {
    height: "90%",
  },
  resizable: {
    paddingBottom: theme.spacing(2),
  },
  resizableBottomHandle: {
    width: "32px !important",
    height: "8px !important",
    borderRadius: "3px",
    margin: "0 auto",
    left: 0,
    right: 0,
    bottom: "3px !important",
    backgroundColor: theme.palette.primary[theme.palette.type],
  },
}));
export default function Correlation() {
  const classes = useStyles();
  const chartContainer = useRef(null);

  const { selectedColumns } = useSelector((state) => state.chart);

  const dispatch = useDispatch();

  const sortingMethods = {
    parcoord: "Parallel coord",
    seriation: "Seriation",
    initial: "Initial",
  };
  const defaultState = { sortingMethod: "parcoord" };
  const [state, setState] = useState(defaultState);

  // FIXME: duplicated code with Overview
  const { initStructure, initData, data, schema, currentDataset, datasets } =
    useSelector((state) => state.dataset);
  const [didOnce, setOnce] = useState(false);

  // use data during initialization to avoid computing correlation in use effect if not empty
  // In that case, sendCorrelation is normally called by Overview
  const dataRef = useRef(data);
  // const selectedColumnsRef = useRef(null);
  useEffect(() => {
    async function fetchStructureEffect() {
      batch(async () => {
        setOnce(true); // Must be the 1st line otherwise it will execute the following code twice.
        await dispatch(fetchDatasets()); // This must be the 1st request, there is extra code to refresh token if not valid.
        await dispatch(initStructureFunc(true));
      });
    }

    async function fetchDataEffect() {
      batch(async () => {
        await dispatch(fetchCurrentDataset());
        await dispatch(initDataFunc(true));
      });
    }
    if (!initStructure && !didOnce) {
      fetchStructureEffect();
    }
    if (initStructure && !initData) {
      fetchDataEffect();
    }
    if (initStructure && initData && datasets.length === 0) {
      dispatch(
        addNotification(
          new Notification("No datasets found for the current user!", "warning")
        )
      );
      // history.push("/data"); // done in Overview Why
    }

    // call sendCorrelation only if data changes
    if (
      dataRef.current !== data && // in deps
      data.length > 0 && // in deps
      schema.length > 0 // not in deps
    ) {
      // FIXME: remove correlation heatmap if not initData...
      // if (!initData){}

      const corrParamObj = getCorrelationParamObject(data, schema);
      corrParamObj.currentDataset = currentDataset;
      dispatch(sendCorrelation(corrParamObj));
    }

    // if(selectedColumnsRef.current !== selectedColumns){
    //   dispatch(synchronizeWithSelectedColumns(selectedColumns));
    // }

    if (dataRef.current !== data) dataRef.current = data;
    // if (selectedColumnsRef.current !== selectedColumns) selectedColumnsRef.current = selectedColumns;
    // eslint-disable-next-line
  }, [initStructure, initData, data]); // , selectedColumns

  function _changeOrderClick() {
    // dispatch(changeOrder());
    dispatch(
      changeOrder({ sortingMethod: state.sortingMethod, selectedColumns })
    );
  }
  function _onChangeSortingMethod(event) {
    setState({ sortingMethod: event.target.value });
  }
  return (
    <Grid
      container
      alignItems="stretch"
      direction="row"
      className={classes.fixedHeight}
    >
      {/* Chart */}
      {/*Add <resizable> as done in Overview*/}
      <Grid
        item
        xs={12}
        style={{ position: "relative" }}
        className={classes.configHeight}
      >
        <StyledSelect
          id="sorting-method-select"
          name="sortingMethod"
          variant="outlined"
          value={state.sortingMethod}
          onChange={_onChangeSortingMethod}
        >
          {Object.keys(sortingMethods).map((v, i) => (
            <MenuItem value={v} key={i} dense>
              {sortingMethods[v]}
            </MenuItem>
          ))}
        </StyledSelect>
        <Button size="small" variant="contained" onClick={_changeOrderClick}>
          Change order
        </Button>
      </Grid>
      <Grid
        item
        xs={7}
        // className={classes.fillHeight}
        style={{ position: "relative" }}
        className={classes.viewHeight}
      >
        <Paper
          className={classes.fillHeight}
          ref={chartContainer}
          variant="outlined"
        // style={{ backgroundColor }}
        >
          <Grid
            container
            alignItems="stretch"
            direction="row"
            className={classes.fillHeight}
          >
            <Grid
              item
              xs={10}
              className={classes.fillHeight}
              style={{ position: "relative" }}
            >
              {/*Will contain heatmap*/}
              <CorrHeatmapChart />
            </Grid>
            <Grid
              item
              xs={2}
              className={classes.fillHeight}
              style={{ position: "relative" }}
            >
              <CorrHeatmapSlider />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid
          item
          xs={5}
          // className={classes.fillHeight}
          style={{ position: "relative" }}
          className={classes.viewHeight}
      >
        <Paper
            className={classes.fillHeight}
            ref={chartContainer}
            variant="outlined"
            // style={{ backgroundColor }}
        >
          <CorrScatterplotChart />
        </Paper>
      </Grid>
    </Grid>
  );
}
