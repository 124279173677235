import Auth from "./auth";

export default function setup() {
  env();
  const proxy = window.__env__.REACT_APP_PROXY === "true";
  const backendURL = proxy ? "/api" : window.__env__.REACT_APP_BACKEND_URI;
  const debug = window.__env__.REACT_APP_DEBUG_MODE === "true";
  const uploadMaxSize = window.__env__.REACT_APP_UPLOAD_MAX_SIZE;
  const nbMaxDatasets = window.__env__.REACT_APP_NB_MAX_DATASETS;
  const authMode = window.__env__.REACT_APP_AUTHENTICATION_MODE;

  if (authMode === "none") sessionStorage.setItem("profileId", 1);

  const getAuthorization = () => {
    const auth = Auth.getAuth();
    return `Bearer ${auth.access_token}`;
  };

  // GY
  const callbackUrl = window.__env__.REACT_APP_SSO_CALLBACK_URL || "/#";
  const ID_KEY = "_key";

  return {
    proxy,
    backendURL,
    debug,
    ID_KEY,
    getAuthorization,
    callbackUrl,
    uploadMaxSize,
    nbMaxDatasets,
    authMode,
  };
}

export function env() {
  if (process.env.NODE_ENV === "development") {
    window.__env__ = process.env;
    return (window.__env__ = process.env);
  }

  if ("__env__" in window) return;
}
