import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { clearNotification, popNotification } from "../../features/app";

/**
 * Severity:
 *
 * - error
 * - warning
 * - info
 * - success
 */

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AppNotification() {
  const dispatch = useDispatch();
  const { hasNotification, notifications } = useSelector((state) => state.app);

  const onClose = (event, reason) => {
    if (event?.id === "btn-close" && reason !== "clickaway")
      return dispatch(clearNotification());
    return reason === "clickaway" ? null : dispatch(popNotification());
  };
  const onNext = (event, reason) => {
    return reason === "clickaway" ? null : dispatch(popNotification());
  };

  if (!hasNotification) return null;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={hasNotification}
      onClose={onClose}
      autoHideDuration={notifications[0]?.duration}
    >
      <Alert
        severity={notifications[0].severity}
        action={
          <React.Fragment>
            <Button size="small" color="inherit" onClick={onNext}>
              {notifications.length > 1 ? "next" : "close"}
            </Button>
            {notifications.length > 1 && (
              <Button
                size="small"
                color="inherit"
                onClick={(e) => onClose({ ...e, id: "btn-close" }, null)}
              >
                Dismiss all
              </Button>
            )}
          </React.Fragment>
        }
      >
        {notifications[0].message}
      </Alert>
    </Snackbar>
  );
}

export default AppNotification;
