import d3Tip from 'd3-tip';
const d3 = require('d3');
const simpleStatistics = require('simple-statistics');

class D3Scatterplot {
    svg=undefined;
    width=undefined;
    height=undefined;
    margin={left:40,right:10,top:40,bottom:40};
    scatterPlotLegendFontSize=12;
    titleFontSize=15;
    idAccessor=undefined;
    colorScaleValues=undefined; // [] (same length of colorScaleRange) given by by state.colorScaleProperties.values in update()
    colorScale=undefined;  // = d3.scaleLinear().range(colorRange).domain(colorScaleValues);
    colorByRowId=undefined;
    xMin=undefined;
    xMax=undefined;
    yMin=undefined;
    yMax=undefined;
    getXValue=undefined;
    getYValue=undefined;
    xScale=undefined;
    xAxisG=undefined;
    yScale=undefined;
    yAxisG=undefined;
    scatterPlotGroup=undefined;
    scatterPlotDataBinding=undefined;
    drawLinesByColor=false;
    lineByColor=undefined;
    plotSize=3.5;

    constructor(el){
        this.el=el;
    }
    create = function (config, state, controllerMethods) {
        // config.size.height and config.size.width are mandatory
        this.idAccessor=controllerMethods.idAccessor;
        if (!config.size) {
            throw new Error("config.size is missing !");
        } else if (!config.size.height || !config.size.width) {
            const errorMessage = "config.size.height or config.size.width is missing !";
            console.log(errorMessage);
            // throw errorMessage
        }
        if(config.drawLinesByColor){
            this.drawLinesByColor=config.drawLinesByColor;
        }
        if(config.plotSize){
            this.plotSize=config.plotSize;
        }
        // const size = config.size;
        this.svg = d3.select(this.el).append('svg')
            .attr('class', 'd3')
            .attr('width', config.size.width)
            .attr('height', config.size.height);

        // config.size.margin is not mandatory
        if (config.size.margin) {
            if (config.size.margin.left) {
                this.margin.left = config.size.margin.left;
            }
            if (config.size.margin.right) {
                this.margin.right = config.size.margin.right;
            }
            if (config.size.margin.top) {
                this.margin.top = config.size.margin.top;
            }
            if (config.size.margin.bottom) {
                this.margin.bottom = config.size.margin.bottom;
            }
        }
        this.width = config.size.width - this.margin.left - this.margin.right;
        this.height = config.size.height - this.margin.top - this.margin.bottom;

        this.scatterPlotGroup=this.svg.append("g")
            .attr("class","scatterPlotG")
            .attr("transform","translate("+this.margin.left+","+this.margin.top+")")
        ;


        this.svg.append("text")
            .attr('class',"TitleText noselect")
            .attr("x",this.margin.left+this.width)
            .attr("y",this.titleFontSize)
            .style("text-anchor", "end")
            .style("font-size", this.titleFontSize)
            .style("font-weight", "bold")
            .style("pointer-events","none")
            .text(config.title)
        // .attr("fill","white")
        ;
        this.svg.append("text")
            .attr('class',"xUnit noselect")
            .attr("x",this.margin.left+this.width/2)
            .attr("y",this.margin.top+this.height+ this.margin.bottom/2 + this.scatterPlotLegendFontSize)
            .style("text-anchor", "middle")
            .style("font-size", this.scatterPlotLegendFontSize)
            .style("font-weight", "bold")
            .style("pointer-events","none")
            .text(config.xUnit)
            // .attr("fill","white")
        ;
        this.svg.append("text")
            .attr('class',"yUnit noselect")
            // .attr("y",this.height/2)
            // .style("text-anchor", "middle")
            .style("font-size", this.scatterPlotLegendFontSize)
            .style("font-weight", "bold")
            .style("text-anchor", "middle")
            .attr("transform", "rotate(-90) translate("+(-this.height/2)+","+(this.scatterPlotLegendFontSize)+")")
            .style("pointer-events","none")
            .text(config.yUnit)
            // .attr("fill","white")
        ;

        this.xScale = d3.scaleLinear()
            .range([0,this.width])
        ;
        this.yScale = d3.scaleLinear()
            .range([this.height,0])
        ;
        this.getXValue=controllerMethods.getXValue;
        this.getYValue=controllerMethods.getYValue;

        if(this.drawLinesByColor){
            this.lineByColor=d3.line()
                .x((d)=>{
                    const xValue=this.getXValue(d);
                    return this.xScale(xValue);
                })
                .y((d) => {
                    const yValue=this.getYValue(d);
                    return this.yScale(yValue);
                })
            ;
        }

        this.tipVar = d3Tip()
            .attr('class', 'd3-tip scatterplot-tooltip')
            .html((d,i)=>  {
                return "<span style='text-anchor: start'>" + this.idAccessor(d) + "</span>";
                // return controllerMethods.getLabel(d)+": " + displayValue + " " + state.unit;
            })
            .direction('e')
        ;
        this.svg.call(this.tipVar);

    }; // end create
    getColor(rowObject){
        if(this.colorScaleValues){
            return this.colorScale(rowObject.type);
        }else{
            return this.colorByRowId[this.idAccessor(rowObject)];
        }
    }
    initColorScaleProperties(state){
        if (state.colorScaleProperties === undefined) {
            throw new Error("state.colorScaleProperties undefined");
        }else if(state.colorScaleProperties.toGetByRowId && state.colorScaleProperties.colorByRowId === undefined) {
            throw new Error("state.colorScaleProperties.toGetByRowId && state.colorScaleProperties.colorByRowId undefined");
        }else if(state.colorScaleProperties.toGetByRowId && state.colorScaleProperties.colorByRowId){
            this.colorScaleValues = undefined;
            this.colorByRowId = state.colorScaleProperties.colorByRowId;
        }else if(state.colorScaleProperties.values === undefined){ // => !state.colorScaleProperties.toGetByRowId
            throw new Error(" !state.colorScaleProperties.toGetByRowId && state.colorScaleProperties.values undefined");
        } else {// => !state.colorScaleProperties.toGetByRowId && state.colorScaleProperties.values !== undefined
            this.colorScaleValues = state.colorScaleProperties.values;
            if((!state.colorScaleProperties.toGetByRowId) && state.colorScaleProperties.colors) {
                this.colorScaleRange = state.colorScaleProperties.colors;
            }else if((!state.colorScaleProperties.toGetByRowId) && state.colorScaleProperties.schemeName){
                this.colorScaleRange = d3["scheme"+state.colorScaleProperties.schemeName];
            }

            this.colorScale = d3
                .scaleOrdinal()
                .range(this.colorScaleRange)
                .domain(this.colorScaleValues)
            ;

        }
    }
    update = function (config, state, controllerMethods, chart) {
        // remove all elements to rerender when data change
        // this.XYZ.selectAll("*").remove();
        this.scatterPlotGroup.selectAll("*")
            .remove()
        ;
        this.svg.selectAll(".scatterPlotLegendG").remove();
        if(!state.data||state.data.length===0){
            return
        }

        this.initColorScaleProperties(state);

        this.computeXScaleDomain(state,controllerMethods);
        this.computeYScaleDomain(state,controllerMethods);

        this.xAxisG=this.scatterPlotGroup.append('g')
            .attr("class","xAxis grid noselect")
            .attr("transform", "translate(0," + this.height + ")")
            .style("pointer-events","none")
        ;

        this.yAxisG=this.scatterPlotGroup.append('g')
            .attr("class","yAxis grid noselect")
            .style("pointer-events","none")
        ;
        this.buildXAxis();
        this.buildYAxis();

        this.buildLegend(state);

        this.scatterPlotDataBinding=(dataItem)=>{
            return this.idAccessor(dataItem)
        };

        this.scatterPlotPointsG=this.scatterPlotGroup.append('g')
            .attr('class','scatterPlotPointsG')
        ;
        this.scatterPlotPointsG.selectAll(".scatterPlotPoint")
            .data(state.data,this.scatterPlotDataBinding)
            .enter()
            .append("circle")
            .attr("class","scatterPlotPoint")
            .attr("cx",(dataItem)=>{
                return this.xScale(controllerMethods.getXValue(dataItem));
            })
            .attr("cy",(dataItem)=>{
                return this.yScale(controllerMethods.getYValue(dataItem));
            })
            .attr("r",this.plotSize)
            .on('mouseover', this.tipVar.show)
            .on('mouseout', this.tipVar.hide)
            .on('click', (d,i)=>{this._onClick(d,i,controllerMethods)})
        ;

        this.filterByThreshold(state,controllerMethods,chart); // coll fillColorOfPoints()

        this.buildLinesByColor(state,controllerMethods,chart);

        this.buildZeroLine();
        this.showZeroLine(state.showZeroLine);

        this.buildIdentityLine();
        this.updateIdentityLine();
        this.showIdentityLine(state.showIdentityLine);

        this.buildBestFitLine();
        this.updateBestFitLine(state,controllerMethods);
        this.showBestFitLine(state.showBestFitLine);

    }; // end update

    computeXScaleDomain=function(state,controllerMethods){
        this.xMin=d3.min(state.data,controllerMethods.getXValue);
        this.xMax=d3.max(state.data,controllerMethods.getXValue);
        if(state.scaleDomainExtPerc){
            const ext=(this.xMax-this.xMin)*state.scaleDomainExtPerc/100;
            this.xMin=this.xMin-ext;
            this.xMax=this.xMax+ext;
        }
        this.xScale.domain([this.xMin,this.xMax]);
    };

    computeYScaleDomain=function(state,controllerMethods){
        this.yMin=d3.min(state.data,controllerMethods.getYValue);
        this.yMax=d3.max(state.data,controllerMethods.getYValue);
        if(state.scaleDomainExtPerc){
            const ext=(this.yMax-this.yMin)*state.scaleDomainExtPerc/100;
            this.yMin=this.yMin-ext;
            this.yMax=this.yMax+ext;
        }

        this.yScale.domain([this.yMin,this.yMax]);
    };

    buildXAxis=function(){
        const xAxis = d3.axisBottom(this.xScale)
            // .ticks(5)
            .tickSize(-this.height)
        ;

        this.xAxisG.call(xAxis)
            .selectAll("text")
            .style("font-size", this.scatterPlotLegendFontSize)
        ;
    };
    buildYAxis=function(){
        const yAxis = d3.axisLeft(this.yScale)
            // .ticks(5)
            .tickSize(-this.width)
        ;
        this.yAxisG.call(yAxis)
            .selectAll("text")
            .style("font-size", this.scatterPlotLegendFontSize)
        ;
    };
    buildLegend=function(state){
        // do nothing if this.colorScaleValues does not exist || colorScaleProperties.legendLabels
        if (! this.colorScaleValues || ! state.colorScaleProperties.legendLabels)return;

        const legendItemSize={height:15,width:25};
        const scatterPlotLegendG=this.svg.append('g')
            .attr("class","scatterPlotLegendG noselect")
            .attr("transform","translate("+(this.margin.right+30)+","+(this.margin.top/2 - legendItemSize.height*this.colorScaleValues.length/2)+")")
            .style("pointer-events","none")
        ;
        // .attr('transform','translate(,)')
        const legendItemG=scatterPlotLegendG.selectAll(".legendItemG")
            .data(this.colorScaleValues)
            .enter()
            .append("g")
            .attr("class","legendItemG")
            .attr("transform",(d,i)=>{
                return "translate(0,"+legendItemSize.height*i+")";
            })
        ;
        legendItemG.append("rect")
            .attr("width",legendItemSize.width)
            .attr("height",legendItemSize.height)
            .attr("fill",(d,i)=>{
                return this.colorScale(d);
                // return state.colorScaleProperties.colors[i];
            })
        ;
        legendItemG.append("text")
            .attr("x",legendItemSize.width+5)
            .attr("y",this.scatterPlotLegendFontSize)
            .text((d,i)=>{
                return state.colorScaleProperties.legendLabels[i];
            })
            .style("font-size", this.scatterPlotLegendFontSize)
        ;
    };
    buildZeroLine=function(){
        this.scatterPlotGroup.append('g')
            .attr('class','zeroLine')
            .style('opacity',0)
            .append('line')
            .attr('x1',0)
            .attr('y1',this.yScale(0))
            .attr('x2',this.width)
            .attr('y2',this.yScale(0))
            .style('stroke',"black")
            .style('stroke-width',1)
        ;
    };
    buildIdentityLine=function(){
        this.scatterPlotGroup.append('g')
            .attr('class','identityLine')
            .style('opacity',0)
            .append('line')
            .style('stroke',"black")
            .style('stroke-width',1)
        ;
    };
    buildBestFitLine=function(){
        this.scatterPlotGroup.append('g')
            .attr('class','bestFitLine')
            .style('opacity',0)
            .append('line')
            .style('stroke',"black")
            .style('stroke-width',1)
            .style("stroke-dasharray","10 5")
        ;
    };

    selectItems = function(state){
        const defaultOpacity=0.5;
        if(state.selectedItems){
            // todo: getObjects from Ids
            const dataItems=state.selectedItems.map((d)=>{
                return state.dataById[d];
            })
            this.scatterPlotPointsG.selectAll(".scatterPlotPoint")
                .style("opacity",1)
                .data(dataItems,this.scatterPlotDataBinding)
                .raise()
                .attr("stroke-width",3)
                .attr("stroke","black")
                .style("opacity",1)
                .exit()
                .attr("stroke-width",0)
                .attr("stroke","black")
                .style("opacity",defaultOpacity)
            ;
        }
        if(state.selectedItem!==undefined && state.selectedItem!==null) {
            const dataItem = state.dataById[state.selectedItem];
            const itemSelection = this.scatterPlotPointsG.selectAll(".scatterPlotPoint")
                .data([dataItem],this.scatterPlotDataBinding)
                .style("opacity",1)
                .attr("stroke-width",3)
                .attr("stroke","red")
                .raise() // put selection to front
            ;
            if(!state.selectedItems){
                itemSelection.exit()
                    .style("opacity",defaultOpacity)
                    .attr("stroke-width",0)
                ;
            }
        }

    };
    updateColorOfPoints(state){
        this.initColorScaleProperties(state);
        this.fillColorOfPoints();
    }

    fillColorOfPoints(){
        this.scatterPlotPointsG.selectAll(".scatterPlotPoint")
            .attr("fill", (d) => {
                return this.getColor(d);
            })
        ;
    }
    filterByThreshold = function(state,controllerMethods){
        // TODO: find a way to optimize...
        this.fillColorOfPoints();
        if(state.yFilterThreshold&&state.yFilterThreshold.min!==state.yFilterThreshold.max) {
            const filteredData = state.data.filter((d) => {
                return state.highlightAccessor(d) >= state.yFilterThreshold.min && state.highlightAccessor(d) <= state.yFilterThreshold.max;
            });
            this.scatterPlotPointsG.selectAll(".scatterPlotPoint")
                .data(filteredData, this.scatterPlotDataBinding)
                .exit()
                .attr("fill", "grey")
        }
    };
    buildLinesByColor=function(state,controllerMethods){
        if(this.drawLinesByColor && this.colorScaleValues) {
            this.colorScaleValues.forEach((colorValue) => {
                const dataFilteredByColorValue = state.data.filter((dataItem) => {
                    return dataItem.type === colorValue;
                });
                this.scatterPlotPointsG
                    .append("path")
                    .datum(dataFilteredByColorValue)
                    .attr("class", "scatterPlotLine_" + colorValue)
                    .style("stroke", this.colorScale(colorValue))
                    .style("stroke-width", 2)
                    .style("fill","none")
                    .attr("d", this.lineByColor);

            });
        }
    };
    maxToUseForLines=function(){
        // take the lowest of max
        let maxToUse=this.xMax;
        if (this.yMax<this.xMax){maxToUse=this.yMax;}
        return maxToUse;
    };
    minToUseForLines=function(xMin,yMin){
        let minToUse=xMin;
        if (yMin>xMin){minToUse=yMin;}
        return minToUse;
    };
    updateIdentityLine=function(){

        // take the max of min
        const minToUse = d3.max([this.xMin,this.yMin])
        // take the min of max
        const maxToUse = d3.min([this.xMax,this.yMax]);
        const identityLineX1=this.xScale(minToUse);
        const identityLineY1=this.yScale(minToUse);
        const identityLineX2=this.xScale(maxToUse);
        const identityLineY2=this.yScale(maxToUse);
        this.scatterPlotGroup.select(".identityLine")
            .select("line")
            .attr('x1',identityLineX1)
            .attr('y1',identityLineY1)
            .attr('x2',identityLineX2)
            .attr('y2',identityLineY2)
        ;
    };
    updateBestFitLine=function(state,controllerMethods){
        const dataForLinearRegression=state.data.map((d)=>{return [controllerMethods.getXValue(d),controllerMethods.getYValue(d)]});
        const linearRegModel=simpleStatistics.linearRegression(dataForLinearRegression); // {m:,b:} slope + intersect
        // check y for x=xMin
        let x1Value=this.xMin;
        let y1Value=linearRegModel.m*this.xMin+linearRegModel.b;
        if(y1Value<this.yMin){
            x1Value=(this.yMin-linearRegModel.b)/linearRegModel.m;
            y1Value=this.yMin;
        }
        let x2Value=this.xMax;
        let y2Value=linearRegModel.m*this.xMax+linearRegModel.b;
        if(y2Value>this.yMax){
            x2Value=(this.yMax-linearRegModel.b)/linearRegModel.m;
            y2Value=this.yMax;
        }

        const lineX1=this.xScale(x1Value);
        const lineY1=this.yScale(y1Value);
        const lineX2=this.xScale(x2Value);
        const lineY2=this.yScale(y2Value);
        this.scatterPlotGroup.select(".bestFitLine")
            .select("line")
            .attr('x1',lineX1)
            .attr('y1',lineY1)
            .attr('x2',lineX2)
            .attr('y2',lineY2)
        ;


    };
    updateXYData(state, controllerMethods){
        this.computeYScaleDomain(state,controllerMethods);
        this.buildYAxis();
        this.computeXScaleDomain(state,controllerMethods);
        this.buildXAxis();

        // TODO: re-render the y axis...
        this.scatterPlotPointsG.selectAll(".scatterPlotPoint")
            .transition().duration(3000)
            .attr("cx",(d)=>{
                return this.xScale(controllerMethods.getXValue(d));
            })
            .attr("cy",(d)=>{
                return this.yScale(controllerMethods.getYValue(d));
            })
        ;

        this.updateIdentityLine();
        this.updateBestFitLine(state,controllerMethods);
    }
    showZeroLine(show){
        this.scatterPlotGroup.select(".zeroLine")
            .style('opacity',()=>{if(show)return 1; else return 0;})
    }
    showIdentityLine(show){
        this.scatterPlotGroup.select(".identityLine")
            .style('opacity',()=>{if(show)return 1; else return 0;})
    }
    showBestFitLine(show){
        this.scatterPlotGroup.select(".bestFitLine")
            .style('opacity',()=>{if(show)return 1; else return 0;})
    }
    updateTitle=function(title){
        this.svg.select(".TitleText")
            .text(title)
        ;
    };
    updateYUnit=function(yUnit){
        this.svg.select(".yUnit")
            .text(yUnit)
        ;
    };
    updateXUnit=function(xUnit){
        this.svg.select(".xUnit")
            .text(xUnit)
        ;

    };

    _onClick(d,i,controllerMethods){
        controllerMethods.setSelectedRow(d);
    }

    destroy = function (chart){
        this.svg.selectAll("*").remove();
        d3.select(".scatterplot-tooltip").remove();
        // do nothing
    };

}
export default D3Scatterplot;