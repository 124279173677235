export function replaceWrongCharacters(name) {
  // eslint-disable-next-line
  const _wrongName = /[^\w-:\.@\(\)\+,=;\$!\*'%]/g; // everything which is not a word and not characters accepted by ArrangoDB /[.*+?^${}()|[\]\\]
  let result = name;
  result = result.replace(new RegExp(_wrongName), "_");
  return result;
}

export function searchIfExistAndUpdateIdentifier(newIdentifier, columns = []) {
  let correctedNewIdentifier = replaceWrongCharacters(newIdentifier);
  let existingCol = columns.find((col) => col.name === newIdentifier);
  let incrPostFix = 1;
  let result = correctedNewIdentifier;
  while (existingCol) {
    // search with increment postfix
    let expected = `${correctedNewIdentifier}_${incrPostFix++}`;
    existingCol = columns.find((col) => col.name === expected);
    result = expected;
  }
  return result;
}
