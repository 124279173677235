import d3Tip from "d3-tip";
// import ColorHelper from "../lib/ColorHelper";
import { getContrast } from "../Colors/colors";
const d3 = require("d3");

// var colorRange = ['#C0D9CC', '#F6F6F4', '#925D60', '#B74F55', '#969943']
// var Rdy = ['#d73027','#fc8d59','#fee090','#ffffbf','#e0f3f8','#91bfdb','#4575b4']

// state.data: {columns:[String] ,indexes:[String], data:[[{value:numerical, list:[row_id]}]]}
// state.countByPair: {key(columns):{key(indexes):int}
// state.scatterplotDataForMatrix: [{valueX:number,valueY:number}]
// state.selectedFeaturePairInMatrix: {xName:String,yName:String} used just in scatterplot!
// state.chosenFeatures: // [String] list of features to highlight on the margin (selected by click)
// state.filterThr: {min:float, max:float}
// config.colorScaleProperties: {values:[],colors:[],schemeName:String,legendLabels:[],gradient:boolean} array (length max 2) with diff categorical values to compute 2 histograms
// state.rawDataCount: int // total number of rows in row data

class D3HeatMapMatrix {
  defaultTitleFontSize = 20;
  defaultMargin = {
    top: this.defaultTitleFontSize,
    right: this.defaultTitleFontSize,
    bottom: 150,
    left: 150,
  };
  margin = Object.assign({}, this.defaultMargin);
  size;
  height;
  width;
  matSvg;
  colorScale; // = d3.scaleLinear().range(colorRange).domain([1, 2, 3, 4, 5]);
  xScale;
  yScale;
  tipVar;
  labelFontSizeMin = 10;
  labelFontSizeMax = 15;
  hideLabelsX = false;
  hideLabelsY = false;
  scatterPlotContainerSize = { width: 200, height: 200 };
  scatterPlotMargin;
  colorScaleProperties; // contains color scheme, gradient or colors (list of colors)
  applySizeEncoding = true;
  showLabelsInCells = false;
  showTooltip = true;
  matrixDimensionLegendFontSize = 15;
  titleFontSize = this.defaultTitleFontSize;
  static ruleOpacity = 0.2;
  static chosenOpacity = 0.5;

  transformedData={};

  constructor(el) {
    this.el = el;
  }

  // view initialization method
  create = function (el, config, state, controllerMethods) {
    this.size = { width: config.size.width, height: config.size.height };
    this.margin = Object.assign({}, this.defaultMargin);
    this.titleFontSize = this.defaultTitleFontSize;
    // margin = {top: 20, right: 20, bottom: 150, left: 150};
    if (this.size.width > this.size.height) {
      this.size.width = this.size.height;
    } else {
      this.size.height = this.size.width;
    }
    // adapt the margin if width is <200
    this.margin.left = this.size.width * 0.25;
    this.margin.bottom = this.margin.left;
    if (this.size.width - this.margin.left - this.margin.right < 170) {
      this.titleFontSize = 14;
      this.margin.top = this.titleFontSize;
      this.margin.right = this.titleFontSize;
    }
    this.width = this.size.width - this.margin.left - this.margin.right;
    this.height = this.size.height - this.margin.top - this.margin.bottom;

    this.colorScaleProperties = config.colorScaleProperties;

    // this.colorScale = d3
    //     .scaleLinear()
    // .range(["#e31a1c", "#ffffff", "#08519c"]);// red (-1) -> white (0) -> blue (+1)
    if (this.colorScaleProperties.gradient) {
      this.colorScale = d3.scaleLinear();
      if (this.colorScaleProperties.schemeName) {
        // put range between 0,1 for handling d3 colorInterpolation using schemeName
        this.colorScale.range([0, 1]);
      } else {
        this.colorScale.range(this.colorScaleProperties.colors); // use colors given in parameter
      }
    } else {
      // ! gradient
      if (this.colorScaleProperties.schemeName) {
        // init list of colors with d3 color scheme
        this.colorScaleProperties.colors =
          d3["scheme" + this.colorScaleProperties.schemeName];
      }
      // else{ by default this.colorScaleProperties.colors contain those given in parameter}

      this.colorScale = d3
        .scaleOrdinal()
        // .range(["#e31a1c", "#ffffff", "#08519c"]);// red (-1) -> white (0) -> blue (+1)
        .range(this.colorScaleProperties.colors); // red (-1) -> white (0) -> blue (+1)
    }

    this.xScale = d3.scaleLinear().range([0, this.width]);

    this.yScale = d3.scaleLinear().range([0, this.height]);

    if (this.showTooltip) {
      this.tipVar = d3Tip()
        .attr("class", "d3-tip heatmap-matrix-tooltip")
        // .style("background-color","black")
        // .style("color","white")
        // .style("opacity",0.7)
        .offset([-10, 0])
        .html((d) => {
          const coverage = Math.round((10000 * d.count) / d.rawDataCount) / 100;
          return (
            "<div class='corrMatrixTooltipHead' style:'pointer-events:none'>" +
            "<strong>Correlation: " +
            Math.round(d.value * 100) / 100 +
            "</strong>" + //<br>("+d.yName.name+" , "+d.xName.name+")" +
            "<strong style='float: right'>Cov. " +
            coverage +
            "%</strong>" +
            "</div>" +
            "<div style:'pointer-events:none clear:both'>" +
            "<svg class='scatterplotSVG' width='200px' height='200px' style:'pointer-events:none'></svg>" +
            "</div>"
          );
        });
    } else {
      this.tipVar = d3Tip()
        .attr("class", "heatmap-matrix-nothing")
        .offset([-10, 0])
        .html((d) => {
          // const coverage = Math.round(10000 * d.count / d.rawDataCount) / 100
          return "<div class='corrMatrixTooltipNothing' style:'pointer-events:none'/>";
        });
    }

    this.svg = d3
      .select(this.el)
      .append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);
    this.matSvg = this.svg
      .append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr("class", "matSvgG")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    this.matSvg.call(this.tipVar);

    this.svg
      .append("text")
      .attr("class", "TitleText noselect")
      .attr("x", this.margin.left + this.width / 2)
      .attr("y", this.titleFontSize)
      .style("text-anchor", "middle")
      .style("font-size", this.titleFontSize)
      .style("font-weight", "bold")
      .style("pointer-events", "none")
      .text(config.title);
    // .attr("fill","white")
    this.svg
      .append("text")
      .attr("class", "xUnit noselect")
      .attr("x", this.margin.left + this.width / 2)
      .attr(
        "y",
        this.margin.top +
          this.height +
          this.margin.bottom -
          this.matrixDimensionLegendFontSize
      )
      .style("text-anchor", "middle")
      .style("font-size", this.matrixDimensionLegendFontSize)
      .style("font-weight", "bold")
      .style("pointer-events", "none")
      .text(config.xUnit);
    // .attr("fill","white")
    this.svg
      .append("text")
      .attr("class", "yUnit noselect")
      // .attr("y",this.height/2)
      // .style("text-anchor", "middle")
      .style("font-size", this.matrixDimensionLegendFontSize)
      .style("font-weight", "bold")
      .style("text-anchor", "middle")
      .attr(
        "transform",
        "rotate(-90) translate(" +
          -this.height / 2 +
          "," +
          this.matrixDimensionLegendFontSize +
          ")"
      )
      .style("pointer-events", "none")
      .text(config.yUnit);
    // .attr("fill","white")
  };

  showFeatureRectAndLabels = function (ruleIds, testIfRule) {
    this.matSvg.selectAll(".featureRectX").style("opacity", (d1) => {
      return this.getFeaturesOpacity(d1, testIfRule, ruleIds);
    });
    this.matSvg.selectAll(".featureRectY").style("opacity", (d1) => {
      return this.getFeaturesOpacity(d1, testIfRule, ruleIds);
    });
    if (this.hideLabelsX) {
      this.matSvg.selectAll(".xMatrLabel").style("opacity", (d1) => {
        const foundIds = ruleIds.filter((x) => x === d1.name);
        if (foundIds.length > 0) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    if (this.hideLabelsY) {
      this.matSvg.selectAll(".yMatrLabel").style("opacity", (d1) => {
        const foundIds = ruleIds.filter((x) => x === d1.name);
        if (foundIds.length > 0) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  };

  getFeaturesOpacity = (d, testIfRule, ruleIds) => {
    if (d.chosen) {
      return D3HeatMapMatrix.chosenOpacity;
    } else if (testIfRule) {
      if (ruleIds !== undefined && ruleIds.indexOf(d.id) >= 0) {
        return D3HeatMapMatrix.ruleOpacity;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  hideFeatureRectIfNotChosen = function (d) {
    this.matSvg.selectAll(".featureRectX").style("opacity", (d) => {
      return this.getFeaturesOpacity(d);
    });
    this.matSvg.selectAll(".featureRectY").style("opacity", (d) => {
      return this.getFeaturesOpacity(d);
    });
    if (this.hideLabelsX) {
      this.matSvg.selectAll(".xMatrLabel").style("opacity", 0);
    }
    if (this.hideLabelsY) {
      this.matSvg.selectAll(".yMatrLabel").style("opacity", 0);
    }
  };

  // view update methods
  update = function (el, config, state, controllerMethods, chart) {
    if (!state.data) return;
    const data = state.data;
    const chosenFeatures = state.chosenFeatures;

    this.matSvg.selectAll("*").remove();

    let filterThr = { min: state.filterThr.min, max: state.filterThr.max };

    this.transformedData = this.transformData(
      data,
      chosenFeatures,
      state.countByPair,
      state.rawDataCount
    );
    var newData = this.transformedData.data;
    var xNames = this.transformedData.xNames;
    var yNames = this.transformedData.yNames;

    this.xScale.domain([0, xNames.length]);
    this.yScale.domain([0, yNames.length]);

    if (this.colorScaleProperties.values) {
      this.colorScale.domain(this.colorScaleProperties.values);
    } else {
      const minData = d3.min(newData, (d) => {
        return d.value;
      });
      const maxData = d3.max(newData, (d) => {
        return d.value;
      });
      this.colorScale.domain([minData, maxData]);
    }

    var xGroup = this.matSvg
      .append("g")
      .attr("class", "xGroup")
      .attr("transform", "translate(0," + this.height + ")");
    var yGroup = this.matSvg.append("g").attr("class", "yGroup");
    this.hideLabelsX = false;
    var cellWidth = this.width / yNames.length;
    var labelFontSizeX = this.labelFontSizeMin;
    if (
      cellWidth - 2 >= this.labelFontSizeMin &&
      cellWidth - 2 <= this.labelFontSizeMax
    ) {
      labelFontSizeX = cellWidth - 2;
    } else if (cellWidth - 2 > this.labelFontSizeMax) {
      labelFontSizeX = this.labelFontSizeMax;
    } else {
      labelFontSizeX = this.labelFontSizeMin;
      if (cellWidth < labelFontSizeX - 2) {
        // overlap situation
        this.hideLabelsX = true;
      }
    }
    this.hideLabelsY = false;
    var cellHeight = this.height / xNames.length;
    var labelFontSizeY = this.labelFontSizeMin;
    if (
      cellHeight - 2 >= this.labelFontSizeMin &&
      cellHeight - 2 <= this.labelFontSizeMax
    ) {
      labelFontSizeY = cellHeight - 2;
    } else if (cellHeight - 2 > this.labelFontSizeMax) {
      labelFontSizeY = this.labelFontSizeMax;
    } else {
      labelFontSizeY = this.labelFontSizeMin;
      if (cellWidth < labelFontSizeY - 2) {
        // overlap situation
        this.hideLabelsY = true;
      }
    }
    const updateRules = (d) => {
      this.matSvg
        .select(".vertMatrixRule")
        .attr("x", () => {
          return this.xScale(d.xIndex);
        })
        .style("opacity", D3HeatMapMatrix.ruleOpacity);
      this.matSvg
        .select(".horizMatrixRule")
        .attr("y", () => {
          return this.yScale(d.yIndex);
        })
        .style("opacity", D3HeatMapMatrix.ruleOpacity);
      this.matSvg
        .select(".vertMatrixRuleSym")
        .attr("x", () => {
          return this.xScale(d.yIndex);
        })
        .style("opacity", D3HeatMapMatrix.ruleOpacity);
      this.matSvg
        .select(".horizMatrixRuleSym")
        .attr("y", () => {
          return this.yScale(d.xIndex);
        })
        .style("opacity", D3HeatMapMatrix.ruleOpacity);
    };
    const hideRules = () => {
      this.matSvg.select(".vertMatrixRule").style("opacity", 0);
      this.matSvg.select(".horizMatrixRule").style("opacity", 0);
      this.matSvg.select(".vertMatrixRuleSym").style("opacity", 0);
      this.matSvg.select(".horizMatrixRuleSym").style("opacity", 0);
    };

    var xNameG = xGroup
      .selectAll(".xName")
      .data(xNames, this.dataBinding)
      .enter()
      .append("g")
      .attr("class", "xName")
      .attr("transform", (d) => {
        return "translate(" + this.xScale(d.pos) + ",0)";
      })
      .on("mouseenter", (d) => {
        updateRules({ xIndex: d.pos, yIndex: d.pos });
        this.showFeatureRectAndLabels([d.id], true);
      })
      .on("mouseout", (d) => {
        hideRules();
        this.hideFeatureRectIfNotChosen(d);
      })
      .on("click", (d) => {
        controllerMethods.updateChosenFeature(d);
      });
    xNameG
      .append("rect")
      .attr("class", "featureRectX")
      .attr("x", 1)
      .attr("width", cellWidth - 2)
      .attr("height", this.margin.bottom - 1)
      .attr("fill", "orange")
      .attr("opacity", (d) => {
        return this.getFeaturesOpacity(d);
      });
    const xBarChartScale = this._getBarChartScale(state, "X");
    xNameG
      .append("rect")
      .attr("class", "xBarChart")
      .attr("x", 1)
      // .attr("y",this.margin.bottom/2)
      .attr("width", cellWidth - 2)
      .attr("height", (d) => {
        let height = 0; //this.margin.bottom/2 - 1;
        if (state.vectorBarchartDataToShow) {
          const vectorSum = this._sumXVectorBarChart(
            state,
            d.name,
            xBarChartScale
          );
          height = xBarChartScale(vectorSum);
        }
        return height;
      })
      .attr("fill", (d) => {
        return this._getXBarColor(state, d.name);
      });
    xNameG
      .append("text")
      .attr("class", "xMatrLabel")
      .attr("transform", "rotate(-90)")
      .attr("x", -5)
      .attr("y", cellWidth - 2 - (cellWidth - labelFontSizeX) / 2)
      .attr("font-size", labelFontSizeX)
      .style("text-anchor", "end")
      .text((d) => {
        return this.getColumnLabel(state,d.name);
      })
      .style("pointer-events", "none")
      .attr("opacity", () => {
        if (this.hideLabelsX) {
          return 0;
        } else {
          return 1;
        }
      });
    function wrapLabel(text, width) {
      text.each(function () {
        var text = d3.select(this);
        // var text=this;
        let textLength = text.node().getComputedTextLength();
        let first = true;
        while (textLength > width) {
          var chars = text.text().split("");
          //     char,
          //     line = []
          //     // lineNumber = 0,
          //     // lineHeight = 1.1, // ems
          //     // y = text.attr("y"),
          //     // dy = parseFloat(text.attr("dy")),
          //     // tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em")
          if (!first) {
            chars.pop();
            chars.pop();
            chars.pop();
          }
          chars.pop();
          text.text(chars.join("") + "...");
          textLength = text.node().getComputedTextLength();
          first = false;
        }
      });
    }

    xGroup.selectAll("text").call(wrapLabel, this.margin.bottom - 4);

    var yNameG = yGroup
      .selectAll(".yName")
      .data(yNames, this.dataBinding)
      .enter()
      .append("g")
      .attr("class", "yName")
      .attr("transform", (d) => {
        return "translate(0," + this.yScale(d.pos) + ")";
      })
      .on("mouseenter", (d) => {
        updateRules({ xIndex: d.pos, yIndex: d.pos });
        this.showFeatureRectAndLabels([d.id], true);
      })
      .on("mouseout", (d) => {
        hideRules();
        this.hideFeatureRectIfNotChosen(d);
      })
      .on("click", (d) => {
        controllerMethods.updateChosenFeature(d);
      });
    yNameG
      .append("rect")
      .attr("class", "featureRectY")
      .attr("x", -this.margin.left)
      .attr("y", 1)
      .attr("width", this.margin.left)
      .attr("height", cellHeight - 2)
      .attr("fill", "orange")
      .attr("opacity", (d) => {
        return this.getFeaturesOpacity(d);
      });
    const yBarChartScale = this._getBarChartScale(state, "Y");
    yNameG
      .append("rect")
      .attr("class", "yBarChart")
      .attr("y", 1)
      // .attr("y",this.margin.bottom/2)
      .attr("height", cellHeight - 2)
      .attr("width", (d) => {
        let width = 0; //this.margin.bottom/2 - 1;
        if (state.vectorBarchartDataToShow) {
          const vectorSum = this._sumYVectorBarChart(state, d.name);
          width = yBarChartScale(vectorSum);
        }
        return width;
      })
      .attr("fill", "steelblue");

    yNameG
      .append("text")
      .attr("class", "yMatrLabel")
      .attr("x", -5)
      .attr("y", cellHeight - 2 - (cellHeight - labelFontSizeY) / 2)
      .attr("font-size", labelFontSizeY)
      .style("text-anchor", "end")
      .text((d) =>{
        const label = this.getColumnLabel(state,d.name);
        return label;
      })
      .style("pointer-events", "none")
      .attr("opacity", () => {
        if (this.hideLabelsY) {
          return 0;
        } else {
          return 1;
        }
      });
    yGroup.selectAll("text").call(wrapLabel, this.margin.left - 4);

    // d3CorrMatrix.highlightChosenFeatureNames(xNames,yNames);
    this.matSvg
      .append("line")
      .attr("x2", this.width)
      .attr("y2", this.height)
      .style("stroke", "Gainsboro")
      .style("stroke-width", 1)
      .style("pointer-events", "none");
    const cellg = this.matSvg
      .selectAll(".cell")
      .data(newData, this.dataBinding)
      .enter()
      .append("g")
      .attr("class", "cell")
      .attr("transform", (d) => {
        const xTranslation = this.xScale(d.xIndex);
        const yTranslation = this.yScale(d.yIndex);
        return "translate(" + xTranslation + "," + yTranslation + ")";
      })
      .on("mouseenter", (d) => {
        let offset = [-10, 0]; // default scatterplot on top of the mouse and on the middle
        if (this.yScale(d.yIndex) < this.scatterPlotContainerSize.height) {
          offset[0] =
            this.scatterPlotContainerSize.height - this.yScale(d.yIndex);
          if (
            this.xScale(d.xIndex) <
            (this.size.width - this.margin.left - this.margin.right) / 2
          ) {
            offset[1] = this.scatterPlotContainerSize.width / 2 + 20;
          } else {
            offset[1] = -this.scatterPlotContainerSize.width / 2 - 20;
          }
        } else {
          if (this.xScale(d.xIndex) < this.scatterPlotContainerSize.width / 2) {
            offset[1] =
              this.scatterPlotContainerSize.width / 2 - this.xScale(d.xIndex);
          }
          if (
            this.xScale(d.xIndex) >
            this.size.width -
              this.margin.left -
              this.margin.right -
              this.scatterPlotContainerSize.width / 2
          ) {
            offset[1] =
              this.size.width -
              this.margin.left -
              this.margin.right -
              this.scatterPlotContainerSize.width / 2 -
              this.xScale(d.xIndex);
          }
        }
        let featuresInMatrix = { xName: d.xName.name, yName: d.yName.name };
        this.tipVar.offset(offset);
        controllerMethods.setSelectedFeaturePairInMatrix(featuresInMatrix);
        updateRules(d);
        this.showFeatureRectAndLabels([d.xName.id, d.yName.id], true);
      })
      .on("mouseover", this.tipVar.show)
      .on("mouseleave", this.tipVar.hide)
      .on("mouseout", (d) => {
        hideRules();
        this.hideFeatureRectIfNotChosen(d.xName);
        this.hideFeatureRectIfNotChosen(d.yName);
      })
      .on("click", (d) => {
        controllerMethods.clickedCell(d);
      });
    cellg // this rect is used to capture events in the group
        .append("rect")
        .attr("width", cellWidth)
        .attr("height", cellHeight)
        .style("opacity",0)
    ;
    cellg
      .append("rect")
      .attr("class", "cellRect")
      .attr("x", (d) => {
        const sizeRate = this.getSizeRate(d);
        const rectWidth = cellWidth * sizeRate;
        const shift = (cellWidth - rectWidth) / 2;
        if (Number.isNaN(shift)) {
          console.log("shift===" + shift + "for " + d.xName + "," + d.yName);
        }
        return shift;
      })
      .attr("width", (d) => {
        const sizeRate = this.getSizeRate(d);
        const rectWidth = cellWidth * sizeRate;

        return rectWidth;
      })
      .attr("y", (d) => {
        const sizeRate = this.getSizeRate(d);
        const rectHeight = cellHeight * sizeRate;
        const shift = (cellHeight - rectHeight) / 2;
        return shift;
      })
      .attr("height", (d) => {
        const sizeRate = this.getSizeRate(d);
        const rectHeight = cellHeight * sizeRate;
        return rectHeight;
      })
      .style("fill", (d) => {
        return this.getColorForCell(d, filterThr);
      })
      .style("opacity", (d) => {
        return this.getOpacity(d, filterThr);
      })
      .style("pointer-events", "none");
    cellg // boolean to enable cell labels in opacity
      .append("text")
      .attr("class", "cellLabel noselect")
      .attr("x", cellWidth / 2)
      .attr("y", (d) => {
        return cellHeight / 2 + this.getFontSizeForCell(d) / 2;
      })
      .text((d) => {
        return d.value;
      })
      .style("font-size", (d) => {
        return this.getFontSizeForCell(d);
      })
      .attr("fill", (d) => {
        const color = this.getColorForCell(d, filterThr);
        const fontColor = getContrast(color);
        return fontColor;
      })
      .style("text-anchor", "middle")
      .style("opacity", (d) => {
        if (this.showLabelsInCells && d.value > 0) {
          return 1;
        } else {
          return 0;
        }
      })
      .style("pointer-events", "none")
    ;

    cellg
        .append("rect")
        .attr("class","cellAreaRect")
        .attr("width", cellWidth)
        .attr("height", cellHeight)
        .attr("fill", "none")
        .attr("stroke","black")
        .attr("stroke-width",2)
        .style("opacity", 0)
        .style("pointer-events", "none")
    ;

    // rules
    this.matSvg
      .append("rect")
      .attr("class", "vertMatrixRule")
      .attr("width", cellWidth)
      .attr("height", this.height)
      .attr("fill", "orange")
      .style("opacity", 0)
      .style("pointer-events", "none");
    this.matSvg
      .append("rect")
      .attr("class", "horizMatrixRule")
      .attr("width", this.width)
      .attr("height", cellHeight)
      .attr("fill", "orange")
      .style("opacity", 0)
      .style("pointer-events", "none");
    this.matSvg
      .append("rect")
      .attr("class", "vertMatrixRuleSym")
      .attr("width", cellWidth)
      .attr("height", this.height)
      .attr("fill", "orange")
      .style("opacity", 0)
      .style("pointer-events", "none");
    this.matSvg
      .append("rect")
      .attr("class", "horizMatrixRuleSym")
      .attr("width", this.width)
      .attr("height", cellHeight)
      .attr("fill", "orange")
      .style("opacity", 0)
      .style("pointer-events", "none");

    this.updateHighlightedCells(state.highlightedCellsInMatrix);
  }; // end update()

  getColorForCell = function (d, filterThr) {
    if (
      this.colorScaleProperties.gradient &&
      this.colorScaleProperties.schemeName
    ) {
      return d3["interpolate" + this.colorScaleProperties.schemeName](
        this.colorScale([d.value])
      );
    } else {
      return this.colorScale([d.value]);
    }
  };
  getFontSizeForCell = function (d) {
    return 12;
  };
  getOpacity = function (d, filterThr) {
    if (
      Math.abs(d.value) < filterThr.min ||
      Math.abs(d.value) > filterThr.max
    ) {
      return 0.0;
    } else {
      return 1.0;
    }
  };
  getSizeRate = function (d) {
    if (this.applySizeEncoding) {
      let count = d.count;
      if (count === undefined) {
        count = 0;
      }
      return Math.sqrt(count / d.rawDataCount);
    } else {
      return 1;
    }
  };
  filterCellsByThr = function (newThr) {
    this.matSvg
      .selectAll(".cellRect")
      .style("fill", (d) => {
        return this.getColorForCell(d, newThr);
      })
      .style("opacity", (d) => {
        return this.getOpacity(d, newThr);
      });
  };

  highlightChosenFeatureNames = function (xNames, yNames) {
    this.matSvg
      .selectAll(".xName")
      .data(xNames, this.dataBinding)
      .select(".featureRectX")
      .style("opacity", (d) => {
        if (d.chosen) {
          return D3HeatMapMatrix.chosenOpacity;
        } else {
          return 0;
        }
      });
    this.matSvg
      .selectAll(".featureRectY")
      .data(yNames, this.dataBinding)
      .style("opacity", (d) => {
        if (d.chosen) {
          return D3HeatMapMatrix.chosenOpacity;
        } else {
          return 0;
        }
      });
  };
  updateChosenFeatures = function (el, state) {
    this.matSvg.selectAll(".featureRectX").style("opacity", 0);
    this.transformedData = this.transformData(
      state.data,
      state.chosenFeatures,
      state.countByPair,
      state.rawDataCount
    );
    // var newData=transformedData.data;
    var xNames = this.transformedData.xNames;
    var yNames = this.transformedData.yNames;

    // <<<<<<< HEAD
    this.highlightChosenFeatureNames(xNames, yNames);
  };

  updateHighlightedCells = function(highlightedCellsInMatrix){
    // get the highlighted data by Id
    const newData = highlightedCellsInMatrix.map(d=>{
      const id= this.buildId(d.xName,d.yName);
      return this.transformedData.dataById[id];
    });
    this.matSvg
        .selectAll(".cellAreaRect")
        .data(newData, this.dataBinding)
        .style("opacity",1)
        .exit()
        .style("opacity",0)
    ;
  }

  getColumnLabel=(state,name)=>{
    if(state.displayedNames){
      return state.displayedNames[name];
    }else{
      return name;
    }
  }

  updateDisplayedLabels=(state)=>{
    this.matSvg.selectAll(".xMatrLabel").text(d=>{
      return this.getColumnLabel(state,d.name);
    })
    this.matSvg.selectAll(".yMatrLabel").text(d=>{
      return this.getColumnLabel(state,d.name);
    })
  }

  updateScatterPlot = function (el, state, controller) {
    this.scatterPlotContainerSize = { width: 200, height: 200 };

    this.scatterPlotMargin = { top: 20, right: 20, bottom: 20, left: 20 };
    if (
      this.scatterPlotContainerSize.width > this.scatterPlotContainerSize.height
    ) {
      this.scatterPlotContainerSize.width =
        this.scatterPlotContainerSize.height;
    } else {
      this.scatterPlotContainerSize.height =
        this.scatterPlotContainerSize.width;
    }
    var scatterplotWidth =
      this.scatterPlotContainerSize.width -
      this.scatterPlotMargin.left -
      this.scatterPlotMargin.right;
    var scatterplotHeight =
      this.scatterPlotContainerSize.height -
      this.scatterPlotMargin.top -
      this.scatterPlotMargin.bottom;
    const scatterPlotSvg = d3
      .select(".scatterplotSVG")
      .attr("width", this.scatterPlotContainerSize.width)
      .attr("height", this.scatterPlotContainerSize.height);
    scatterPlotSvg.selectAll("*").remove();
    var scatterPlotGroup = scatterPlotSvg
      .append("g")
      .attr("class", "scatterPlotG")
      .attr(
        "transform",
        "translate(" +
          this.scatterPlotMargin.left +
          "," +
          this.scatterPlotMargin.top +
          ")"
      );
    if (!state.selectedFeaturePairInMatrix || !state.scatterPlotDataForPair) {
      return;
    }
    // =======
    var scatterPlotLegendFontSize = 10;
    let rightOrder = true;
    // connect matrix dimensions to scatterplot dimensions
    const featurePairForScatterplot = controller.connectDimensionsCorrMatrixToScatterplot(state.selectedFeaturePairInMatrix);
    if(!rightOrder){
      const xNameOld=featurePairForScatterplot.xName;
      featurePairForScatterplot.xName=this.getColumnLabel(state,featurePairForScatterplot.yName);
      featurePairForScatterplot.yName=this.getColumnLabel(state,xNameOld);
    }
    // put the name of the x axis in scatterplot
    scatterPlotSvg
      .append("text")
      .attr(
        "x",
        scatterplotWidth +
          this.scatterPlotMargin.left +
          this.scatterPlotMargin.right
      )
      .attr(
        "y",
        scatterplotHeight +
          this.scatterPlotMargin.top +
          this.scatterPlotMargin.bottom
      )
      .style("text-anchor", "end")
      .text(this.getColumnLabel(state.selectedFeaturePairInMatrix.xName)) // scat branch (before develop merge): featurePairForScatterplot.xName
      .attr("fill", "white");

    // put the name of the y axis in scatterplot
    scatterPlotSvg
      .append("text")
      .attr("y", scatterPlotLegendFontSize)
      .text(this.getColumnLabel(state.selectedFeaturePairInMatrix.yName)) // scat branch (before develop merge): featurePairForScatterplot.yName
      .attr("fill", "white")
    ;

    let scatterplotDataForMatrix = state.scatterPlotDataForPair;

    if (!scatterplotDataForMatrix) {
      return;
    }
    let xMin = Number.POSITIVE_INFINITY,
      xMax = Number.NEGATIVE_INFINITY,
      yMin = Number.POSITIVE_INFINITY,
      yMax = Number.NEGATIVE_INFINITY;
    const getXValue = (dataItem, rightOrder) => {
      // more intuitive to have I values (rows of matrix in x axis)
      if (rightOrder) {
        return dataItem.valueX; // was I in develop before merge to scat
      } else {
        return dataItem.valueY; // was J in develop before merge to scat
      }
    };
    const getYValue = (dataItem, rightOrder) => {
      // more intuitive to have J values (columns of matrix in x axis)
      if (rightOrder) {
        return dataItem.valueY; // was J in develop before merge to scat
      } else {
        return dataItem.valueX; // was I in develop before merge to scat
      }
    };
    scatterplotDataForMatrix.forEach((dataItem) => {
      let xVal = getXValue(dataItem, rightOrder);
      if (xVal <= xMin) {
        xMin = xVal;
      }
      if (xVal >= xMax) {
        xMax = xVal;
      }
      let yVal = getYValue(dataItem, rightOrder);
      if (yVal <= yMin) {
        yMin = yVal;
      }
      if (yVal >= yMax) {
        yMax = yVal;
      }
    });
    var scatterPlotXScale = d3
      .scaleLinear()
      .range([0, scatterplotWidth])
      .domain([xMin, xMax]);
    // >>>>>>> heatmap

    var scatterPlotYScale = d3
      .scaleLinear()
      .range([scatterplotHeight, 0])
      .domain([yMin, yMax]);
    const scatterPlotDataBinding = (dataItem) => {
      return dataItem.objPos;
    };
    scatterPlotGroup
      .selectAll(".scatterPlotPoint")
      .data(scatterplotDataForMatrix, scatterPlotDataBinding)
      .enter()
      .append("circle")
      .attr("class", "scatterPlotPoint")
      .attr("cx", (dataItem) => {
        return scatterPlotXScale(getXValue(dataItem, rightOrder));
      })
      .attr("cy", (dataItem) => {
        return scatterPlotYScale(getYValue(dataItem, rightOrder));
      })
      .attr("r", 1)
      .attr("fill", "white");
  };
  highlightedSelectedFeature = function (el, selectedFeaturePairInMatrix) {
    console.log(
      "selectedFeaturePairInMatrix changed: " + selectedFeaturePairInMatrix
    );
  };
  buildId(xName, yName){
    return yName + "|" + xName // row i | column j
  }
  dataBinding = function (d) {
    return d.id;
  };
  transformData = function (data, chosenFeatures, countByPair, rawDataCount) {
    var xNames = data.columns.map((d, i) => {
      let chosen = chosenFeatures.indexOf(d) >= 0;
      return { id: d, name: d, pos: i, chosen: chosen };
    });
    var yNames = data.index.map((d, i) => {
      let chosen = chosenFeatures.indexOf(d) >= 0;
      return { id: d, name: d, pos: i, chosen: chosen };
    });
    const newData = [];
    const newDataById = {};
    data.data.forEach((di, i) => {
      di.forEach((dj, j) => {
        if (dj === undefined) {
          console.log(dj);
        }
        let count = dj.value;
        if (countByPair) {
          let firstEntry = yNames[i].name;
          let secondEntry = xNames[j].name;
          if (
            !countByPair[firstEntry] ||
            !countByPair[firstEntry][secondEntry]
          ) {
            firstEntry = xNames[j].name;
            secondEntry = yNames[i].name;
          }
          count = countByPair[firstEntry][secondEntry];
        }
        const id=this.buildId(xNames[j].id,yNames[i].id);
        const newItem={
          id: id,
          yIndex: yNames[i].pos,
          xIndex: xNames[j].pos,
          yName: yNames[i],
          xName: xNames[j],
          value: dj.value,
          items: dj.list,
          count: count,
          rawDataCount: rawDataCount,
        }
        newData.push(newItem);
        newDataById[id] = newItem;
      });
    });
    return { data: newData, dataById:newDataById, xNames: xNames, yNames: yNames };
  };
  animatedPermutation = function (el, state) {
    var transitionDuration = 3000;
    setTimeout(() => {
      this.animation(el, state, transitionDuration);
    }, 300);
  };
  opacity = function () {
    this.matSvg.selectAll(".cellRect").style("opacity", 0.5);
  };
  opacityRemove = function () {
    this.matSvg.selectAll(".cellRect").style("opacity", 1);
  };
  animation = function (el, state, transitionDuration) {
    const newMatrix = state.data;
    const chosenFeatures = state.chosenFeatures;
    this.transformedData = this.transformData(
      newMatrix,
      chosenFeatures,
      state.countByPair,
      state.rawDataCount
    );
    var newData = this.transformedData.data;
    var xNames = this.transformedData.xNames;
    var yNames = this.transformedData.yNames;

    this.matSvg
      .selectAll(".xName")
      .data(xNames, this.dataBinding)
      .transition()
      .duration(transitionDuration)
      .attr("transform", (d) => {
        return "translate(" + this.xScale(d.pos) + ",0)";
      });
    this.matSvg
      .selectAll(".yName")
      .data(yNames, this.dataBinding)
      .transition()
      .duration(transitionDuration)
      .attr("transform", (d) => {
        return "translate(0," + this.yScale(d.pos) + ")";
      });
    this.matSvg
      .selectAll(".cell")
      .data(newData, this.dataBinding)
      .transition()
      .duration(transitionDuration)
      .attr("transform", (d) => {
        const xTranslate = this.xScale(d.xIndex);
        const yTranslate = this.yScale(d.yIndex);
        return "translate(" + xTranslate + "," + yTranslate + ")";
      });
  };
  _getBarChartScale(state, dimension) {
    let sum = 0;
    if (state.vectorBarchartDataToShow) {
      const vectors = state.vectorBarchartDataToShow.vectors;
      Object.keys(vectors).forEach((key) => {
        Object.keys(vectors[key]).forEach((key2) => {
          sum = sum + vectors[key][key2];
        });
      });
    }
    const scale = d3.scaleLinear().domain([0, sum]);
    if (dimension === "X") {
      scale.range([
        0,
        this.margin.bottom - 2 * this.matrixDimensionLegendFontSize,
      ]);
    } else if (dimension === "Y") {
      scale.range([0, this.margin.left - this.matrixDimensionLegendFontSize]);
    }

    return scale;
  }
  _sumXVectorBarChart(state, name) {
    let count = 0;
    if (state.vectorBarchartDataToShow) {
      const vectors = state.vectorBarchartDataToShow.vectors[name];
      if (vectors) {
        Object.keys(vectors).forEach((key) => {
          count = count + vectors[key];
        });
      }
    }
    return count;
  }
  _sumYVectorBarChart(state, name) {
    let count = 0;
    if (state.vectorBarchartDataToShow) {
      Object.keys(state.vectorBarchartDataToShow.vectors).forEach((key) => {
        const vector = state.vectorBarchartDataToShow.vectors[key];
        if (vector[name] !== undefined) {
          count = count + vector[name];
        }
      });
    }
    return count;
  }
  _getXBarColor(state, name) {
    let color = "steelblue";
    if (
      state.vectorBarchartDataToShow &&
      state.vectorBarchartDataToShow.selectedPrediction === name
    ) {
      color = "red";
    }
    return color;
  }
  updateVectorBarCharts(state) {
    const xBarchartScale = this._getBarChartScale(state, "X");
    this.matSvg
      .selectAll(".xBarChart")
      .attr("height", (d) => {
        const sum = this._sumXVectorBarChart(state, d.name);
        const height = xBarchartScale(sum);
        return height;
      })
      .attr("fill", (d) => {
        return this._getXBarColor(state, d.name);
      });
    const yBarchartScale = this._getBarChartScale(state, "Y");
    this.matSvg
      .selectAll(".yBarChart")
      .attr("width", (d) => {
        const sum = this._sumYVectorBarChart(state, d.name);
        const width = yBarchartScale(sum);
        return width;
      })
      .attr("x", (d) => {
        const sum = this._sumYVectorBarChart(state, d.name);
        const width = yBarchartScale(sum);
        return -width;
      });
  }

  // view destroy method
  destroy = function (el) {
    // Any clean-up would go here
    // remove all the view
    d3.select(this.el).selectAll("*").remove();
    // remove d3-tip
    d3.select(".heatmap-matrix-tooltip").remove();
  };

  resize = function (el, config, state, controllerMethods, chart) {
    this.destroy();
    this.create(el, config, state, controllerMethods);
    this.update(el, config, state, controllerMethods, chart);
  };
}
export default D3HeatMapMatrix;
