import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

// components
import AppBar from "./AppBar";
import AppDrawer from "./AppDrawer";

const useStyles = makeStyles((theme) => {
  // Equals the toolbar height with the variant "dense"
  // It replaces the theme.mixins.toolbar
  const minHeight = 48;
  return {
    offset: { minHeight },
  };
});

function AppLayout() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar />
      <div className={classes.offset}></div>
      <AppDrawer />
    </React.Fragment>
  );
}

export default AppLayout;
