import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import ChooseDataset from "../components/Data/ChooseDataset";
import ImportDataset from "../components/Data/ImportDataset";
import FeaturesTable from "../components/Data/FeaturesTable";

import {
  sendCorrelation,
  getCorrelationParamObject,
} from "../features/analytic";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import DeleteDatasetConfirm from "../components/Data/dialog/DeleteDatasetConfirm";
import ImportUpdatedHeaders from "../components/Data/dialog/ImportUpdatedHeaders";

const useStyles = makeStyles((theme) => ({
  chooseDataset: {
    order: 1,
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  importDataset: {
    order: 2,
    [theme.breakpoints.up("sm")]: {
      order: 1,
    },
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
}));

function Data() {
  const classes = useStyles();
  const { openDeleteDatasetDialog, openImportHeadersDialog } = useSelector((state) => state.app);

  // use effect
  const dispatch = useDispatch();
  const { data, schema, currentDataset } = useSelector(
    (state) => state.dataset
  );
  const dataRef = useRef(data);
  useEffect(() => {
    if (dataRef.current !== data && data.length > 0 && schema.length > 0) {
      const corrParamObj = getCorrelationParamObject(data, schema);
      corrParamObj.currentDataset = currentDataset;
      dispatch(sendCorrelation(corrParamObj));
    }
    if (dataRef.current !== data) dataRef.current = data;
    // eslint-disable-next-line
  }, [data]);

  return (
    <Grid container>
      <Grid container direction="row" justifyContent="center" spacing={2}>
        <Grid item xs={12} md={6} className={classes.chooseDataset}>
          <ChooseDataset />
        </Grid>
        <Grid item xs={12} md={6} className={classes.importDataset}>
          <ImportDataset />
        </Grid>
      </Grid>
      <Grid container>
        <FeaturesTable />
      </Grid>

      {/* Dialogs */}
      <DeleteDatasetConfirm open={openDeleteDatasetDialog} />
      <ImportUpdatedHeaders open={openImportHeadersDialog} />
    </Grid>
  );
}

export default Data;
