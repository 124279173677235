import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// MUI components
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  TextField,
  withStyles,
} from "@material-ui/core";
// Lamda components
import StyledSelect from "../../styled/Select";

import { sendCluster } from "../../../features/analytic";
import { toggleColumns } from "../../../features/table";

import { searchIfExistAndUpdateIdentifier } from "../../../utils/character";
import SelectClusterVariables from "./columns-selection/SelectClusterVariables";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  maxWidth: {
    maxWidth: "300px",
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}));

// local components
function Row(props) {
  const { children, title, classes, ...other } = props; // eslint-disable-line
  return (
    <Grid item xs={12} md={10} lg={5} className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <InputLabel htmlFor={`${title}-label`}>{title}</InputLabel>
        </Grid>
        <Grid item xs={8}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
const StyledRow = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))(Row);


function AnalyzeTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // store
  const { schema, currentDataset } = useSelector((state) => state.dataset);
  const selectedColumnsChart = useSelector(
    (state) => state.chart.selectedColumns
  );
  const selectedColumnsTable = useSelector(
    (state) => state.table.selectedColumns
  );
  const { syncColumns } = useSelector((state) => state.table);
  const { loading } = useSelector((state) => state.analytic);

  // state
  // TODO: define the identifier automatically depending on parameters
  const defaultConfigObj = {
    method: "KMeans",
    centers: 10,
    identifier: null,
    schema: [],
    disabled: true,
  };

  defaultConfigObj.identifier = searchIfExistAndUpdateIdentifier(
    `${defaultConfigObj.method}_${defaultConfigObj.centers}`,
    schema
  );
  const [state, setState] = useState(defaultConfigObj);
  const [variables, setVariables] = useState([]);
  const [reset, setReset] = useState(false);

  const checkDisabled = () => {
    if (!variables)
      return false;
    return (
      variables.length === 0 ||
      state.centers === 0 ||
      state.identifier.length <= 3
    );
  };

  // events
  const onChange = (event) => {
    setState((state) => {
      const newState = { ...state, [event.target.name]: event.target.value };
      return newState;
    });
  };
  const onChangeNumber = (event) => {
    if (!event.target.value) return;

    // let disabled =
    //   variables.length === 0 ||
    //   newState.centers === 0 ||
    //   newState.identifier.length <= 3;
    // setState((state) => ({ ...newState, disabled }));
    setState((state) => {
      const newState = {
        ...state,
        [event.target.name]: parseFloat(event.target.value),
      };
      newState.identifier = searchIfExistAndUpdateIdentifier(
        `${defaultConfigObj.method}_${newState.centers}`,
        state.schema
      );
      return newState;
    });
  };
  const onIdentifierChange = (event) => {
    // do not store in state but computed dynamically by calling the checkDisabled
    // let disabled = checkDisabled(newState);
    // setState((state) => ({ ...newState, disabled }));
    setState((state) => {
      const newIdentifier = searchIfExistAndUpdateIdentifier(
        event.target.value,
        schema
      );

      const newState = {
        ...state,
        [event.target.name]: newIdentifier,
      };

      return newState;
    });
  };

  const onCalculate = async (event) => {
    // if (state.disabled) return;
    if (checkDisabled()) return;

    const syncColumns_ = syncColumns;

    await dispatch(
      sendCluster({
        method: state.method,
        identifier: state.identifier,
        // identifier: searchIfExistAndUpdateIdentifier(
        //   `${defaultConfigObj.method}_${defaultConfigObj.centers}`,
        //   schema
        // ),
        centers: state.centers,
        variables: variables,
        currentDataset,
        selectedColumns: {
          chart: selectedColumnsChart,
          table: selectedColumnsTable,
        },
      })
    )
      .then(() => dispatch(toggleColumns(syncColumns_))) // keep syncColumns as it was before
      .then(() => onClear(event)) // reset parameters with default ones
      .catch(console.log);
  };
  const onClear = (event) => {
    setReset(true); // clear the "SelectClusterVariables" component state
    setState((state) => {
      defaultConfigObj.identifier = searchIfExistAndUpdateIdentifier(
        `${defaultConfigObj.method}_${defaultConfigObj.centers}`,
        state.schema
      );
      return { ...defaultConfigObj, schema: state.schema };
    });
  };
  useEffect(() => {
    // do something when dependents items are updated
    setState((state) => {
      defaultConfigObj.identifier = searchIfExistAndUpdateIdentifier(
        `${state.method}_${state.centers}`,
        schema
      );
      return { ...defaultConfigObj, schema };
    });
    // cleanup
    return () => { }; // return a function for cleaning the component (equ to didUnmount)
    // eslint-disable-next-line
  }, [schema]); // list of dependent items coming from useSelector and useState

  // const mockeup = range(0, 41, 2);
  return (
    <Grid container direction="row" className={classes.root}>
      <StyledRow title="Method">
        <StyledSelect
          id="Method-label"
          name="method"
          variant="outlined"
          value={state.method}
          onChange={onChange}
          displayEmpty
          className={classes.marginRight}
        >
          <MenuItem key="kmeans" value="KMeans">
            K-Means
          </MenuItem>
          {/*<MenuItem key="kmeansNN" value="KMeansNN">*/}
          {/*  K-Means NN*/}
          {/*</MenuItem>*/}
        </StyledSelect>
      </StyledRow>
      <StyledRow title="Identifier">
        <TextField
          name="identifier"
          placeholder="Identifier"
          arial-label="identifier"
          color="primary"
          size="small"
          variant="outlined"
          onChange={onIdentifierChange}
          value={state.identifier}
        />
      </StyledRow>
      <StyledRow title="Cluster count">
        <TextField
          name="centers"
          placeholder="Cluster count (default: 14)"
          arial-label="cluster count"
          color="primary"
          size="small"
          variant="outlined"
          onChange={onChangeNumber}
          value={state.centers}
          type="number"
          InputProps={{
            inputProps: { min: 1, inputMode: "decimal", pattern: "[0-9]*" },
          }}
        />
      </StyledRow>

      <StyledRow title="Cluster variables">
        <SelectClusterVariables updateParentState={setVariables} reset={reset} setReset={setReset} />
      </StyledRow>
      <StyledRow title="Actions">
        <Button
          variant="outlined"
          color="primary"
          // disabled={state.disabled || loading}
          disabled={checkDisabled() || loading}
          onClick={onCalculate}
          className={classes.marginRight}
        >
          {loading ? <CircularProgress size={24} /> : "Calculate"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          disabled={loading}
          onClick={onClear}
        >
          Clear
        </Button>
      </StyledRow>
    </Grid>
  );
}

export default AnalyzeTab;
