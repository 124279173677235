import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import setup from "../../utils/setup";
import {
  sendDatasetFile,
  initData as initDataFunc,
  ID_KEY,
  fetchPublicDatasets,
  importPublicDataset,
} from "../../features/dataset";

import {
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  ButtonGroup,
  makeStyles,
  TextField,
  CircularProgress,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { Clear as ClearIcon } from "@material-ui/icons";

// Lamda components
import StyledSelect from "../styled/Select";

const { uploadMaxSize, nbMaxDatasets } = setup();
// const maxUploadSize=window.__env__.UPLOAD_MAX_SIZE;
function getUploadMaxSize(uploadMaxSizeStr) {
  return (Number.parseInt(uploadMaxSizeStr) / 10) * 10e6;
}
const useStyles = makeStyles((theme) => ({
  container: {
    height: "36px",
  },
  title: {
    textAlign: "left",
  },
  upload: {
    textAlign: "right",
    "& > label": {
      margin: 0,
    },
  },
  textfield: {
    width: "100%",
  },
  alertFontColor: {
    color: "red",
    // fontSize:"small",
  },
}));

export default function ImportDataset() {
  const CHOOSE_FILE = "Choose file";
  const classes = useStyles();
  const dispatch = useDispatch();

  // reference
  const didOnce = useRef(false);

  // state
  const [state, setState] = useState({
    name: CHOOSE_FILE,
    datasetName: "",
    datasetDescription: "",
    size: 0,
  });

  const [publicDatasetId, selectPublicDatasetId] = useState("");

  // store
  const { datasets, publicDatasets, loading } = useSelector(
    (state) => state.dataset
  );

  // use effects
  useEffect(() => {
    if (didOnce.current === false) {
      dispatch(fetchPublicDatasets());
      didOnce.current = true;
    }
    // eslint-disable-next-line
  }, [didOnce]);

  // events

  const onFileUpload = (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      // const limit = getUploadMaxSize(uploadMaxSize)
      // if(file.size<limit){
      setState({
        selectedFile: file,
        name: file.name,
        datasetName: file.name.replace(".csv", "").replace(/(-| )/, "_"),
        size: file.size,
      });
      // }else{
      //   // TODO: alert
      //   // TODO: disable send
      // }
    }
  };

  const onClear = () =>
    setState({
      name: CHOOSE_FILE,
      selectedFile: null,
      datasetName: "",
      datasetDescription: "",
      size: 0,
    });

  const onChange = (event) =>
    setState({ ...state, [event.target.name]: event.target.value });

  const onPublicDatasetChange = (event) => {
    selectPublicDatasetId(event.target.value);
  };

  const onSend = async (event) => {
    await dispatch(
      sendDatasetFile(
        state.selectedFile,
        state.datasetName,
        state.datasetDescription
      )
    );
    await dispatch(initDataFunc(false));
    onClear();
  };

  const onImport = async (event) => {
    dispatch(importPublicDataset({ datasetId: publicDatasetId }));
    selectPublicDatasetId("");
  };

  // internal methods

  function checkSendEnabled() {
    if (!state.selectedFile || !state.datasetName) return false;

    return checkSizeLimit() && checkNbDatasetLimit();
  }
  function checkSizeLimit() {
    const limit = getUploadMaxSize(uploadMaxSize);
    return state.size < limit;
  }
  function checkNbDatasetLimit() {
    return datasets.length < nbMaxDatasets;
  }

  return (
    <Card>
      <CardContent>
        <Grid container alignItems="center" className={classes.container}>
          <Grid item xs={4} className={classes.title}>
            Import a new dataset (csv file):
          </Grid>
          <Grid item xs={8} className={classes.upload}>
            <input
              type="file"
              accept=".csv"
              id="import-dataset"
              value=""
              onChange={onFileUpload}
              style={{ display: "none" }}
              disabled={!checkNbDatasetLimit()}
            />
            <label htmlFor="import-dataset">
              <ButtonGroup size="medium">
                <Button
                  variant="outlined"
                  component="span"
                  disabled={!checkNbDatasetLimit()}
                >
                  {state.name === CHOOSE_FILE
                    ? CHOOSE_FILE + " (<" + uploadMaxSize + "M)"
                    : state.name}
                </Button>
                <Button
                  variant="outlined"
                  onClick={onClear}
                  disabled={state.name === CHOOSE_FILE}
                >
                  <ClearIcon />
                </Button>
              </ButtonGroup>
            </label>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={5} className={classes.textfield}>
            <TextField
              name="datasetName"
              type="text"
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Dataset name"
              value={state.datasetName}
              onChange={onChange}
              disabled={!checkNbDatasetLimit()}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textfield}>
            <TextField
              name="datasetDescription"
              type="text"
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Dataset description"
              value={state.datasetDescription}
              onChange={onChange}
              disabled={!checkNbDatasetLimit()}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={2} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              fullWidth
              onClick={onSend}
              disabled={!checkSendEnabled()}
            >
              {loading ? <CircularProgress size={24} /> : "Send"}
            </Button>
          </Grid>

          {!checkSizeLimit() && (
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <span className={classes.alertFontColor}>
                  File size {`> `}
                  {uploadMaxSize}M
                </span>
              </Grid>
            </Grid>
          )}
          {!checkNbDatasetLimit() && (
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <span className={classes.alertFontColor}>
                  Maximum datasets reached (={nbMaxDatasets})
                </span>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} md={10}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography style={{ marginLeft: "4px" }}>
                  Select a public dataset to import
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <StyledSelect
                  variant="outlined"
                  displayEmpty
                  fullWidth
                  value={publicDatasetId}
                  disabled={publicDatasets.length === 0}
                  onChange={onPublicDatasetChange}
                >
                  <MenuItem value="" dense>
                    <em>None</em>
                  </MenuItem>
                  {publicDatasets.map((dset) => (
                    <MenuItem
                      key={dset[ID_KEY]}
                      value={"" + dset[ID_KEY]}
                      dense
                    >
                      {dset.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={onImport}
              disabled={publicDatasetId === ""}
            >
              Import
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
