/**
 * Sort an array of object based on another array
 * @param {Array<Object>} source The array to sort
 * @param {Array<Object>} target The list of ordered properties
 * @param {string} key The property name on which the array will be sort
 * @returns An array
 *
 * Modifications:
 * - push doesn't found index at the end of the array, nor at the beginning
 */
export function sort(source = [], target = [], key = null) {
  if (key === null) throw new Error(`Missing parameter "key" at position[2]`);
  if (source.length === 0) return [];
  return source.sort(
    (a, b) =>
      validateIndex(target.indexOf(a[key])) -
      validateIndex(target.indexOf(b[key]))
  );
}

function validateIndex(index) {
  if (index === -1) return Number.MAX_SAFE_INTEGER;
  return index;
}

/**
 * The range() function returns a sequence of numbers, starting from 0 by default, and increments by 1 (by default), and stops before a specified number.
 *
 * Based on the python range function, source: https://www.w3schools.com/python/ref_func_range.asp
 *
 * @param {*} start Optional. An integer number specifying at which position to start. Default is 0
 * @param {*} stop  Required. An integer number specifying at which position to stop (not included).
 * @param {*} step  Optional. An integer number specifying the incrementation. Default is 1
 * @returns An array containing a sequence of numbers
 */
export function range(start = 0, stop, step = 1) {
  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) return [];

  const result = [];

  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }
  return result;
}
